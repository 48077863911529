import React from 'react';
import Menu from '@material-ui/core/Menu';
import ColorPicker from '../ColorPicker/ColorPicker';

class ColorPickerMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
      anchorEl: null,
    };

    this.toggleColorPicker = this.toggleColorPicker.bind(this);
  }

  toggleColorPicker(e, bool, fillColor) {
    this.setState({
      anchorEl: e.currentTarget,
      showMenu: bool
    });

    if(!bool && (typeof fillColor === 'string' || fillColor instanceof String)) {
      this.props.setFillColor(fillColor);
    }
  }

  render() {
    return (
      <div>
        <div
          onClick={(e) => this.toggleColorPicker(e, true)}
          style={{ cursor: 'pointer', height: '20px' }}
        >
          <svg height="20px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <rect x="1" y="1" fill={this.props.squareColor} stroke="#959595" strokeWidth="2" width="18" height="18" rx="2" />
            {this.props.squareColor === 'transparent' ?
              <line x1="1" y1="1" x2="19" y2="19" stroke="#ff0000" strokeWidth="2" />
              : null
            }
          </svg>
        </div>
        {this.state.showMenu ?
          <Menu
            id="simple-menu"
            anchorEl={this.state.anchorEl}
            keepMounted
            open={this.state.showMenu}
            onClose={this.toggleColorPicker.bind(this, { currentTarget: null }, false)}
          >
            <div className="legendOptsMenuDiv">
              <ColorPicker
                handleCellClick={this.toggleColorPicker.bind(this, { currentTarget: null }, false)}
                addTransparent={this.props.addTransparent}
                bluesOnly={this.props.bluesOnly}
              />
            </div>
          </Menu>
          : null
        }
      </div>
    );
  }
}

export default ColorPickerMenu;