import React from 'react';
import CloseIcon from '@material-ui/icons/Close';

class DeleteShapes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      prevPoints: this.props.points
    }

    this.closePanel = this.closePanel.bind(this);
    this.deleteShapes = this.deleteShapes.bind(this);
  }

  closePanel() {
    this.props.toggleDeleteShapes();
  }

  deleteShapes() {
    const newLegend = this.props.shapeLegend;
    const shapes = this.props.shapes;
    let currentShapeLayerId = this.props.currentShapeLayerId;
    const delLayerId = this.props.shapeLegend[this.props.shapeLayerIndex].layerId;
    const delStyle = this.props.shapeIndex ? this.props.shapeLegend[this.props.shapeLayerIndex].styles[this.props.shapeIndex] : null;

    const newShapes = shapes.filter(s=>{
        return this.props.shapeIndex ?!(s.layerId===delLayerId&&s.fill===delStyle.fill&&s.type.toLowerCase()===delStyle.type.toLowerCase()
                &&s.stroke===delStyle.stroke&&s.opacity===delStyle.opacity)
                : s.layerId!==delLayerId;
    });

    if(this.props.shapeIndex){
        const newStyles = newLegend[this.props.shapeLayerIndex].styles;
        newStyles.splice(this.props.shapeIndex,1);
        newLegend[this.props.shapeLayerIndex].styles = newStyles;
    } 
    else {
        if(newLegend.length>1){
            newLegend.splice(this.props.shapeLayerIndex,1);
            if(this.props.currentShapeLayerId===delLayerId){
                currentShapeLayerId = newLegend[newLegend.length-1].layerId;
            }
        }
        else{
            newLegend[0].styles=[];
        }
    }

    this.props.updateMapObj({shapes:newShapes,shapeLegend:newLegend,currentShapeLayer:currentShapeLayerId});
    this.closePanel();
  }
  
  render() {
    return (
      <div>
        <h2 className="panel-title">
          <span className="panel-icon-span">
            <svg id="squaresIconSmall" xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 94.5 94.5">
              <path id="Path_4187" stroke="#0c9ed9" fill="none" strokeWidth="4" d="M24.29-4.79v-31.3h-60v60h31.3" transform="translate(36.96 37.34)" />
              <path id="Path_4188" stroke="#0c9ed9" fill="none" strokeWidth="4" d="M-4.65,23.67V55.91H56.29V-5H24.05" transform="translate(36.96 37.34)" />
              <path id="Path_4189" stroke="#0c9ed9" fill="none" strokeWidth="4" d="M3.5,23.76h-8v-8" transform="translate(36.96 37.34)" />
              <path id="Path_4191" stroke="#0c9ed9" fill="none" strokeWidth="4" d="M-4.5,3.12v-8h8" transform="translate(36.96 37.34)" />
              <path id="Path_4193" stroke="#0c9ed9" fill="none" strokeWidth="4" d="M16.14-4.88h8v8" transform="translate(36.96 37.34)" />
              <path id="Path_4195" stroke="#0c9ed9" fill="none" strokeWidth="4" d="M24.14,15.76v8h-8" transform="translate(36.96 37.34)" />
            </svg>
          </span>
          <span>Delete Shapes</span>
          <span className="panel-close closeStyle"><CloseIcon onClick={this.closePanel} fontSize="small" /></span>
        </h2>
        <div className="panel-body">
          Are you sure?
          <div className="geocodeDiv flex">
            <button className="geocodeBtn" onClick={this.closePanel}>Cancel</button>
            <button className="geocodeBtn" onClick={this.deleteShapes}>Delete</button>
          </div>
        </div>
      </div>
    );
  }
}

export default DeleteShapes;