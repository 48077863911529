import React, { useState } from 'react'

import TextField from '@material-ui/core/TextField';
import translateObj from '../../../../utils/translate';
import CloseIcon from '@material-ui/icons/Close';

const nameDiv = {
    display: 'inline-flex',
    alignItems: 'center',
    padding: '3px 0px'
  }
  
  const labelSpan = {
    paddingRight: '8px'
  }
  

class EditAddedPointAttrs extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        pointName: this.props.currentPoint.name,
        pointAddress: this.props.currentPoint.address,
        pointCity: this.props.currentPoint.city,
        pointState: this.props.currentPoint.state,
        pointZip: this.props.currentPoint.zip,
        pointLegenddata: this.props.currentPoint.legenddata,
        pointLabel: this.props.currentPoint.label,
        id: this.props.currentPoint.id
      };
  
      this.savePointInfo = this.savePointInfo.bind(this);
      this.closePanel = this.closePanel.bind(this);
    }
  
    closePanel() {
        this.props.togglePointAttribute(false);
      }
    
      updatePointAttribute(key, value) {
        this.setState({
          [key]: value
        });
      }
    
      savePointInfo() {
        this.props.editPoint(this.state);
        this.props.togglePointAttribute(false);
      }

render() {
    return (
      <div>
        <h2 className="panel-title">
          <span className="panel-icon-span">
            <svg id="editAttributesIcon" xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 0 83.63 94.5">
              <path id="Path_3747" d="M37.69-21.35A7.28,7.28,0,0,0,45-28.63a7.28,7.28,0,0,0-7.28-7.28,7.28,7.28,0,0,0-7.28,7.28,7.28,7.28,0,0,0,7.28,7.28Z" transform="translate(32.02 37.28)" fill="none" stroke="#0c9ed9" strokeWidth="4" />
              <path id="Path_3748" d="M11.66,56a7.28,7.28,0,1,0-7.28-7.28A7.28,7.28,0,0,0,11.66,56Z" transform="translate(32.02 37.28)" fill="none" stroke="#0c9ed9" strokeWidth="4" />
              <path id="Path_3749" d="M8.49,2.09,32.68-23.54" transform="translate(32.02 37.28)" fill="none" stroke="#0c9ed9" strokeWidth="4" />
              <path id="Path_3750" d="M4.08,18.92l5.8,22.79" transform="translate(32.02 37.28)" fill="none" stroke="#0c9ed9" strokeWidth="4" />
              <path id="Path_3751" d="M45.38,39.22a5,5,0,1,0-5-5,5,5,0,0,0,5,5Z" transform="translate(32.02 37.28)" fill="none" stroke="#0c9ed9" strokeWidth="4" />
              <path id="Path_3752" d="M-25.78,30.17a5,5,0,0,0,5-5,5,5,0,0,0-5-5,5,5,0,0,0-5,5,5,5,0,0,0,5,5Z" transform="translate(32.02 37.28)" fill="none" stroke="#0c9ed9" strokeWidth="4" />
              <path id="Path_3753" d="M-20-26a5,5,0,0,0,5-5,5,5,0,0,0-5-5,5,5,0,0,0-5,5,5,5,0,0,0,5,5Z" transform="translate(32.02 37.28)" fill="none" stroke="#0c9ed9" strokeWidth="4" />
              <path id="Path_3756" d="M-17.75-26.46-3.11.5" transform="translate(32.02 37.28)" fill="none" stroke="#0c9ed9" strokeWidth="4" />
              <path id="Path_3759" d="M23.7,22l-13.39-7.8" transform="translate(32.02 37.28)" fill="none" stroke="#0c9ed9" strokeWidth="4" />
              <path id="Path_3761" d="M-21.94,22.81l14.74-8.6" transform="translate(32.02 37.28)" fill="none" stroke="#0c9ed9" strokeWidth="4" />
              <path id="Path_3762" d="M1.62,19.33A10.09,10.09,0,1,0-8.47,9.25,10.08,10.08,0,0,0,1.62,19.33Z" transform="translate(32.02 37.28)" fill="none" stroke="#0c9ed9" strokeWidth="4" />
            </svg>
          </span>
          <span>{translateObj.editPointDataTitle[this.props.translate]}</span>
          <span className="panel-close closeStyle"><CloseIcon onClick={this.closePanel} fontSize="small" /></span>
        </h2>
        <div className="panel-body">
          <div>
            <div style={nameDiv}>
              <div>
                <span style={labelSpan}>Name:</span>
              </div>
              <TextField
                id="pointNameInput"
                defaultValue={this.state.pointName}
                variant="outlined"
                size="small"
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    this.updatePointAttribute('pointName', e.target.value)
                  }
                }}
                onBlur={(e) => {
                  this.updatePointAttribute('pointName', e.target.value)
                }}
              />
            </div>
            <div style={nameDiv}>
              <div>
                <span style={labelSpan}>Address:</span>
              </div>
              <TextField
                id="pointAddressInput"
                defaultValue={this.state.pointAddress}
                variant="outlined"
                size="small"
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    this.updatePointAttribute('pointAddress', e.target.value)
                  }
                }}
                onBlur={(e) => {
                  this.updatePointAttribute('pointAddress', e.target.value)
                }}
              />
            </div>
            <div style={nameDiv}>
              <div>
                <span style={labelSpan}>City:</span>
              </div>
              <TextField
                id="pointCityInput"
                defaultValue={this.state.pointCity}
                variant="outlined"
                size="small"
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    this.updatePointAttribute('pointCity', e.target.value)
                  }
                }}
                onBlur={(e) => {
                  this.updatePointAttribute('pointCity', e.target.value)
                }}
              />
            </div>
            <div style={nameDiv}>
              <div>
                <span style={labelSpan}>{this.props.secyGeoName + ':'}</span>
              </div>
              <TextField
                id="pointStateInput"
                defaultValue={this.state.pointState}
                variant="outlined"
                size="small"
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    this.updatePointAttribute('pointState', e.target.value)
                  }
                }}
                onBlur={(e) => {
                  this.updatePointAttribute('pointState', e.target.value)
                }}
              />
            </div>
            <div style={nameDiv}>
              <div>
                <span style={labelSpan}>ZIP:</span>
              </div>
              <TextField
                id="pointZIPInput"
                defaultValue={this.state.pointZip}
                variant="outlined"
                size="small"
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    this.updatePointAttribute('pointZip', e.target.value)
                  }
                }}
                onBlur={(e) => {
                  this.updatePointAttribute('pointZip', e.target.value)
                }}
              />
            </div>
            <div style={nameDiv}>
              <div>
                <span style={labelSpan}>Legend Data:</span>
              </div>
              <TextField
                id="pointLegenddataInput"
                defaultValue={this.state.pointLegenddata}
                variant="outlined"
                size="small"
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    this.updatePointAttribute('pointLegenddata', e.target.value)
                  }
                }}
                onBlur={(e) => {
                  this.updatePointAttribute('pointLegenddata', e.target.value)
                }}
              />
            </div>
            <div style={nameDiv}>
              <div>
                <span style={labelSpan}>Label:</span>
              </div>
              <TextField
                id="pointLabelInput"
                defaultValue={this.state.pointLabel}
                variant="outlined"
                size="small"
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    this.updatePointAttribute('pointLabel', e.target.value)
                  }
                }}
                onBlur={(e) => {
                  this.updatePointAttribute('pointLabel', e.target.value)
                }}
              />
            </div>
          </div>
          <div className="geocodeDiv flex">
            <button className="geocodeBtn" onClick={this.closePanel}>{translateObj.cancelBtn[this.props.translate]}</button>
            <button className="geocodeBtn" onClick={this.savePointInfo}>{translateObj.saveBtn[this.props.translate]}</button>
          </div>
        </div>
      </div>
    );
  }
}
export default EditAddedPointAttrs