import React, { Component } from 'react';
import SVGIconComponent from '../../MainPage/SVGIconComponent/SVGIconComponent';

export default class ReleaseNotes3Pt2 extends Component {
  render() {
    return (
    <div>
        <div className="updateMenuBody">


            <div className="updateMenuFeatureName">
                <div className="updateMenuFeatureSVG">
                    <SVGIconComponent name="boomFavIcon" size={12} fill={'#25408f'} />
                </div>
                <div className="updateMenuFeatureLabel">Shape Legend</div>
            </div>
            <div className="updateMenuFeatureDesc">
                A legend can be added for drawn shapes, or shapes imported from geojson.  
                <br/>Click on the "Legend" button on the left panel to customize.  
                <br/>Legend entries are based on unique shape colors.  "Shape Layers" can be added.
                <br/>Styles can be reorder or moved to layers by dragging the style.
            </div>

            <div className="updateMenuFeatureName">
                <div className="updateMenuFeatureSVG">
                    <SVGIconComponent name="boomFavIcon" size={12} fill={'#25408f'} />
                </div>
                <div className="updateMenuFeatureLabel">Standard Geography Overlays</div>
            </div>
            <div className="updateMenuFeatureDesc">
                Counties, Places and Zip Codes can be added as overlays by using "Add Data" and "Add Standard Geographies".
            </div>
            <div className="updateMenuFeatureName">
                <div className="updateMenuFeatureSVG">
                    <SVGIconComponent name="boomFavIcon" size={12} fill={'#25408f'} />
                </div>
                <div className="updateMenuFeatureLabel">More Submarket (and Standard Geography) Options</div>
            </div>
            <div className="updateMenuFeatureDesc">
                Add 7 individual thematic colors by checking "Display Random Colors".
                <br/>Selected polygons can be 'Highlighted' as a different color from the other polygons.
                <br/>More than one polygon may be selected for individual dispaly or highlighting.
            </div>

            <div className="updateMenuFeatureName">
                <div className="updateMenuFeatureSVG">
                    <SVGIconComponent name="boomFavIcon" size={12} fill={'#25408f'} />
                </div>
                <div className="updateMenuFeatureLabel">Display Colliers Offices</div>
            </div>
            <div className="updateMenuFeatureDesc">
                Display Colliers Offices can be displayed under "Add Data".
                <br/>You can use the Colliers logo, or regular point styling.
            </div>

            {/* <div className="updateMenuFeatureName">
                <div className="updateMenuFeatureSVG">
                    <SVGIconComponent name="boomFavIcon" size={12} fill={'#25408f'} />
                </div>
                <div className="updateMenuFeatureLabel">Edit Point or Add Ring/Drivetime from "Click on Map" or "Type Address"</div>
            </div>
            <div className="updateMenuFeatureDesc">You can edit point attributes</div> */}



        </div>
    </div>
    );
  }
}
