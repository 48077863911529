import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import SVGIconComponent from '../../SVGIconComponent/SVGIconComponent';
import Select from '@material-ui/core/Select';
import axios from 'axios';
import { TextField } from '@material-ui/core';
import { debounce } from 'lodash';
import SelectedRetailerRow from './SelectedRetailerRow';
import { GetIconOffset, ResizeLogo } from '../../../utils/tools';
import LogoSizeMenu from './LogoSizeMenu';
import SearchLogoAreaMenu from './SearchLogoAreaMenu';
import LogoCheckListPanel from './LogoCheckListPanel';
import RetailLineStyle from './RetailLineStyle';

class RetailFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      retailerList: [],
      categoryList: [
        {id: 1, category: "Auto Service, Parts, + Tires"},
        {id: 2, category: "Banks + Financial Services"},
        {id: 3, category:"Big Box"},
        {id: 4, category: "Clothing + Apparel"},
        {id: 5, category: "Discount Stores"},
        {id: 6, category: "Drug Stores + Pharmacies"},
        {id: 7, category: "Gas Stations"},
        {id: 8, category: "Grocery"},
        {id: 9, category: "Gyms + Fitness Facilities"},
        {id: 10, category: "Hotels"},
        {id: 11, category: "Pet Stores + Supplies"},
        {id: 12, category: "Restaurant"},
        {id: 13, category: "Restaurant - QSR/Fast Food"},
        {id: 14, category: "Theatres"}],
      retailerLoading: false,
      categoryLoading: false,
      currentCategory: 'None',
      prevMapBounds: this.props.mapObj.mapBounds,
      retailerSearchVal: '',
      retailerSuggest: [],
      isError: false,
      gettingRetailers: false,
      gettingLogos: false,
      gettingSearchAreaLogos: false,
      showSearchAreaLogoDiv: false,
      searchAreaList: [],
      errorType: 'generic',
      tooManyPoints: null,
      allRetailers: null,
      noLogoRetailers: null,
      logoRetailers: null
    }

    this.closePanel = this.closePanel.bind(this);
    this.backToAddData = this.backToAddData.bind(this);
    this.getLogos = this.getLogos.bind(this);
    this.onChangeDebounced = debounce(this.onChangeDebounced, 1200);
    this.selectCategory = this.selectCategory.bind(this);
    this.setSelectedRetailer = this.setSelectedRetailer.bind(this);
    this.removeRetailer = this.removeRetailer.bind(this);
    this.clearLogos = this.clearLogos.bind(this);
    this.clearRetailList = this.clearRetailList.bind(this);
    this.refreshRetailList = this.refreshRetailList.bind(this);
    this.updateLogoState = this.updateLogoState.bind(this);
    this.backToMainLogoPanel = this.backToMainLogoPanel.bind(this);
    this.doneWithLogoList = this.doneWithLogoList.bind(this);
    this.setOrRemoveAllRetailersFromList = this.setOrRemoveAllRetailersFromList.bind(this);
    this.toggleRetailerLeaderLine = this.toggleRetailerLeaderLine.bind(this);
    this.toggleRetailerLeaderLineAnchor = this.toggleRetailerLeaderLineAnchor.bind(this);
    this.changeLeaderLineColor = this.changeLeaderLineColor.bind(this);
    this.changeLeaderLineWidth = this.changeLeaderLineWidth.bind(this);
    this.changeLeaderLineOpacity = this.changeLeaderLineOpacity.bind(this);
  }

  componentDidMount() {
    this.props.updateMapObj({ isSaving: true });
    this.setState({ gettingRetailers: true });
    this.callAllRetailers();
  }

  componentDidUpdate() {
    if (this.state.prevMapBounds !== this.props.mapObj.mapBounds) {
      if (this.state.gettingRetailers) {
        this.getRetailers();
      }
      if (this.state.gettingLogos) {
        this._getLogos();
      }
      this.setState({
        prevMapBounds: this.props.mapObj.mapBounds
      });
    }
  }

  closePanel() {
    this.props.togglePanel("addData");
  }

  backToAddData() {
    this.props.toggleRetailFilter(true, false);
  }

  async getRetailers() {
    this.props.updateIsSaving('isSaving', true);
    const sendObj = {
      mapbounds: this.props.mapObj.mapBounds
    }

    this.setState({
      retailerLoading: true,
      isError: false,
      gettingRetailers: false
    });

    axios.post(process.env.REACT_APP_APIURL + "/retailers", sendObj, { headers: this.props.headers })
      .then((result) => {
        this._setSelectedRetailerArray(result.data);
        this._setSelectedCategoryArray();
        this.setState({
          retailerLoading: false,
          retailerList: result.data
        });
        this.props.updateIsSaving('isSaving', false);
        this.props.updateMapObj({ isSaving: false });
      }).catch(error => {
        console.log(error);
        this.setState({
          retailerLoading: false,
          retailerList: [],
          isError: true,
          errorType: 'generic'
        });
        this.props.updateIsSaving('isSaving', false);
        this.props.updateMapObj({ isSaving: false });
      });
  }

  _setSelectedRetailerArray(results) {
    if (this.props.mapObj.selectedRetailer.retailers) {
      const selectedRetailerClone = Array.from(this.props.mapObj.selectedRetailer.retailers);
      const resultsClone = Array.from(results);
      if (selectedRetailerClone.length > 0) {
        let formattedArray = resultsClone.filter(retailer => selectedRetailerClone.includes(parseInt(retailer.chainid)));
        this.props.updateMapObj({ selectedRetailer: formattedArray });
      }
    }
    return;
  }

  _setSelectedCategoryArray() {
    if (this.props.mapObj.selectedCategory.categories) {
      const selectedCategoryClone = Array.from(this.props.mapObj.selectedCategory.categories);
      if (selectedCategoryClone.length > 0) {
        this.props.updateMapObj({ selectedCategory: selectedCategoryClone });
      }
    }
    return;
  }

  setRetailerSearch(e) {
    this.setState({ isError: false });
    const searchVal = e.target.value;
    this.setState({
      retailerSearchVal: searchVal
    });
    this.onChangeDebounced(searchVal);
  }

  onChangeDebounced = (searchVal) => {
    let resultsList = [];
    if (searchVal !== '' && searchVal !== " ") {
      for (const retailer of this.state.retailerList) {
        if (retailer.chainname.toLowerCase().startsWith(searchVal.toLowerCase()) || retailer.chainname.toLowerCase().startsWith('the ' + searchVal.toLowerCase())) {
          resultsList.push(retailer);
        }
      }
    } else {
      
    }

    this.setState({
      retailerSuggest: resultsList
    });
  }

  selectCategory(event) {
    this.setState({ isError: false });
    const val = event.target.value;
    const catName = val.split("_");

    let selectedCategoriesNew = Array.from(this.props.mapObj.selectedCategory);
    let add = true;

    if (val === "None") {
      add = false;
    } else {
      for (const category of selectedCategoriesNew) { //don't add dupes
        if (catName[0] === category) {
          add = false;
          break;
        }
      }
    }

    if (add) {
      selectedCategoriesNew.push(catName[0]);
    }
    this.setState({
      currentCategory: val
    });
    this.props.updateMapObj({ selectedCategory: selectedCategoriesNew });
  }

  setSelectedRetailer(e, suggestion) {
    this.setState({ isError: false });
    let selectedRetailersNew = Array.from(this.props.mapObj.selectedRetailer);
    let add = true;
    for (const retailer of selectedRetailersNew) { //don't add dupes
      if (suggestion.chainname === retailer.chainname) {
        add = false;
        break;
      }
    }

    if (add) { //add and sort
      selectedRetailersNew.push(suggestion);
      selectedRetailersNew.sort((a, b) => (a.chainname > b.chainname) ? 1 : ((b.chainname > a.chainname) ? -1 : 0));
    }

    this.props.updateMapObj({
      selectedRetailer: selectedRetailersNew
    });
  }

  removeRetailer(id, name, removeType) {
    let newStateObj = {
      isError: false
    };
    if (removeType === "category") {
      newStateObj.currentCategory = "None";
    }
    this.setState(newStateObj);
    let selectedArrayClone = removeType === "category" ? Array.from(this.props.mapObj.selectedCategory) : Array.from(this.props.mapObj.selectedRetailer);
    let objectProp = removeType === "category" ? "selectedCategory" : "selectedRetailer";
    if (removeType === 'retailer') {
      selectedArrayClone = selectedArrayClone.filter(retailer => retailer.chainid !== id);
    } else {
      selectedArrayClone = selectedArrayClone.filter(category => category !== name);
    }

    let updateObject = {
      [objectProp]: selectedArrayClone
    }
    this.props.updateMapObj(updateObject);
  }

  setOrRemoveAllRetailersFromList(add) {
    let formattedSearchAreaList = [];
    let selectedRetailerClone = this.props.mapObj.selectedRetailer;
    let finalRetailerArray = [];
    for (const retailer of this.state.searchAreaList) {
      const suggestion = {
        categories: "",
        chainid: retailer.chainid,
        chainname: retailer.chainname,
        naics: "000000",
        primarycategory: "",
        sic: "0000"
      };
      formattedSearchAreaList.push(suggestion);
    }

    if (add) {
      selectedRetailerClone = selectedRetailerClone.concat(formattedSearchAreaList);
      finalRetailerArray = [...new Map(selectedRetailerClone.map(item => [item.chainid, item])).values()];
    } else {
      //remove only retailers that are in our search area list
      //TODO: can this be more efficient?
      finalRetailerArray = selectedRetailerClone.filter(ar => !formattedSearchAreaList.find(rm => (rm.chainid === ar.chainid) ));
    }

    finalRetailerArray.sort((a, b) => (a.chainname > b.chainname) ? 1 : ((b.chainname > a.chainname) ? -1 : 0));
    this.props.updateMapObj({ selectedRetailer: finalRetailerArray });
  }

  getLogos() {
    this.props.updateMapObj({ isSaving: true });
    this.setState({ gettingLogos: true });
  }

  async _getLogos() {
    const retailerIds = this._getRetailerIds();
    const categoryNames = this.props.mapObj.selectedCategory;

    this.props.updateIsSaving('isSaving', true);
    this.setState({ retailerLoading: true, isError: false, gettingLogos: false });
    const sendObj = {
      mapbounds: this.props.mapObj.mapBounds,
      retailerids: retailerIds,
      category: categoryNames,
      logotype: "fullcolor",
      mapid: this.props.mapObj.mapId
    }

    await axios.post(process.env.REACT_APP_APIURL + "/retailPoints", sendObj, { headers: this.props.headers })
      .then((result) => {
        this.props.updateIsSaving('isSaving', false);
        if((result.data.message || '').startsWith("Too many points")){
          console.log(result.data.message);
          const message = result.data.message.replace("Too many points ","") + " points returned.  The limit is 200";
          //tooManyPoints
          this.props.updateMapObj({
            isSaving: false
          });
          this.setState({ retailerLoading: false, isError: true, errorType: 'toomany', tooManyPoints: message});
        }
        else{
          this.props.updateMapObj({
            retailPoints: this._getIconOffset(result.data),
            isSaving: false,
            retailerBounds: this.props.mapObj.mapBounds
          });
        }

        this.setState({ retailerLoading: false });
      }).catch(error => {
        console.log(error);
        this.props.updateIsSaving('isSaving', false);
        this.props.updateMapObj({
          isSaving: false
        });
        this.setState({ retailerLoading: false, isError: true, errorType: 'generic' });
      });
  }

  _getRetailerIds() {
    const selectedRetailerClone = Array.from(this.props.mapObj.selectedRetailer);
    let retailerIdsArray = [];

    for (const retailer of selectedRetailerClone) {
      retailerIdsArray.push(parseInt(retailer.chainid));
    }

    return retailerIdsArray;
  }

  _getIconOffset(retailPts) {
    let formattedArray = [];
    //resize
    let retailPtsClone = Array.from(retailPts);
    for (const retPt of retailPtsClone) {
      retPt.fullcolorsvg = ResizeLogo(retPt.fullcolorsvg, this.props.mapObj.logoSize);
      // retPt.originallatitude = retPt.latitude;
      // retPt.originallongitude = retPt.longitude;

    }
    //icon offset
    for (const point of retailPtsClone) {
      point.iconOffset = GetIconOffset(point.fullcolorsvg, 'logo');
      formattedArray.push(point);
    }
    return formattedArray;
  }

  clearLogos() {
    this.setState({ isError: false, currentCategory: 'None' });
    this.props.updateMapObj({ retailPoints: [], selectedCategory: [], selectedRetailer: [], retailerBounds: null });
  }

  clearRetailList() {
    this.setRetailerSearch({target: {value: ''}});
  }

  refreshRetailList() {
    this.setRetailerSearch({target: {value: ''}});
    this.props.updateMapObj({ isSaving: true });
    this.setState({ gettingRetailers: true });
  }

  updateLogoState(updateObj) {
    this.setState(updateObj);
  }

  toggleRetailerLeaderLine(bool) {
    this.props.updateMapObj({ retailerLeaderLine: bool });
  }

  toggleRetailerLeaderLineAnchor(bool) {
    this.props.updateMapObj({ retailerLeaderLineAnchor: bool });
  }


  changeLeaderLineColor(type, newStyle) {
    this.props.updateMapObj({ retailerLeaderColor: newStyle });
  }

  changeLeaderLineWidth(value) {
    this.props.updateMapObj({ retailerLeaderWidth: value });
  }

  changeLeaderLineOpacity(value) {
    this.props.updateMapObj({ retailerLeaderOpacity: value });
  }

  backToMainLogoPanel() {
    this.setState({ showSearchAreaLogoDiv: false });
  }

  doneWithLogoList() {
    this.setState({ showSearchAreaLogoDiv: false });
  }



  setAllRetailerArrays(results) {
    //const names = results.map(r => r.chainname);
    //this.state.allRetailers = results;
    const logos = results.filter(r => r.haslogo === "true");
    const nologos =  results.filter(r => r.haslogo === "false" && r.count > 9);
    this.setState({
      allRetailers: results,
      noLogoRetailers: nologos,
      logoRetailers: logos
    });
  }


  callAllRetailers() {
    if (this.state.allRetailers === null){
      axios.get(process.env.REACT_APP_APIURL + "/all_retailers?country=" + this.props.mapObj.country , { headers: this.props.headers })
      .then((result) => {
        this.setAllRetailerArrays(result.data);
      }).catch(error => {
        console.log(error);
      });
    }
  }


  render() {
    return (
      <div>
        <h2 className="panel-title">
          <span className="panel-icon-span">
            <SVGIconComponent name={'retailFilterIcon'} color={'#0c9ed9'} size={'16px'} />
          </span>
          Add Retail Logos
          <span className="panel-close closeStyle"><CloseIcon onClick={this.closePanel} fontSize="small" /></span>
        </h2>
        <div className="panel-body">
          {
            this.state.retailerLoading || this.state.categoryLoading ?
              <div className="panel-loader"></div>
              :
              <div>
                {this.state.showSearchAreaLogoDiv ?
                  <LogoCheckListPanel
                    searchAreaList={this.state.searchAreaList}
                    selectedRetailer={this.props.mapObj.selectedRetailer}
                    removeRetailer={this.removeRetailer}
                    setSelectedRetailer={this.setSelectedRetailer}
                    setOrRemoveAllRetailersFromList={this.setOrRemoveAllRetailersFromList}
                  />
                  :
                  <div className="paddingTB10">
                    <LogoSizeMenu
                      mapObj={this.props.mapObj}
                      updateMapObj={this.props.updateMapObj}
                      headers={this.props.headers}
                      allRetailers={this.state.allRetailers}
                      noLogoRetailers={this.state.noLogoRetailers}
                      logoRetailers={this.state.logoRetailers}
                    />
                    <SearchLogoAreaMenu
                      mapObj={this.props.mapObj}
                      updateMapObj={this.props.updateMapObj}
                      updateLogoState={this.updateLogoState}
                      updateIsSaving={this.props.updateIsSaving}
                      gettingSearchAreaLogos={this.state.gettingSearchAreaLogos}
                      headers={this.props.headers}
                    />
                    <div className="retailerOuterDiv padding-t20">
                      <div className="retailerLabel">Search Category:</div>
                      <div style={{ flexGrow: 2 }}>
                        <div>
                          <Select
                            native
                            labelId="category-select"
                            id="category-select"
                            onChange={this.selectCategory}
                            value={this.state.currentCategory}
                            variant="outlined"
                            autoWidth={true}
                          >
                            <option value="None">None</option>
                            {this.state.categoryList.map((category, idx) =>
                              <option key={'category_' + idx} value={category.category + "_" + category.id}>{category.category}</option>
                            )}
                          </Select>
                        </div>
                      </div>
                    </div>
                    <div className="retailerOuterDiv padding-t20">
                      <div className="retailerLabel">Search Retailer:</div>
                      <div style={{ flexGrow: 2 }}>
                        <div>
                          <TextField
                            id="retailer-input"
                            label="Type to search retailers"
                            variant="outlined"
                            value={this.state.retailerSearchVal}
                            size="small"
                            fullWidth
                            onChange={(e) => { this.setRetailerSearch(e) }}
                          />
                        </div>
                        <div className="retailSuggestionDiv">
                          {this.state.retailerSuggest ?
                            <ul className="retailListUL">
                              {this.state.retailerSuggest.map(suggestion => (
                                <li
                                  className="search"
                                  id={suggestion.chainid}
                                  key={suggestion.chainid}
                                  value={suggestion.text}
                                  onClick={(e) => { this.setSelectedRetailer(e, suggestion) }}
                                >
                                  {suggestion.chainname}
                                </li>
                              ))}
                            </ul>
                            : null}
                        </div>
                      </div>
                    </div>
                    <div className="flexRight">
                      <button className="geocodeBtnSmall" onClick={this.clearRetailList}>Clear List</button>
                      <button className="geocodeBtnSmall margin-l8" onClick={this.refreshRetailList}>Refresh List</button>
                    </div>
                    <div>
                      {this.props.mapObj.selectedCategory.length > 0 ?
                        <div className="selectedRetailerDiv">
                          <div className="selectedRetailerTag">Selected categories</div>
                          {this.props.mapObj.selectedCategory.map((category, i) => (
                            <SelectedRetailerRow
                              key={"selectedCategory_" + i}
                              retailer={{chainid: i, chainname: category}}
                              removeRetailer={this.removeRetailer}
                              type={"category"}
                            />
                          ))}
                        </div>
                        : null}
                    </div>
                    <div>
                      {this.props.mapObj.selectedRetailer.length > 0 ?
                        <div className="selectedRetailerDiv">
                          <div className="selectedRetailerTag">Selected retailers</div>
                          {this.props.mapObj.selectedRetailer.map(retailer => (
                            <SelectedRetailerRow
                              key={"selectedRetailer_" + retailer.chainid}
                              retailer={retailer}
                              removeRetailer={this.removeRetailer}
                              type={"retailer"}
                            />
                          ))}
                        </div>
                        : null}
                    </div>
                  </div>
                }
                {this.state.showSearchAreaLogoDiv ?
                  <div className="geocodeDiv flex">
                    <button className="geocodeBtn" onClick={this.backToMainLogoPanel}>Back</button>
                    <button className="geocodeBtn" onClick={this.doneWithLogoList}>Done</button>
                  </div>
                  :
                  <div className="geocodeDiv flex">
                    
                    {this.props.mapObj.retailPoints.length > 0 ?
                      <button className="geocodeBtn" onClick={this.clearLogos}>Clear All</button>
                      : null
                    }
                    {this.props.mapObj.selectedCategory.length === 0 && this.props.mapObj.selectedRetailer.length === 0 ?
                      null :
                      <button className="geocodeBtn" onClick={this.getLogos}>Get Retailers</button>
                    }
                  </div>
                }
                {this.state.isError ?
                  <div className="errorText padding-t10">
                    {this.state.errorType === 'generic' ? 'There was a problem retrieving logos.' : '' }
                    {this.state.errorType === 'toomany' ? this.state.tooManyPoints + ' Please zoom in or try refining your search.': '' }
                  </div>
                  : null
                }
              </div>
          }
            <RetailLineStyle
                retailerLeaderLine={this.props.mapObj.retailerLeaderLine}
                toggleRetailerLeaderLine={this.toggleRetailerLeaderLine}
                retailerLeaderLineAnchor={this.props.mapObj.retailerLeaderLineAnchor}
                toggleRetailerLeaderLineAnchor={this.toggleRetailerLeaderLineAnchor}
                retailerLeaderColor={this.props.mapObj.retailerLeaderColor}
                changeLeaderLineColor={this.changeLeaderLineColor}
                retailerLeaderWidth={this.props.mapObj.retailerLeaderWidth}
                changeLeaderLineWidth={this.changeLeaderLineWidth}
                retailerLeaderOpacity={this.props.mapObj.retailerLeaderOpacity}
                changeLeaderLineOpacity={this.changeLeaderLineOpacity}
              />
              <button className="geocodeBtn" onClick={this.backToAddData}>Back</button>
        </div>
      </div>
    );
  }
}

export default RetailFilter;