import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import translateObj from '../../../utils/translate';
import { TextField } from '@material-ui/core';
import ColorPickerMenu from '../ColorPicker/ColorPickerMenu';

class EditShapeAttributes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fill: null,
            stroke: null,
            opacity: null,
            strokeOpacity: null
        }
        this.closePanel = this.closePanel.bind(this);
        this.setFillColor = this.setFillColor.bind(this);
        this.setStrokeColor = this.setStrokeColor.bind(this);
        this.setFillOpacity = this.setFillOpacity.bind(this);
        this.setStrokeOpacity = this.setStrokeOpacity.bind(this);
        this.applyStyle = this.applyStyle.bind(this);
    }
    componentDidMount() {
        // console.log(this.props.shapeLayerIndex);
        // console.log(this.props.shapeIndex);
        // console.log(this.props.shapes);
        // console.log(this.props.shapeLegend);
        const style = this.props.shapeLegend[this.props.shapeLayerIndex].styles[this.props.shapeIndex];
        // this.setState({isIniting: true});
        // if (this.props.currentShape) {
          this.setFillColor(style.fill);
          this.setStrokeColor(style.stroke);
          this.setFillOpacity(style.opacity || 0.5);
          this.setStrokeOpacity(null); //style.strokeOpacity || 1);
          this.setWidth(null);
        // }
        // this.setState({isIniting: false});
    }

    
    closePanel() {
        console.log('close Panel');
        this.props.toggleEditShape(false);
    }

    applyStyle() {
        console.log('Apply Style');
        const shapes = this.props.shapes;

        const layer = this.props.shapeLegend[this.props.shapeLayerIndex];
        const style = layer.styles[this.props.shapeIndex];


        let newshapes = shapes.map(s => {
            if(s.type.toLowerCase()===style.type.toLowerCase()&&s.fill===style.fill 
                &&s.opacity===style.opacity&&s.stroke===style.stroke&&s.layerId===layer.layerId
            ){
                s.fill = this.state.fill;
                s.opacity = this.state.opacity;
                s.stroke = this.state.stroke;
                if(this.state.strokeOpacity)
                    s.strokeOpacity = this.state.strokeOpacity;
                if(this.state.width)
                    s.width = this.state.width;
            }
            return s;
        });

        let newLegend = this.props.shapeLegend || null;


        newLegend[this.props.shapeLayerIndex].styles[this.props.shapeIndex].fill = this.state.fill;
        newLegend[this.props.shapeLayerIndex].styles[this.props.shapeIndex].opacity = this.state.opacity;
        newLegend[this.props.shapeLayerIndex].styles[this.props.shapeIndex].stroke = this.state.stroke;


        //console.log why did i do this

        // const matchIx = layer.styles.findIndex(s=>s.type.toLowerCase()===style.type.toLowerCase()
        // &&s.fill===this.state.fill 
        // &&s.opacity===this.state.opacity
        // &&s.stroke===this.state.stroke);
        // if(matchIx > -1 && layer.styles.length > 1){
        //     newLegend[this.props.shapeLayerIndex].styles.splice(matchIx,1);
        // }

        

        this.props.updateMapObj({shapes:newshapes,shapeLegend:newLegend});

    }

    setFillColor(fill) {
        this.setState({fill: fill});
    }
    
    setStrokeColor(stroke) {
        this.setState({stroke: stroke});
    }
    
    setFillOpacity(value) {
        
        // console.log('setFillOpa');
        // console.log(value);
        let userVal = value || 0;
        if (value > 1) {
          userVal = 1;
        } else if (value < 0) {
          userVal = 0;
        }

        this.setState({opacity: userVal});
    
        // const num = Math.round((userVal) * 100) / 100;
        // if (this.props.currentShape) {
        //   this._updateShapesArray('opacity', num);
        // }
    
        // this.props.updateMapState({
        //   shapeFillOpacity: num
        // });
      }
    
      setWidth(value) {
        //this.setState({width: value});
        let num = parseInt(value);
    
        if(value<0){
          num = 1
        }
        else if(value>5){
          num = 5
        }

        this.setState({width: num});
      }
    
      setStrokeOpacity(value) {
        //this.setState({strokeOpacity: value});
        let userVal = value || 0;
        if (value > 1) {
          userVal = 1;
        } else if (value < 0) {
          userVal = 0;
        }

        this.setState({strokeOpacity: userVal});
    
        // const num = Math.round((userVal) * 100) / 100;
        // if (this.props.currentShape) {
        //   this._updateShapesArray('strokeOpacity', num);
        // }
    
        // this.props.updateMapState({
        //   shapeStrokeOpacity: num
        // });
      }


    render() {
        return (
            <div>
          <h2 className="panel-title">
            <span className="panel-icon-span">
              <svg id="styleIconGroups" xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 0 88.62 94.1">
                <line id="Line_20" stroke="#0c9ed9" fill="none" strokeWidth="4" y1="92.85" x2="88.62" y2="92.85" />
                <path id="Path_4362" fill="none" stroke="#0c9ed9" strokeWidth="4" d="M24.45-22.9,7.23-35A4.68,4.68,0,0,0,.72-33.89a4.48,4.48,0,0,0-.58,1.13L-3.51-22.3-30.89,5.46a4.65,4.65,0,0,0-1.34,3.23V56.11" transform="translate(34.31 37.14)" />
                <path id="Path_4363" fill="none" stroke="#0c9ed9" strokeWidth="4" d="M-28.37,55.58,13.52,40.67a4.6,4.6,0,0,0,2.57-2.37L32.84,3l8.58-7a4.68,4.68,0,0,0,.66-6.59,4.62,4.62,0,0,0-.92-.85" transform="translate(34.31 37.14)" />
                <circle id="Ellipse_2" fill="none" stroke="#0c9ed9" strokeWidth="4" cx="33.22" cy="52.86" r="5.67" />
                <line id="Line_21" fill="none" stroke="#0c9ed9" strokeWidth="4" x1="3.37" y1="92.08" x2="30.5" y2="56.12" />
              </svg>
            </span>
            {translateObj.styleShapesTitle[this.props.translate]}
            <span className="panel-close closeStyle"><CloseIcon onClick={this.closePanel} fontSize="small" /></span>
          </h2>
            <div className="panel-body">


            <div className="panel-body">
            <div className="add-ring-row-div paddingTB10">
              <div className="add-ring-row">
                <div className="paddingL15">{translateObj.outline[this.props.translate]}</div>
                <div style={{ paddingLeft: '5px' }}>
                  <ColorPickerMenu
                    squareColor={this.state.stroke ? this.state.stroke : '#0c9ed9'}
                    //squareColor={'#0c9ed9'}
                    addTransparent={true}
                    setFillColor={this.setStrokeColor}
                  />
                </div>
              </div>
              <div className="add-ring-row paddingL15">
                {translateObj.outlineOpacity[this.props.translate]}
                <div style={{ width: '80px', paddingLeft: '5px' }}>
                  <TextField
                    //label="Opacity"
                    variant="outlined"
                    size="small"
                    type="number"
                    value={this.state.strokeOpacity ? this.state.strokeOpacity : null}
                    //value={1}
                    InputProps={{ inputProps: { min: 0, max: 1, step: 0.1 } }}
                    onChange={(e) => { this.setStrokeOpacity(e.target.value) }}
                  />
                </div>
              </div>

              <div className="add-ring-row paddingL15">
              Width:
                <div style={{ width: '80px', paddingLeft: '5px' }}>
                  <TextField
                    //label="Width"
                    variant="outlined"
                    size="small"
                    type="number"
                    value={this.state.width ? this.state.width : null}
                    InputProps={{ inputProps: { min: 0, max: 5, step: 1 } }}
                    onChange={(e) => { this.setWidth(e.target.value) }}
                  />
                </div>
              </div>


            </div>
            {/* {this.props.currentShape && this.props.currentShape.type !== 'polyline' ? */}
              <div className="add-ring-row-div">
                <div className="add-ring-row">
                  <div>
                    <span className="paddingL15">{translateObj.fill[this.props.translate]}</span>
                  </div>
                  <div style={{ paddingLeft: '5px' }}>
                    <ColorPickerMenu
                      squareColor={this.state.fill ? this.state.fill : '#0c9ed9'}
                      setFillColor={this.setFillColor}
                      addTransparent={true}
                    />
                  </div>
                </div>
                <div className="add-ring-row paddingL15">
                  {translateObj.fillOpacity[this.props.translate]}
                  <div style={{ width: '80px', paddingLeft: '5px' }}>
                    <TextField
                      label="Opacity"
                      variant="outlined"
                      size="small"
                      type="number"
                      value={this.state.opacity ? this.state.opacity : 1}
                      InputProps={{ inputProps: { min: 0, max: 1, step: 0.1 } }}
                      onChange={(e) => { this.setFillOpacity(e.target.value) }}
                    />
                  </div>
                </div>
              </div>
              {/* : null
            } */}
              <button 
                className="geocodeBtn"
                onClick={this.applyStyle}
              >
               Apply
              </button>

          </div>



            </div>
            </div>
        );
    }
}

export default EditShapeAttributes;
