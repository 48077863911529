import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

class GeocodingSnackbar extends React.Component {
  constructor(props) {
    super(props);

    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.props.updateMapObj({
      checkOverlap: false,
      overlapDetected: false,
      geocodingSuccess: false,
      badGeocodes: false,
      tooManyLogos: false,
      legendOverlapDetected: false
    });
  }

  componentWillUnmount() {
    this.props.updateMapObj({
      checkOverlap: false,
      overlapDetected: false,
      geocodingSuccess: false,
      badGeocodes: false,
      legendOverlapDetected: false
    });
  }

  render() {
    const maxLegendPoints = process.env.REACT_APP_MAX_LEGEND_POINTS || 30;
    const bodyColor = this.props.overlapDetected || this.props.legendOverlapDetected ? 'snackBarWarn' : this.props.tooManyLogos ? 'snackBarRed' : 'snackBarSuccess';
    let message = this.props.overlapDetected ? this.props.message + "  Note: some points may overlap." : this.props.tooManyLogos ? 'There are more than 200 logos in this map zoom.  Please zoom in and reload logos.' : this.props.message;
    if(this.props.badGeocodes)
      message = 'Questionable results from Geocoder Please check your results';
    // if(this.props.noPointsInLegend)
    //   message += " Note: Over " + maxLegendPoints + " points uploaded, Points will not display in legend.";
    
    if(this.props.noPointsInLegend){
      if(!message.includes("  Note: "))
        message += "  Note: ";

      message += " Over " + maxLegendPoints + " points uploaded, Points will not display in legend by default.  This can be changed in Legend->Legend Options";
    }
      
    
    if(this.props.legendOverlapDetected){
      if(!message.includes("  Note: "))
        message += "  Note: ";

      message += " Some Points may be hidden behind legend";
    }
      

      return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          ContentProps={{
            classes: {
              root: bodyColor
            }
          }}
          open={this.props.geocodingSuccess}
          autoHideDuration={50000}
          onClose={this.handleClose}
          message={message}
          action={
            <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      </div >
    );
  }
}

export default GeocodingSnackbar;