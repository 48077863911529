import React from 'react';
import ColorPickerCell from './ColorPickerCell';

const colorPickerDiv = {
  width: '120px',
}

const colorPickerRow = {
  display: 'flex',
  flex: 1,
  alignItems: 'center'
}

class ColorPicker extends React.Component {

  render() {
    return (
      <div style={colorPickerDiv}>
        <div style={colorPickerRow}>
          <ColorPickerCell
            handleCellClick={this.props.handleCellClick}
            fillColor="#000759"
          />
          <ColorPickerCell
            handleCellClick={this.props.handleCellClick}
            fillColor="#25408f"
          />
          <ColorPickerCell
            handleCellClick={this.props.handleCellClick}
            fillColor="#1c54f4"
          />
          <ColorPickerCell
            handleCellClick={this.props.handleCellClick}
            fillColor="#4d93ff"
          />
          <ColorPickerCell
            handleCellClick={this.props.handleCellClick}
            fillColor="#c3e6ff"
          />
        </div>

        {this.props.bluesOnly ? null :
        <div>
        <div style={colorPickerRow}>
          <ColorPickerCell
            handleCellClick={this.props.handleCellClick}
            fillColor="#353e59"
          />
          <ColorPickerCell
            handleCellClick={this.props.handleCellClick}
            fillColor="#56648f"
          />
          <ColorPickerCell
            handleCellClick={this.props.handleCellClick}
            fillColor="#7b8bbd"
          />
          <ColorPickerCell
            handleCellClick={this.props.handleCellClick}
            fillColor="#acbbe8"
          />
          <ColorPickerCell
            handleCellClick={this.props.handleCellClick}
            fillColor="#dbe5ff"
          />
        </div>
        
        <div style={colorPickerRow}>
          <ColorPickerCell
            handleCellClick={this.props.handleCellClick}
            fillColor="#ed1b34"
          />
          <ColorPickerCell
            handleCellClick={this.props.handleCellClick}
            fillColor="#ffd400"
          />
          <ColorPickerCell
            handleCellClick={this.props.handleCellClick}
            fillColor="#0c9ed9"
          />
          <ColorPickerCell
            handleCellClick={this.props.handleCellClick}
            fillColor="#2ab6a9"
          />
          <ColorPickerCell
            handleCellClick={this.props.handleCellClick}
            fillColor="#fa6609"
          />
        </div>

        <div style={colorPickerRow}>
        <ColorPickerCell
            handleCellClick={this.props.handleCellClick}
            fillColor="#9c45ae"
          />
        <ColorPickerCell
            handleCellClick={this.props.handleCellClick}
            fillColor="#cccdd5"
          />
        <ColorPickerCell
            handleCellClick={this.props.handleCellClick}
            fillColor="#4a4a4d"
          />
          <ColorPickerCell
            handleCellClick={this.props.handleCellClick}
            fillColor="#ffffff"
            isWhite={'true'}
          />
          {this.props.addTransparent ?
            <ColorPickerCell
              handleCellClick={this.props.handleCellClick}
              fillColor="transparent"
              isTransparent={'true'}
            />
            : null
          }
        </div>
        </div>
        }
      </div>
    );
  }
}

export default ColorPicker;