import React from 'react';
import CancelIcon from '@material-ui/icons/Cancel';

class SelectedRow extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div key={this.props.key} className="selectedRetailerRowDiv">
        <span>
          {this.props.name}
        </span>
        <span className="selectedRetailerCancelSpan">
          <CancelIcon
            style={{ fontSize: '16px' }}
            onClick={this.props.removeRow.bind(this, this.props.name)}
          ></CancelIcon>
        </span>
      </div>
    );
  }
}

export default SelectedRow;