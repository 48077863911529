import React from 'react';
import SVGIconComponent from '../../SVGIconComponent/SVGIconComponent';
import DemographicLegend from './DemographicLegend';
import { GetTextColor, GetIconColor, GetIconName, GetSVGLabel, GetIconSize, GetIconWidth, GetLegendIconOffset, GetLongestStringLength, GetDataColumnPadding,isNullorEmptyArray } from '../../../utils/tools';
import EarthquakeLegend from './EarthquakeLegend';

class LegendPrintView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prevIsSaving: false
    }
    this.legendRef = React.createRef();
  }

  componentDidUpdate() {
    if (this.state.prevIsSaving !== this.props.isSaving) {
      if (this.props.isSaving === true) {
        if(this.legendRef.current!==null){
          const legendWidth = this.legendRef.current.clientWidth + 2; //add 2 for border
          const legendHeight = this.legendRef.current.clientHeight + 2; //add 2 for border

          const legendClone = Array.from(this.props.legend);
          legendClone[0].height = legendHeight;
          legendClone[0].width = legendWidth;

          this.props.updateMapObj({
            legend: legendClone
          });
        }
      }

      this.setState({
        prevIsSaving: this.props.isSaving
      });
    }
  }

  render() {
    const renderGroupPoints = (group, twoCol, type) => {
      let filteredPoints = this.props.points.filter(point => point.content.group === group.id);
      let longestString = '';
      let oddPoints = [];
      let evenPoints = [];
      const half = Math.round(filteredPoints.length / 2);

      for (var i = 0; i < filteredPoints.length; i++) {
        (i < half ? oddPoints : evenPoints).push(filteredPoints[i]);
        if (twoCol === true) {
          if (type === 'odd') {
            if (i < half && filteredPoints[i].content.name.length > longestString.length) {
              longestString = filteredPoints[i].content.name;
            }
          } else {
            if (i > half && filteredPoints[i].content.name.length > longestString.length) {
              longestString = filteredPoints[i].content.name;
            }
          }
        } else {
          if (filteredPoints[i].content.name.length > longestString.length) {
            longestString = filteredPoints[i].content.name;
          }
        }
      }

      if (type === 'odd' && twoCol === true) {
        filteredPoints = oddPoints;
      } else if (type === 'even' && twoCol === true) {
        filteredPoints = evenPoints;
      }

      const longestStringPixels = GetLongestStringLength(longestString);

      let maxWidth = 0;
      for (const point of filteredPoints) {
        const iconWidth = GetIconWidth(point.content.icon.options.html);
        if (iconWidth > maxWidth) {
          maxWidth = iconWidth;
        }
      }

      filteredPoints.sort((a,b)=>a.content.order - b.content.order)

      return filteredPoints.map((point, i) =>
      <div key={'pointkey_a_' + i}>
        <div key={'pointkey_' + i} className="legendView-point">
          <span style={{ paddingLeft: GetLegendIconOffset(point, maxWidth) }}>
            <SVGIconComponent
              color={GetIconColor(this.props.groups, point)}
              name={GetIconName(this.props.groups, point)}
              label={GetSVGLabel(this.props.groups, point)}
              textFill={GetTextColor(this.props.groups, point)}
              size={GetIconSize(this.props.groups, point)}
            ></SVGIconComponent>
          </span>
          <span style={{ paddingLeft: GetLegendIconOffset(point, maxWidth) }} className="legendView-point-name">{point.content.name}</span>
          {group.showLegendData ?
            <span style={{ paddingLeft: GetDataColumnPadding(point, longestStringPixels) }} className="legendView-point-name" >{point.content.legenddata}</span>
            : null
          }
        </div>
          <div>
            {getRings(point.content.id)}
          </div>
        </div>
      )
    }

    const getRings = (point_id) => {
      let matchingRings = this.props.rings.filter(ring => ring.pointId === point_id);
      matchingRings.sort(function(a,b){return a.radius-b.radius});

      const matchingDts = this.props.driveTimes.filter(ring => ring.pointId === point_id);
      matchingDts.sort(function(a,b){return a.radius-b.radius});
      matchingRings = matchingRings.concat(matchingDts);
      
      return matchingRings.map((mr,i) => {
        if(mr.displayInLegend)
        {
          return <div key={'point_' + mr.id + 'ring_' + i} className="legendView-ring">
          <svg height="25" viewBox="0 0 20 20">
          <rect x={2} y={2} width={15} height={15} fill={mr.fill} fillOpacity={mr.opacity} strokeWidth={2} stroke={mr.stroke} />
          </svg>
          <span>{mr.label}</span>
          </div>
        }
        return null;
      })
    }

    const isSomething = (objArray) => {
      let isSomething = false;
      objArray.forEach(obj=>{
        let isnothing = false;
        if(obj===0)
          isnothing = true;
        else if(obj==='0')
          isnothing = true;
        else if(obj===null)
          isnothing = true;
        else if(obj===undefined)
          isnothing = true;
        else if(obj===false)
          isnothing = true;

        if(!isnothing)
          isSomething = true;

      })
      return isSomething;

    }

    //const customLayers = this.props.customLayers;
    //const clPoints = customLayers.filter(cl => cl.type === 'point' && cl.showInLegend === true && cl.minZoom < this.props.mapZoom);
    //const gapiPoints = this.props.mapObj.gapiPoints;
    const visgroups = this.props.groups.filter(g=>g.groupInLegend===true);

    const _realGapiPoints =  this.props.realGapiPoints || [];
    const realGapiPoints = _realGapiPoints.filter(r=>r.showInLegend===true);
    
    const vis_tc = this.props.gapiPointSettings[0]?.showInLegend;
    const stuff = [];

    stuff.push(this.props.points.length===0 ? 0 : visgroups.length);
    stuff.push(this.props.gapiPointLength === 0 ? 0 : vis_tc);
    stuff.push(this.props.demographicLength);
    stuff.push(this.props.demographicLegend);
    stuff.push(this.props.earthquakeLayer);
    stuff.push(this.props.showShapeLegend && (this.props.shapes || []).length>0);
    stuff.push(this.props.gapiPolygonSettings?.selectedGapiPolygon && this.props.gapiPolygonSettings?.displayInLegend);
    stuff.push(realGapiPoints.length);
    let _shapeLegend = this.props.polygonLegend || [];
    let polygonLegend = _shapeLegend.filter(s=>s.styles!=null);

    return (
    <div>
    {isSomething(stuff) ?
    <div ref={this.legendRef} className={this.props.legend[0].scale > 1 ? 'legendView-outer-landscape-small' : (this.props.legend[0].scale < 1 ? 'legendView-outer-landscape-large' : 'legendView-outer-landscape') }>
      {this.props.points.length > 0 ?
        this.props.groups.map((group, i) =>
          <div key={'groupkey_' + i}>
            {group.groupInLegend ?
              <div className="legendView-group">
                {group.nameInLegend && group.pointsInLegend ?
                  <div>
                    <div className="legendView-group-name">{group.name}</div>
                    {this.props.legend[0].columns > 1 ?
                      <div style={{ display: 'flex' }}>
                        <div>
                          {renderGroupPoints(group, true, 'odd')}
                        </div>
                        <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                          {renderGroupPoints(group, true, 'even')}
                        </div>
                      </div>
                      :
                      <div style={{ paddingRight: '10px' }}>
                        {renderGroupPoints(group, false, null)}
                      </div>
                    }
                  </div>
                  : null
                }
                {!group.nameInLegend && group.pointsInLegend ?
                  <div>
                    {this.props.legend[0].columns > 1 ?
                      <div style={{ display: 'flex' }}>
                        <div>
                          {renderGroupPoints(group, true, 'odd')}
                        </div>
                        <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                          {renderGroupPoints(group, true, 'even')}
                        </div>
                      </div>
                     :
                      <div style={{ paddingRight: '10px' }}>
                        {renderGroupPoints(group, false, null)}
                      </div>
                    }
                  </div>
                  : null
                }
                {group.nameInLegend && !group.pointsInLegend ?
                  <div className="legendView-point">
                    <span>
                      <SVGIconComponent
                        color={GetIconColor(this.props.groups, group, true)}
                        name={GetIconName(this.props.groups, group, true)}
                        label={0}
                        textFill={GetTextColor(this.props.groups, group, true)}
                        size={GetIconSize(this.props.groups, group, true)}
                      ></SVGIconComponent>
                    </span>
                    <span className="legendView-group-name">{group.name}</span>
                  </div>
                  : null
                }
              </div>
              : null
            }
          </div>
        )
        : null
      }
      {this.props.gapiPointLength > 0 && vis_tc ?
        <div key={"legpanel_gapiPoint_0"} className="legendView-group-name">
        <div className={"customLayerPrint"}>
          <SVGIconComponent
            color={this.props.gapiPointSettings[0].pointStyle.pointColor}
            name={this.props.gapiPointSettings[0].pointStyle.pointIconName}
            size={this.props.gapiPointSettings[0].pointStyle.pointIconSize}
          />
          <span className={"legendView-customlayer-name"}>Traffic Counts</span>
        </div>
        </div>
        : null
      }
      {realGapiPoints.map((rgpl, i) =>
      <div key={'rgp_key_' + i}  className="customLayerPrint">
          {rgpl.useLogo ? null :
          <SVGIconComponent
            color={rgpl.pointStyle.pointColor}
            name={rgpl.pointStyle.pointIconName}
            size={'default'}
          />
          }
          <span className={"legendView-customlayer-name"}>{rgpl.layerTitle}</span>
      </div>
      )}
      {this.props.gapiPolygonSettings?.selectedGapiPolygon && this.props.gapiPolygonSettings.displayInLegend ?
        <div className={"customLayerPrint"}>
          <svg height="30" viewBox="0 0 20 20">
          <rect x={2} y={2} width={15} height={15} fill={this.props.gapiPolygonSettings.polygonStyle.fill} strokeWidth={2} stroke={this.props.gapiPolygonSettings.polygonStyle.stroke} />
          {/* <rect x={2} y={2} width={5} height={15} fill={"#2ab6a9"} strokeWidth={0} stroke={this.props.gapiPolygonSettings.polygonStyle.stroke} />
          <rect x={7} y={2} width={5} height={15} fill={"#fa6609"} strokeWidth={0} stroke={this.props.gapiPolygonSettings.polygonStyle.stroke} />
          <rect x={12} y={2} width={5} height={15} fill={"#ffd400"} strokeWidth={0} stroke={this.props.gapiPolygonSettings.polygonStyle.stroke} /> */}
          </svg>
          <span className={"legendView-customlayer-name"}>{this.props.gapiPolygonSettings.selectedGapiPolygonLabel}</span>
        </div>
      : null}
      {this.props.showShapeLegend && (this.props.shapes || []).length > 0 ?
        <div>
        {polygonLegend.map((sl,ix2)=>
        <div key={"polygonLegend_" + ix2}>
        {sl.displayName ?
        <div className="legendView-group-name">{sl.name}</div>
        : null}
        {sl.styles.map((pg,idx) =>
          <div key={'polylegend_' + idx} className="legendView-shape">
            {pg.type==='polygon' ?
            <svg height="30px" viewBox="0 0 20 20">
            <rect x={3} y={3} width={14} height={14} fill={pg.fill} fillOpacity={pg.opacity} strokeWidth={2} stroke={pg.stroke} strokeOpacity={pg.strokeOpacity} />
            </svg>
            : null}
            {pg.type==='polyline' ?
              <div>
              <svg height="30px" viewBox="0 0 20 20">
                <line stroke={pg.stroke} strokeOpacity={pg.strokeOpacity} fill="none" strokeWidth="3" x1="1" y1="12" x2="19" y2="12" />
              </svg>
              </div>
            : null}
            {pg.type==='circle'?
              <div>
                <svg height="30px" viewBox="0 0 20 20">
                  <circle stroke={pg.stroke} strokeOpacity={pg.strokeOpacity} fill={pg.fill} fillOpacity={pg.opacity} strokeWidth="2" cx="9.5" cy="11" r="8" />
                </svg>
              </div>              
            : null}

          <span className="legendView-point-name margin-l3">{pg.name}</span>
          </div>
          )}
        </div>
        )}
          </div>
        : null
      }
      {this.props.demographicLegend && this.props.demographicLength > 0 ?
        <DemographicLegend
          demographicLegend={this.props.demographicLegend}
          demographicColors={this.props.demographicColors}
          outlineColor={this.props.outlineColor}
          demographicFillOpacity={this.props.demographicFillOpacity}
          demographicStrokeOpacity={this.props.demographicStrokeOpacity}
        />
      : null
      }
      {this.props.earthquakeLayer &&
          <EarthquakeLegend />
      }
      </div>
    : null}
    </div>
    );
  }
}

export default LegendPrintView;