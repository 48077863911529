import React from 'react';
import '../App.css';
import MapComponent from './MapComponent/MapComponent';
import NavBar from './NavBar/NavBar';
import SideBar from './SideBar/SideBar';
import MaskComponent from './MaskComponent/MaskComponent';
import L from 'leaflet';
import ReactDOMServer from 'react-dom/server';
import SVGIconComponent from './SVGIconComponent/SVGIconComponent';
import translateObj from '../utils/translate';
import { GenerateRandomId, GetFallbackCoords, GetIconOffset, InitialMapZoom, getLabelIconOffset,
   ResizeLogo, getBoundingBoxAndCenterFromPosition,isNullorEmptyArray,GetDriveTimeModeInteger,
   GetDriveTimeMode, getBBboxFromFeatures } from '../utils/tools';
import GeocodingSnackbar from './GeocodingSnackbar/GeocodingSnackbar';
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import LoadingPanel from './LoadingPanel/LoadingPanel';

let apiToken = '';
let headers = null;

function updateOrderAndIcon(groups, groupId, pointArray, isLabel) {
  const group = groups.filter(group => group.id === groupId);
  let startNumber = group[0].startNumber;
  const groupColor = group[0].groupIconColor;
  const groupIconName = group[0].groupIconName;
  const useGroupStyle = group[0].useGroupStyle;
  const groupTextColor = group[0].groupTextColor;
  const groupIconSize = group[0].groupIconSize;
  const pointArrayClone = Array.from(pointArray);

  pointArrayClone.forEach(point => {
    point.content.order = isLabel ? point.content.order : startNumber;
    point.content.useGroupStyle = useGroupStyle;
    point.content.zIndex = (group[0].order * -5000) + (startNumber * 50);
    let color = point.content.pointColor;
    let textFill = point.content.pointTextColor;
    let iconName = point.content.pointIconName;
    let iconSize = point.content.pointIconSize;
    let label = point.content.useCustomLabel ? point.content.label : point.content.order;

    if (useGroupStyle === true) {
      color = groupColor;
      textFill = groupTextColor;
      iconName = groupIconName;
      iconSize = groupIconSize;
    }

    point.content.icon = L.divIcon({
      className: 'custom-icon',
      html: ReactDOMServer.renderToString(<SVGIconComponent outline={color === 'transparent' ? 'transparent' : null} color={color} label={label} name={iconName} textFill={textFill} size={iconSize} />)
    });

    point.content.icon.options.iconAnchor = GetIconOffset(point.content.icon.options.html, iconName);

    startNumber++;
  });

  return pointArrayClone;
}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mapCenter: null,
      mapZoom: 14,
      mapAddPt: false,
      mapId: null,
      userId: null,
      mapUserName: 'Jane User',
      mapOptionsLayout: 'landscape',
      mapOptionsBasemap: 'colliers-grey',
      mapOptionsTitle: '',
      mapOptionsProject: '',
      mapGroups: [],
      mapPoints: [],
      currentGroup: null,
      currentPoint: null,
      isStyling: false,
      isStylingFromAdd: false,
      isReGeocoding: false,
      isSaving: false,
      isPrinting: false,
      isLoading: false,
      popupOpen: false,
      isEditingRing: false,
      isEditingRingFromAdd: false,
      isLabeling: false,
      geocodingSuccess: false,
      pixelBounds: null,
      mapBounds: null,
      maskBounds: null,
      rings: [],
      driveTimes: [],
      legend: [],
      outputType: 'pdf',
      mapLoaded: false,
      sidebarOpen: false,
      undoMoveLocation: null,
      shapes: [],
      customLayoutDimensions: [4, 4],
      retailPoints: [],
      selectedCategory: [],
      selectedRetailer: [],
      overlapDetected: false,
      legendOverlapDetected: false,
      checkOverlap: false,
      badGeocodes: false,
      logoSize: 100,
      labels: [],
      shields: [],
      demographics: [],
      demographicFillOpacity: .5,
      demographicLegend: null,
      demokey: null,
      tooManyLogos: false,
      country: null,
      updateBasemap: false,
      forceDemos: false,
      vtDemographicSettings:{
        demographicVariable: null,
        demographicColors: 'd3d9e9,a8b3d2,7c8cbc,5166a5,25408f', //ed1b34,f68b1f,ffd400,c59fc5,85bcb8',
        demographicGeography: "census_tracts",
        demographicRanges: null,
        demographicRangeSetting: "default",
        demogrpahicRangeRound: false,
        demographicStroke: 'transparent',
        demographicStrokeOpacity: 1,
        demographicWidth: 2,
        demographicFillOpacity: 0.5
      },
      atlasToken: null,
      gcAtlasToken: null,
      gcAtlasTokenExp: null,
      updateLayer: null,
      removeLayer: null,
      labelLayer: null,
      labelRoad: null,
      gettingLayers: false,
      customLayers: [],
      startOver: false,
      loadLayers: false,
      importPoints: false,
      translate: 'eng',
      earthquakeLayer: false,
      retailerLeaderColor: '#ffffff',
      retailerLeaderOpacity: 1,
      retailerLeaderWidth: 3,
      pointLeaderColor: '#ffffff',
      pointLeaderWidth: 3,
      newExtent: null,
      pointLeaderOpacity: 1,
      pointLeaderAnchor: false,
      layerlabels: [],
      scaleText: null,
      scaleWidth: null,
      scaleLocation: 'bottom_right',
      noPointsInLegend: false,
      zoomToExtent: null,
      retailerBounds: null,
      loadingBar: false,
      loadingMessage: 'init',
      supergeo: false,
      verifyPoint: false,
      closePopup: false,
      infoMessage: '',
      infoBar: false,
      errorBar: false,
      errorMessage: '',
      secyGeoName: 'State',
      loadingPanel: false,
      loadingPanelMessage: '',
      updateMapBounds: false,
      gapiPolygons: [],
      gapiPolygonLabels: [],
      gapiPolygonSettings:{
        displayThem7: false,
        displayInLegend: true,
        resultNames: [],
        displayNames: [],
        selectedGapiPolygon: false,
        selectedGapiPolygonLabel: '',
        gettingGapiPolygons: false,
        polygonStyle: {
          fill: '#0c9ed9',
          opacity: 0.5,
          stroke: '#ffffff',
          strokeOpacity: 1,
          width: 3
        },
        displayLabels: false,
        labelStyle: {
          mapAddLabelStroke: '#4a4a4d',
          mapAddLabelColor: '#ffffff',
          mapAddLabelFontSize: 12,
          mapAddLabelOpacity: 1,
          mapAddLabelFont: 'Open Sans',
          mapAddLabelWeight: true,
        }
      },
      updateGapiLabels: null,
      updateGapiPolygonFeatureId: null,
      gapiPointsLoading: false,
      gapiPointsErrorMessage: null,
      gapiPoints: [],
      gapiPointLabels: [],
      gapiPointCustomLabels: [],
      gapiPointHideLabels: [],
      gapiPointSettings: [{
        displayLabels: false,
        minValue: 10000,
        minDisplayValue: 10000,
        labelStyle: {
          labelStroke: '#4a4a4d',
          labelColor: '#ffffff',
          labelFontSize: 12,
          labelOpacity: 1,
          labelFont: 'Open Sans',
          labelWeight: true
        },
        pointStyle:{
          pointColor: "#0c9ed9",
          pointIconName: "circleIcon",
          pointIconSize: "default",
          pointTextColor: "#ffffff"
        },
        showInLegend: true
      }],
      gapiPointIcons: [],
      gapiPointBounds: null,
      addGapiPoints: null,
      loadedGapiLabels: null,
      loadedCustomGapiLabels: null,
      demoPanelOpen: false,
      demoPanelRedraw: false,
      maskScale: null,
      moveCenterandZoom: false,
      gettingDriveTimes: null,
      addedPoint: null
    };

    this.getStartNumber = this.getStartNumber.bind(this);
    this.createNewPoint = this.createNewPoint.bind(this);
    this.deletePoint = this.deletePoint.bind(this);
    this.deleteShape = this.deleteShape.bind(this);
    this.updateMapObj = this.updateMapObj.bind(this);
    this.setPointIcons = this.setPointIcons.bind(this);
    this._getUserCoords = this._getUserCoords.bind(this);
    this.createNewLabel = this.createNewLabel.bind(this);
    this.createNewShield = this.createNewShield.bind(this);
    this.getRetailPoints = this.getRetailPoints.bind(this);
    //this.getDemographics = this.getDemographics.bind(this);
    //this.getRangeDemographics = this.getRangeDemographics.bind(this);
    this.closeErrorBar = this.closeErrorBar.bind(this);
    this.closeInfoBar = this.closeInfoBar.bind(this);
    this.getGAPIPolygons = this.getGAPIPolygons.bind(this);
    //this.getGAPIPoint = this._getGAPIPoints.bind(this);
    this.updateGAPIPolygonLabels = this.updateGAPIPolygonLabels.bind(this);
    this.updateGAPIPolygonLabel = this.updateGAPIPolygonLabel.bind(this);
    this.zoomToGAPIPolygon = this.zoomToGAPIPolygon.bind(this);
    this.setGapiIcon = this.setGapiIcon.bind(this);
    this.setGAPILabelStyle = this.setGAPILabelStyle.bind(this);
    this.setGapiPointFilter = this.setGapiPointFilter.bind(this);
    this.makeGAPICustomLabels = this.makeGAPICustomLabels.bind(this);
    this.getGAPIPoints = this.getGAPIPoints.bind(this);
    this.retriveGCAtlasToken = this.retriveGCAtlasToken.bind(this);
    this.getDriveTime = this.getDriveTime.bind(this);
    this.getRealGAPIPoints = this.getRealGAPIPoints.bind(this);
    this.getRingLabelIcon = this.getRingLabelIcon.bind(this);
  }


  componentDidMount() {
    const firstIDNum = GenerateRandomId();
    const firstLayerIdNum = GenerateRandomId();
    this.setState({
      mapGroups: [{
        id: firstIDNum,
        order: 1,
        name: 'Layer 1',
        useGroupStyle: false,
        useCustomLabel: false,
        groupIconName: 'circleIcon',
        groupIconColor: '#0c9ed9',
        groupTextColor: '#ffffff',
        groupIconSize: 'default',
        startNumber: 1,
        visible: true,
        nameInLegend: true,
        pointsInLegend: true,
        groupInLegend: true,
        icon: '',
        showLegendData: false,
        groupLeaderLine: false,
        groupLeaderColor: '#ffffff',
        groupLeaderOpacity: 1,
        groupLeaderWidth: 3,
        groupLeaderAnchor: false,
        groupTextLabel: false,
        groupTextLabelStroke: '#4a4a4d',
        groupTextLabelColor: '#ffffff',
        groupTextLabelFontSize: 14,
        groupTextLabelOpacity: 1,
        groupTextLabelFont: 'Open Sans',
        groupTextLabelWeight: true,
        groupTextLabelPosition: 'upper-right',
        groupTextLabelField: 'name'
      }],
      legend: [{
        height: 42,
        width: 85,
        visible: false,
        scale: 1,
        columns: 1,
        position: ['right', 'top'],
        northArrowVisible: true,
        northArrowPosition: ['left', 'bottom']
      }],
      showShapeLegend: true,
      shapeLegend: [
        {
          layerId: firstLayerIdNum,
          name: "Custom Shapes",
          displayName: true
        }
      ],
      currentGroup: firstIDNum,
      currentShapeLayer: firstLayerIdNum
    });

    this._getUserCoords();
  }

  componentDidUpdate() {
    if(this.state.updateGapiLabels){
      this._updateGAPIPolygonLabels(this.state.updateGapiLabels);
      this.setState({updateGapiLabels: null})
    }

    if(this.state.updateGapiPolygonFeatureId){
      this.setState({loadingPanel: true,loadingPanelMessage: 'Loading Polygons'});
      let region = '';
      let geo = ''
      if(this.state.updateGapiPolygonFeatureId === 7){
        region = 'na';
        geo = 'industrial_submarkets';
      }
      else if(this.state.updateGapiPolygonFeatureId === 5) {
        region = 'na';
        geo = 'office_submarkets';
      }
      else if(this.state.updateGapiPolygonFeatureId === 8) {
        region = 'na';
        geo = 'retail_submarkets';
      }
      else if(this.state.updateGapiPolygonFeatureId === 1) {
        region = 'us';
        geo = 'places';
      }
      else if(this.state.updateGapiPolygonFeatureId === 2) {
        region = 'us';
        geo = 'zip_codes';
      }
      else if(this.state.updateGapiPolygonFeatureId === 3) {
        region = 'us';
        geo = 'counties';
      }

      //this.getGAPIPolygons(this.state.updateGapiPolygonFeatureId);
      this.getGAPIPolygons(region,geo);
      this.setState({updateGapiPolygonFeatureId: null});
    }

    if(this.state.addGapiPoints){
      this.getGAPIPoints(this.state.addGapiPoints);
      this.setState({addGapiPoints: null});
    }

    if(this.state.addRealGapiPoints){
      this.getRealGAPIPoints(this.state.addRealGapiPoints);
      this.setState({addRealGapiPoints: null});
    }
  }

  async _processUploadPoints (uploadPts) {
    this.setState({importPoints: true,
      uploadPtObj: uploadPts
    });
  }

  async _getAtlasToken () {
    axios.get(process.env.REACT_APP_APIURL + '/getAtlasToken', { headers: headers })
    .then(result => {
      console.log(result.data.expires,'atlas expires');
      this.state.atlasToken = result.data.token
    }).catch(error => {
      console.log('getAtlasTokenError');
      console.log(error);
    });
  }

  async retriveGCAtlasToken() {
    let token = this.state.gcAtlasToken;
    if(token===null || (Date.now()>this.state.gcAtlasTokenExp)){
      token = await axios.get(process.env.REACT_APP_APIURL + '/getGCAtlasToken', { headers: headers })
      .then(result => {
        this.state.gcAtlasToken = result.data.token;
        this.state.gcAtlasTokenExp = result.data.expires;
        return result.data.token;
      }).catch(error => {
        console.log('getGCAtlasTokenError');
        console.log(error);
      });
      return token;
    }
    else {
      return token;
    }

  }

  async _getUserCoords() {
    await this.props.getTokenSilently()
      .then((result) => {
        apiToken = result;
        headers = {
          'Authorization': 'Bearer ' + apiToken
        };
      }).catch((error) => {
        this.props.loginWithRedirect({});
        console.log(error);
      });

    this._getAtlasToken();
    this.retriveGCAtlasToken(); //_getGCAtlasToken();

    axios.post(process.env.REACT_APP_APIURL + "/getOfficeCoordsAndUserId", {}, { headers: headers })
      .then((result) => {
        if (result.data) {
          let country = result.data.country;
          const forceCountry = window.sessionStorage.getItem("force_country");
          let forceFallback = false;
          if(forceCountry!==null) { //&&result.data.supergeo===true){
            country = forceCountry;
            forceFallback = true;
            window.sessionStorage.removeItem("force_country");
          }
         
          const fallbackCoords = GetFallbackCoords(country);
          const initialMapZoom = forceFallback ? 4 : InitialMapZoom(country, result.data.x);
          const xCoord = (country !== 'OT' && !forceFallback) ? result.data.x : fallbackCoords.x;
          const yCoord = (country !== 'OT' && !forceFallback) ? result.data.y : fallbackCoords.y;
          const mapZoom = initialMapZoom;
          const secGeo =  country === 'CA' ? 'Province' : 'State'
          this.setState({
            mapCenter: [yCoord, xCoord],
            userId: result.data.userId,
            country: country,
            mapZoom: mapZoom,
            mapOptionsBasemap: result.data.country === 'JPN' ? 'esri-topo-jpn' : 'colliers-grey',
            supergeo: result.data.supergeo,
            secyGeoName: secGeo,
            mapOptionsBasemap: result.data.country === 'JPN' ? 'esri-topo-jpn' : 'colliers-grey',
            updateBasemap: true,
            customLayoutDimensions: result.data.country === 'US' ? [4, 4] : [3.937, 3.937]
          });

          if(country === 'US'){
            this.retriveGCAtlasToken(); //_getGCAtlasToken();
          }

          window.addEventListener("beforeunload", function (e) {
            var confirmationMessage = translateObj.mainConfirmMessage["eng"];
            e.returnValue = confirmationMessage;     // Gecko, Trident, Chrome 34+
            return confirmationMessage;              // Gecko, WebKit, Chrome <34
          });
        }
      }).catch(error => {
        console.log(error);
        const fallbackCoords = GetFallbackCoords(this.state.country);
        this.setState({
          mapCenter: [fallbackCoords.y, fallbackCoords.x],
          mapZoom: 4
        });

        window.addEventListener("beforeunload", function (e) {
          var confirmationMessage = translateObj.mainConfirmMessage["eng"];
          e.returnValue = confirmationMessage;     // Gecko, Trident, Chrome 34+
          return confirmationMessage;              // Gecko, WebKit, Chrome <34
        });
      });
  }

  updateMapObj(mapObj) {
    this.setState(mapObj);
  }

  createNewPoint(pointArray) {
    const group = this.state.mapGroups.filter(group => group.id === this.state.currentGroup);
    let groupIconColor = group[0].groupIconColor;
    let groupTextFill = group[0].groupTextColor;
    let groupIconName = group[0].groupIconName;
    let groupIconSize = group[0].groupIconSize;
    let groupStyle = group[0].useGroupStyle;
    let groupCustomLabel = group[0].useCustomLabel;
    let groupOrder = group[0].order;
    let groupLeaderLine = group[0].groupLeaderLine;
    let groupLeaderColor = group[0].groupLeaderColor;
    let groupLeaderWidth = group[0].groupLeaderWidth;
    let groupLeaderOpacity = group[0].groupLeaderOpacity;
    let groupLeaderAnchor =group[0].groupLeaderAnchor;

    let groupTextLabel = group[0].groupTextLabel;
    let groupTextLabelStroke = group[0].groupTextLabelStroke;
    let groupTextLabelColor = group[0].groupTextLabelColor;
    let groupTextLabelFontSize = group[0].groupTextLabelFontSize;
    let groupTextLabelOpacity = group[0].groupTextLabelOpacity;
    let groupTextLabelFont = group[0].groupTextLabelFont;
    let groupTextLabelWeight = group[0].groupTextLabelWeight;
    let groupTextLabelPosition = group[0].groupTextLabelPosition;
    let groupTextLabelField =  group[0].groupTextLabelField;

    let startNumber = this.getStartNumber(this.state.currentGroup);
    let prevGroupID = null;
    const pointsArrayClone = Array.from(this.state.mapPoints);

    for (const point of pointArray) {
      if (point.content.group) {
        const pointGroup = this.state.mapGroups.filter(group => group.id === point.content.group);
        groupIconColor = pointGroup[0].groupIconColor;
        groupTextFill = pointGroup[0].groupTextColor;
        groupIconName = pointGroup[0].groupIconName;
        groupIconSize = pointGroup[0].groupIconSize;
        groupOrder = pointGroup[0].order;
        groupLeaderLine = pointGroup[0].groupLeaderLine;
        groupLeaderColor = pointGroup[0].groupLeaderColor;
        groupLeaderWidth = pointGroup[0].groupLeaderWidth;
        groupLeaderOpacity = pointGroup[0].groupLeaderOpacity;
        groupLeaderAnchor = pointGroup[0].groupLeaderAnchor;
        groupTextLabel = pointGroup[0].groupTextLabel;
        groupTextLabelStroke = pointGroup[0].groupTextLabelStroke;
        groupTextLabelColor = pointGroup[0].groupTextLabelColor;
        groupTextLabelFontSize = pointGroup[0].groupTextLabelFontSize;
        groupTextLabelOpacity = pointGroup[0].groupTextLabelOpacity;
        groupTextLabelFont = pointGroup[0].groupTextLabelFont;
        groupTextLabelWeight = pointGroup[0].groupTextLabelWeight;
        groupTextLabelPosition = pointGroup[0].groupTextLabelPosition;
        groupTextLabelField = pointGroup[0].groupTextLabelField;

        if (prevGroupID !== point.content.group) {
          startNumber = this.getStartNumber(point.content.group);
        }
      }

      let label = groupCustomLabel ? point.content.label : startNumber;

      let icon = L.divIcon({
        className: 'custom-icon',
        html: ReactDOMServer.renderToString(<SVGIconComponent outline={groupIconColor === 'transparent' ? 'transparent' : null} color={groupIconColor} label={label} name={groupIconName} textFill={groupTextFill} size={groupIconSize} />)
      });

      icon.options.iconAnchor = GetIconOffset(icon.options.html, groupIconName);

      const newPoint = {position: point.position,
      content: {
        id: GenerateRandomId(),
        order: startNumber,
        label: point.content.label || '',
        group: point.content.group || this.state.currentGroup,
        icon: icon,
        pointColor: groupIconColor,
        pointIconName: groupIconName,
        pointTextColor: groupTextFill,
        pointIconSize: groupIconSize,
        userLeaderLine: groupLeaderLine,
        pointLeaderColor: groupLeaderColor,
        pointLeaderWidth: groupLeaderWidth,
        pointLeaderOpacity: groupLeaderOpacity,
        pointLeaderAnchor: groupLeaderAnchor,
        pointTextLabel: groupTextLabel,
        pointTextLabelStroke: groupTextLabelStroke,
        pointTextLabelColor: groupTextLabelColor,
        pointTextLabelFontSize: groupTextLabelFontSize,
        pointTextLabelOpacity: groupTextLabelOpacity,
        pointTextLabelFont: groupTextLabelFont,
        pointTextLabelWeight: groupTextLabelWeight,
        pointTextLabelPosition: groupTextLabelPosition,
        pointTextLabelField: groupTextLabelField,
        lat: point.position.lat,
        lng: point.position.lng,
        name: point.content.name ? point.content.name : point.content.address ? point.content.address : point.content.city ? (point.content.city + ", " + point.content.state) : 'Point ' + startNumber,
        address: point.content.address || '',
        city: point.content.city || '',
        state: point.content.state || '',
        zip: point.content.zip || '',
        legenddata: point.content.legenddata || '',
        useGroupStyle: groupStyle,
        useCustomLabel: groupCustomLabel,
        zIndex: (groupOrder * -5000) + (startNumber * 50),
        accuracy: point.content.accuracy || null
      }
    }
      this.setState({addedPoint: newPoint});
      pointsArrayClone.push(newPoint);

      prevGroupID = point.content.group;
      startNumber++;
    }

    // groupLeaderLine = pointGroup[0].groupLeaderLine;
    // groupLeaderColor = pointGroup[0].groupLeaderColor;
    // groupLeaderWidth = pointGroup[0].groupLeaderWidth;
    // groupLeaderOpacity = pointGroup[0].groupLeaderOpacity;
    // groupLeaderAnchor = pointGroup[0].groupLeaderAnchor;

    return pointsArrayClone;
  }

  createNewLabel(labelArray,useLayerLabel) {

    let labelArrayClone = Array.from(this.state.labels);
    if(useLayerLabel){
      labelArrayClone = Array.from(this.state.layerlabels);
    }

    for (const lbl of labelArray) {
      const icon = this.getLabelIcon(lbl);

      labelArrayClone.push({
        position: lbl.position,
        content: {
          id: GenerateRandomId(),
          color: lbl.content.color,
          stroke: lbl.content.stroke,
          text: lbl.content.text,
          icon: icon,
          fontSize: lbl.content.fontSize,
          opacity: lbl.content.opacity,
          font: lbl.content.font,
          weight: lbl.content.weight,
          angle: lbl.content.angle,
          offsetAnchor: lbl.content.offsetAnchor,
          customlayer: lbl.content.customlayer,
          offsetPosition: lbl.content.offsetPosition,
          draggable: lbl.content.draggable
        },
        box: lbl.box || null
      });
    }

    return labelArrayClone;
  }

  createNewShield(shieldArray) {
    const shieldArrayClone = Array.from(this.state.shields);

    for (const shld of shieldArray) {
      const icon = this.getShieldIcon(shld);

      shieldArrayClone.push({
        position: shld.position,
        content: {
          id: GenerateRandomId(),
          color: shld.content.color,
          text: shld.content.text,
          icon: icon,
          size: shld.content.size,
          type: shld.content.type
        }
      });
    }

    return shieldArrayClone;
  }

  // getRingLabelIcon(ring){
  //   const lbl = {
  //     content: {
  //       weight: 'bold',
  //       angle: 0,
  //       fontSize: 12,
  //       font: 'Open Sans',
  //       stroke: ring.labelStroke,
  //       color: ring.labelFill,
  //       opacity: 1,
  //       text: ring.label
  //     }
  //   }
  //   return this.getLabelIcon(lbl);
  // }

  getRingLabelIcon(ring){
    let labelIconAnchor = [0,0];
    if(ring.labelDirection==='bottom'){
      labelIconAnchor = [33,0];
    }
    else if(ring.labelDirection==='top'){
      labelIconAnchor = [33,18];
    }
    else if(ring.labelDirection==='left'){
      labelIconAnchor = [66,0];
    }
    else if(ring.labelDirection==='right'){
      labelIconAnchor = [0,0];
    }

    let icon = L.divIcon({
      className: 'custom-label',
      html: '<span style="font-size: 12px; font-weight: bold; font-family: Open Sans; color: ' + ring.labelFill + '; text-shadow: -1px -1px 0 ' + ring.labelStroke + ',1px -1px 0 ' + ring.labelStroke+ ',-1px 1px 0 ' + ring.labelStroke + ',1px 1px 0 ' + ring.labelStroke + '; opacity:1;" class="custom-iconlabel">' + ring.label + '</span>',
      iconAnchor: labelIconAnchor //[0,0] //labelIconAnchor
    });

    return icon;
  }


  getLabelIcon(lbl) {
    // let element = document.createElement('span');
    const fontWeight = lbl.content.weight ? 'bold' : 'normal';
    const labelIconAnchor = getLabelIconOffset(lbl)
    //document.body.removeChild(element);
    let angle = "";
    if(lbl.content.angle){
      //angle = "-webkit-transform: rotate(-" + lbl.content.angle + "deg);"
      angle = "-webkit-transform: rotate(" + lbl.content.angle + "deg);"
    }

    let icon = L.divIcon({
      className: 'custom-label',
      html: '<span style="' + angle + ' font-size: ' + lbl.content.fontSize + 'px; font-weight: ' + fontWeight + '; font-family: ' + lbl.content.font + '; color: ' + lbl.content.color + '; text-shadow: -1px -1px 0 ' + lbl.content.stroke + ',1px -1px 0 ' + lbl.content.stroke + ',-1px 1px 0 ' + lbl.content.stroke + ',1px 1px 0 ' + lbl.content.stroke + '; opacity:' + lbl.content.opacity + ';" class="custom-iconlabel">' + lbl.content.text + '</span>',
      iconAnchor: labelIconAnchor
    });

    return icon;
  }

  getShieldIcon(shld) {
    const icon = L.divIcon({
      className: 'custom-shield',
      html: ReactDOMServer.renderToString(<SVGIconComponent size={shld.content.size} label={shld.content.text} name={"boom_" + shld.content.type + "_" + shld.content.color} />),
      iconAnchor: [shld.content.size/2, shld.content.size/2]
    });

    return icon;
  }

  async getShield(latLng, zoom, country) {
    return axios.get(process.env.REACT_APP_SHIELD_URL + "?lat=" + latLng.lat + "&lng=" + latLng.lng + "&zoom=" + zoom + '&country=' + (country || 'us'), { headers: headers })
    //return axios.get(process.env.REACT_APP_ATLASAPIURL + "/shield?lat=" + latLng.lat + "&lng=" + latLng.lng + "&zoom=" + zoom, { headers: headers })
      .then((result) => {
        return result.data.rows;
      }).catch(error => {
        console.log(error);
        return [];
      });
  }

  setPointIcons(points, groups) {
    const newArray = [];
    for (const point of points) {
      let iconName = point.content.pointIconName;
      let iconColor = point.content.pointColor;
      //const orderNumber = point.content.order;
      let textColor = point.content.pointTextColor || '#fff000';
      let iconSize = point.content.pointIconSize || 'default';
      const myGroup = groups.filter(group => group.id === point.content.group);
      if(myGroup.length>0){
        if (myGroup[0].useGroupStyle === true) {
          iconName = myGroup[0].groupIconName;
          iconColor = myGroup[0].groupIconColor;
          textColor = myGroup[0].groupTextColor || '#fff000';
          iconSize = myGroup[0].groupIconSize;
        }
      }

      let label = point.content.useCustomLabel ? point.content.label : point.content.order;

      let icon = L.divIcon({
        className: 'custom-icon',
        html: ReactDOMServer.renderToString(<SVGIconComponent outline={iconColor === 'transparent' ? 'transparent' : null} color={iconColor} label={label} name={iconName} textFill={textColor} size={iconSize} />)
      });

      icon.options.iconAnchor = GetIconOffset(icon.options.html, iconName);

      point.content.icon = icon;
      if(myGroup.length>0){
        point.content.zIndex = (myGroup[0].order * -5000) + (point.content.order * 5);
      }
      else {
        point.content.zIndex = (point.content.order * 5);
      }
      
      newArray.push(point);
    }

    return newArray;
  }

  getStartNumber(currentGroup) {
    const pointsArrayClone = Array.from(this.state.mapPoints);
    const groupStartNumber = Array.from(this.state.mapGroups).filter(group => group.id === currentGroup);

    //find out how many points belong to current group
    const pointsInCurrentGroup = [];
    for (const point of pointsArrayClone) {
      if (point.content.group === currentGroup) {
        pointsInCurrentGroup.push(point);
      }
    }

    const startNumber = groupStartNumber[0].startNumber + pointsInCurrentGroup.length;
    return startNumber;
  }

  deletePoint(point) {
    const deleteGroup = point.group;
    const deleteId = point.id;
    const sourceArray = this.state.mapPoints.filter(point => point.content.group === deleteGroup);
    const leftoverArray = this.state.mapPoints.filter(point => point.content.group !== deleteGroup);
    const leftoverClone = Array.from(leftoverArray);

    const indexToDelete = sourceArray.findIndex(point => point.content.id === deleteId);
    sourceArray.splice(indexToDelete, 1);

    const orderedArray = updateOrderAndIcon(this.state.mapGroups, deleteGroup, sourceArray);
    const newPointsArray = orderedArray.concat(leftoverClone);

    //remove rings and drivetimes associated with point
    const ringsArrayClone = Array.from(this.state.rings).filter(ring => ring.pointId !== deleteId);
    const dtArrayClone = Array.from(this.state.driveTimes).filter(ring => ring.pointId !== deleteId);

    const legendClone = Array.from(this.state.legend);
    if (newPointsArray.length === 0 && this.state.demographicLegend === null && this.state.earthquakeLegend === null) {
      legendClone[0].visible = false;
    }

    this.setState({
      mapPoints: newPointsArray,
      rings: ringsArrayClone,
      driveTimes: dtArrayClone,
      isStyling: false,
      isEditingRing: false,
      legend: legendClone
    });
  }

  deleteShape(shape) {
    let shapesArrayClone = Array.from(this.state.shapes).filter(thisShape => thisShape.id !== shape.id);
    shapesArrayClone.sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));

    this.setState({
      shapes: shapesArrayClone
    });
  }


  async getRetailPoints(mapBounds,retailers,categories,skipRetailerHashIds,orgRetBounds) {

    const justretids = retailers.map(r=>r.chainid);

    this.setState({
      loadingPanel: true,
      loadingPanelMessage: 'Loading Retailers'
    });

    const sendObj = {
      mapbounds: mapBounds,
      retailerids: justretids, //retailers,
      category: categories,
      logotype: "fullcolor",
      mapid: this.state.mapId,
      skippointids: skipRetailerHashIds
    }
 
    await axios.post(process.env.REACT_APP_APIURL + "/retailPoints", sendObj, { headers: headers })
      .then((result) => {
        if((result.data.message || '').startsWith("Too many points")){
          console.log(result.data.message);
          const errMsg = result.data.message.replace("Too many points ","") + " points returned.  The limit is 200";
          this.setState({retailerBounds: orgRetBounds, gettingLogos: false, loadingPanel: false, loadingBar: false, loadingMessage:'..', errorBar: true, errorMessage: errMsg});
        }
        else{
          const rpClone = this.state.retailPoints;
          const addRps = this._getIconOffset(result.data,this.state.logoSize);
          
          let newRps = null;
          if(rpClone.length + addRps.length < 220){
            newRps = rpClone.concat(addRps);
          }
          else{
            const n = mapBounds._northEast.lat;
            const e = mapBounds._northEast.lng;
            const s = mapBounds._southWest.lat;
            const w = mapBounds._southWest.lng;
            const rpC2 = rpClone.filter(rp => parseFloat(rp.latitude) < n && parseFloat(rp.latitude) > s && parseFloat(rp.longitude) < e && parseFloat(rp.longitude) > w);
            if(rpC2.length + addRps.length < 240){
              newRps = rpC2.concat(addRps);
            }
          }
          if(newRps===null){
            let errMsg = "Too many points returned on redraw, please change filters and try again";
            this.setState({retailerBounds: orgRetBounds, gettingLogos: false, loadingBar: false, loadingMessage:'..', errorBar: true, errorMessage: errMsg});
          }else{
            this.setState({retailerBounds: mapBounds, gettingLogos: false, retailPoints: newRps, loadingPanel: false, loadingPanelMessage:'..'});
          }
        }
      }).catch((error) => {
        console.log(error);
        let errMsg = "There was an error retrieving retail points";
        this.setState({retailerBounds: orgRetBounds, gettingLogos: false, loadingPanel: false, loadingPanelMessage:'..', errorBar: true, errorMessage: errMsg});
      }
    );
  }

  getGAPIPoints(addPoints,mapBounds) {
    if(addPoints===null){
      addPoints = this.state.gapiPointSettings[0];
    }
    if(!mapBounds){
      this.setState({updateMapBounds:true});
      setTimeout(() => {
        mapBounds = this.state.mapBounds;
        this._getGAPIPoints(addPoints,mapBounds)
      }, 500);
    }
    else{
      this._getGAPIPoints(addPoints,mapBounds)
    }
  }

  getRealGAPIPoints(addPoints,mapBounds) {
    // if(addPoints===null){
    //   addPoints = this.state.gapiPointSettings[0];
    // }
    if(!mapBounds){
      this.setState({updateMapBounds:true});
      setTimeout(() => {
        mapBounds = this.state.mapBounds;
        this._getRealGAPIPoints(addPoints,mapBounds)
      }, 500);
    }
    else{
      this._getRealGAPIPoints(addPoints,mapBounds)
    }
  }


  setGapiPointFilter(key,value) {
    let cloneLabelSettings = this.state.gapiPointSettings;
    let thisObj = cloneLabelSettings[0];
    thisObj[key] = value;
    cloneLabelSettings[0] = thisObj;
    this.setState({gapiPointSettings:cloneLabelSettings});
  }

  setGapiIcon(pointStyle){
    const cloneIcons = []; //this.state.gapiPointIcons;

    let _icon = L.divIcon({
      className: 'gapi-custom-icon',
      html: ReactDOMServer.renderToString(<SVGIconComponent 
        outline={pointStyle.pointColor === 'transparent' ? 'transparent' : null}
        color={pointStyle.pointColor}
        //label={label}
        name={pointStyle.pointIconName}
        //textFill={groupTextFill}
        size={pointStyle.pointIconSize} />)
    });

    _icon.options.iconAnchor = GetIconOffset(_icon.options.html, pointStyle.pointIconName);
    
    let cloneLabelSettings = this.state.gapiPointSettings;
    let thisObj = cloneLabelSettings[0];
    thisObj.pointStyle = pointStyle;
    cloneLabelSettings[0] = thisObj;
    cloneIcons.push({id:0,icon:_icon});
    this.setState({gapiPointIcons:cloneIcons,gapiPointSettings:cloneLabelSettings});
  }

  _getRealGAPIPoints (addPoints,mapBounds) {
    let message = 'Loading Points';
    this.setState({
      loadingBar: false,
      loadingMessage: '....',
      loadingPanel: true,
      loadingPanelMessage: message
    });
    let url = process.env.REACT_APP_ATLASAPIURL + "/gapipoints?";

    // const mapDistLat = 10; //(mapBounds._northEast.lat - mapBounds._southWest.lat) / 2;
    // const mapDistLng = 20; //(mapBounds._northEast.lng - mapBounds._southWest.lng) / 2;

    const mapDistLat = (mapBounds._northEast.lat - mapBounds._southWest.lat) * 4;
    const mapDistLng = (mapBounds._northEast.lng - mapBounds._southWest.lng) * 4;

    const newBounds = {
      _northEast: {
          lat: mapBounds._northEast.lat + mapDistLat,
          lng: mapBounds._northEast.lng + mapDistLng
      },
      _southWest: {
          lat: mapBounds._southWest.lat - mapDistLat,
          lng: mapBounds._southWest.lng - mapDistLng
      }
    }
    url += "&mapBounds=" + JSON.stringify(newBounds);


    axios.get(url,{ headers: headers })
    .then((result) => {
      if(result.data.message==='success'){
        const formattedPoints = result.data.features.map(p=>{
          let props = p.properties;
          props.id = p.id;
          props.icon = null;
          return {
            position:{
              lat:p.geometry.coordinates[1],
              lng:p.geometry.coordinates[0]
            },
            content:props
          }
        });

        const prevGapi = this.state.realGapiPoints || [];
        const existingix = prevGapi.findIndex(g=>g.layer===addPoints.layer);

        if(existingix===-1){
          const newLayer = addPoints;
          newLayer.points = formattedPoints;
          prevGapi.push(newLayer);
        }
        else{
          prevGapi[existingix].points = formattedPoints;
        }

        

        const legendClone = Array.from(this.state.legend);
        legendClone[0].visible = true;

        this.setState({
          realGapiPoints: prevGapi,
          addRealGapiPoints: null,
          isSaving: false,
          gapiPointsLoading: false,
          loadingBar: false,
          loadingMessage: '...',
          loadingPanel: false,
          loadingPanelMessage: '...',
          realGapiPointBounds: newBounds,
          legend: legendClone
        });
      }
      else if (result.data.message==='too many points'){
        this.setState({
          addRealGapiPoints: null,
          isSaving: false,
          gapiPointsLoading: false,
          loadingBar: false,
          loadingMessage: '...',
          loadingPanel: false,
          loadingPanelMessage: '...',
          errorBar: true,
          errorMessage: 'There were too many points'
        });
      }
      else {
        this.setState({
          addRealGapiPoints: null,
          isSaving: false,
          gapiPointsLoading: false,
          loadingBar: false,
          loadingMessage: '...',
          loadingPanel: false,
          loadingPanelMessage: '...',
          errorBar: true,
          errorMessage: 'There was an error'
        });
      }
    });
  }

  _getGAPIPoints (addPoints,mapBounds) {
    let message = 'Loading Traffic Counts';
    this.setState({
      loadingBar: false,
      loadingMessage: '....',
      loadingPanel: true,
      loadingPanelMessage: message
    });
	

    this.setGapiIcon(addPoints.pointStyle);
    const newMinValue = addPoints.minDisplayValue || addPoints.minValue || 0;
    let url = process.env.REACT_APP_ATLASAPIURL + "/atlaspoints?minValue=" + newMinValue + "&country=" + this.state.country;

    const mapDistLat = (mapBounds._northEast.lat - mapBounds._southWest.lat) / 2;
    const mapDistLng = (mapBounds._northEast.lng - mapBounds._southWest.lng) / 2;

    const newBounds = {
      _northEast: {
          lat: mapBounds._northEast.lat + mapDistLat,
          lng: mapBounds._northEast.lng + mapDistLng
      },
      _southWest: {
          lat: mapBounds._southWest.lat - mapDistLat,
          lng: mapBounds._southWest.lng - mapDistLng
      }
    }
    url += "&mapBounds=" + JSON.stringify(newBounds);
    let _icon = L.divIcon({
      className: 'gapi-custom-icon',
      html: ReactDOMServer.renderToString(<SVGIconComponent 
        outline={addPoints.pointStyle.pointColor === 'transparent' ? 'transparent' : null}
        color={addPoints.pointStyle.pointColor}
        //label={label}
        name={addPoints.pointStyle.pointIconName}
        //textFill={groupTextFill}
        size={addPoints.pointStyle.pointIconSize} />)
    });

    _icon.options.iconAnchor = GetIconOffset(_icon.options.html, addPoints.pointIconName);
    this.setState({gapiPointsLoading: true, gapiPointsErrorMessage: null});
    axios.get(url,{ headers: headers })
    .then((result) => {
      if(result.data.message==='success'){
        const formattedPoints = result.data.features.map(p=>{
          let props = p.properties;
          props.id = p.id;
          props.icon = null;
          return {
            position:{
              lat:p.geometry.coordinates[1],
              lng:p.geometry.coordinates[0]
            },
            content:props
          }
        });

        const legendClone = Array.from(this.state.legend);
        
        // LEGEND LOAD BUG
        // if(!this.state.isLoading)
        //   legendClone[0].visible = true;

        this.setState({gapiPoints:formattedPoints, legend: legendClone});
        if(!isNullorEmptyArray(this.state.loadedCustomGapiLabels)){
          this.makeGAPICustomLabels(this.state.loadedCustomGapiLabels);
          this.setState({loadedCustomGapiLabels: null});
        }
        // else if(!isNullorEmptyArray(this.state.loadedGapiLabels)){
        //   this.makeGAPIPointLabels(this.state.loadedGapiLabels);
        //   this.setState({loadedGapiLabels: null});
        //   //this.setState({gapiPointLabels: this.state.loadedGapiLabels}); //,loadedGapiLabels:null});
        // }
        // else{
          
        // }

        this.makeGAPIPointLabels(formattedPoints);
   
        //this.setState({gapiPointsLoading: false,gapiPointBounds: newBounds});
        this.setGapiPointFilter('minValue',newMinValue);
        this.setGapiPointFilter('minDisplayValue',newMinValue); //this.state.minValue);
        
        this.setState({
          isSaving: false,
          gapiPointsLoading: false,
          loadingBar: false,
          loadingMessage: '...',
          loadingPanel: false,
          loadingPanelMessage: '...',
          gapiPointBounds: newBounds
        });

      }
      else {
        //this.setState({gapiPointsLoading: false, gapiPointsErrorMessage:'There was an error, ' + result.data.message + ' please adjust filters or map view and try again'});
        //this.setState({ loadingPanel: false, loadingPanelMessage: '...', gettingDemographics:false, isSaving: false, errorBar: true, errorMessage: result.data.message });
        this.setState({
          isSaving: false,
          gapiPointsLoading: false,
          loadingBar: false,
          loadingMessage: '...',
          loadingPanel: false,
          loadingPanelMessage: '...',
          gapiPointsErrorMessage:'There was an error, ' + result.data.message + ' please adjust filters or map view and try again',
          errorBar: true,
          errorMessage:'There was an error, ' + result.data.message + ' please adjust filters or map view and try again'
        });
      
      }

    }).catch((error)=>{
      console.log(error,'atlas points error');
      //this.setState({gapiPointsLoading: false, gapiPointsErrorMessage:'There was an error, please wait a few minutes and try again'});

      this.setState({
        isSaving: false,
        gapiPointsLoading: false,
        loadingBar: false,
        loadingMessage: '...',
        loadingPanel: false,
        loadingPanelMessage: '...',
        gapiPointsErrorMessage:'There was an error, please wait a few minutes and try again'
      });
    });
  }

  makeGAPICustomLabels(feats){
    const labels = [];
    //const labelStyle = this.state.gapiPointSettings[0].labelStyle;
    feats.forEach(d=>{
      //const bbnc = getBoundingBoxAndCenterFromPosition(d.position);
      //label.content.offsetPosition
      let _text = d.content.text || d.content.Traffic1 || d.content.traffic_co;
      //console.log(_text);
      _text = _text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      //console.log(_text);
      const lbl = {
        content:{
          id: d.content.id || d.content.OBJECTID,
          count: d.content.count || d.content.Traffic1 || d.content.traffic_co,
          text: _text,
          color: d.content.color,
          stroke: d.content.stroke,
          fontSize: d.content.fontSize,
          opacity: d.content.opacity,
          font: d.content.font,
          weight: d.content.weight,
          offsetPosition: d.content.offsetPosition || 'upper-right',
          customLabel: d.content.customLabel
        },
        position: d.position
      }

      let icon = this.getLabelIcon(lbl);
      lbl.content.icon = icon;
      labels.push(lbl);
    });
    this.setState({
      gapiPointCustomLabels: labels
    });
  }

  makeGAPIPointLabels(feats){
  //const existingPointArray = 
  const existingLabels = this.state.gapiPointLabels;
  const existingIds = existingLabels.map(p=>p.content.id);
  // console.log(existingIds,'existingIds');
  // console.log(feats,'feats');
  const newPoints = feats.filter(p=>!existingIds.includes(p.content.id || p.content.OBJECTID));
  //console.log(newPoints,'newPoints');
    let labels = [];
    const labelStyle = this.state.gapiPointSettings[0].labelStyle;
    newPoints.forEach(d=>{
      //const bbnc = getBoundingBoxAndCenterFromPosition(d.position);
      //label.content.offsetPosition
      let _text = d.content.text || d.content.Traffic1 || d.content.traffic_co;
      //console.log(_text);
      _text = _text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      //console.log(_text);
      const lbl = {
        content:{
          id: d.content.id || d.content.OBJECTID,
          count: d.content.count || d.content.Traffic1 || d.content.traffic_co,
          text: _text,
          color: d.content.color || labelStyle.labelColor,
          stroke: d.content.stroke || labelStyle.labelStroke,
          fontSize: d.content.fontSize || labelStyle.labelFontSize,
          opacity: d.content.opacity || labelStyle.labelOpacity,
          font: d.content.font || labelStyle.labelFont,
          weight: d.content.weight || labelStyle.labelWeight,
          offsetPosition: d.content.offsetPosition || 'upper-right',
          customLabel: d.content.customLabel
        },
        position: d.position
      }

      let icon = this.getLabelIcon(lbl);
      lbl.content.icon = icon;
      labels.push(lbl);
    });
    labels = labels.concat(existingLabels);

    this.setState({
      gapiPointLabels: labels
    });
  }

  setGAPILabelStyle(styleObj){

    const strobj = JSON.stringify(styleObj).replace("mapAddL","l");
    const newobj = JSON.parse(strobj);
    const keys = Object.keys(newobj);
    let cloneLabelSettings = this.state.gapiPointSettings;
    let thisObj = cloneLabelSettings[0];
    thisObj.labelStyle[keys[0]] = newobj[keys[0]];
    cloneLabelSettings[0] = thisObj;

    this.setState({gapiPointSettings:cloneLabelSettings});
    this.makeGAPIPointLabels(this.state.gapiPointLabels);
  }

  _getGAPIPolygons(featureRegion,featureName,mapBounds) {
    let curInfo = this.state.gapiPolygonSettings;
    let displayWasOn = curInfo.displayInLegend;
    
    curInfo.gettingGapiPolygons = true;
    curInfo.displayInLegend = false;

    this.setState({loadingMessage: 'Polygons loading...',gapiPolygonSettings: curInfo,gapiPolygons:[],gapiPolygonLabels:[]});

    // {featureId: 5, label: "Office Submarkets"},
    // {featureId: 7, label: "Industrial Submarkets"},
    // {featureId: 8, label: "Retail Submarkets"},
    // let type = 'office_submarkets';
    // let region = 'na';

    let mapDistLat = (mapBounds._northEast.lat - mapBounds._southWest.lat) / 2;
    let mapDistLng = (mapBounds._northEast.lng - mapBounds._southWest.lng) / 2;

    let secondBounds = {
      _northEast: {
          lat: mapBounds._northEast.lat,// + mapDistLat,
          lng: mapBounds._northEast.lng// + mapDistLng
      },
      _southWest: {
          lat: mapBounds._southWest.lat,// - mapDistLat,
          lng: mapBounds._southWest.lng //- mapDistLng
      }
    }

    // if(featureRegion==='us')
    // {
    //   mapDistLat = 1; //(mapBounds._northEast.lat - mapBounds._southWest.lat) * 3;
    //   mapDistLng = 2; //(mapBounds._northEast.lng - mapBounds._southWest.lng) * 3;
    // }

    let url = process.env.REACT_APP_ATLASAPIURL + "/atlaspolygons?type=" + featureName + "&region=" + featureRegion;

    let newBounds = {
      _northEast: {
          lat: mapBounds._northEast.lat + mapDistLat,
          lng: mapBounds._northEast.lng + mapDistLng
      },
      _southWest: {
          lat: mapBounds._southWest.lat - mapDistLat,
          lng: mapBounds._southWest.lng - mapDistLng
      }
    }
    url += "&mapBounds=" + JSON.stringify(newBounds) + "&secondBounds=" + JSON.stringify(secondBounds);

    axios.get(url,{ headers: headers })
    .then((result) => {

      if(result.data.features===null){
        const message = result.data.message || 'There was a problem';
        curInfo.gettingGapiPolygons = false;


        //let curInfo = this.props.gapiPolygonSettings;
        curInfo.selectedGapiPolygon = false;
        curInfo.selectedGapiPolygonLabel = '';
        curInfo.resultNames = [];
        curInfo.displayNames = [];
        //this.props.updateMapObj({gapiPolygons: [],gapiPolygonLabels: [],gapiPolygonSettings: curInfo});

        this.setState({
          errorBar: true,
          errorMessage: message, //'There are no polygons in map',
          gapiPolygonSettings: curInfo,
          gapiPolygons: [],
          loadingPanel: false
        });

      }
      else{
        curInfo.gettingGapiPolygons = false;
        curInfo.displayInLegend = displayWasOn;
        curInfo.featureRegion = featureRegion;
        curInfo.featureName = featureName;
        
        const names = [...new Set(result.data.features.map(n => n.data.displayname))];
        names.sort();
        curInfo.resultNames = names;
  
        const labelFeats = [];
        names.forEach(n=>{
          const thisFeat = result.data.features.filter(f=>f.data.displayname===n);
          if(thisFeat.length===1){
            labelFeats.push(thisFeat[0]);
          }
          else{
            const maxArea = thisFeat.reduce((prev,current) => {return prev.data.area > current.data.area ? prev : current});
            labelFeats.push(maxArea);
          }
        })
  
  
        this._makeGAPIPolygonLabels(labelFeats);
  
        const legendClone = Array.from(this.state.legend);
        legendClone[0].visible = true;
        let returnBounds = result.data.bounds;// || newBounds;

        let finalBounds = null;
        if(featureName.includes('submarkets')){
          const subBounds = getBBboxFromFeatures(result.data.features);
          finalBounds ={ 
            _northEast: {
              lat: newBounds._northEast.lat > subBounds._northEast.lat ? newBounds._northEast.lat : subBounds._northEast.lat,
              lng: newBounds._northEast.lng > subBounds._northEast.lng ? newBounds._northEast.lng : subBounds._northEast.lng
            },
            _southWest: {
              lat: newBounds._southWest.lat < subBounds._southWest.lat ? newBounds._southWest.lat : subBounds._southWest.lat,
              lng: newBounds._southWest.lng < subBounds._southWest.lng ? newBounds._southWest.lng : subBounds._southWest.lng
            }
          }
        }
        else {
          finalBounds = returnBounds || newBounds;
        }
        console.log(curInfo,'curInfo');
        this.setState({
          //gapiPolygons: result.data.features,
          gapiPolygonSettings: curInfo,
          legend: legendClone,
          loadingBar: false,
          loadingMessage: '',
          loadingPanel: false,
          // loadingPanelMessage: '',
          gapiPolygonBounds: finalBounds
        });
      }
    })
    .catch((error) => {
      console.log('gapi Polygon errror');
      console.log(error);
      curInfo.gettingGapiPolygons = false;

      this.setState({
        errorBar: true,
        errorMessage: 'There was an error retriving polygons',
        gapiPolygonSettings: curInfo,
        loadingPanel: false
      });
    });    
  }

  updateGAPIPolygonLabel(e,label){
    const newPosition = e.target.getLatLng();

    const curlabels = this.state.gapiPolygonLabels;
    const ix = curlabels.findIndex(l=>l.content.text===label.content.text);
    let newlabel = label;
    newlabel.position = newPosition;
    curlabels[ix] = newlabel;

    this.setState({
      gapiPolygonLabels: curlabels
    });
  }

  _updateGAPIPolygonLabels(labels){
    const newlabels = []
    labels.forEach(lbl =>{
      let newlabel = {
        content:{
          text: lbl.content.text,
          color: this.state.gapiPolygonSettings.labelStyle.mapAddLabelColor,
          stroke: this.state.gapiPolygonSettings.labelStyle.mapAddLabelStroke,
          fontSize: this.state.gapiPolygonSettings.labelStyle.mapAddLabelFontSize,
          opacity: this.state.gapiPolygonSettings.labelStyle.mapAddLabelOpacity,
          font: this.state.gapiPolygonSettings.labelStyle.mapAddLabelFont,
          weight: this.state.gapiPolygonSettings.labelStyle.mapAddLabelWeight
        },
        position: lbl.position,
        boundingBox: lbl.boundingBox
      }
      const icon = this.getLabelIcon(newlabel);
      newlabel.content.icon = icon;
      newlabels.push(newlabel);
    });
    this.setState({
      gapiPolygonLabels: newlabels
    });
  }

  updateGAPIPolygonLabels(){
    const curlabels = this.state.gapiPolygonLabels;
    const newlabels = []
    curlabels.forEach(lbl =>{
      let newlabel = {
        content:{
          text: lbl.content.text,
          color: this.state.gapiPolygonSettings.labelStyle.mapAddLabelColor,
          stroke: this.state.gapiPolygonSettings.labelStyle.mapAddLabelStroke,
          fontSize: this.state.gapiPolygonSettings.labelStyle.mapAddLabelFontSize,
          opacity: this.state.gapiPolygonSettings.labelStyle.mapAddLabelOpacity,
          font: this.state.gapiPolygonSettings.labelStyle.mapAddLabelFont,
          weight: this.state.gapiPolygonSettings.labelStyle.mapAddLabelWeight
        },
        position: lbl.position,
        boundingBox: lbl.boundingBox
      }
      const icon = this.getLabelIcon(newlabel);
      newlabel.content.icon = icon;
      newlabels.push(newlabel);
    });

    this.setState({
      gapiPolygonLabels: newlabels
    });
  }

  _makeGAPIPolygonLabels(data){
    const labels = [];
    data.forEach(d=>{
      const bbnc = getBoundingBoxAndCenterFromPosition(d.position);

      const lbl = {
        content:{
          text: d.data.displayname,
          color: this.state.gapiPolygonSettings.labelStyle.mapAddLabelColor,
          stroke: this.state.gapiPolygonSettings.labelStyle.mapAddLabelStroke,
          fontSize: this.state.gapiPolygonSettings.labelStyle.mapAddLabelFontSize,
          opacity: this.state.gapiPolygonSettings.labelStyle.mapAddLabelOpacity,
          font: this.state.gapiPolygonSettings.labelStyle.mapAddLabelFont,
          weight: this.state.gapiPolygonSettings.labelStyle.mapAddLabelWeight
        },
        position:{
          lat:bbnc.centroidy, //d.position[0].lat,
          lng:bbnc.centroidx //d.position[1].lng
        },
        boundingBox:{
          minx:bbnc.minx,
          miny:bbnc.miny,
          maxx:bbnc.maxx,
          maxy:bbnc.maxy
        }
      }

      let icon = this.getLabelIcon(lbl);
      lbl.content.icon = icon;
      labels.push(lbl);
    });
    this.setState({
      gapiPolygonLabels: labels
    });
  }

  zoomToGAPIPolygon(displayname) {
    const poly = this.state.gapiPolygons.filter(p=>p.displayname===displayname);
    //console.log(poly);
    if(poly.length===0){
      return;
    }

    let maxy = -90;
    let miny = 90;
    let maxx = -180;
    let minx = 180;

    for(let i=0;i<poly.length;i++){
      //const bb = getBoundingBoxAndCenterFromPosition(poly[i].position);
      const bb = poly[i].bounds;
      console.log(bb);
      if(bb.maxy>maxy)
        maxy = bb.maxy;
      if(bb.maxx>maxx)
        maxx = bb.maxx;
      if(bb.miny<miny)
        miny = bb.miny;
      if(bb.minx<minx)
        minx = bb.minx;
    }

    this.setState({newExtent:{
      north: maxy,
      south: miny,
      east: maxx,
      west: minx
    }});
  }

  getGAPIPolygons(featureRegion,featureName,mapBounds) {
    console.log('getGAPIPolygons');
    if(!mapBounds){
      this.setState({updateMapBounds:true});
      setTimeout(() => {
        mapBounds = this.state.mapBounds;
        this._getGAPIPolygons(featureRegion,featureName,mapBounds)
      }, 500);
    }
    else{
      this._getGAPIPolygons(featureRegion,featureName,mapBounds)
    }
  }

  // getRangeDemographics(mb,mz) {
  //   let message = 'Loading Demographics';
  //   this.setState({
  //     loadingBar: false,
  //     loadingMessage: '....',
  //     loadingPanel: true,
  //     loadingPanelMessage: message
  //   });

  //   if(this.state.mapBounds==null){
  //       return;
  //   }

  //   const demoMult = 0; //process.env.REACT_APP_USE_DEMO_MULT || 0.1;

  //   console.log(demoMult,'demoMult');

  //   const mapDistLat = (mb._northEast.lat - mb._southWest.lat) * demoMult;
  //   const mapDistLng = (mb._northEast.lng - mb._southWest.lng) * demoMult;

  //   console.log(mapDistLat,'mapDistLat');
  //   console.log(mapDistLng,'mapDistLng');
  //   //
  //   const newBounds = {
  //     _northEast: {
  //         lat: mb._northEast.lat + mapDistLat,
  //         lng: mb._northEast.lng + mapDistLng
  //     },
  //     _southWest: {
  //         lat: mb._southWest.lat - mapDistLat,
  //         lng: mb._southWest.lng - mapDistLng
  //     }
  //   }

  //   let url = process.env.REACT_APP_ATLASAPIURL + "/demographics";

  //   url += "?mapBounds=" + JSON.stringify(newBounds);
  //   url += "&variable=" + this.state.vtDemographicSettings.demographicVariable;
  //   url += "&colors=" + this.state.vtDemographicSettings.demographicColors; //JSON.stringify(selColors); //.replace('#',''); ///'ff0000','ffa500','ffff00','00ff00','0000ff'"; //ffff00
  //   url += '&geography=' + this.state.vtDemographicSettings.demographicGeography;
  //   url += '&rangetype=' + this.state.vtDemographicSettings.demographicRangeSetting; //demographicRanges;
  //   if(this.state.demographicRangeSetting==='custom'){
  //     url += '&ranges=' + this.state.vtDemographicSettings.demographicRanges;
  //   }
  //   url += '&country=' + this.state.country;
  //   url = encodeURI(url);
  //   axios.get(url,{ headers: headers })
  //   .then((result) => {
  //       if(result.data.message==="success"){
  //           //const legendClone = Array.from(this.state.legend);

  //           // LEGEND LOAD BUG
  //           // if(!this.state.isLoading)
  //           //   legendClone[0].visible = true;
  //         const nogeo = result.data.features.map(f=>f.data);
  //         this.setState({demographics: nogeo,
  //           //demographicLegend: result.data.legend,
  //           // legend: legendClone,
  //           // isError: false,
  //           // updateBasemap: true,
  //           // forceDemos: true,
  //           demographicRangeBounds: newBounds,
  //         });

  //           setTimeout(() => {
  //             this.setState({
  //                 isSaving: false,
  //                 gettingDemographics: false,
  //                 loadingBar: false,
  //                 loadingMessage: '...',
  //                 loadingPanel: false,
  //                 loadingPanelMessage: '...'
  //             });
  //           }, 2000);

  //       }
  //       else {
  //         console.log(result.data,'getDemoFail');
  //           this.setState({
  //               isSaving: false
  //             });
  //           this.setState({ loadingPanel: false, loadingPanelMessage: '...', gettingDemographics:false, isSaving: false, errorBar: true, errorMessage: result.data.message });
  //       }
  //   }).catch(error => {
  //       console.log(error);
  //       this.setState({ loadingPanel: false, loadingPanelMessage: '...', gettingDemographics: false, isSaving: false, errorBar: true, errorMessage: 'There was a problem retriving demographics' });
  //     });
  // }

  // getDemographics(mb, mz) {
  //   let message = 'Loading Demographics';
  //   this.setState({
  //     loadingBar: false,
  //     loadingMessage: '....',
  //     loadingPanel: true,
  //     loadingPanelMessage: message
  //   });

  //   if(this.state.mapBounds==null){
  //       return;
  //   }

  //   const demoMult = process.env.REACT_APP_USE_DEMO_MULT || 0.1;

  //   console.log(demoMult,'demoMult');

  //   const mapDistLat = (mb._northEast.lat - mb._southWest.lat) * demoMult;
  //   const mapDistLng = (mb._northEast.lng - mb._southWest.lng) * demoMult;

  //   console.log(mapDistLat,'mapDistLat');
  //   console.log(mapDistLng,'mapDistLng');
  //   //
  //   const newBounds = {
  //     _northEast: {
  //         lat: mb._northEast.lat + mapDistLat,
  //         lng: mb._northEast.lng + mapDistLng
  //     },
  //     _southWest: {
  //         lat: mb._southWest.lat - mapDistLat,
  //         lng: mb._southWest.lng - mapDistLng
  //     }
  //   }

  //   let url = process.env.REACT_APP_ATLASAPIURL + "/demographics";

  //   url += "?mapBounds=" + JSON.stringify(newBounds);
  //   url += "&variable=" + this.state.demographicVariable;
  //   url += "&colors=" + this.state.demographicColors; //JSON.stringify(selColors); //.replace('#',''); ///'ff0000','ffa500','ffff00','00ff00','0000ff'"; //ffff00
  //   url += '&geography=' + this.state.demographicGeography;
  //   url += '&rangetype=' + this.state.demographicRangeSetting; //demographicRanges;
  //   if(this.state.demographicRangeSetting==='custom'){
  //     url += '&ranges=' + this.state.demographicRanges;
  //   }
  //   url += '&country=' + this.state.country;
  //   url = encodeURI(url);
  //   axios.get(url,{ headers: headers })
  //   .then((result) => {
  //       if(result.data.message==="success"){
  //           const legendClone = Array.from(this.state.legend);

  //           // LEGEND LOAD BUG
  //           // if(!this.state.isLoading)
  //           //   legendClone[0].visible = true;

  //         this.setState({demographics: result.data.features,
  //           //demographicLegend: result.data.legend,
  //           legend: legendClone,
  //           isError: false,
  //           updateBasemap: true,
  //           forceDemos: true,
  //           demographicBounds: newBounds,
  //         });

  //         if(result.data.demo_layer_change){
  //           this.setState({
  //             loadingBar: true,
  //             loadingMessage: 'Geography has changed',
  //             demographicGeography:result.data.demo_layer_change,
  //             demographicRangeSetting: 'custom'
  //           });
  //         }

  //           setTimeout(() => {
  //             this.setState({
  //                 isSaving: false,
  //                 gettingDemographics: false,
  //                 loadingBar: false,
  //                 loadingMessage: '...',
  //                 loadingPanel: false,
  //                 loadingPanelMessage: '...'
  //             });
  //           }, 2000);

  //       }
  //       else {
  //         console.log(result.data,'getDemoFail');
  //           this.setState({
  //               isSaving: false
  //             });
  //           this.setState({ loadingPanel: false, loadingPanelMessage: '...', gettingDemographics:false, isSaving: false, errorBar: true, errorMessage: result.data.message });
  //       }
  //   }).catch(error => {
  //       console.log(error);
  //       this.setState({ loadingPanel: false, loadingPanelMessage: '...', gettingDemographics: false, isSaving: false, errorBar: true, errorMessage: 'There was a problem retriving demographics' });
  //     });
  // }

  // const use_us_api = process.env.REACT_APP_USE_USDTAPI || 'true';
  // if(this.props.country === 'US' && use_us_api === 'true'){
  //   return await this._getNewDriveTime_US(radius,lat,lng);
  // }
  // return await this._getNewDriveTime_Intl(radius,lat,lng);

  async getDriveTime(radius,lng,lat,type) {
    this.setState({gettingDriveTimes: true});
    if(this.state.country==='US' && process.env.REACT_APP_USE_USDTAPI === 'true'){
      const _res = await this._getDriveTimeUS(radius,lng,lat,type);
      this.setState({gettingDriveTimes: false});
      return _res;
    }
    else if(this.state.country!=='OT'){
      const _res = await this._getDriveTime(radius,lng,lat,type);
      this.setState({gettingDriveTimes: false});
      return _res;
    }
    return null;
  }

  async _getDriveTimeUS(radius,lng,lat,type) {
    const gcAtlasToken = await this.retriveGCAtlasToken();
    const driveTimeMode = GetDriveTimeModeInteger({type: type});
    const dtUrl = process.env.REACT_APP_USDTAPI;
    const dtQry = 'f=json&outSr=4326&defaultBreaks=' + radius + '&facilities=' + lng + ',' + lat
      + '&travelMode=' + driveTimeMode + '&splitPolygonsAtBreaks=false&trimOuterPolygon=true&trimPolygonDistance=100&trimPolygonDistanceUnits=esriMeters&useHierarchy=true'
      + '&token=' + gcAtlasToken; // this.props.gcAtlasToken;
    //const agent = new https.Agent({ rejectUnauthorized: false });
    //let res = await axios.post(dtUrl,dtQry,{httpsAgent: agent}).then(result=>{
    let returnRoutes = await axios.post(dtUrl,dtQry).then(result=>{
      if (result.status === 200 && result.data) {
        try {
          let features = result.data.saPolygons.features;
          
          //reverse arrays so they are lat/lng, not lnt/lat
          let reverseRoutes = [];
          let finalArray = [];
          //sort features so the larger rings are first
          features.sort((a, b) => parseFloat(b.attributes.AREA_DESC) - parseFloat(a.attributes.AREA_DESC));
          for (const feature of features) {
            //let featureArray = feature.geometry.rings;

            ///// BUG FIX
            ///// some dt return multiple polygons, this will take the polygon with the most nodes and use that.
            ///// TODO:   handle multiple polygons (42 Cedar Ln. Stafford VA was the sample )

            let rte = feature.geometry.rings.reduce(function (prev,current) { return (prev.length > current.legnth) ? prev : current });
            const reverseArray = rte.map(innerArray => innerArray.reverse());
            reverseRoutes.push(reverseArray);
          }

          finalArray.push(reverseRoutes);

          this.setState({
            isLoading: false,
          });
          //this.props.updateStatus('isLoadingHistory', false);
          return finalArray;
        }
        catch (e){
          return null;
        }

      } else {
      this.setState({
        isLoading: false,
        isDriveError: true,
        isError: true
      });
      //this.props.updateStatus('isLoadingHistory', false);
      return false;
    }
    });
    return returnRoutes;
  }

  async _getDriveTime(radius, lng, lat,type) {
    let CancelToken = axios.CancelToken;
    let source = CancelToken.source();

      // this.setState({
      //   isLoading: true,
      //   isDriveError: false,
      //   isError: false
      // });
      const isIntl = this.state.country === 'US' ? false : true;
      const driveTimeMode = GetDriveTimeMode({type: type},isIntl);
      const returnRoutes = await axios({
        method: 'get',
        url: process.env.REACT_APP_HYDRAAPI + '/drawStudyAreas?lat=' + lat + '&lng=' + lng + '&rings=[' + radius + ']&atlasmode=' + driveTimeMode + '&isintl=' + isIntl + '&country=' + this.state.country,
        headers: headers,
        cancelToken: source.token
      }).then((result) => {
        if (result.status === 200 && result.data) {
          //const routes = result.data[0].featureset.features[0].geometry.rings;
          let features = result.data[0].featureset.features;
          //reverse arrays so they are lat/lng, not lnt/lat
          let reverseRoutes = [];
          let finalArray = [];
          //sort features so the larger rings are first
          features.sort((a, b) => parseFloat(b.attributes.AREA_DESC) - parseFloat(a.attributes.AREA_DESC));
          for (const feature of features) {
  
            let biggestringlen = 0;
            let biggestringix = -1;
            for(let i=0;i<feature.geometry.rings.length;i++){
              if(feature.geometry.rings[i].length > biggestringlen){
                biggestringlen = feature.geometry.rings[i].length;
                biggestringix = i;
              }
            }
            let rte = feature.geometry.rings[biggestringix];
  
            
            const reverseArray = rte.map(innerArray => innerArray.reverse());
            reverseRoutes.push(reverseArray);
  
            // for (const rte of featureArray) {
            //   console.log(rte,'rte');
            //   const reverseArray = rte.map(innerArray => innerArray.reverse());
            //   reverseRoutes.push(reverseArray);
            // }
          }
  
          finalArray.push(reverseRoutes);
  
          // this.setState({
          //   isLoading: false,
          // });
          return finalArray;
        } else {
          // this.setState({
          //   isLoading: false,
          //   isDriveError: true,
          //   isError: true
          // });
          // this.props.updateStatus('isLoadingHistory', false);
          return false;
        }
      }).catch((error) => {
        console.log(error);
        this.setState({
          isLoading: false,
          isDriveError: true,
          isError: true
        });
        return false;
      });
  
      return returnRoutes;
  }
  
  _getIconOffset(retailPts,logoSize) {
    let formattedArray = [];
    //resize
    let retailPtsClone = Array.from(retailPts);
    for (const retPt of retailPtsClone) {
      retPt.fullcolorsvg = ResizeLogo(retPt.fullcolorsvg, logoSize); //this.props.mapObj.logoSize);
    }
    //icon offset
    for (const point of retailPtsClone) {
      point.iconOffset = GetIconOffset(point.fullcolorsvg, 'logo');
      formattedArray.push(point);
    }
    return formattedArray;
  }

  closeErrorBar() {
    this.setState({errorBar: false, errorMessage:''});
  }

  closeInfoBar() {
    this.setState({infoBar: false, infoMessage:''})
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", function (e) {
      var confirmationMessage = translateObj.beforeUnloadMessage["eng"];
      e.returnValue = confirmationMessage;     // Gecko, Trident, Chrome 34+
      return confirmationMessage;              // Gecko, WebKit, Chrome <34
    });
  }

  render() {
    var loadingClass = this.state.isLoading ? 'loaderShow' : 'loaderHide';
    var mainClass = this.state.isLoading ? 'loaderHide' : 'loaderShowMain';
    return (
      <div className="appDiv">
        <div className={loadingClass}>
          <div className="loader">
          </div>
        </div>
        <div className={mainClass}>
          <NavBar
            country={this.state.country}
            subTitle={'Build Our Own Maps'}
            link={'/support'}
            linkTitle={translateObj.supportCenter[this.state.translate]}
            isMain={true}
            setLanguage={(val) => this.setState({ translate: val })}
            translate={this.state.translate}
          >
          </NavBar>
          <div className="pageContainer">
            <SideBar
              key={this.props.currentGroup + "_" + this.state.country}
              mapAddPt={this.state.mapAddPt}
              basemap={this.state.mapOptionsBasemap}
              layout={this.state.mapOptionsLayout}
              mapid={this.state.mapId}
              title={this.state.mapOptionsTitle}
              userid={this.state.userId}
              points={this.state.mapPoints}
              groups={this.state.mapGroups}
              demographicLegend={this.state.demographicLegend}
              demographicColors={this.state.demographicColors}
              //demographicLength={this.state.demographics.length}
              hasDemographics={this.state.hasDemographics}
              currentPoint={this.state.currentPoint}
              currentGroup={this.state.currentGroup}
              isStyling={this.state.isStyling}
              isStylingFromAdd={this.state.isStylingFromAdd}
              isReGeocoding={this.state.isReGeocoding}
              createNewPoint={this.createNewPoint}
              deletePoint={this.deletePoint}
              updateOrderAndIcon={updateOrderAndIcon}
              popupOpen={this.state.popupOpen}
              mapObj={this.state}
              updateMapObj={this.updateMapObj}
              headers={headers}
              setPointIcons={this.setPointIcons}
              getLabelIcon={this.getLabelIcon}
              getShieldIcon={this.getShieldIcon}
              isEditingRing={this.state.isEditingRing}
              isEditingRingFromAdd={this.state.isEditingRingFromAdd}
              isLabeling={this.state.isLabeling}
              rings={this.state.rings}
              driveTimes={this.state.driveTimes}
              legend={this.state.legend}
              country={this.state.country}
              importPoints={this.state.importPoints}
              uploadPtObj={this.state.uploadPtObj}
              translate={this.state.translate}
              pointLeaderWidth={this.state.pointLeaderWidth}
              pointLeaderOpacity={this.state.pointLeaderOpacity}
              pointLeaderAnchor={this.state.pointLeaderAnchor}
              customLayers={this.state.customLayers}
              scaleLocation={this.state.scaleLocation}
              secyGeoName={this.state.secyGeoName}
              getGAPIPolygons={this.getGAPIPolygons}
              gapiPolygonSettings={this.state.gapiPolygonSettings}
              updateGAPIPolygonLabels={this.updateGAPIPolygonLabels}
              zoomToGAPIPolygon={this.zoomToGAPIPolygon}
              setGapiIcon={this.setGapiIcon}
              setGAPILabelStyle={this.setGAPILabelStyle}
              setGapiPointFilter={this.setGapiPointFilter}
              gapiPointLength={this.state.gapiPoints.length}
              //redrawDemos={this.state.redrawDemos}
 			        supergeo={this.state.supergeo}
              badGeocodes={this.state.badGeocodes}
              undoMoveLocation={this.state.undoMoveLocation}
              retriveGCAtlasToken={this.retriveGCAtlasToken}
              getDriveTime={this.getDriveTime}
              gettingDriveTimes={this.state.gettingDriveTimes}
              addedPoint={this.state.addedPoint}
            ></SideBar>
            <MapComponent
              mapAddPt={this.state.mapAddPt}
              basemap={this.state.mapOptionsBasemap}
              points={this.state.mapPoints}
              groups={this.state.mapGroups}
              currentGroup={this.state.currentGroup}
              createNewPoint={this.createNewPoint}
              deletePoint={this.deletePoint}
              deleteShape={this.deleteShape}
              mapCenter={this.state.mapCenter}
              mapObj={this.state}
              updateMapObj={this.updateMapObj}
              geocodingSuccess={this.state.geocodingSuccess}
              rings={this.state.rings}
              driveTimes={this.state.driveTimes}
              mapLoaded={this.state.mapLoaded}
              updateOrderAndIcon={updateOrderAndIcon}
              createNewLabel={this.createNewLabel}
              getLabelIcon={this.getLabelIcon}
              getRingLabelIcon={this.getRingLabelIcon}
              createNewShield={this.createNewShield}
              getShieldIcon={this.getShieldIcon}
              getShield={this.getShield}
              country={this.state.country}
              translate={this.state.translate}
              pointLeaderColor={this.state.pointLeaderColor}
              pointLeaderWidth={this.state.pointLeaderWidth}
              pointLeaderOpacity={this.state.pointLeaderOpacity}
              pointLeaderAnchor={this.state.pointLeaderAnchor}
              getRetailPoints={this.getRetailPoints}
              getDemographics={this.getDemographics}
              getRangeDemographics={this.getRangeDemographics}
              getGAPIPoints = {this.getGAPIPoints}
              getGAPIPolygons = {this.getGAPIPolygons}
              getRealGAPIPoints = {this.getRealGAPIPoints}
              secyGeoName={this.state.secyGeoName}
              updateGAPIPolygonLabel={this.updateGAPIPolygonLabel}
              retriveGCAtlasToken={this.retriveGCAtlasToken}
              getDriveTime={this.getDriveTime}
              headers={headers}
            ></MapComponent>
          </div>

          <div className="maskContainer">
            {this.state.mapOptionsLayout !== 'none'
              ? <MaskComponent
                key={this.state.pixelBounds ? this.state.pixelBounds[0] + "_" + this.state.pixelBounds[1] + "_" + this.state.mapOptionsLayout + this.state.legend[0].scale : "0_0_" + this.state.mapOptionsLayout + "_" + this.state.mapLoaded + "_" + this.state.customLayoutDimensions[0] + "_" + this.state.customLayoutDimensions[1]}
                layout={this.state.mapOptionsLayout}
                pixelBounds={this.state.pixelBounds || [80, 80]}
                updateMapObj={this.updateMapObj}
                mapObj={this.state || {}}
                legend={this.state.legend}
                mapLoaded={this.state.mapLoaded}
                scaleText={this.state.scaleText}
                scaleWidth={this.state.scaleWidth}
                scaleLocation={this.state.scaleLocation}
                demographicLength={this.state.demographics.length}
                gapiPolygonSettings={this.state.gapiPolygonSettings}
                gapiPointLength={this.state.gapiPoints.length}
                translate={this.state.translate}
              ></MaskComponent>
              : null
            }
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            ContentProps={{
              classes: {
                root: 'snackBarSuccess'
              }
            }}
            autoHideDuration={5000}
            message={this.state.infoMessage}
            open={this.state.infoBar}
            onClose={() => {this.setState({infoBar:false});}}
            />
          </div>
          <div id="snackbar">
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            ContentProps={{
              classes: {
                root: 'snackBarSuccess'
              }
            }}
            message={this.state.loadingMessage}
            open={this.state.loadingBar}
            autoHideDuration={600000}
            onClose={() => {this.setState({loadingBar:false});}}
            />
            <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            ContentProps={{
              classes: {
                root: 'snackBarRed'
              }
            }}
            autoHideDuration={5000}
            message={this.state.errorMessage}
            open={this.state.errorBar}
            onClose={this.closeErrorBar}
            />
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            ContentProps={{
              classes: {
                root: 'snackBarWarn'
              }
            }}
            autoHideDuration={5000}
            message={this.state.infoMessage}
            open={this.state.infoBar}
            onClose={this.closeInfoBar}
            />

          <GeocodingSnackbar
            geocodingSuccess={this.state.geocodingSuccess}
            updateMapObj={this.updateMapObj}
            objProp={'geocodingSuccess'}
            message={translateObj.uploadSuccess[this.state.translate]}
            overlapDetected={this.state.overlapDetected}
            legendOverlapDetected={this.state.legendOverlapDetected}
            tooManyLogos={this.state.tooManyLogos}
            noPointsInLegend={this.state.noPointsInLegend}
            badGeocodes={this.state.badGeocodes}
          />
        </div>
        {this.state.loadingPanel ? 
        <LoadingPanel
          text={this.state.loadingPanelMessage}
        />
        : null}
      </div>
    );
  }
}

export default App;
