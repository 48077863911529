import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import SVGIconComponent from '../../SVGIconComponent/SVGIconComponent';
import RetailFilter from './RetailFilter';
import DemographicsFilter from './DemographicsFilter';
import ImportFile from './ImportFile';
import TrafficCountsFilter from './TrafficCountsFilter';
import GapiPolygonPanel from './GapiPolygonPanel';
import EarthquakeLayerPanel from './EarthquakeLayerPanel';
import AddGapiPointsPanel from './AddGapiPointsPanel';
import translateObj from '../../../utils/translate';

class AddDataPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataPanelShow: true,
      retailPanelShow: false,
      demographicsPanelShow: false,
      otherPanelShow: false,
      earthquakePanelShow: false,
      importFileShow: false,
      colliersPointsShow: false,
      subMarketLayer: {
        label:'Submarkets',
        name:'submarkets',
        url:'https://atlas.colliers.com/server/rest/services/Hosted/_ColliersSubmarkets/FeatureServer/1',
        type:'polygon',

        filters: [
          {order: 0, value:'type',label:"Type",type:"select",values:['','Office','Industrial','Retail'],selectedValue:''},
          {order: 1, value:'market',label:"Market",type:"select",values:[], valuesFromTable: true, selectedValue:''},
          {order: 2, value:'name',label:"Name",type:"select",values:[], valuesFromTable: true, selectedValue:''}
        ],

        // filters: [
        //   {order: 0, value:'type',label:"Type",type:"select",values:['','Office','Industrial','Retail'],selectedValue:''},
        //   {order: 1, value:'name',label:"Name",type:"select",values:[], valuesFromMap: true, selectedValue:''}
        // ],

        //filter:{value:'type',label:"Type",type:"select",values:['Office','Industrial','Retail']},
        //,values:['Office','Industrial','Retail']
        labelColumn: 'name',
        minZoom: 6
      },
      gapiPolygonTitle: "Add Polygons"
    }

    this.closePanel = this.closePanel.bind(this);
    this.toggleRetailFilter = this.toggleRetailFilter.bind(this);
    this.toggleDemographicsFilter = this.toggleDemographicsFilter.bind(this);
    this.toggleOtherLayersFilter = this.toggleOtherLayersFilter.bind(this);
    this.toggleImportFile = this.toggleImportFile.bind(this);
    this.toggleEarthquakePanel = this.toggleEarthquakePanel.bind(this);
    this.toggleColliersPoints = this.toggleColliersPoints.bind(this);
  }

  closePanel() {
    this.props.togglePanel('addData');
  }

  // componentDidMount(){
  //   if(this.props.redrawDemos){
  //     this.setState({
  //       drawPanelShow: false,
  //       demographicsPanelShow: true});
  //   }
  // }

  toggleRetailFilter(bool1, bool2) {
    this.setState({
      dataPanelShow: bool1,
      retailPanelShow: bool2
    });
  }

  toggleDemographicsFilter(bool1, bool2) {
    this.setState({
      dataPanelShow: bool1,
      demographicsPanelShow: bool2,
    });
  }

  toggleOtherLayersFilter(bool1, bool2) {
    this.setState({
      dataPanelShow: bool1,
      otherPanelShow: bool2
    });
  }
  
  toggleEarthquakePanel(bool1, bool2) {
    this.setState({
      dataPanelShow: bool1,
      earthquakePanelShow: bool2
    });
  }

  toggleImportFile(bool1, bool2) {
    this.setState({
      dataPanelShow: bool1,
      importFileShow: bool2
    });
  }

  toggleColliersPoints(bool1, bool2) {
    this.setState({
      dataPanelShow: bool1,
      colliersPointsShow: bool2
    });
  }

  toggleTrafficCounts(bool1, bool2) {
    this.setState({
      dataPanelShow: bool1,
      trafficCountPanelShow: bool2
    });
  }

  toggleSubMarkets(bool1, bool2) {
    this.setState({
      dataPanelShow: bool1,
      gapiPolygonShow: bool2,
      gapiPolygonTitle: 'Add Submarkets'
    });
    this.props.updateMapObj({
      gapiPolygonType:{
      title: "Submarkets",
        types: [
        {featureId: 5, featureName: 'office_submarkets',featureRegion: 'na', label: "Office Submarkets"},
        {featureId: 7, featureName: 'industrial_submarkets',featureRegion: 'na', label: "Industrial Submarkets"},
        {featureId: 8, featureName: 'retail_submarkets',featureRegion: 'na', label: "Retail Submarkets"}
        ] 
      }
    })

  }

  toggleStdGeos(bool1,bool2) {
    this.setState({
      dataPanelShow: bool1,
      gapiPolygonShow: bool2,
      gapiPolygonTitle: 'Add Standard Geographies'
    });
      this.props.updateMapObj({
        gapiPolygonType:{
        title: "Standard Geographies",
          types: [
            {featureId: 1, featureName: 'places',featureRegion: 'us', label: "Cities and Towns (Places)"},
            {featureId: 2, featureName: 'zip_codes',featureRegion: 'us', label: "Zip Codes"},
            {featureId: 3, featureName: 'counties',featureRegion: 'us', label: "Counties"}          
          ]
        }  
    });
  }        




  render() {
    return (
      <div className="sidePanelStyle">
        {this.state.dataPanelShow ?
          <div>
            <h2 className="panel-title">
              <span className="panel-icon-span">
                <SVGIconComponent name={'addDataBtnIcon'} color={'#0c9ed9'} size={'16px'} />
              </span>
              {translateObj.addDataTitle[this.props.translate]}
              <span className="panel-close closeStyle"><CloseIcon onClick={this.closePanel} fontSize="small" /></span>
            </h2>
            <div className="panel-body">
              {this.props.country === 'US' || this.props.country === 'CA' ?
                <div className="addPtsBtnsDiv padding-t20">
                  <div>
                    <button className="geocodeBtn" onClick={this.toggleRetailFilter.bind(this, false, true)}>Add Retail Logos</button>
                  </div>
                </div>
              : null
              }
              {this.props.country === 'US' || this.props.country === 'CA' || this.props.country === 'AUS' ?
              <div className="addPtsBtnsDiv padding-t20">
                <div>
                  <button className="geocodeBtn" onClick={this.toggleDemographicsFilter.bind(this, false, true)}>Add Demographics</button>
                </div>
              </div>
              : null
              }
              {this.props.country === 'US' || this.props.country === 'CA' ?
              <div>
                <div className="addPtsBtnsDiv padding-t20">
                  <div>
                    <button className="geocodeBtn" onClick={this.toggleTrafficCounts.bind(this, false, true)}>Add Traffic Counts</button>
                  </div>
                </div>
                </div>
              : null }
              {this.props.country === 'US' || this.props.country === 'CA' ? 
                <div>
                  <div className="addPtsBtnsDiv padding-t20">
                    <div>
                      <button className="geocodeBtn" onClick={this.toggleSubMarkets.bind(this, false, true)}>Add Submarkets</button>
                    </div>
                  </div>
                </div>
              : null }

              {this.props.country === 'US' ? 
                <div>
                  <div className="addPtsBtnsDiv padding-t20">
                    <div>
                      <button className="geocodeBtn" onClick={this.toggleStdGeos.bind(this, false, true)}>Add Standard Geographies</button>
                    </div>
                  </div>
                </div>
              : null }


			    {this.props.country === 'JPN' &&
                <div className="addPtsBtnsDiv padding-t20">
                  <div>
                    <button
                      className="geocodeBtn"
                      onClick={this.toggleEarthquakePanel.bind(this, false, true)}
                    >
                      {translateObj.addEarthquakeBtn[this.props.translate]}
                    </button>
                  </div>
                </div>
              }
              

              <div className="addPtsBtnsDiv padding-t20">
                <div>
                  <button className="geocodeBtn" onClick={this.toggleImportFile.bind(this, false, true)}>
                    {translateObj.importGeojsonBtn[this.props.translate]}
                  </button>
                </div>
              </div>

              <div className="addPtsBtnsDiv padding-t20">
                <div>
                  <button className="geocodeBtn" onClick={this.toggleColliersPoints.bind(this, false, true)}>
                    {"Add Colliers Offices"}
                    {/* {translateObj.importGeojsonBtn[this.props.translate]} */}
                  </button>
                </div>
              </div>



            </div>
          </div>
          : null
        }
        {this.state.retailPanelShow ?
          <RetailFilter
            togglePanel={this.props.togglePanel}
            toggleRetailFilter={this.toggleRetailFilter}
            mapObj={this.props.mapObj}
            updateMapObj={this.props.updateMapObj}
            updateIsSaving={this.props.updateIsSaving}
            headers={this.props.headers}
          />
          : null
        }
        {this.state.demographicsPanelShow ?
          <DemographicsFilter 
            togglePanel={this.props.togglePanel}
            toggleDemographicsFilter={this.toggleDemographicsFilter}
            mapObj={this.props.mapObj}
            updateMapObj={this.props.updateMapObj}
            updateIsSaving={this.props.updateIsSaving}
            headers={this.props.headers}
            country={this.props.country}
            getDemographics={this.props.getDemographics}
            //redrawDemos={this.props.redrawDemos}
          />
          : null
        }
        {
          this.state.trafficCountPanelShow ?
          <TrafficCountsFilter
            togglePanel={this.props.togglePanel}
            customLayers={this.props.mapObj.customLayers}
            updateMapObj={this.props.updateMapObj}
            updateIsSaving={this.props.updateIsSaving}
            closePanel={this.closePanel}
            setGapiIcon={this.props.setGapiIcon}
            setGAPILabelStyle={this.props.setGAPILabelStyle}
            gapiPointSettings={this.props.mapObj.gapiPointSettings}
            setGapiPointFilter={this.props.setGapiPointFilter}
            gapiPointsLoading={this.props.mapObj.gapiPointsLoading}
            gapiPointsErrorMessage={this.props.mapObj.gapiPointsErrorMessage}
            hasGapiPoints={this.props.mapObj.gapiPoints.length > 0 ? true : false}
          />
          : null
        }
        {
          this.state.gapiPolygonShow ?
          <GapiPolygonPanel
          closePanel={this.closePanel}
          //getGAPIPolygons={this.props.getGAPIPolygons}
          updateMapObj={this.props.updateMapObj}
          gapiPolygonSettings={this.props.gapiPolygonSettings}
          updateGAPIPolygonLabels={this.props.updateGAPIPolygonLabels}
          zoomToGAPIPolygon={this.props.zoomToGAPIPolygon}
          gapiTypes={this.props.mapObj.gapiPolygonType.types}
          panelTitle={this.state.gapiPolygonTitle}
          gapiPolygons={this.props.mapObj.gapiPolygons}
          //getGapiPolygonsNow={this.props.getGapiPolygonsNow}
          />
          :null
        }
        {this.state.importFileShow ? 
          <ImportFile
          togglePanel={this.props.togglePanel}
          toggleImportFile={this.toggleImportFile}
          mapObj={this.props.mapObj}
          updateMapObj={this.props.updateMapObj}
          groups={this.props.groups}
          addGroup={this.saveAddGroup}
          currentGroup={this.props.currentGroup}
          points={this.props.points}
          createNewPoint={this.props.createNewPoint}
          updateStatus={this.props.updateStatus}
          legend={this.props.legend}
          headers={this.props.headers}
          country={this.props.country}
          translate={this.props.translate}
          />
          : null
        }
        {this.state.earthquakePanelShow ?
            <EarthquakeLayerPanel
              togglePanel={this.props.togglePanel}
              toggleEarthquakePanel={this.toggleEarthquakePanel}
              mapObj={this.props.mapObj}
              updateMapObj={this.props.updateMapObj}
              updateIsSaving={this.props.updateIsSaving}
              headers={this.props.headers}
              translate={this.props.translate}
              legend={this.props.legend}
            />
            :null
        }
        {
          this.state.colliersPointsShow ?
          <AddGapiPointsPanel
          layerName={"ColliersOffice"}
          layerTitle={"Colliers Offices"}
          iconName={"colliersLogo"}
          title={"Add Colliers Offices"}
          text="Add current Colliers offices as logos"
          togglePanel={this.props.togglePanel}
          toggleChild={this.toggleColliersPoints}
          mapObj={this.props.mapObj}
          updateMapObj={this.props.updateMapObj}
          updateIsSaving={this.props.updateIsSaving}
          headers={this.props.headers}
          translate={this.props.translate}
          legend={this.props.legend}
          setGapiIcon={this.props.setGapiIcon}
        />
          : null
        }
      </div>
    );
  }
}

export default AddDataPanel;