import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import AddRing from './AddRing';
import Checkbox from '@material-ui/core/Checkbox';
import translateObj from '../../../utils/translate';

class EditRings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showAddRing: false,
      showRingMain: true,
      isDrive: false,
      toggleRingLegend: false,
    }

    this.toggleAddRing = this.toggleAddRing.bind(this);
    this.closePanel = this.closePanel.bind(this);
    this.toggleRinginLegend = this.toggleRinginLegend.bind(this);
  }

  componentDidMount() {
    const pointRings = this.props.rings.filter(ring => ring.pointId === this.props.currentPoint.id);
    const pointRoutes = this.props.driveTimes.filter(ring => ring.pointId === this.props.currentPoint.id);
    let inLegend = false;
    if(pointRings.length > 0){
      if(pointRings[0].displayInLegend === true){
        inLegend = true;
      }    
    }
    if(pointRoutes.length > 0){
      if(pointRoutes[0].displayInLegend === true){
        inLegend = true;
      }    
    }

    this.setState({toggleRingLegend:inLegend});
  }

  toggleAddRing(bool, bool2, bool3) {
    this.setState({
      isDrive: bool3,
      showAddRing: bool,
      showRingMain: bool2
    });
  }

  closePanel() {
    if (!this.props.isLoadingHistory)
      if (!this.props.fromLegend) {
        this.props.updateMapObj({ isEditingRing: false, isEditingRingFromAdd: false });
      } else {
        this.props.updateMapObj({ isEditingRing: false });
        this.props.updateLegendObj({ isEditingRings: false });
      }
  }

  getDTLabel(type) {
    if (type === 'driving_time' || type === 'trucking_time' || type === 'walking_time') {
      return 'minute';
    } else {
      return this.props.country === 'US' ? 'mile' : 'km';
    }
  }

  toggleRinginLegend() {
    console.log(this.props.currentPoint);
    const ringInLegend = this.state.toggleRingLegend;
    this.setState({toggleRingLegend:!ringInLegend});

    const ringClone = this.props.rings;
    const routeClone = this.props.driveTimes;
    const pointRings = ringClone.filter(ring => ring.pointId === this.props.currentPoint.id);
    const pointRoutes = this.props.driveTimes.filter(ring => ring.pointId === this.props.currentPoint.id);


    pointRings.forEach(pr=>{
      const ix = ringClone.findIndex(r=>r.id===pr.id)
      pr.displayInLegend = !ringInLegend;
      ringClone[ix] = pr;
    });

    pointRoutes.forEach(prt=>{
      const ix = routeClone.findIndex(r=>r.id===prt.id)
      prt.displayInLegend = !ringInLegend;
      routeClone[ix] = prt;
    })

    this.props.updateMapObj({ringClone: ringClone, driveTimes: routeClone});
  }

  render() {
    const pointRings = this.props.rings.filter(ring => ring.pointId === this.props.currentPoint.id);
    const pointRoutes = this.props.driveTimes.filter(ring => ring.pointId === this.props.currentPoint.id);
    return (
      <div>
        <h2 className="panel-title">
          <span className="panel-icon-span">
            <svg id="ringsIconSmall" xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 94.5 94.5">
              <path id="Path_4383" stroke="#0c9ed9" fill="none" strokeWidth="4" d="M10.14,56A46,46,0,1,1,41,44" transform="translate(37.11 37.3)" />
              <path id="Path_4383-2" stroke="#0c9ed9" fill="none" strokeWidth="4" d="M9.72,42a31.58,31.58,0,1,0-21.21-8.18" transform="translate(37.11 37.3)" />
              <path id="Path_4384" stroke="#0c9ed9" fill="none" strokeWidth="4" d="M9.76-7.31A17.63,17.63,0,1,0,21.6-2.74" transform="translate(37.11 37.3)" />
            </svg>
          </span>
          <span>{translateObj.addEditRingsRoutesTitle[this.props.translate]}</span>
          <span className="panel-close closeStyle"><CloseIcon onClick={this.closePanel} fontSize="small" /></span>
        </h2>
        <div className="panel-body">
          {
            this.state.showRingMain ?
              <div>
                <div className="edit-ring-div">
                  <div>
                    <div>
                      <div className="geocodeDiv flexRight">
                        <button className="geocodeBtnSmall" onClick={this.toggleAddRing.bind(this, true, false, false)}>{translateObj.addEditRingBtn[this.props.translate]}</button>
                      </div>
                      {pointRings.length === 0 ?
                        <div>
                          {translateObj.noRingsAddedToThisPoint[this.props.translate]}
                        </div>
                        :
                        <div>
                          {pointRings.map((ring, i) =>
                            <div className="edit-ringRow" key={ring.id}>
                              <svg xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 0 20 20">
                                <circle fill={ring.fill} fillOpacity={ring.opacity} stroke={ring.stroke} strokeOpacity={ring.strokeOpacity} cx="10" cy="10" r="8" />
                              </svg>
                              <span style={{ paddingLeft: '5px' }}>
                                {ring.radius} {this.props.country === 'US' ? 'mile' : 'km'} ring
                          </span>
                            </div>
                          )}
                        </div>
                      }
                    </div>
                    {this.props.country !== 'OT' ?
                    <div className="padding-t20">  
                      <div className="geocodeDiv flexRight">
                        <button className="geocodeBtnSmall" onClick={this.toggleAddRing.bind(this, true, false, true)}>{translateObj.addEditRouteBtn[this.props.translate]}</button>
                      </div>

                      {pointRoutes.length === 0 ?
                        <div>
                          {translateObj.noRoutesAddedToThisPoint[this.props.translate]}
                        </div>
                        :
                        <div>
                          {pointRoutes.map((ring, i) =>
                            <div className="edit-ringRow"
                              key={ring.id}
                            >
                              <svg id="driveTimeIcon" height="30px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.73 19.65">
                                <polygon points="13.86 7.76 17.16 1.19 9.8 5.17 6.59 2.11 6.42 6.24 1.1 6.92 4.35 10.05 3.12 16.27 8.15 14.61 8.15 18.28 12.55 13.02 18.47 16.48 13.86 7.76" fill={ring.fill} fillOpacity={ring.opacity} stroke={ring.stroke} strokeOpacity={ring.strokeOpacity} strokeMiterlimit="10" />
                              </svg>
                              <span style={{ paddingLeft: '5px' }}>
                                {ring.radius} {this.getDTLabel(ring.type)} route
                          </span>
                            </div>
                          )}
                        </div>
                      }
                    </div>
                    : null }
                  </div>
                  <div className="ringCheckBox">
                    <div>Display In Legend</div>
                    <Checkbox
                      color="default"
                      defaultValue="default"
                      inputProps={{ 'aria-label': 'checkbox with default color' }}
                      onChange={(event) => this.toggleRinginLegend()}
                      checked={this.state.toggleRingLegend}
                      disableRipple
                      //disabled={this.props.country === 'AUS'}
                    />
                  </div>
                </div>
                <div className="geocodeDiv flex">
                  <button
                    className="geocodeBtn"
                    onClick={this.closePanel}
                  >
                    {translateObj.cancelBtn[this.props.translate]}
                  </button>
                </div>
              </div>
              : null
          }
          {
            this.state.showAddRing ?
              <AddRing
                toggleAddRing={this.toggleAddRing}
                updateMapObj={this.props.updateMapObj}
                currentPoint={this.props.currentPoint}
                rings={this.props.rings}
                driveTimes={this.props.driveTimes}
                headers={this.props.headers}
                isDrive={this.state.isDrive}
                updateStatus={this.props.updateStatus}
                country={this.props.country}
                gcAtlasToken={this.props.gcAtlasToken}
                displayInLegend={this.state.toggleRingLegend}
                translate={this.props.translate}
                retriveGCAtlasToken={this.props.retriveGCAtlasToken}
              />
              : null
          }
        </div>
      </div>
    );
  }
}

export default EditRings;