import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import SVGIconComponent from '../../SVGIconComponent/SVGIconComponent';
import CustomPolygonStyle from '../../MapComponent/CustomPolygonStyle';
import Checkbox from '@material-ui/core/Checkbox';
import LabelStyleOnly from '../../MapComponent/LabelStyleOnly';
import SelectedRow from './SelectedRow'

class GapiPolygonPanel extends React.Component {
    constructor(props) {
      super(props);
  
        this.state = {
            type: '',
            defaultLayerStyle: false,
            defaultHighlightStyle: false,
            currentStyle: {
                fill: '#0c9ed9',
                opacity: 0.5,
                stroke: '#ffffff',
                strokeOpacity: 0.5,
                width: 3
            },
            currentHighlightStyle: {
                fill: '#ffd400',
                opacity: 0.5,
                stroke: '#ffffff',
                strokeOpacity: 0.5,
                width: 3
            },
            labelFeats: false,
            selectResultName: '',
            displaySinglePoly: false,
            higlilghtSinglePoly: false,
            displayThem7: false,
            resultNames: [],
            gapiTypes: [
                {featureId: 5, featureName: 'office_submarkets',featureRegion: 'na', label: "Office Submarkets"},
                {featureId: 7, featureName: 'industrial_submarkets',featureRegion: 'na', label: "Industrial Submarkets"},
                {featureId: 8, featureName: 'retail_submarkets',featureRegion: 'na', label: "Retail Submarkets"},
                {featureId: 1, featureName: 'places',featureRegion: 'us', label: "Places"}
            ],
            displayNames: [],
            gapiPolygons: []
        };
        this.getSubs = this.getSubs.bind(this);
        this.selectFilter = this.selectFilter.bind(this);
        this.changeFill = this.changeFill.bind(this);
        this.changeHighlightFill = this.changeHighlightFill.bind(this);
        this.clearSubs = this.clearSubs.bind(this);
        this.updateLabelStyle = this.updateLabelStyle.bind(this);
        this.selectResultName = this.selectResultName.bind(this);
        this.removeGapiPolygon = this.removeGapiPolygon.bind(this);
    }

    componentDidMount () {
        this.props.updateMapObj({updateMapBounds: true});
        this.setState({type: this.props.gapiPolygonSettings.selectedGapiPolygon, displayThem7: this.props.gapiPolygonSettings.displayThem7 || false});

        if(this.props.gapiPolygonSettings.highlightNames?.length > 0){
            this.setState({higlilghtSinglePoly: true,
                displayNames:this.props.gapiPolygonSettings.highlightNames,
                currentHighlightStyle:this.props.gapiPolygonSettings.highlightPolygonStyle
            })
        }
        else if (this.props.gapiPolygonSettings.displayNames?.length > 0){
            this.setState({displaySinglePoly: true, displayNames:this.props.gapiPolygonSettings.displayNames})
        }
        else {
            this.setState({displaySinglePoly: false})
        }

        if(this.props.gapiTypes){
            this.setState({gapiTypes:this.props.gapiTypes});
        }                        
    }

    // componentDidUpdate() {
    //     console.log(this.props.gapiPolygons);
    //     // const names=[];
    //     // this.props.gapiPolygons.map(p=>names.push(p.displayname));
    //     // console.log(names);
    //     // this.setState({resultNames:names});
    // }

    clearSubs(){
        let curInfo = this.props.gapiPolygonSettings;
        curInfo.selectedGapiPolygon = false;
        curInfo.selectedGapiPolygonLabel = '';
        curInfo.resultNames = [];
        curInfo.displayNames = [];
        curInfo.displayLabels = false;
        this.setState({type:'',displaySinglePoly: false, resultNames:[],displayNames: [],higlilghtSinglePoly: false, displayThem7: false});
        this.props.updateMapObj({clearVTPolys:true,gapiPolygonSettings:curInfo});
        
        //this.props.updateMapObj({gapiPolygons: [],gapiPolygonLabels: [],gapiPolygonSettings: curInfo, gapiPolygonBounds: null});
    }

    getSubs() {
        let curInfo = this.props.gapiPolygonSettings;
        let ix = this.state.gapiTypes.findIndex(t=>t.featureId===this.state.type);
        curInfo.selectedGapiPolygon = this.state.type;
        curInfo.selectedGapiPolygonLabel = this.state.gapiTypes[ix].label;
        curInfo.resultNames = [];
        curInfo.displayNames = [];
        this.setState({displaySinglePoly: false, gettingSubs: true, displayNames: []});
        let curType = this.state.gapiTypes[ix];
        curInfo.featureRegion = curType.featureRegion;
        curInfo.featureName = curType.featureName;
        this.props.updateMapObj({getVTPolygonOverlays:true,gapiPolygonSettings:curInfo});
    }

    // getNames() {
    //     console.log('getNames');
    //     console.log(this.props);
    // }

    // getNames() {
    //     const mapBounds = this.props.mapBounds;
    //     let curInfo = this.props.gapiPolygonSettings;
    //     //featureRegion,featureName
    //     let mapDistLat = (mapBounds._northEast.lat - mapBounds._southWest.lat) / 2;
    //     let mapDistLng = (mapBounds._northEast.lng - mapBounds._southWest.lng) / 2;

    //     let newBounds = {
    //         _northEast: {
    //             lat: mapBounds._northEast.lat + mapDistLat,
    //             lng: mapBounds._northEast.lng + mapDistLng
    //         },
    //         _southWest: {
    //             lat: mapBounds._southWest.lat - mapDistLat,
    //             lng: mapBounds._southWest.lng - mapDistLng
    //         }
    //       }

    //     let url = process.env.REACT_APP_ATLASAPIURL + "/atlaspolygons?type=" + curInfo.featureName + "&region=" + curInfo.featureRegion + "&mapBounds=" + JSON.stringify(newBounds);
        
    //     console.log(this.props.headers);
    //     axios.get(url,{ headers: this.props.headers })
    //         .then((result) => {
    //         if(result.data.features===null){
    //             console.log('no features');
    //         }
    //         else {
    //             const names = [...new Set(result.data.features.map(n => n.data.displayname))];
    //             names.sort();
    //             curInfo.resultNames = names;
    //             this.props.updateMapObj({gapiPolygonSettings:curInfo});
    //             //this.setState({displayNames:names});
    //         }
    //     });
    // }

    // getSubs () {
    //     console.log('getsubs');
    //     let curInfo = this.props.gapiPolygonSettings;
    //     let ix = this.state.gapiTypes.findIndex(t=>t.featureId===this.state.type);
    //     curInfo.selectedGapiPolygon = this.state.type;
    //     curInfo.selectedGapiPolygonLabel = this.state.gapiTypes[ix].label;
    //     curInfo.resultNames = [];
    //     curInfo.displayNames = [];
    //     this.setState({displaySinglePoly: false, gettingSubs: true, displayNames: []});
    //     this.props.updateMapObj({gapiPolygonSettings: curInfo});
    //     let curType = this.state.gapiTypes[ix];

    //     this.props.getGAPIPolygons(curType.featureRegion,curType.featureName);
    // }

    selectFilter(e) {
        this.setState({type:e.target.value});
    }

    toggleDefaultStyling (chk) {
        this.setState({defaultLayerStyle: chk});
    }

    changeHighlightFill(type,newStyle) {
        let newFill = this.state.currentHighlightStyle;
        if(type==='fill')
            newFill.fill = newStyle;
        if(type==='opacity')
            newFill.opacity = newStyle;
        if(type==='stroke')
            newFill.stroke = newStyle;
        if(type==='strokeOpacity')
            newFill.strokeOpacity = newStyle;
        if(type==='width')
            newFill.width = newStyle;
        this.setState({currentHighlightStyle: newFill});
        
        let curInfo = this.props.gapiPolygonSettings;
        curInfo.highlighPolygonStyle = newFill;
        this.props.updateMapObj({gapiPolygonSettings: curInfo,getVTPolygonOverlays: true});
    }

    changeFill(type,newStyle) {
        let newFill = this.state.currentStyle;
        if(type==='fill')
            newFill.fill = newStyle;
        if(type==='opacity')
            newFill.opacity = newStyle;
        if(type==='stroke')
            newFill.stroke = newStyle;
        if(type==='strokeOpacity')
            newFill.strokeOpacity = newStyle;
        if(type==='width')
            newFill.width = newStyle;
        this.setState({currentStyle: newFill});
        
        let curInfo = this.props.gapiPolygonSettings;
        curInfo.polygonStyle = newFill;
        this.props.updateMapObj({gapiPolygonSettings: curInfo,getVTPolygonOverlays: true});
    }

    toggleDisplaySinglePoly(chk){
        this.setState({displaySinglePoly:chk,higlilghtSinglePoly:false});
        let curInfo = this.props.gapiPolygonSettings;
        const names = chk ? this.state.displayNames : [];
        curInfo.displayNames = names;
        curInfo.highlightNames = []
        this.props.updateMapObj({gapiPolygonSettings: curInfo,getVTPolygonOverlays: true});
    }

    toggleHighlightPoly(chk){
        let newFill = this.state.currentHighlightStyle;
        this.setState({higlilghtSinglePoly:chk,displaySinglePoly:false});
        let curInfo = this.props.gapiPolygonSettings;
        const names = chk ? this.state.displayNames : [];
        curInfo.highlightNames = names;
        curInfo.displayNames = [];
        curInfo.highlightPolygonStyle = newFill;
        this.props.updateMapObj({gapiPolygonSettings: curInfo,getVTPolygonOverlays: true});
    }

    toggleThem7(chk){
        this.setState({displayThem7:chk});
        let curInfo = this.props.gapiPolygonSettings;
        curInfo.displayThem7 = chk;
        this.props.updateMapObj({gapiPolygonSettings: curInfo,getVTPolygonOverlays: true});
    }

    toggleLabelFeats (chk) {
        let curInfo = this.props.gapiPolygonSettings;
        curInfo.displayLabels = chk;
        this.props.updateMapObj({gapiPolygonSettings: curInfo});
    }

    updateLabelStyle(obj) {
        let curInfo = this.props.gapiPolygonSettings;
        let curLabel = curInfo.labelStyle;
        const name = Object.keys(obj);
        curLabel[name] = obj[name];
        curInfo.labelStyle = curLabel;
        this.props.updateMapObj({gapiPolygonSettings: curInfo});
        this.props.updateGAPIPolygonLabels();
    }

    removeGapiPolygon(name) {
        const displayNames = this.state.displayNames;
        const ix = displayNames.findIndex(n=>n===name);
        displayNames.splice(ix,1);
        this.setState({displayNames:displayNames});
        if(this.state.displaySinglePoly){
            let curInfo = this.props.gapiPolygonSettings;
            curInfo.displayNames = displayNames;
            this.props.updateMapObj({gapiPolygonSettings: curInfo});
        }
    }

    _updateDisplaySinglePoly(bool,mkt){
        let curInfo = this.props.gapiPolygonSettings;
        curInfo.displayNames = bool ? [mkt] : [];
        this.props.updateMapObj({gapiPolygonSettings: curInfo});
    }

    _addSelectedPoly(mkt){
        const selNames = this.state.displayNames;
        selNames.push(mkt);
        this.setState({displayNames:selNames});
        if(this.state.displaySinglePoly){
            let curInfo = this.props.gapiPolygonSettings;
            curInfo.displayNames = selNames;
            this.props.updateMapObj({gapiPolygonSettings: curInfo});
        }
    }

    selectResultName(e) {
        this.setState({selectResultName:e.target.value});
        if(e.target.value!=='--Select Submarket--'){
            this.props.zoomToGAPIPolygon(e.target.value);
            const find = this.state.displayNames.find(n=>n===e.target.value)
            if(!find)
                this._addSelectedPoly(e.target.value);
        }
    }

    render() {
        let newPanel = false;
        const currentName = this.props.gapiPolygonSettings?.selectedGapiPolygonLabel || 'None';
        const panelMatch = this.props.gapiTypes.find(gt=>gt.label===currentName);
        if(panelMatch===undefined){
            newPanel = true;
        }

        return (
        <div>
        <h2 className="panel-title">
            <span className="panel-icon-span">
                <SVGIconComponent name={'retailFilterIcon'} color={'#0c9ed9'} size={'16px'} />
            </span>
            {this.props.panelTitle ? 
                this.props.panelTitle
                :
                <span>Add Polygons</span>
            }
            <span className="panel-close closeStyle"><CloseIcon onClick={this.props.closePanel} fontSize="small" /></span>
        </h2>
            <div className="panel-body">
            {this.props.gapiPolygonSettings.gettingGapiPolygons ? 
                <div className="panel-loader"></div>
                :
                <div>
                {currentName === 'None' ? null 
                    : <div>Current Layer: {currentName}</div>
                }
                {this.props.gapiPolygons.length === 0 || newPanel ?
                <div className="retailerOuterDiv">
                <Select
                    labelId="filter-select"
                    id="filter-select"
                    onChange={(e) => this.selectFilter(e)}
                    value={this.state.type}
                    variant="outlined"
                    autoWidth={true}
                >
                {this.state.gapiTypes.map((gt, ix)=>
                    <MenuItem key={"gapi_type" + ix} value={gt.featureId}>{gt.label}</MenuItem>
                )}
                </Select>
                </div> : null}
                {this.props.gapiPolygons.length > 0 && !newPanel ?
                    <div>
                        <div className="retailerOuterDiv padding-t10">
                        <Select
                            native
                            labelId="filter-select"
                            id="filter-select"
                            onChange={(e) => this.selectResultName(e)}
                            value={this.state.selectResultName}
                            variant="outlined"
                            autoWidth={true}
                        >
                            <option key={'otherField_equal_none'} value={'--Select Polygon--'}>{'--Select Polygon--'}</option>
                            {this.props.gapiPolygons.map((fSelValue, idx) =>
                                <option key={'otherField_equal_' + idx} value={fSelValue.displayname}>{fSelValue.displayname}</option>
                            )}
                        </Select>
                        </div>
                    </div>
                : null}
                {this.props.gapiPolygons.length > 0 && !newPanel ?

                    <div>
                        <div className="selectedRetailerDiv">
                            <div className="selectedRetailerTag">Selected Polygons</div>
                            {this.state.displayNames.map((name,ix) => (
                            <SelectedRow
                                key={ix}
                                name={name}
                                removeRow={this.removeGapiPolygon}
                            />
                            ))}
                        </div>
                        <div>
                            <span>Display Selected Polygons:</span>
                            <Checkbox
                                color="default"
                                value={this.state.displaySinglePoly}
                                inputProps={{ 'aria-label': 'use layer style' }}
                                onChange={(event) => this.toggleDisplaySinglePoly(event.target.checked)}
                                checked={this.state.displaySinglePoly}
                                disableRipple
                            />
                        </div>
                        <div>
                            <span>Highlight Selected Polygons:</span>
                            <Checkbox
                                color="default"
                                value={this.state.higlilghtSinglePoly}
                                inputProps={{ 'aria-label': 'use layer style' }}
                                onChange={(event) => this.toggleHighlightPoly(event.target.checked)}
                                checked={this.state.higlilghtSinglePoly}
                                disableRipple
                            />
                        </div>
                        {this.state.higlilghtSinglePoly ?
                            <CustomPolygonStyle
                            toggleDefaultStyling={this.toggleDefaultStyling}
                            defaultLayerStyle={this.state.defaultHighlightStyle}
                            currentFill={this.props.gapiPolygonSettings.highlightPolygonStyle}
                            changeFill={this.changeHighlightFill}
                            />
                        : null }
                    </div>
                : null }
                {this.props.gapiPolygons.length > 0 && !newPanel ?
                <div className="retailerOuterDiv">
                    Display Random Colors:
                    <Checkbox
                        color="default"
                        value={this.state.displayThem7}
                        inputProps={{ 'aria-label': 'use layer style' }}
                        onChange={(event) => this.toggleThem7(event.target.checked)}
                        checked={this.state.displayThem7}
                        disableRipple
                    />
                </div>
                : null}
                {this.state.displayThem7 || this.props.gapiPolygons.length === 0 || newPanel ?
                    null
                :
                <CustomPolygonStyle
                    toggleDefaultStyling={this.toggleDefaultStyling}
                    defaultLayerStyle={this.state.defaultLayerStyle}
                    currentFill={this.props.gapiPolygonSettings.polygonStyle}
                    changeFill={this.changeFill}
                /> 
                }
                {this.props.gapiPolygons.length > 0 && !newPanel ?
                <div>
                Label Layer Features:
                <Checkbox
                    color="default"
                    value={this.props.gapiPolygonSettings.displayLabels}
                    inputProps={{ 'aria-label': 'use layer style' }}
                    onChange={(event) => this.toggleLabelFeats(event.target.checked)}
                    checked={this.props.gapiPolygonSettings.displayLabels}
                    disableRipple
                />
                </div> : null}
                {this.props.gapiPolygonSettings.displayLabels ?
                    <LabelStyleOnly
                        updateMapState={this.updateLabelStyle}
                        text={''}
                        readOnlyText={true}
                        color={this.props.gapiPolygonSettings.labelStyle.mapAddLabelColor}
                        stroke={this.props.gapiPolygonSettings.labelStyle.mapAddLabelStroke}
                        size={this.props.gapiPolygonSettings.labelStyle.mapAddLabelFontSize}
                        opacity={this.props.gapiPolygonSettings.labelStyle.mapAddLabelOpacity}
                        font={this.props.gapiPolygonSettings.labelStyle.mapAddLabelFont}
                        weight={this.props.gapiPolygonSettings.labelStyle.mapAddLabelWeight}
                    />
                    : null
                }

                <div className="geocodeDiv flex">
                {/* {this.props.gapiPolygonSettings.resultNames.length > 0  ? */}
                <button className="geocodeBtn" onClick={this.clearSubs}>Clear Layer</button>
                {/* : null } */}
                { this.state.type !== this.props.gapiPolygonSettings.selectedGapiPolygon ?
                <div style={{ marginLeft: 'auto' }}>
                    <button className="geocodeBtn" onClick={this.getSubs}>Get Layer</button>
                    </div>  
                    :
                    null
                }
                </div>
                </div>
            }
            </div>
        </div>
        )
    }
}

export default GapiPolygonPanel