import React, { useState } from 'react'
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import DeleteIcon from '@material-ui/icons/Delete';
import PaletteIcon from '@material-ui/icons/Palette';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import translateObj from '../../../../utils/translate';
import EditAddedPointAttrs from './EditAddedPointAttrs';

class EditAddedPoint extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        showEdit: false
      }
      this.addRings = this.addRings.bind(this);
      this.togglePointAttribute = this.togglePointAttribute.bind(this);
      this.editPointInfo = this.editPointInfo.bind(this);
    }

    togglePointAttribute() {
      this.setState({showEdit: false});
      this.props.toggleText(true);
    }

    addRings () {
      console.log('addRings');
      this.props.updateMapObj({
        currentPoint: this.props.addedPoint.content,
        isEditingRingFromAdd: true
      });
    }

    deletePoint() {
      this.props.deletePoint(this.props.addedPoint);
    }

    editPointInfo(info) {
      this.props.editPointInfo(info);
    }

    setStylePoint() {
      this.props.updateMapObj({
        currentPoint: this.props.addedPoint.content,
        currentGroup: this.props.addedPoint.content.group,
        isStylingFromAdd: true,
        isEditingRing: false,
        isLabeling: false
      });
    }

    setEditPoint() {
      this.setState({showEdit: true});
      this.props.toggleText(false);
    }

    setLabelPoint() {
      this.props.updateMapObj({
      currentPoint: this.props.addedPoint.content,
      currentGroup: this.props.addedPoint.content.group,
      isStyling: false,
      isEditingRing: false,
      isLabeling: true
      });
    }
  
    // toggleAddRings (point) {
    //   console.log(this.props)
    //   this.props.updateMapObj({
    //     currentPoint: this.props.addedPoint,
    //     isEditingRing: true
    //   });
    // }

    render() {
      return (
        <div>
          {
            this.state.showEdit ?
            <EditAddedPointAttrs
            currentPoint={this.props.addedPoint.content}
            editPoint={this.editPointInfo}
            // points={this.props.points}
            // updateMapObj={this.props.updateMapObj}
            togglePointAttribute={this.togglePointAttribute}
            // groups={this.props.groups}
            // updateOrderAndIcon={this.props.updateOrderAndIcon}
            // secyGeoName={this.props.secyGeoName}
            translate={this.props.translate}
            />
            : 
            <div className="flex">
            <div style={{ marginLeft: 'auto', cursor: 'pointer', paddingRight: '5px'  }} onClick={this.addRings.bind(this)}>
              <Tooltip title={translateObj.addEditPointTooltip[this.props.translate]} placement="top">
              <RadioButtonCheckedIcon fontSize="small" />
              </Tooltip>
            </div>
            <div style={{ cursor: 'pointer' }} onClick={this.setEditPoint.bind(this)}>
              <Tooltip title={translateObj.editPointDataTooltip[this.props.translate]} placement="top">
                <EditIcon fontSize="small" />
              </Tooltip>
            </div>
            <div style={{ cursor: 'pointer' }} onClick={this.setStylePoint.bind(this)}>
              <Tooltip title={translateObj.editPointStyleTooltip[this.props.translate]} placement="top">
                <PaletteIcon fontSize="small" />
              </Tooltip>
            </div>
            <div style={{ cursor: 'pointer' }} onClick={this.setLabelPoint.bind(this)}>
              <Tooltip title="Add Label" placement="top">
                <TextFieldsIcon fontSize="small" />
              </Tooltip>
            </div>
            <div style={{ cursor: 'pointer' }} onClick={this.deletePoint.bind(this)}>
              <Tooltip title={translateObj.deletePointTooltip[this.props.translate]} placement="top">
                <DeleteIcon fontSize="small" />
              </Tooltip>
            </div>
          </div>
          }
        </div>
      );
    }
}
export default EditAddedPoint

