import React from 'react';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import PaletteIcon from '@material-ui/icons/Palette';
import DeleteIcon from '@material-ui/icons/Delete';

const listLIStyle = (isDragging, draggableStyle) => ({
    border: '1px solid #d6d7dd',
    borderRadius: '2px',
    padding: '3px 5px',
    marginBottom: '8px',
    marginTop: '4px',
    background: '#ffffff',
    display: 'flex',
    alignItems: 'center',
    ...draggableStyle
  });

class ShapeComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          editing: false
        }
        this.editName = this.editName.bind(this);
        this.saveName = this.saveName.bind(this);
    }

    editName() {
        this.state.editing = true;
        this.props.updateShapeEditing(true)
    }

    saveName(ix,layerix,val){
        this.state.editing=false;
        this.props.updateShapeEditing(false);
        this.props.updateShapeText(ix,layerix,val);
    }

render(){
    const pg = this.props.shapeGroup;
    const ix = this.props.index;
    const layerIndex = this.props.layerIndex;
    return(
            <li
            ref={this.props.provided.innerRef}
            {...this.props.provided.draggableProps}
            {...this.props.provided.dragHandleProps}
            //style={listLIStyle2}
            style={listLIStyle(this.props.snapshot.isDragging, this.props.provided.draggableProps.style)}
          >

        {pg.type==='polygon' ?
          <svg height="30px" viewBox="0 0 20 20">
          <rect x={2} y={2} width={15} height={15} fill={pg.fill} fillOpacity={pg.opacity} strokeWidth={2} stroke={pg.stroke} strokeOpacity={pg.strokeOpacity}/>
          </svg>
        : null }
        {pg.type==='polyline' ?
        <div>
            <svg height="30px" viewBox="0 0 20 20">
            <line stroke={pg.stroke} fill="none" strokeWidth="3" x1="0" y1="10" x2="20" y2="10" />
            </svg>
        </div>
        : null}
        {pg.type==='circle' ?
        <div>
        <svg height="30px" viewBox="0 0 20 20">
            <circle stroke={pg.stroke} fill={pg.fill} fillOpacity={pg.opacity} strokeWidth="2" cx="10" cy="10" r="8" />
        </svg>
        </div>
        : null  
        }
        {this.state.editing ?
        <TextField
        defaultValue={pg.name}
        size="small"
        variant="outlined"
        onKeyPress={(e) => {
            if (e.key === 'Enter') {
            this.saveName(ix, layerIndex, e.target.value)
            }
        }}
        onBlur={(e) => {
            this.saveName(ix, layerIndex, e.target.value)
        }}
        />
        : 
        <div className="shapeLegend">
        <div>{pg.name}</div>
         {/* <button className="geocodeBtnSmall margin-l5" onClick={this.editName}>{translateObj.editBtn[this.props.translate]}</button> */}
        <button className="geocodeBtnSmall margin-l5" onClick={this.editName}>{"Edit"}</button>


        <div style={{ marginLeft: 'auto' }} onClick={() => this.props.editShapeStyle(ix, layerIndex)}>
        <Tooltip title="Edit Style" placement="top">
            <PaletteIcon  style={{ fontSize: 20, cursor: 'pointer' }}/>
        </Tooltip>
        </div>
        <div onClick={() => this.props.deleteShapeStyle(ix, layerIndex)}>
                <Tooltip title="Delete Shapes" placement="top">
                    <DeleteIcon  style={{ fontSize: 20, cursor: 'pointer' }}/>
                </Tooltip>
            </div>
        </div>
        }
        </li>
    )
    }
}

export default ShapeComponent;
