import React from 'react';
import Script from 'react-load-script';
import { Circle, Map, Marker, Polygon, Polyline, Popup, Tooltip, ZoomControl } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L, { latLngBounds } from 'leaflet';
import '../../utils/esri-leaflet-vector/mapbox-gl.css';
import PopupContent from './PopupContent/PopupContent';
import RetailPopupContent from './PopupContent/RetailPopupContent';
import '../../App.css';
import { bearing, cogo, CheckBoundsToRadius, FormatShieldData, GenerateRandomId, GetGeoCenter, GetRingRadius, GetMapPadding,
  GetShieldType, GetShieldTypeManual, GetShieldText, GetGroupName, CountDecimals, GetFourCornerBounds,
  GetCornerDirection, GetNewPositionArray, ReverseToLngLat, ReverseToLatLng, GetIconOffset, getPointTextLabelOffset,getLabelSize,LatLongToPixelXY,pixelXYToLatLong,
  isNullorEmptyArray,ResizeLogo, GetMeterDistanceForDT } from '../../utils/tools';
import CustomMapBtns from './CustomMapBtns';
import SimpleBanner from '../UpdateBanner/SimpleBanner';
import UpdateBanner from '../UpdateBanner/UpdateBanner';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import '../../utils/pm-Ortho/index';
import ShapeStylePanel from './ShapeStylePanel';
import ResizeShapePanel from './ResizeShapePanel';
import CustomLabelPanel from './CustomLabelPanel';
import CustomLabelStyle from './CustomLabelStyle';
import CustomShieldPanel from './CustomShieldPanel';
import CustomShieldStyle from './CustomShieldStyle';
import bboxClip from '@turf/bbox-clip';
import { polygon as turfPolygon, multiLineString as turfLine } from '@turf/helpers';
import AddGapiLabelPanel from './AddGapiLabelPanel';
import axios from 'axios';
import SVGIconComponent from '../SVGIconComponent/SVGIconComponent';
import ReactDOMServer from 'react-dom/server';
import Message from './Message';
import translateObj from '../../utils/translate';


class MapComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      atlasToken: this.props.atlasToken,
      prevIsSaving: false,
      prevIsPrinting: false,
      esriLeafletLoaded: false,
      esriVectorLoaded: false,
      mapBoxGLLoaded: false,
      prevMapCenter: this.props.mapObj.mapCenter,
      prevMapZoom: this.props.mapObj.mapZoom,
      prevBasemapName: this.props.basemap,
      currentZoom: this.props.mapObj.mapZoom,
      prevSidebarOpen: this.props.mapObj.sidebarOpen,
      prevOverlapDetected: this.props.mapObj.overlapDetected,
      prevCheckOverlap: this.props.mapObj.checkOverlap,
      zoomToAll: false,
      shapeFill: '#0c9ed9',
      shapeStroke: '#0c9ed9',
      shapeFillOpacity: 0.5,
      shapeStrokeOpacity: 1,
      shapeStrokeWidth: 3,
      selectedShape: [],
      showBanner: true,
      showUpdateBanner: false,
      shapeStylePanel: false,
      colorEditMode: false,
      resizeEditMode: false,
      resizeShapePanel: false,
      makeCalloutMode: false,
      shapeToEdit: [],
      distanceMeasurePts: [],
      distanceSegments: [],
      currentDistance: 0,
      distanceLabel: 0,
      resizeMarkerPoints: [],
      customLabelPanel: false,
      mapAddLabel: false,
      mapAddLabelText: 'Label',
      mapAddLabelStroke: '#4a4a4d',
      mapAddLabelColor: '#ffffff',
      mapAddLabelFontSize: 12,
      mapAddLabelOpacity: 1,
      mapAddLabelFont: 'Open Sans',
      mapAddLabelWeight: true,
      mapAddLabelPosition: 'upper-right',
      mapAddLabelAngle: '0',
      selectedLabel: null,
      selectedGapiLabel: null,
      removedGapiLabelId: null,
      //selectedLayerLabel: null,
      //potentialLayerLabel: null,
      potentialGapiLabel: null,
      customShieldPanel: false,
      mapAddShield: false,
      mapAddShieldText: '10',
      mapAddShieldColor: 'monotone',
      mapAddShieldSize: 20,
      mapAddShieldType: 'loophwy',
      selectedShield: null,
      shieldManualMode: false,
      lastClick: null,
      bannertext: '',
      editingCallout: false,
      rotateMode: false,
      closeMapMessage: false
    };

    this.panningTimeout = 0;
    this.updateMarkerPosition = this.updateMarkerPosition.bind(this);
    this.updateRetailPointPosition = this.updateRetailPointPosition.bind(this);
    this.deleteMarker = this.deleteMarker.bind(this);
    this.getGroupVisible = this.getGroupVisible.bind(this);
    this.getGroupVisibleRing = this.getGroupVisibleRing.bind(this);
    this.handleZoomEnd = this.handleZoomEnd.bind(this);
    this.handleMoveEnd = this.handleMoveEnd.bind(this);
    this._getMapZoom = this._getMapZoom.bind(this);
    this._setBasemap = this._setBasemap.bind(this);
    this._demoOpacity = this._demoOpacity.bind(this);
    this.getRingLabelOffset = this.getRingLabelOffset.bind(this);
    this.handleMapResize = this.handleMapResize.bind(this);
    this.updateMapState = this.updateMapState.bind(this);
    this._onCreated = this._onCreated.bind(this);
    this.closeBanner = this.closeBanner.bind(this);
    this.toggleShapeStyle = this.toggleShapeStyle.bind(this);
    this.toggleMapAddLabel = this.toggleMapAddLabel.bind(this);
    this.toggleLabelStyle = this.toggleLabelStyle.bind(this);
    //this.toggleLayerLabelStyle = this.toggleLayerLabelStyle.bind(this);
    this.toggleGapiPointLabel = this.toggleGapiPointLabel.bind(this);
    this.toggleMapAddShield = this.toggleMapAddShield.bind(this);
    this.toggleShieldStyle = this.toggleShieldStyle.bind(this);
    this.toggleShieldManualMode = this.toggleShieldManualMode.bind(this);
    //this.closePotentialLayerLabel = this.closePotentialLayerLabel.bind(this);
    this.closePotentialGapiLabel = this.closePotentialGapiLabel.bind(this);
    this.addGapiLabel = this.addGapiLabel.bind(this);
    this.updateGapiLabel = this.updateGapiLabel.bind(this);
    this.removeGapiLabel = this.removeGapiLabel.bind(this);
    this.readdGapiLabel = this.readdGapiLabel.bind(this);
    //this.addLayerLabel = this.addLayerLabel.bind(this);
    //this.turnOffLayerLabels = this.turnOffLayerLabels.bind(this);
    //this.turnOffGapiLabels = this.turnOffGapiLabels.bind(this);
    
    this._getPointTextLabelOffset = this._getPointTextLabelOffset.bind(this);
    this.getPointTextLabelWeight = this.getPointTextLabelWeight.bind(this);
    this.getPointTextLabel = this.getPointTextLabel.bind(this);
    this.hideRetailPoint = this.hideRetailPoint.bind(this);
    this.updateRetailPoint = this.updateRetailPoint.bind(this);
    this.closeMapMessage = this.closeMapMessage.bind(this);
    this.updateMapZoom = this.updateMapZoom.bind(this);
    this.getShapeColor = this.getShapeColor.bind(this);
    this.getLabelBoxPostions = this.getLabelBoxPostions.bind(this);
    this.updateLabelBoxes = this.updateLabelBoxes.bind(this);
    this.toggleGapiPointLabel = this.toggleGapiPointLabel.bind(this);
    this._updateShapeLegend = this._updateShapeLegend.bind(this);
    this.popup = React.createRef();
  }

  componentDidMount() {
    this._getMaxZoom();

    const mapRef = this.refs.map;
    mapRef.leafletElement.pm.addControls({
      position: 'bottomright',
      oneBlock: true,
      drawMarker: false,
      drawText: false,
      drawCircleMarker: false,
      cutPolygon: false
    });


    //dpane.setAttribute('sytle','opacity: 0.5;');


    const _banner = process.env.REACT_APP_SHOW_BANNER;
    const _updateBanner = process.env.REACT_APP_SHOW_UPDATE_BANNER;
    //const bannertext = process.env.REACT_APP_BANNER_TEXT;

    if(_updateBanner==='true')
      this.setState({updateBanner: true});
    else
      this.setState({updateBanner: false});

    if (_banner === 'false')
      this.setState({ showBanner: false });
    else
      this.setState({bannertext: process.env.REACT_APP_BANNER_TEXT })


    mapRef.leafletElement.on('pm:rotateenable', () => {
      this.toggleMapAddLabel();
      const self = this;
      self.setState({rotateMode: true});
    });

    mapRef.leafletElement.on('pm:rotatedisable', () => {
      this.toggleMapAddLabel();
      const self = this;
      self.setState({rotateMode: false});
    });

    mapRef.leafletElement.on('pm:rotateend', ({layer, startAngle, angle}) => {
      if(layer.options.id?.startsWith("Label_Box_")){
        let id = layer.options.id.replace("Label_Box_","");
        const labelClone = this.props.mapObj.labels;
        const newLabelIx = labelClone.findIndex(l => l.content.id === id);
        const newLabel = this.props.mapObj.labels[newLabelIx];
        const orgAngle =  newLabel.content.org_angle || newLabel.content.angle;
        //console.log(angle,'angle');
        //console.log(orgAngle,'orgAngle');

        let newAngle = parseInt(orgAngle) + angle; 

        //let newAngle = angle > 180 ? parseInt(orgAngle) + (360 - angle) : angle; //parseInt(orgAngle) + angle;
        //console.log(newAngle,'newAngle1');

        if(newAngle <= 0)
          newAngle = 360 + newAngle;

        //console.log(newAngle,'newAngle2');
        newAngle = parseInt(newAngle);
        newLabel.content.org_angle = orgAngle;
        newLabel.content.angle = newAngle; //angle;
        newLabel.content.icon = this.props.getLabelIcon(newLabel);
        this.props.updateMapObj({labels:labelClone});
      }
    });

    mapRef.leafletElement.on('pm:drawstart', ({ workingLayer }) => {
      const self = this;
      self.setState({mapAddPt: false});
      workingLayer.on('pm:vertexadded', e => {
        const distanceMeasureClone = Array.from(self.state.distanceMeasurePts);
        distanceMeasureClone.push(e.latlng);
        const distanceSegmentClone = Array.from(self.state.distanceSegments);
        distanceSegmentClone.push(self.state.currentDistance);
        self.setState({ distanceMeasurePts: distanceMeasureClone, distanceSegments: distanceSegmentClone });
        mapRef.leafletElement.on('mousemove', e => {
          self._getDistanceMeasure(e);
        });
      });
      workingLayer.on('pm:centerplaced', e => {
        const distanceMeasureClone = [];
        distanceMeasureClone.push(e.latlng);
        self.setState({ distanceMeasurePts: distanceMeasureClone });
        mapRef.leafletElement.on('mousemove', e => {
          self._getDistanceMeasure(e);
        });
      });
      this.toggleColorMode(false);
      this.toggleResizeMode(false);
      this.toggleMapAddLabel();
      this.toggleMapAddShield();
      this.toggleLabelStyle(false);
    });

    mapRef.leafletElement.on('pm:drawend', e => {
      mapRef.leafletElement.off('mousemove');
      //reset tooltip distance measures
      this.setState({
        distanceMeasurePts: [],
        distanceSegments: [],
        currentDistance: 0,
        distanceLabel: 0
      });
    });

    mapRef.leafletElement.on('pm:globaleditmodetoggled', e => {
      console.log('pm:globaleditmodetoggled');
      this.setState({editingCallout:false});
      this.toggleColorMode(false);
      this.toggleResizeMode(false);
      this.toggleMapAddLabel();
      this.toggleMapAddShield();
      this.toggleLabelStyle(false);
      const self = this;
      mapRef.leafletElement.eachLayer(layer => {

        //disable()
        const direction =  layer.options?.calloutDirection || 'none';
        if (!e.enabled) {
          if (layer.options && layer.options.attribution === "shapeItem") {
            self._onCreated({ layer: layer }, true, true);
          }
        }

        if(layer.options.id?.startsWith("Label_Box_")){
          layer.pm.setOptions({allowEditing: false});
        }

          if(direction!=='none'){
            layer.on('pm:markerdragend', function ({layer,indexPath,markerEvent,shape,intersectionEvent}) {
              const orglls = layer.getLatLngs()[0];
              let node = indexPath[1];
              let newLL = markerEvent.target.getLatLng();
              
              if(orglls.length===8){        
                if(node===1){
                  orglls[0] = newLL;
                }
                else if(node===2){
                  newLL.lng = orglls[1].lng;
                }
                else if(node===3){
                  newLL.lat = orglls[2].lat;
                }
                else if(node === 4){
                  newLL.lng = orglls[3].lng;
                }
                else if(node === 5){
                  newLL.lat = orglls[4].lat;
                }
                else if(node === 6){
                  newLL.lng = orglls[5].lng;
                }
                else if(node === 7){
                  orglls[0] = newLL;
                  layer.setLatLngs([orglls]);
                }
                
                orglls.splice(node,1);

                if (node === 7)
                  node = 0;
                else
                  node = node - 1;
                  
                if(node===0)
                  layer.setLatLngs([orglls]);

              }

              const editDirection = self.state.editingCallout || direction;

              if(node===0){
                const maxy = Math.max(...orglls.map(o => o.lat));
                const miny = Math.min(...orglls.map(o => o.lat));

                if(newLL.lat===maxy && editDirection.includes('upper-')){
                  const newlls = [
                    orglls[0],
                    {lat:orglls[3].lat,lng:(orglls[3].lng+orglls[0].lng)/2},
                    orglls[3],
                    orglls[2],
                    orglls[5],
                    orglls[4],
                    {lat:orglls[3].lat,lng:(orglls[4].lng+orglls[0].lng)/2}
                  ]
                  layer.setLatLngs([newlls]);
                  layer.options.calloutDirection = editDirection.replace('upper','lower');
                  self.setState({editingCallout:editDirection.replace('upper','lower')});
                }
                if(newLL.lat===miny && editDirection.includes('lower-')){
                  self.setState({editingCallout:editDirection});
                  const newlls = [
                    orglls[0],
                    {lat:orglls[4].lat,lng:(orglls[4].lng+orglls[0].lng)/2},
                    orglls[4],
                    orglls[5],
                    orglls[2],
                    orglls[3],
                    {lat:orglls[3].lat,lng:(orglls[3].lng+orglls[0].lng)/2}
                  ]
                  layer.setLatLngs([newlls]);
                  layer.options.calloutDirection = editDirection.replace('lower','upper');
                  self.setState({editingCallout:editDirection.replace('lower','upper')});
                }

              }

              if(node===1){
                orglls[1] = newLL;
                orglls[2] = {lat:newLL.lat,lng:orglls[2].lng};
                orglls[5] = {lat:newLL.lat,lng:orglls[5].lng};
                orglls[6] = {lat:newLL.lat,lng:orglls[6].lng};
                layer.setLatLngs([orglls]);
              }
              else if(node===2){
                orglls[1] = {lat:newLL.lat,lng:orglls[1].lng};
                orglls[2] = newLL;
                orglls[3] = {lat:orglls[3].lat,lng:newLL.lng};
                orglls[5] = {lat:newLL.lat,lng:orglls[5].lng};
                orglls[6] = {lat:newLL.lat,lng:orglls[6].lng};
                layer.setLatLngs([orglls]);
              }
              else if(node===3){
                orglls[2] = {lat:orglls[1].lat,lng:newLL.lng};
                orglls[3] = newLL;
                orglls[4] = {lat:newLL.lat,lng:orglls[4].lng};
                layer.setLatLngs([orglls]);
              }
              else if(node===4){
                orglls[3] = {lat:newLL.lat,lng:orglls[3].lng};
                orglls[4] = newLL;
                orglls[5] = {lat:orglls[5].lat,lng:newLL.lng};
                layer.setLatLngs([orglls]);
              }
              else if(node===5){
                orglls[4] = {lat:orglls[4].lat,lng:newLL.lng};
                orglls[5] = newLL;
                orglls[6] = {lat:newLL.lat,lng:orglls[6].lng};
                orglls[1] = {lat:newLL.lat,lng:orglls[1].lng};
                orglls[2] = {lat:newLL.lat,lng:orglls[2].lng};
                layer.setLatLngs([orglls]);
              }
              else if(node===6){
                orglls[6] = newLL;
                orglls[2] = {lat:newLL.lat,lng:orglls[2].lng};
                orglls[5] = {lat:newLL.lat,lng:orglls[5].lng};
                orglls[1] = {lat:newLL.lat,lng:orglls[1].lng};
                layer.setLatLngs([orglls]);
              }
              layer.pm.disable();
              layer.pm.enable();
            });
          }
      });
    });

    mapRef.leafletElement.on('pm:globaldragmodetoggled', e => {
      this.toggleColorMode(false);
      this.toggleResizeMode(false);
      this.toggleMapAddLabel();
      this.toggleMapAddShield();
      this.toggleLabelStyle(false);
      const self = this;
      mapRef.leafletElement.eachLayer(layer => {
        if (e.enabled) {
          layer.on('pm:dragstart', function (e) {
            if (layer.options && layer.options.radius)
              self.setState({ shapeToEdit: e.layer.getLatLng() });
          });
          layer.on('pm:dragend', function (e) {
            self._onCreated(e, true);
          });
        } else {
          layer.off('pm:dragend');
          layer.off('pm:dragstart');
        }
      });
    });

    mapRef.leafletElement.on('pm:globalremovalmodetoggled', e => {
      this.toggleColorMode(false);
      this.toggleResizeMode(false);
      this.toggleMapAddLabel();
      this.toggleMapAddShield();
      this.toggleLabelStyle(false);
      const self = this;
      mapRef.leafletElement.eachLayer(layer => {
        if (e.enabled) {
          layer.on('pm:remove', function (e) {
            console.log('pm:remove');
            self.deleteShapeFromEvent(e);
          });
        } else {
          layer.off('pm:remove');
        }
      });
    });

    mapRef.leafletElement.on('pm:globalcutmodetoggled', e => {
      this.toggleColorMode(false);
      this.toggleResizeMode(false);
      this.toggleMapAddLabel();
      this.toggleMapAddShield();
      this.toggleLabelStyle(false);
    });

    mapRef.leafletElement.on('pm:create', e => {
      this._onCreated(e);
    });

    mapRef.leafletElement.on('pm:cut', e => {
      this._onCreated(e, true);
    });

    mapRef.leafletElement.pm.Toolbar.createCustomControl({
      name: 'colorPicker',
      block: 'edit',
      className: 'mapSideButtonsDivB',
      title: 'Style Shapes',
      onClick: this.toggleColorMode.bind(this, true),
      actions: [{ text: 'Finish', onClick: () => { this.toggleColorMode(false) } }]
    });

    mapRef.leafletElement.pm.Toolbar.createCustomControl({
      name: 'polygonResize',
      block: 'edit',
      className: 'mapSideButtonsDivC',
      title: 'Resize Rectangles',
      onClick: this.toggleResizeMode.bind(this, true),
      actions: [{ text: 'Finish', onClick: () => { this.toggleResizeMode(false) } }]
    });

    mapRef.leafletElement.pm.Toolbar.copyDrawControl("Rectangle", {
      name: 'makeCallOut',
      block: 'draw',
      className: 'mapSideButtonsDivD',
      title: 'Make Callout',
      onClick: this.toggleCalloutMode.bind(this, true),
      actions: [{ text: 'Cancel', onClick: () => { this.toggleCalloutMode(false) } }]
    });

    mapRef.leafletElement.pm.Toolbar.createCustomControl({
      name: 'deleteAll',
      block: 'edit',
      className: 'mapSideButtonsDivE',
      title: 'Delete All Shapes',
      toggle: false,
      onClick: this.deleteAllShapes.bind(this, true)//,
      //actions: [{ text: 'Finish', onClick: () => { this.toggleResizeMode(false) } }]
    });

    let pmOrtho = new L.PMOrtho(mapRef.leafletElement);
    pmOrtho.setOptions({ baseAngleOfLastSegment: false, snapAngle: 45 });
  }

  async componentDidUpdate(prevProps) {

    if(this.props.mapObj.closePopup){
      this.refs.map.leafletElement.closePopup();
      this.props.updateMapObj({ closePopup: false} );
    }


    if(this.props.mapObj.moveCenterandZoom){
      const mapRef = this.refs.map.leafletElement;
      let mapZoom = mapRef.getZoom();
      
      if(this.props.mapObj.moveCenterandZoom.changeZoomUnder){
        if(mapZoom < this.props.mapObj.moveCenterandZoom.changeZoomUnder){
          mapZoom = this.props.mapObj.moveCenterandZoom.zoom
        }
      }
      else if (this.props.mapObj.moveCenterandZoom.zoom){
        mapZoom = this.props.mapObj.moveCenterandZoom.zoom
      } 
      mapRef.setView(this.props.mapObj.moveCenterandZoom.center, mapZoom);
      this.props.updateMapObj({moveCenterandZoom:false});
    }

    if(this.state.currentZoom<4){
      ///add this to enable the pacific Hack
      const mapRef = this.refs.map.leafletElement;
      const mapBounds = mapRef.getBounds();
      this.props.updateMapObj({mapBounds:mapBounds});
    }

    if(this.props.mapObj.updateMapBounds){
      const mapRef = this.refs.map.leafletElement;
      const mapBounds = mapRef.getBounds();
      this.props.updateMapObj({mapBounds:mapBounds,updateMapBounds:false});
    }

    if(prevProps.demographicFillOpacity !== this.props.mapObj.demographicFillOpacity){
      this.updateDemoPaneOpacity(this.props.mapObj.demographicFillOpacity);
    }

    if(prevProps.geocodingSuccess !== this.props.mapObj.geocodingSuccess){
      if(this.props.mapObj.geocodingSuccess){
        this.setState({zoomToAll: true});
      }
    }

    if(prevProps.country!==this.props.mapObj.country){
      const mapRef = this.refs.map.leafletElement;
      let scale = L.control.scale({
        metric: this.props.country !== 'US',
        imperial: this.props.country === 'US'
      });
      scale.addTo(mapRef);
    }

    if(this.props.mapObj.newExtent){
      const mapRef = this.refs.map.leafletElement;
      const corner1 = L.latLng(this.props.mapObj.newExtent.north, this.props.mapObj.newExtent.west);
      const corner2 = L.latLng(this.props.mapObj.newExtent.south, this.props.mapObj.newExtent.east);
      const newBounds = L.latLngBounds(corner1, corner2);

      const paddingArray = GetMapPadding(mapRef, this.props.mapObj.maskBounds);

      mapRef.fitBounds(newBounds, { padding: [(20 + paddingArray[0]), (20 + paddingArray[1])] });
      this.props.updateMapObj({ newExtent: null} );

    }
  
    //// FIRES ON START OVER
    if (this.props.mapObj.gettingLayers && !this.props.mapObj.updateLayers && !this.props.mapObj.removeLayer && this.props.mapObj.customLayers.length==0){
      //console.log('start over layer');
      const map1 = this.refs.map.leafletElement;
      map1.eachLayer(function (layer) {
        const name = layer.options?.icon?.options?.name || 'none';
        const name2 = layer.options?.name || 'none';
        if(name.includes('-customlayer-')){
          layer.remove();
        }
        else if(name2.includes('-customlayer-')){
          layer.remove();
        }
      });
      this.props.updateMapObj({ gettingLayers: false});
    }

    ////REMOVE LAYER
    if (this.props.mapObj.gettingLayers && this.props.mapObj.removeLayer){      
      const map1 = this.refs.map.leafletElement;
      let _layer = this.props.mapObj.removeLayer;
      const layername = _layer.name;
      let customLayers = this.props.mapObj.customLayers;
      const newCustomLayers = []
      customLayers.forEach(cl => {
        if(cl.name!==layername){
          newCustomLayers.push(cl);
        }
      });

      if(customLayers.length>0){
        map1.eachLayer(function (layer) {
          const name = layer.options?.icon?.options?.name || 'none';
          const name2 = layer.options?.name || 'none';
          if(name.includes(layername)){
            layer.remove();
          }
          else if(name2.includes(layername)){
            layer.remove();
          }
        });
      }

      const newLayerLabels = this.props.mapObj.layerlabels.filter(ll => ll.content.customlayer !== layername);

      const legendClone = Array.from(this.props.mapObj.legend);
      
      if(this.props.mapObj.mapPoints.length===0  && newCustomLayers.length === 0){
        legendClone[0].visible = false;
      }
      this.props.updateMapObj({ 
        legend: legendClone,
        layerlabels: newLayerLabels,
        customLayers: newCustomLayers,
        gettingLayers: false,
        removeLayer: null});
    }

    //// ADD or RE ADD LAYER
    if (this.props.mapObj.gettingLayers && this.props.mapObj.updateLayers){
      const map1 = this.refs.map.leafletElement;
      const newCustomLayers = [];

      let customLayers = this.props.mapObj.customLayers;
      let updatenames = this.props.mapObj.updateLayers.map(u => u.name)
      customLayers.forEach(cl => {
        if(!updatenames.includes(cl.name)){
          newCustomLayers.push(cl);
        }
      });

      for(let i=0;i<this.props.mapObj.updateLayers.length;i++){

        let _layer = this.props.mapObj.updateLayers[i];
        const layername = _layer.name;
        const existingNewQuery = customLayers.filter(cl => cl.name === layername && cl.query !== _layer.query);
        
        if(existingNewQuery.length > 0 || !_layer.labelFeats){
          const ll = this.props.mapObj.layerlabels;
          const newll = ll.filter(l => l.content.customlayer !== layername)
          this.props.updateMapObj({layerlabels: newll});
        }


        //remove shapes if new layer exists.
        if(customLayers.length>0){
          map1.eachLayer(function (layer) {
            const name = layer.options?.icon?.options?.name || 'none';
            const name2 = layer.options?.name || 'none';
            if(name.includes(layername)){
              layer.remove();
            }
            else if(name2.includes(layername)){
              layer.remove();
            }
          });
        }

        let iconOffset = [0,0];
        const divIcon = new L.divIcon({
          name: layername + "-customlayer-marker", // + random,
          className: "leaflet-data-marker",
          html: _layer.html
        });

        let pane = 'layerPane';

        if(_layer.type==='point'){
          iconOffset = GetIconOffset(_layer.html, _layer.pointStyle.pointIconName);
          divIcon.options.iconAnchor = iconOffset;
          pane = 'layerPtPane';
        }
        _layer.iconOffset = iconOffset;

        let fl = new L.esri.FeatureLayer({
          name: layername + "-customlayer-base",
          token: this.props.atlasToken,
          url: _layer.url,        
          ignoreRenderer: true,
          where: _layer.query,
          minZoom: _layer.minZoom,
          pane: pane,
          fillColor: _layer.fillStyle.fill,
          fillOpacity: _layer.fillStyle.opacity,
          color:  _layer.fillStyle.stroke,
          opacity: _layer.fillStyle.strokeOpacity,
          weight: _layer.fillStyle.width || 3,
          draggable: false,
          pmIgnore: true,
          interactive: false,
          pointToLayer: (geojson, latlng) => {
            return L.marker(latlng, {
              icon: divIcon,
              draggable: false,
              pmIgnore: true,
              pane: pane
            });
          }
        });

        if(_layer.type === 'point'){
          fl.on('click', function(e) {
            this.that.layerClick(e);
          });
        }


        const _lcol = _layer.labelColumn;
        
        let existing = this.props.mapObj.layerlabels.filter(l => l.content.customlayer === _layer.name);
        let labelIt = true;
        
        if(_layer.labelFeats){
          fl.on("createfeature", function(e) {
            if(this.that.props.mapObj.layerlabels.length<100  && labelIt){
              const id = e.feature.id;
              const feature = fl.getFeature(id);
              let pos = null;
              let off = [0,0];
              let offsetPos = null;
              let draggable = false;
              if(_layer.type === 'polygon'){
                pos = feature.getBounds().getCenter();
                draggable = true;
              }
              else if(_layer.type === 'point'){
                pos = feature.getLatLng();
                off = iconOffset; //[iconOffset[0] * -1 ,iconOffset[1] * 2];
                offsetPos = 'upper-right'
              }
              const newLabel = {
                position: pos, //e.latlng,
                content: {
                  text: feature.feature.properties[_lcol].toLocaleString(), //this.state.mapAddLabelText,
                  color: _layer.labelColor, //this.state.mapAddLabelColor,
                  stroke: _layer.labelStroke, //this.state.mapAddLabelStroke,
                  fontSize: _layer.labelFontSize, //this.state.mapAddLabelFontSize,
                  opacity: _layer.labelOpacity, //this.state.mapAddLabelOpacity,
                  font: _layer.labelFont, //this.state.mapAddLabelFont,
                  weight: _layer.labelWeight, //this.state.mapAddLabelWeight
                  customlayer: layername,
                  offsetPosition: offsetPos,
                  //iconOffset: off
                  offsetAnchor: off,
                  draggable: draggable,
                  pane: 'labelPane'
                }
              }
              const _existing = this.existing.filter(ex => ex.position.lat === pos.lat && ex.position.lng === pos.lng && ex.content.customlayer === layername);
              if(_existing.length===0 || existingNewQuery.length > 0){
                this.that.addLayerLabel(newLabel);
              }
            }
            else if(labelIt){
              this.that.turnOffLayerLabels(_layer);
              labelIt = false;
              //turn it off
            }
          });
        }

        fl.that = this;
        fl.existing = existing;    
        fl.addTo(map1);
        newCustomLayers.push(_layer);
        
      }

      const legendClone = Array.from(this.props.mapObj.legend);
      
      // LEGEND LOAD BUG
      //legendClone[0].visible = true;

      this.props.updateMapObj({ 
        legend: legendClone,
        customLayers: newCustomLayers,
        gettingLayers: false,
        updateLayers: null});
    }

    if (this.props.mapObj.updateBasemap) {
      const map1 = this.refs.map.leafletElement;

      map1.eachLayer(function (layer) {
        if (layer._mapboxGL && layer._mapboxGL.options.accessToken === 'ezree')
          map1.removeLayer(layer);
        if (layer.options && layer.options.pane === 'tile') {
          map1.removeLayer(layer);
        }
      });

      this._setBasemap(map1, this.props.mapObj.forceDemos);

      //move map slightly to refresh
      setTimeout(() => {
        const currentCenter = map1.getCenter();
        map1.setView([currentCenter.lat + 0.001, currentCenter.lng + 0.001], map1.getZoom());
        map1.setView([(currentCenter.lat + 0.001) - 0.001, (currentCenter.lng + 0.001) - 0.001], map1.getZoom());
      }, 1000);
      this.props.updateMapObj({ updateBasemap: false, forceDemos: false });
    }

    if (this.state.prevMapZoom !== this.props.mapObj.mapZoom || this.state.prevMapCenter !== this.props.mapObj.mapCenter) {
      this.setState({
        prevMapCenter: this.props.mapObj.mapCenter,
        prevMapZoom: this.props.mapObj.mapZoom,
      });
    }

    if (this.state.prevIsSaving !== this.props.mapObj.isSaving || this.state.prevIsPrinting !== this.props.mapObj.isPrinting) {
      if (this.props.mapObj.isSaving === true || this.props.mapObj.isPrinting === true) {
        const mapRef = this.refs.map.leafletElement;
        const mapZoom = mapRef.getZoom();
        const mapCenter = mapRef.getCenter();
        const pixelBounds = mapRef.getPixelBounds();
        const mapBounds = mapRef.getBounds();
        const newRingArray = this._getRingLabelWidth();
        const newRouteArray = this._getRingLabelWidth(true);
        const newLabelArray = this._getCustomLabelWidth();
        let shapeResults = [];

        ///took this out if some clicks save the print this doesn't fire
        //if (this.props.mapObj.isPrinting) {
          //check for shapes outside of map bounds, clip if necessary
        try{
          shapeResults = this._clipShapesToMapBounds(mapRef, mapBounds);
        }
        catch(err){
          console.log('error with shapeResults');
        }
        
        this.props.updateMapObj({
          mapCenter: [mapCenter.lat, mapCenter.lng],
          mapZoom: mapZoom,
          pixelBounds: [Math.round(pixelBounds.max.x - pixelBounds.min.x), Math.round(pixelBounds.max.y - pixelBounds.min.y)],
          mapBounds: mapBounds,
          rings: newRingArray,
          driveTimes: newRouteArray,
          labels: newLabelArray,
          printShapes: shapeResults[0],
          demoPrintShapes: shapeResults[1],
          gapPrintShapes: shapeResults[2]
        });
      }

      this.setState({
        prevIsSaving: this.props.mapObj.isSaving,
        prevIsPrinting: this.props.mapObj.isPrinting
      });
    }

    //if (this.props.geocodingSuccess === true || this.state.zoomToAll === true) {
    if (this.state.zoomToAll === true) {
      const mapRef = this.refs.map.leafletElement;

      if (this.props.mapObj.mapPoints.length > 0) {
        let mapBounds = latLngBounds([this.props.mapObj.mapPoints[0].position.lat, this.props.mapObj.mapPoints[0].position.lng]);

        // if (this.props.mapObj.mapPoints.length === 1) {
        //   mapRef.setView([this.props.mapObj.mapPoints[0].position.lat, this.props.mapObj.mapPoints[0].position.lng], this.props.mapObj.mapZoom);
        // } else {
        this.props.mapObj.mapPoints.forEach((point) => {
          mapBounds.extend([point.position.lat, point.position.lng]);
        });

        const paddingArray = GetMapPadding(mapRef, this.props.mapObj.maskBounds);
        
        if (mapBounds._northEast.lat === 0 && mapBounds._northEast.lng === 0 && mapBounds._southWest.lat === 0 && mapBounds._southWest.lng === 0) {
          mapBounds._northEast.lat = 5;
          mapBounds._northEast.lng = -5;
          mapBounds._southWest.lat = -5;
          mapBounds._southWest.lng = 5;
        }

        if(mapBounds._northEast.lat===mapBounds._southWest.lat && mapBounds._northEast.lng === mapBounds._southWest.lng){
          mapBounds._northEast.lat = mapBounds._northEast.lat + .01;
          mapBounds._northEast.lng = mapBounds._northEast.lng + .01;
          mapBounds._southWest.lat = mapBounds._southWest.lat - .01;
          mapBounds._southWest.lng = mapBounds._southWest.lng - .01;
        }
        mapRef.fitBounds(mapBounds, { padding: [(20 + paddingArray[0]), (20 + paddingArray[1])] });
        //mapRef.fitBounds(mapBounds);
        
        if(this.state.zoomToAll){
          setTimeout(async () => {
            this._checkforLegendOverlap(false);
          },100);
        }
      }


      if (this.state.zoomToAll) {
        this.setState({ zoomToAll: false });
      }
    }

    //check for overlaps
    if ((this.state.prevCheckOverlap !== this.props.mapObj.checkOverlap) && this.props.mapObj.geocodingSuccess) {
      setTimeout(async () => {
        await this._checkForOverlap(this.refs.map.leafletElement);
        await this._checkforLegendOverlap(true);
      }, 200);

      this.props.updateMapObj({
        checkOverlap: false
      });
      this.setState({ prevCheckOverlap: this.props.mapObj.checkOverlap });
    }

    if (this.state.prevBasemapName !== this.props.basemap) {
      const map1 = this.refs.map.leafletElement;
      map1.eachLayer(function (layer) {
        if (layer._mapboxGL && layer._mapboxGL.options.accessToken === 'ezree')
          map1.removeLayer(layer);
        if (layer.options && layer.options.pane === 'tile') {
          map1.removeLayer(layer);
        }
      });

      this._setBasemap(map1);
      this._getMaxZoom();

      //move map slightly to refresh
      setTimeout(() => {
        const currentCenter = map1.getCenter();
        map1.setView([currentCenter.lat + 0.01, currentCenter.lng + 0.01], map1.getZoom());
        map1.setView([(currentCenter.lat + 0.01) - 0.01, (currentCenter.lng + 0.01) - 0.01], map1.getZoom());
      }, 500);


      this.setState({
        prevBasemapName: this.props.basemap
      });
    }

    if (prevProps.country !== this.props.country) {
      if ((this.props.country === 'AUS' || this.props.country === 'JPN') && !this.state.shieldManualMode) {
        this.setState({
          shieldManualMode: true
        });
      }
    }

	if (prevProps.mapObj.earthquakeLayer !== this.props.mapObj.earthquakeLayer) {
		const map1 = this.refs.map.leafletElement;
	    map1.eachLayer(function (layer) {
        	if (layer._mapboxGL && layer._mapboxGL.options.accessToken === 'ezree')
          		map1.removeLayer(layer);
        	if (layer.options && layer.options.pane === 'tile') {
          		map1.removeLayer(layer);
        	}
      	});
      	this._setBasemap(map1);
	}

    if (this.state.closeMapMessage){
      let customLayers = this.props.mapObj.customLayers;
      customLayers.forEach(cl => {
        if(this.state.currentZoom > cl.minZoom){
          this.setState({closeMapMessage: false});
        }
      });
    }
  }

  updateMapZoom(){
    if(this.props.mapObj.mapZoom!==this.state.currentZoom){
      this.props.updateMapObj({ mapZoom: this.state.currentZoom });
    }
  }


  _checkforLegendOverlap(isGeocode) {
    const mapRef = this.refs.map.leafletElement;

    const legClass = document.getElementsByClassName("legendView-outer-landscape");//
    //const maskCont = document.getElementsByClassName("maskContainer");
    if(legClass.length===0){
      return;
    }
    const legendWidth = legClass[0].offsetWidth * this.props.mapObj.maskScale;
    const legendHeight = legClass[0].offsetHeight * this.props.mapObj.maskScale;
    const offsetTop = legClass[0].offsetTop;

    const otherMc = mapRef.getCenter();
    const pixelCenter = mapRef.latLngToContainerPoint(otherMc);


    // console.log(this.props.mapObj.legend[0],'legend');
    // console.log(this.props.mapObj.maskBounds,'maskBounds');

    const topPixelLegend = this.props.mapObj.legend[0].position[1] === 'top' 
      ? pixelCenter.y - (this.props.mapObj.maskBounds[1] / 2) + offsetTop
      : pixelCenter.y + (this.props.mapObj.maskBounds[1] / 2) - legendHeight;// is some random number
    const bottomPixelLegend = topPixelLegend + legendHeight;


    const rightPixelLegend = this.props.mapObj.legend[0].position[0] === 'right'
      ? pixelCenter.x + (this.props.mapObj.maskBounds[0] / 2) - 18
      : pixelCenter.x - (this.props.mapObj.maskBounds[0] / 2) + legendWidth; // 18;

    const leftPixelLegend = rightPixelLegend - legendWidth;

    let legendOverlap = false;

    // console.log(topPixelLegend, 'tpl');
    // console.log(rightPixelLegend, 'rpl');
    // console.log(bottomPixelLegend, 'bpl');
    // console.log(leftPixelLegend, 'lpl');

    for (const pt of this.props.mapObj.mapPoints){
      const pxll = mapRef.latLngToContainerPoint([pt.position.lat, pt.position.lng]);
      if(pxll.x > leftPixelLegend && pxll.x < rightPixelLegend && pxll.y < bottomPixelLegend && pxll.y > topPixelLegend){
        legendOverlap = true;
      }
    }

    if(legendOverlap){
      if(isGeocode){
        this.props.updateMapObj({ legendOverlapDetected: true});
      }
      else {
        this.props.updateMapObj({ infoBar: true, infoMessage: 'Warning:  Some map points are hidden behind the legend'});
      }
    }
  }

  async _checkForOverlap(mapRef) {
    const ptsArrayClone = Array.from(this.props.mapObj.mapPoints);
    const otherPtsArrayClone = Array.from(this.props.mapObj.mapPoints);
    for (const pt of ptsArrayClone) {
      const ptWidth1 = pt.content.icon.options.iconAnchor ? pt.content.icon.options.iconAnchor[0] : 15;
      const pixelLatLng = await mapRef.latLngToLayerPoint([pt.position.lat, pt.position.lng]);
      const innerArrayBool = await this._checkForOverlapInner(mapRef, pt, otherPtsArrayClone, pixelLatLng, ptWidth1);
      if (innerArrayBool === true) {
        this.props.updateMapObj({ overlapDetected: true });
        break;
      }
    }
  }

  async _checkForOverlapInner(mapRef, pt, otherPtsArray, pixelLatLng, ptWidth1) {
    let overlapBool = false;
    for (const otherPt of otherPtsArray) {
      if (pt.content.id !== otherPt.content.id) {
        const otherPtPixelLatLng = await mapRef.latLngToLayerPoint([otherPt.position.lat, otherPt.position.lng]);
        const pixelDist = Math.sqrt(Math.pow((pixelLatLng.y - otherPtPixelLatLng.y), 2) + Math.pow((pixelLatLng.x - otherPtPixelLatLng.x), 2));
        const ptWidth2 = otherPt.content.icon.options.iconAnchor ? otherPt.content.icon.options.iconAnchor[0] : 15;
        const distThreshold = Math.max(ptWidth1, ptWidth2);
        if (pixelDist < distThreshold) {
          overlapBool = true;
          break;
        }
      }
    }
    return overlapBool;
  }

  _getRingLabelWidth(driveTime) {
    let ringArrayClone = Array.from(this.props.mapObj.rings);
    let refName = 'circle';

    if (driveTime === true) {
      ringArrayClone = Array.from(this.props.mapObj.driveTimes);
      refName = 'route';
    }
    for (const ring of ringArrayClone) {
      if (this.refs[refName + ring.id]) {
        if (this.refs[refName + ring.id].leafletElement._tooltip) {
          ring.labelWidth = this.refs[refName + ring.id].leafletElement._tooltip._container.clientWidth;
        }
      } else {
        ring.labelWidth = 0;
      }
    }

    return ringArrayClone;
  }

  _getCustomLabelWidth() {
    let labelArrayClone = Array.from(this.props.mapObj.labels);
    let refName = 'customlabel';

    for (const lbl of labelArrayClone) {
      if (this.refs[refName + lbl.content.id]) {
        if (this.refs[refName + lbl.content.id].leafletElement._tooltip) {
          lbl.content.labelWidth = this.refs[refName + lbl.content.id].leafletElement._tooltip._container.clientWidth;
          lbl.content.labelHeight = this.refs[refName + lbl.content.id].leafletElement._tooltip._container.clientHeight;
        }
      } else {
        lbl.content.labelWidth = 0;
        lbl.content.labelHeight = 0;
      }
    }

    return labelArrayClone;
  }

  _demoOpacity(opacity) {
    this.props.updateMapObj({ demographicFillOpacity: opacity });
  }

  _setBasemap(map1, forceDemos) {

    var isPane = map1.getPane('shieldsPane');

    if(map1){
      try{
        const currentCenter = map1.getCenter();
      }
      catch{
        console.log('No Center');
      }
    }


    if (isPane === undefined) {
      //set pane for shields below overlay (points, shapes, rings, etc);
      map1.createPane('shieldsPane');
      map1.getPane('shieldsPane').style.zIndex = 398;
      map1.createPane('demosPane');
      map1.getPane('demosPane').style.zIndex = 151;
      map1.createPane('transdemosPane');
      map1.getPane('transdemosPane').style.zIndex = 152;
      map1.createPane('tile');
      map1.getPane('tile').style.zIndex = 150;
      map1.createPane('earthquake');
      map1.getPane('earthquake').style.zIndex = 151;
      map1.createPane('gapipolysPane');
      map1.getPane('gapipolysPane').style.zIndex = 360;
      map1.createPane('labelPane');
      map1.getPane('labelPane').style.zIndex = 401;
      map1.createPane('labelBoxPane');
      map1.getPane('labelBoxPane').style.zIndex = 400;
      map1.createPane('layerPane');
      map1.getPane('layerPane').style.zIndex = 370;
      map1.createPane('layerPtPane');
      map1.getPane('layerPtPane').style.zIndex = 391;
      map1.createPane('rgapiPtPane');
      map1.getPane('rgapiPtPane').style.zIndex = 400;
      map1.createPane('shapesPane');
      map1.getPane('shapesPane').style.zIndex = 380;
      map1.createPane('rpPane');
      map1.getPane('rpPane').style.zIndex = 397;
      map1.createPane('ringPane');
      map1.getPane('ringPane').style.zIndex = 389;
      map1.createPane('pointsPane');
      map1.getPane('pointsPane').style.zIndex = 399;
      map1.createPane('pointLinesPane');
      map1.getPane('pointLinesPane').style.zIndex = 390;
      map1.createPane('ptLineAnchorPane');
      map1.getPane('ptLineAnchorPane').style.zIndex = 391
      map1.createPane('rpLinePane');
      map1.getPane('rpLinePane').style.zIndex = 392;
      map1.createPane('rpLineAnchorPane');
      map1.getPane('rpLineAnchorPane').style.zIndex = 393;
    }

    if (this.props.basemap.includes('-topo') && !this.props.basemap.includes('esri-')) {
      L.esri.tiledMapLayer({ url: 'https://services.arcgisonline.com/arcgis/rest/services/Elevation/World_Hillshade/MapServer', maxNativeZoom: 15, pane: 'tile' }).addTo(map1);
      if (this.props.mapObj.earthquakeLayer) {
        L.esri.tiledMapLayer({ url: 'https://tiles.arcgis.com/tiles/wlVTGRSYTzAbjjiC/arcgis/rest/services/jishin_max/MapServer', maxNativeZoom: 15, pane: 'tile' }).addTo(map1);
      } else {
        L.esri.tiledMapLayer({ url: 'https://services.arcgisonline.com/arcgis/rest/services/Elevation/World_Hillshade/MapServer', maxNativeZoom: 15, pane: 'tile' }).addTo(map1);
      }
      this._demoOpacity(.5);
      if (this.props.basemap === 'colliers-topo') {
        L.esri.Vector.layer('97c115df68304ca7b4fccca7d1ef3098').addTo(map1);
      } else if (this.props.basemap === 'colliers-topo_roads') {
        L.esri.Vector.layer('a4661da747b540d3a5b33d9cad21be38').addTo(map1);
      } else if (this.props.basemap === 'colliers-topo-no-shields') {
        L.esri.Vector.layer('9b3006a612724b3180d34fea7fe0f205').addTo(map1);
      } else if (this.props.basemap === 'colliers-topo_roads-no-shields') {
        L.esri.Vector.layer('2abc66c5731f458e9f9f54991620b13c').addTo(map1);
      } else if (this.props.basemap === 'colliers-topo-clean') {
        L.esri.Vector.layer('e348de5f74594f34ae47b2499b1e4a6c').addTo(map1);
      } else if (this.props.basemap === 'colliers-topo-japanese') {
        L.esri.Vector.layer('2c6784fd338a43d6a457e4798512dbfe').addTo(map1);
      } else if (this.props.basemap === 'colliers-topo-japanese_roads') {
        L.esri.Vector.layer('f13031536f564cf5b6263b43bb50f5f7').addTo(map1);
      } else if (this.props.basemap === 'colliers-topo-japanese-no-shields') {
        L.esri.Vector.layer('f2e4288864794eaaaea4aedab5381c2d').addTo(map1);
      } else if (this.props.basemap === 'colliers-topo-japanese_roads-no-shields') {
        L.esri.Vector.layer('3cdf5d95ae0749abb87e51b852b4c8b2').addTo(map1);
      } else if (this.props.basemap === 'colliers-topo-japanese-clean') {
        L.esri.Vector.layer('f15de6fd412b4b108207b348986b56bc').addTo(map1)
      }
    }
    //forcedemos?
    else if (this.props.basemap.startsWith('colliers-')) {
      if (this.props.mapObj.demographics.length > 0 || this.props.mapObj.forceDemos || this.props.mapObj.earthquakeLayer) {

        //L.esri.basemapLayer('Gray', { pane: 'tile' }).addTo(map1);
        //this._demoOpacity(1);

        if (this.props.mapObj.earthquakeLayer) {
          L.esri.tiledMapLayer({ url: 'https://tiles.arcgis.com/tiles/wlVTGRSYTzAbjjiC/arcgis/rest/services/jishin_max/MapServer', maxNativeZoom: 15, pane: 'tile' }).addTo(map1);
        }
        if (this.props.basemap === 'colliers-grey') {
          //L.esri.Vector.layer('91617e8fdec94676a406cf77ce53c96a',{pane:'tile'}).addTo(map1);
          L.esri.Vector.layer('91617e8fdec94676a406cf77ce53c96a').addTo(map1);
        } else if (this.props.basemap === 'colliers-grey_roads') {
          L.esri.Vector.layer('919986a763054198a476f631f5be5622').addTo(map1);
        } else if (this.props.basemap === 'colliers-grey-clean') {
          L.esri.Vector.layer('64e256acd582422f8252d7abf20a1004').addTo(map1);
        } else if (this.props.basemap === 'colliers-grey-no-shields') {
          L.esri.Vector.layer('5cae4163f929481694a0ba15a52e97d5').addTo(map1);
        } else if (this.props.basemap === 'colliers-grey_roads-no-shields') {
          L.esri.Vector.layer('84b0f4ceb72f4407a800119f80f41570').addTo(map1);
        } else if (this.props.basemap === 'colliers-grey-japanese') {
          L.esri.Vector.layer('5816ef24831a419da1675d1975d3e736').addTo(map1);
        } else if (this.props.basemap === 'colliers-grey-japanese_roads') {
          L.esri.Vector.layer('115f58bd3ea54c3c8780878684e0bc2a').addTo(map1);
        } else if (this.props.basemap === 'colliers-grey-japanese-clean') {
          L.esri.Vector.layer('64e256acd582422f8252d7abf20a1004').addTo(map1);
        } else if (this.props.basemap === 'colliers-grey-japanese-no-shields') {
          L.esri.Vector.layer('5b38d8c2cb2f47e78c5be2421666b08b').addTo(map1);
        } else if (this.props.basemap === 'colliers-grey-japanese_roads-no-shields') {
          L.esri.Vector.layer('13e710ddc1cb44aab176a0ce56234505').addTo(map1);
        } else if (this.props.basemap === 'colliers-bayview') {
          L.esri.Vector.layer('db67a6de80c34a84b5b46cb164730ac0').addTo(map1);
        } else if (this.props.basemap === 'colliers-bayview_roads') {
          L.esri.Vector.layer('c36fab52727c49aeaeaadca107749b02').addTo(map1);
        } else if (this.props.basemap === 'colliers-bayview-clean') {
          L.esri.Vector.layer('30e1817411f44ffe804fbea5739a6e31').addTo(map1);
        } else if (this.props.basemap === 'colliers-bayview-no-shields') {
          L.esri.Vector.layer('6528d77be2004237893edda378cffd93').addTo(map1);
        } else if (this.props.basemap === 'colliers-bayview_roads-no-shields') {
          L.esri.Vector.layer('4e7c416c9ca5425aa07b132de852bb0f').addTo(map1);
        } else if (this.props.basemap === 'colliers-bayview-japanese') {
          L.esri.Vector.layer('4907dc0ae1ad4a0ca3539695cf9fb4e5').addTo(map1);
        } else if (this.props.basemap === 'colliers-bayview-japanese_roads') {
          L.esri.Vector.layer('187b2ddc7955415db0d8a3ffd58b5dfc').addTo(map1);
        } else if (this.props.basemap === 'colliers-bayview-japanese-clean') {
          L.esri.Vector.layer('30e1817411f44ffe804fbea5739a6e31').addTo(map1);
        } else if (this.props.basemap === 'colliers-bayview-japanese-no-shields') {
          L.esri.Vector.layer('41ebab3759fd4c2aa37b8fe93a63def8').addTo(map1);
        } else if (this.props.basemap === 'colliers-bayview-japanese_roads-no-shields') {
          L.esri.Vector.layer('e43f38cc62e343eabc427bd272aedd48').addTo(map1);
        } else if (this.props.basemap === 'colliers-northstar') {
          L.esri.Vector.layer('6e395e1a3aa541a28b3a26351a237d80').addTo(map1);
        } else if (this.props.basemap === 'colliers-northstar_roads') {
          L.esri.Vector.layer('6df230467fb54524bc99c4fceff3df38').addTo(map1);
        } else if (this.props.basemap === 'colliers-northstar-clean') {
          L.esri.Vector.layer('6ad0c0229e6b49f888eeb2704eb90c0c').addTo(map1);
        } else if (this.props.basemap === 'colliers-northstar-no-shields') {
          L.esri.Vector.layer('1b472256273447748b7434b838ceb9b5').addTo(map1);
        } else if (this.props.basemap === 'colliers-northstar_roads-no-shields') {
          L.esri.Vector.layer('ddc8ae90fb14425b8f225b816ddc9ce4').addTo(map1);
        } else if (this.props.basemap === 'colliers-lakeside') {
          L.esri.Vector.layer('e770cb143b174a22b092ff00e418be8e').addTo(map1);
        } else if (this.props.basemap === 'colliers-lakeside-clean') {
          L.esri.Vector.layer('13e9740f10364de7a24ed5481685f2ee').addTo(map1);
        } else if (this.props.basemap === 'colliers-lakeside-no-shields') {
          L.esri.Vector.layer('d4c2468b1dba428dbe97c6fd483605b8').addTo(map1);
        } else if (this.props.basemap === 'colliers-lakeside_roads') {
          L.esri.Vector.layer('bd804e75d8dc42548b9f1f2189d1e98e').addTo(map1);
        } else if (this.props.basemap === 'colliers-lakeside_roads-no-shields') {
          L.esri.Vector.layer('8be773f124fc49bf80fccc27c7ebd35c').addTo(map1);
        } else if (this.props.basemap === 'colliers-northstar-japanese') {
          L.esri.Vector.layer('b6a7c08733b54a5591b9ec6ff68fdff9').addTo(map1);
        } else if (this.props.basemap === 'colliers-northstar-japanese_roads') {
          L.esri.Vector.layer('63eca1c958ce4ba3a1d9bae354329ba2').addTo(map1);
        } else if (this.props.basemap === 'colliers-northstar-japanese-clean') {
          L.esri.Vector.layer('6ad0c0229e6b49f888eeb2704eb90c0c').addTo(map1);
        } else if (this.props.basemap === 'colliers-northstar-japanese-no-shields') {
          L.esri.Vector.layer('1ab9dad25f8f4011a616e26d23d58593').addTo(map1);
        } else if (this.props.basemap === 'colliers-northstar-japanese_roads-no-shields') {
          L.esri.Vector.layer('598deeecbc084bcfa9f63903b3e52c5b').addTo(map1);
        }

        setTimeout(() => {
          const currentCenter = map1.getCenter();
          map1.setView([currentCenter.lat + 0.01, currentCenter.lng + 0.01], map1.getZoom());
          map1.setView([(currentCenter.lat + 0.01) - 0.01, (currentCenter.lng + 0.01) - 0.01], map1.getZoom());
        }, 1000);

      }
      else {
        if (this.props.basemap === 'colliers-grey') {
          L.esri.Vector.layer('855a4d90d0f34769aa5f9672ef65e5a3').addTo(map1);
        } else if (this.props.basemap === 'colliers-grey_roads') {
          L.esri.Vector.layer('fdff464a65df47aeadb2933122e85eac').addTo(map1);
        } else if (this.props.basemap === 'colliers-grey-clean') {
          L.esri.Vector.layer('f37233a6d3554aeb9461c16073827d0f').addTo(map1);
        } else if (this.props.basemap === 'colliers-grey-no-shields') {
          L.esri.Vector.layer('3e82fab749fd4c1d8d22b033a6460ac1').addTo(map1);
        } else if (this.props.basemap === 'colliers-grey_roads-no-shields') {
          L.esri.Vector.layer('0193bb69edf247c49baab7b4ea7f1b88').addTo(map1);
        } else if (this.props.basemap === 'colliers-grey-japanese') {
          L.esri.Vector.layer('b283bad824fe43d9bea9ef295f2e23e0').addTo(map1);
        } else if (this.props.basemap === 'colliers-grey-japanese_roads') {
          L.esri.Vector.layer('5ef1d58e168d43cea8689975a3fc741d').addTo(map1);
        } else if (this.props.basemap === 'colliers-grey-japanese-clean') {
          L.esri.Vector.layer('f37233a6d3554aeb9461c16073827d0f').addTo(map1);
        } else if (this.props.basemap === 'colliers-grey-japanese-no-shields') {
          L.esri.Vector.layer('83c6d30333f44ee2a6cbca1a4f0c1e6e').addTo(map1);
        } else if (this.props.basemap === 'colliers-grey-japanese_roads-no-shields') {
          L.esri.Vector.layer('601dc207514c4de58f9f458eb21b0e8d').addTo(map1);
        } else if (this.props.basemap === 'colliers-bayview') {
          L.esri.Vector.layer('cd810177c025418e88f2f60bfdf24ea1').addTo(map1);
        } else if (this.props.basemap === 'colliers-bayview-clean') {
          L.esri.Vector.layer('a4cb3e35e2c548f4beea55833c61062b').addTo(map1);
        } else if (this.props.basemap === 'colliers-bayview-no-shields') {
          L.esri.Vector.layer('02864d206eb248d69fe2301730b8ed62').addTo(map1);
        } else if (this.props.basemap === 'colliers-bayview_roads') {
          L.esri.Vector.layer('55401338ebbe4c14b7eb6c3e9171f038').addTo(map1);
        } else if (this.props.basemap === 'colliers-bayview_roads-no-shields') {
          L.esri.Vector.layer('601f64db0a9945b0823ec0bb2e8ce9fc').addTo(map1);
        } else if (this.props.basemap === 'colliers-bayview-japanese') {
          L.esri.Vector.layer('0f7e9d1c6be8422399d81c9979e03303').addTo(map1);
        } else if (this.props.basemap === 'colliers-bayview-japanese-clean') {
          L.esri.Vector.layer('a4cb3e35e2c548f4beea55833c61062b').addTo(map1);
        } else if (this.props.basemap === 'colliers-bayview-japanese-no-shields') {
          L.esri.Vector.layer('f43f5aa8320547438214b559b1fcabdb').addTo(map1);
        } else if (this.props.basemap === 'colliers-bayview_roads') {
          L.esri.Vector.layer('ff1ab9130e0d476a83c72467f8f9b3a3').addTo(map1);
        } else if (this.props.basemap === 'colliers-bayview_roads-no-shields') {
          L.esri.Vector.layer('cd34880adff449958d5b072a00324c76').addTo(map1);
        } else if (this.props.basemap === 'colliers-northstar') {
          L.esri.Vector.layer('c12f4643f7cb4effa9f0558804543fc6').addTo(map1);
        } else if (this.props.basemap === 'colliers-northstar-clean') {
          L.esri.Vector.layer('b587fb560b5e4134be59432ade5f0923').addTo(map1);
        } else if (this.props.basemap === 'colliers-northstar_roads') {
          L.esri.Vector.layer('c12f4643f7cb4effa9f0558804543fc6').addTo(map1);
        } else if (this.props.basemap === 'colliers-northstar_roads-no-shields') {
          L.esri.Vector.layer('e211946b60364019a1988d223ca221c6').addTo(map1);
        } else if (this.props.basemap === 'colliers-northstar-no-shields') {
          L.esri.Vector.layer('e211946b60364019a1988d223ca221c6').addTo(map1);
        } else if (this.props.basemap === 'colliers-northstar-japanese') {
          L.esri.Vector.layer('61f4cf412a86489eac80df5c9aa069ec').addTo(map1);
        } else if (this.props.basemap === 'colliers-northstar-japanese-clean') {
          L.esri.Vector.layer('b587fb560b5e4134be59432ade5f0923').addTo(map1);
        } else if (this.props.basemap === 'colliers-northstar-japanese_roads') {
          L.esri.Vector.layer('61f4cf412a86489eac80df5c9aa069ec').addTo(map1);
        } else if (this.props.basemap === 'colliers-northstar-japanese_roads-no-shields') {
          L.esri.Vector.layer('3393dd2481af44db9f91263a6bba02d1').addTo(map1);
        } else if (this.props.basemap === 'colliers-northstar-japanese-no-shields') {
          L.esri.Vector.layer('3393dd2481af44db9f91263a6bba02d1').addTo(map1);
        } else if (this.props.basemap === 'colliers-lakeside') {
          L.esri.Vector.layer('f83e12a366ec4ffc9cf7e2c732b03401').addTo(map1);
        } else if (this.props.basemap === 'colliers-lakeside-clean') {
          L.esri.Vector.layer('7b7c15b5c91545b08c4be10ae167ee0b').addTo(map1);
        } else if (this.props.basemap === 'colliers-lakeside-no-shields') {
          L.esri.Vector.layer('eea89e2848f5415cb17761c574ef504f').addTo(map1);
        } else if (this.props.basemap === 'colliers-lakeside_roads') {
          L.esri.Vector.layer('f102d216b46d49bfa5f303f9c8e356d6').addTo(map1);
        } else if (this.props.basemap === 'colliers-lakeside_roads-no-shields') {
          L.esri.Vector.layer('2a34a43e00c04365ae078299f1a92e53').addTo(map1);
        }

        setTimeout(() => {
          const currentCenter = map1.getCenter();
          map1.setView([currentCenter.lat + 0.01, currentCenter.lng + 0.01], map1.getZoom());
          map1.setView([(currentCenter.lat + 0.01) - 0.01, (currentCenter.lng + 0.01) - 0.01], map1.getZoom());
        }, 1000);

      }
    }
    else if (this.props.basemap === 'aerial' || this.props.basemap.startsWith('hybrid')) {
      this._demoOpacity(.5);
      L.esri.basemapLayer('ImageryFirefly', { pane: 'tile' }).addTo(map1);
      if (this.props.mapObj.earthquakeLayer) {
        L.esri.tiledMapLayer({ url: 'https://tiles.arcgis.com/tiles/wlVTGRSYTzAbjjiC/arcgis/rest/services/jishin_max/MapServer', maxNativeZoom: 15, pane: 'earthquake', opacity: 0.5 }).addTo(map1);
      }
      if (this.props.basemap === 'hybrid') {
        L.esri.Vector.layer('c116ff57c1b9474c9924b07fd45ae60d').addTo(map1);
      } else if (this.props.basemap === "hybrid_roads") {
        L.esri.Vector.layer('7d943fb76b7b4bc9a733be91a0785584').addTo(map1);
      } else if (this.props.basemap === "hybrid-no-shields") {
        L.esri.Vector.layer('f542a11dd16c44daaacee2036e0ed638').addTo(map1);
      } else if (this.props.basemap === "hybrid_roads-no-shields") {
        L.esri.Vector.layer('a0db1e27477e4fe0a0f66b40437ea489').addTo(map1);
      } else if (this.props.basemap === "hybrid-clean") {
        L.esri.Vector.layer('76feda73755248ea89796976c9894fe6').addTo(map1);
      } else if (this.props.basemap === 'hybrid-japanese') {
        L.esri.Vector.layer('ffc25653142441c7b0d4797b62a1a6b9').addTo(map1);
      } else if (this.props.basemap === "hybrid-japanese_roads") {
        L.esri.Vector.layer('7d943fb76b7b4bc9a733be91a0785584').addTo(map1);
      } else if (this.props.basemap === "hybrid-japanese-no-shields") {
        L.esri.Vector.layer('f542a11dd16c44daaacee2036e0ed638').addTo(map1);
      } else if (this.props.basemap === "hybrid-japanese_roads-no-shields") {
        L.esri.Vector.layer('a0db1e27477e4fe0a0f66b40437ea489').addTo(map1);
      } else if (this.props.basemap === "hybrid-japanese-clean") {
        L.esri.Vector.layer('76feda73755248ea89796976c9894fe6').addTo(map1);
      }

    }
    else if (this.props.basemap.startsWith('naip-')) {
      this._demoOpacity(.5);
      L.esri.tiledMapLayer({ url: 'https://naip.maptiles.arcgis.com/arcgis/rest/services/NAIP/MapServer', maxNativeZoom: 15, pane: 'tile' }).addTo(map1);
      if (this.props.basemap === 'naip-hybrid') {
        L.esri.Vector.layer('c116ff57c1b9474c9924b07fd45ae60d').addTo(map1);
      } else if (this.props.basemap === "naip-hybrid_roads") {
        L.esri.Vector.layer('7d943fb76b7b4bc9a733be91a0785584').addTo(map1);
      } else if (this.props.basemap === "naip-hybrid-no-shields") {
        L.esri.Vector.layer('f542a11dd16c44daaacee2036e0ed638').addTo(map1);
      } else if (this.props.basemap === "naip-hybrid_roads-no-shields") {
        L.esri.Vector.layer('a0db1e27477e4fe0a0f66b40437ea489').addTo(map1);
      } else if (this.props.basemap === "naip-hybrid-clean") {
        L.esri.Vector.layer('76feda73755248ea89796976c9894fe6').addTo(map1);
      }
    } 
    else if (this.props.basemap.startsWith('esri-')) {
      if (this.props.mapObj.demographics.length > 0 || this.props.mapObj.forceDemos || this.props.mapObj.earthquakeLayer) {
        if (this.props.mapObj.earthquakeLayer) {
          L.esri.tiledMapLayer({ url: 'https://tiles.arcgis.com/tiles/wlVTGRSYTzAbjjiC/arcgis/rest/services/jishin_max/MapServer', maxNativeZoom: 15, pane: 'tile' }).addTo(map1);
        }
        if (this.props.basemap === 'esri-topo-jpn') {
          L.esri.Vector.layer('ecb243fc9995473fb7abb4485fb56b10').addTo(map1);
        } else if (this.props.basemap === 'esri-topo-jpn-clean') {
          L.esri.Vector.layer('f54fa5c34b5a43a7b7fc19ab010ef4a4').addTo(map1);
        } else if (this.props.basemap === "esri-topo-jpn_roads") {
          L.esri.Vector.layer('ecb243fc9995473fb7abb4485fb56b10').addTo(map1);
        } else if (this.props.basemap === "esri-topo-jpn-no-shields") {
          L.esri.Vector.layer('ecb243fc9995473fb7abb4485fb56b10').addTo(map1);
        } else if (this.props.basemap === "esri-topo-jpn_roads-no-shields") {
          L.esri.Vector.layer('ecb243fc9995473fb7abb4485fb56b10').addTo(map1);
        } else if (this.props.basemap === 'esri-nav-jpn-clean' || this.props.basemap === 'esri-nav-eng-clean') {
          L.esri.Vector.layer('53963edf6ab844f0b6ad51addda28e78').addTo(map1);
        } else if (this.props.basemap === 'esri-nav-jpn') {
          L.esri.Vector.layer('d2a0961f8fc442b4b623147e563c0e90').addTo(map1);
        } else if (this.props.basemap === "esri-nav-jpn_roads") {
          L.esri.Vector.layer('bfdab6b69b114bec92d2e612eead165f').addTo(map1);
        } else if (this.props.basemap === "esri-nav-jpn-no-shields") {
          L.esri.Vector.layer('b63b10c4f0214e32b7b58897efff2bb0').addTo(map1);
        } else if (this.props.basemap === "esri-nav-jpn_roads-no-shields") {
          L.esri.Vector.layer('eb86e35834c444669c95be1d26b47fb2').addTo(map1);
        } else if (this.props.basemap === 'esri-nav-eng') {
          L.esri.Vector.layer('8e081b43153f44a4ae892d6ed6415428').addTo(map1);
        } else if (this.props.basemap === "esri-nav-eng_roads") {
          L.esri.Vector.layer('b950fb8fe7d64631afb825bbc2e5bbd6').addTo(map1);
        } else if (this.props.basemap === "esri-nav-eng-no-shields") {
          L.esri.Vector.layer('c334585875d341b1938b4a3768edefd5').addTo(map1);
        } else if (this.props.basemap === "esri-nav-eng_roads-no-shields") {
          L.esri.Vector.layer('873070cefab341828abac652193e7d07').addTo(map1);
        }
      } else {
        if (this.props.basemap === 'esri-topo-jpn') {
          L.esri.Vector.layer('4016b31c19a946f199bd21591c705026').addTo(map1);
        } else if (this.props.basemap === 'esri-topo-jpn-clean') {
          L.esri.Vector.layer('f19da9784e6c4d2bbd9aa6d18d16b0c4').addTo(map1);
        } else if (this.props.basemap === "esri-topo-jpn_roads") {
          L.esri.Vector.layer('3322ded89eab4d1593b5bb7dd62ed21d').addTo(map1);
        } else if (this.props.basemap === "esri-topo-jpn-no-shields") {
          L.esri.Vector.layer('4016b31c19a946f199bd21591c705026').addTo(map1);
        } else if (this.props.basemap === "esri-topo-jpn_roads-no-shields") {
          L.esri.Vector.layer('6cb3b30f9a8746459a148ab1d05ea242').addTo(map1);
        } else if (this.props.basemap === 'esri-nav-jpn') {
          L.esri.Vector.layer('311352048bcd4afeb4d5bca0088ab27a').addTo(map1);
        } else if (this.props.basemap === "esri-nav-jpn_roads") {
          L.esri.Vector.layer('e9535c60ab8a4873a33e6f83f8b7b23b').addTo(map1);
        } else if (this.props.basemap === "esri-nav-jpn-no-shields") {
          L.esri.Vector.layer('964f525c254a4e31abc742d73a30353c').addTo(map1);
        } else if (this.props.basemap === "esri-nav-jpn_roads-no-shields") {
          L.esri.Vector.layer('7197c3fca1694ab0a26b461888c847f9').addTo(map1);
        } else if (this.props.basemap === 'esri-nav-eng') {
          L.esri.Vector.layer('c4c4f1059e22483097a0ab30ebe3da4f').addTo(map1);
        } else if (this.props.basemap === "esri-nav-eng_roads") {
          L.esri.Vector.layer('03e4a50003da41ad9392951f2985960e').addTo(map1);
        } else if (this.props.basemap === "esri-nav-eng-no-shields") {
          L.esri.Vector.layer('a381223126f14c4195379d2b23b8d06d').addTo(map1);
        } else if (this.props.basemap === "esri-nav-eng_roads-no-shields") {
          L.esri.Vector.layer('6fd59d3c0572489db06e4e0c1d44ffd5').addTo(map1);
        } else if (this.props.basemap === 'esri-nav-jpn-clean' || this.props.basemap === 'esri-nav-eng-clean') {
          L.esri.Vector.layer('e860744b5aaf485e8ddf61bcb72fb726').addTo(map1);
        } else if (this.props.basemap === "esri-world-nav-eng"){
          L.esri.Vector.layer('6e7308929ee940318293ca052e009bee').addTo(map1);
        } else if (this.props.basemap === "esri-world-nav"){
          L.esri.Vector.layer('6a08cfe977c0416586234600d97ce310').addTo(map1);
        }
      }
    } else {
      console.log('no basemap');
      L.esri.Vector.layer('855a4d90d0f34769aa5f9672ef65e5a3').addTo(map1); //default colliers-grey
    }

    // } else if (this.props.basemap === 'naip-aerial') {
    //   
    // } else if (this.props.basemap === 'naip-hybrid') {
    //   L.esri.tiledMapLayer({ url: 'https://naip.maptiles.arcgis.com/arcgis/rest/services/NAIP/MapServer', maxNativeZoom: 15, pane: 'tile' }).addTo(map1);
    //   L.esri.Vector.layer('c116ff57c1b9474c9924b07fd45ae60d').addTo(map1);
    // } else if (this.props.basemap === "naip-hybrid_roads") {
    //   L.esri.tiledMapLayer({ url: 'https://naip.maptiles.arcgis.com/arcgis/rest/services/NAIP/MapServer', maxNativeZoom: 15, pane: 'tile' }).addTo(map1);
    //   L.esri.Vector.layer('7d943fb76b7b4bc9a733be91a0785584').addTo(map1);
    // } else if (this.props.basemap === "naip-hybrid-no-shields") {
    //   L.esri.tiledMapLayer({ url: 'https://naip.maptiles.arcgis.com/arcgis/rest/services/NAIP/MapServer', maxNativeZoom: 15, pane: 'tile' }).addTo(map1);
    //   L.esri.Vector.layer('f542a11dd16c44daaacee2036e0ed638').addTo(map1);
    // } else if (this.props.basemap === "naip-hybrid_roads-no-shields") {
    //   L.esri.tiledMapLayer({ url: 'https://naip.maptiles.arcgis.com/arcgis/rest/services/NAIP/MapServer', maxNativeZoom: 15, pane: 'tile' }).addTo(map1);
    //   L.esri.Vector.layer('a0db1e27477e4fe0a0f66b40437ea489').addTo(map1);
    // } else if (this.props.basemap === "naip-hybrid-clean") {
    //   L.esri.tiledMapLayer({ url: 'https://naip.maptiles.arcgis.com/arcgis/rest/services/NAIP/MapServer', maxNativeZoom: 15, pane: 'tile' }).addTo(map1);
    //   L.esri.Vector.layer('76feda73755248ea89796976c9894fe6').addTo(map1);
    // }

    //console.log(map1);
    var originalInitTile = L.GridLayer.prototype._initTile
    L.GridLayer.include({
      _initTile: function (tile) {
        originalInitTile.call(this, tile);

        var tileSize = this.getTileSize();

        tile.style.width = tileSize.x + 1 + 'px';
        tile.style.height = tileSize.y + 1 + 'px';
      }
    });
  }

  _getMaxZoom() {
    if (this.props.mapObj.mapOptionsBasemap === "naip-hybrid" || this.props.mapObj.mapOptionsBasemap === "naip-aerial" || this.props.mapObj.mapOptionsBasemap === "naip-hybrid_roads") {
      this.refs.map.leafletElement.setMaxZoom(14.9);
    } else if (this.props.mapObj.mapOptionsBasemap === "aerial" || this.props.mapObj.mapOptionsBasemap === "hybrid" || this.props.mapObj.mapOptionsBasemap === "hybrid_roads" ||
      this.props.mapObj.mapOptionsBasemap === "hybrid-japanese" || this.props.mapObj.mapOptionsBasemap === "hybrid-japanese_roads") {
      this.refs.map.leafletElement.setMaxZoom(17.4);
    } else {
      this.refs.map.leafletElement.setMaxZoom(19);
    }
  }

  _clipShapesToMapBounds(mapRef, mapBounds) {
    const shapesArrayClone = Array.from(this.props.mapObj.shapes);
    const ringsArrayClone = Array.from(this.props.mapObj.rings);
    const routesArrayClone = Array.from(this.props.mapObj.driveTimes);
    const gapiPolyArrayClone = isNullorEmptyArray(this.props.mapObj.gapiPolygonSettings.displayNames)
      ? Array.from(this.props.mapObj.gapiPolygons) 
      : Array.from(this.props.mapObj.gapiPolygons.filter(g => this.props.mapObj.gapiPolygonSettings.displayNames.includes(g.data.name)));

    const demoArrayClone = Array.from(this.props.mapObj.demographics);

    const ringConstant = this.props.country === 'US' ? 1609.34 : 1000;
    let printShapes = [];
    let demoShapes = [];
    let gapiPolyShapes = [];

    for (const ring of ringsArrayClone) {
      if (this.refs['circle' + ring.id])
      {
        const myCircle = this.refs['circle' + ring.id].leafletElement;
        const doesContain = L.latLngBounds(mapBounds).contains(myCircle.getBounds());
        const doesOverlap = L.latLngBounds(mapBounds).overlaps(myCircle.getBounds());
        if (!doesContain) {
          const allBoundsLessThanRadius = CheckBoundsToRadius(ring.center, (ring.radius * ringConstant), mapBounds.getWest(), mapBounds.getSouth(), mapBounds.getEast(), mapBounds.getNorth());
          if (allBoundsLessThanRadius) {
            let newShape = JSON.parse(JSON.stringify(ring));
            newShape.position = [[
              { lat: mapBounds.getSouth(), lng: mapBounds.getWest() },
              { lat: mapBounds.getNorth(), lng: mapBounds.getWest() },
              { lat: mapBounds.getNorth(), lng: mapBounds.getEast() },
              { lat: mapBounds.getSouth(), lng: mapBounds.getEast() },
              { lat: mapBounds.getSouth(), lng: mapBounds.getWest() }
            ]];
            newShape.type = 'polygon';
            printShapes.push(newShape);
          } else if (!doesOverlap) {
            let newShape = JSON.parse(JSON.stringify(ring));
            newShape.type = 'ignore';
            printShapes.push(newShape);
          }
        }
      }
    }

    for (const route of routesArrayClone) {
      const doesContain = L.latLngBounds(mapBounds).contains(L.latLngBounds(route.polygon));
      if (!doesContain) {
        const poly = turfPolygon(ReverseToLngLat(route.polygon, true)); //format position array for turf
        const clipped = bboxClip(poly, [mapBounds.getWest(), mapBounds.getSouth(), mapBounds.getEast(), mapBounds.getNorth()]); //bbox = [minx, miny, maxx, maxy]
        const newPosition = ReverseToLatLng(clipped.geometry.coordinates, false, true);
        let newShape = JSON.parse(JSON.stringify(route));
        newShape.polygon = newPosition;
        printShapes.push(newShape);
      }
    }

    for (const gapiPoly of gapiPolyArrayClone) {
      const doesContain = L.latLngBounds(mapBounds).contains(L.latLngBounds(gapiPoly.position));
      //const doesOverlap = L.latLngBounds(mapBounds).overlaps(L.latLngBounds(gapiPoly.position));
      
      if (!doesContain) {
        let position = gapiPoly.position;
        if(!Array.isArray(gapiPoly.position[0])){
          position = [position];
        
          //end if was here

        // console.log(gapiPoly.position);
        // const poly = turfPolygon(ReverseToLngLat([gapiPoly.position])); //format position array for turf
          const poly = turfPolygon(ReverseToLngLat(position)); //format position array for turf
          //console.log(poly,'poly');
          const clipped = bboxClip(poly, [mapBounds.getWest(), mapBounds.getSouth(), mapBounds.getEast(), mapBounds.getNorth()]); //bbox = [minx, miny, maxx, maxy]
          const newPosition = ReverseToLatLng(clipped.geometry.coordinates);
          let newShape = JSON.parse(JSON.stringify(gapiPoly));
          newShape.position = newPosition;
          gapiPolyShapes.push(newShape);
        }
      }
    }

    for (const demo of demoArrayClone) {
      const doesContain = L.latLngBounds(mapBounds).contains(L.latLngBounds(demo.position));
      const doesOverlap = L.latLngBounds(mapBounds).overlaps(L.latLngBounds(demo.position));
      
      if(doesContain || doesOverlap){
        demoShapes.push(demo);
      }
      // else if(doesOverlap){
      //   let newDemo = JSON.parse(JSON.stringify(demo));
      //   const poly = turfPolygon(ReverseToLngLat([demo.position])); //format position array for turf
      //   const clipped = bboxClip(poly, [mapBounds.getWest(), mapBounds.getSouth(), mapBounds.getEast(), mapBounds.getNorth()]); //bbox = [minx, miny, maxx, maxy]
      //   const newPosition = ReverseToLatLng(clipped.geometry.coordinates);
      //   newDemo.position = newPosition;
      //   demoShapes.push(newDemo);
      // }
      
    }

    for (const shape of shapesArrayClone) {

      if (shape.type === 'polygon') {
        if(shape.position[0].length===0) {
          console.log('empty')
        }
        else {
          const doesContain = L.latLngBounds(mapBounds).contains(L.latLngBounds(shape.position));
          if (!doesContain) {
            const poly = turfPolygon(ReverseToLngLat(shape.position)); //format position array for turf
            const clipped = bboxClip(poly, [mapBounds.getWest(), mapBounds.getSouth(), mapBounds.getEast(), mapBounds.getNorth()]); //bbox = [minx, miny, maxx, maxy]
            const newPosition = ReverseToLatLng(clipped.geometry.coordinates);
            let newShape = JSON.parse(JSON.stringify(shape));
            newShape.position = newPosition;
            //console.log(newShape,'newShape');
            printShapes.push(newShape);
          }
        }
      } else if (shape.type === 'polyline') {
        if(shape.position[0].length===0) {
          console.log('empty')
        }
        else {
          const doesContain = L.latLngBounds(mapBounds).contains(L.latLngBounds(shape.position));
          if (!doesContain) {
            const poly = turfLine(ReverseToLngLat(shape.position)); //format position array for turf
            const clipped = bboxClip(poly, [mapBounds.getWest(), mapBounds.getSouth(), mapBounds.getEast(), mapBounds.getNorth()]); //bbox = [minx, miny, maxx, maxy]
            
            
            if(clipped.geometry.type==="MultiLineString"){
                const mls_pos = [];
                for(let i =0; i < clipped.geometry.coordinates.length; i++)
                {
                  const coords = clipped.geometry.coordinates[i];
                  const newPosition = ReverseToLatLng(coords, true);
                  mls_pos.push(newPosition);
                }
                let newShape = JSON.parse(JSON.stringify(shape));
                newShape.position = mls_pos; //[newPosition]; 
                printShapes.push(newShape);
            }
            else {
              const newPosition = ReverseToLatLng(clipped.geometry.coordinates, true);
              let newShape = JSON.parse(JSON.stringify(shape));
              newShape.position = [newPosition]; //wrap newPosition in an outer array for polyline
              printShapes.push(newShape);
            }
          }
        }
      } else if (shape.type === 'circle') {
        const myCircle = this.refs['shape' + shape.id].leafletElement;
        const doesContain = L.latLngBounds(mapBounds).contains(myCircle.getBounds());
        const doesOverlap = L.latLngBounds(mapBounds).overlaps(myCircle.getBounds());
        if (!doesContain) {
          const allBoundsLessThanRadius = CheckBoundsToRadius(shape.center, shape.radius, mapBounds.getWest(), mapBounds.getSouth(), mapBounds.getEast(), mapBounds.getNorth());
          if (allBoundsLessThanRadius) {
            let newShape = JSON.parse(JSON.stringify(shape));
            newShape.position = [[
              { lat: mapBounds.getSouth(), lng: mapBounds.getWest() },
              { lat: mapBounds.getNorth(), lng: mapBounds.getWest() },
              { lat: mapBounds.getNorth(), lng: mapBounds.getEast() },
              { lat: mapBounds.getSouth(), lng: mapBounds.getEast() },
              { lat: mapBounds.getSouth(), lng: mapBounds.getWest() }
            ]];
            newShape.type = 'polygon';
            printShapes.push(newShape);
          } else if (!doesOverlap) {
            let newShape = JSON.parse(JSON.stringify(shape));
            newShape.type = 'ignore';
            printShapes.push(newShape);
          }
        }
      }
    }

    return [printShapes,demoShapes,gapiPolyShapes];
  }

  // closePotentialLayerLabel = () => {
  //   this.setState({potentialLayerLabel: null});
  // }

  closePotentialGapiLabel = () => {
    this.setState({potentialGapiLabel: null,selectedGapiLabel: null});
  }

  layerClick = async(e) => {
    const eprops = (e.layer.feature.properties);
    const ell = e.latlng;
    const foundLL = this.props.mapObj.layerlabels.filter(ll => ell === ll.position);
    if(foundLL.length > 0){
      this.setState({selectedLayerLabel: foundLL[0]});
    }
    else {
      const layerName = e.target.options.name;
      const foundCL = this.props.mapObj.customLayers.filter(cl => layerName.startsWith(cl.name) );
      const _layer = foundCL[0];
      let iconOffset = null;
      let offPos = null;
      let drag = true;
      if(_layer.type==='point'){
        iconOffset = GetIconOffset(_layer.html, _layer.pointStyle.pointIconName);
        offPos = 'upper-right';
        drag = false;
      }
      const _lcol = _layer.labelColumn;
      const label = eprops[_lcol].toLocaleString();
      const newLabel = {
        position: ell, //e.latlng,
        content: {
          text: label, //feature.feature.properties[_lcol].toLocaleString(), //this.state.mapAddLabelText,
          color: _layer.labelColor, //this.state.mapAddLabelColor,
          stroke: _layer.labelStroke, //this.state.mapAddLabelStroke,
          fontSize: _layer.labelFontSize, //this.state.mapAddLabelFontSize,
          opacity: _layer.labelOpacity, //this.state.mapAddLabelOpacity,
          font: _layer.labelFont, //this.state.mapAddLabelFont,
          weight: _layer.labelWeight, //this.state.mapAddLabelWeight
          customlayer: _layer.name,
          offsetPosition: offPos,
          offsetAnchor: iconOffset, 
          draggable: drag,
          pane: 'labelPane'
        }
      }

      //only doing points for now.
      //if(_layer.type==='point'
      if(!this.makeCalloutMode && !this.mapAddPt  && !this.mapAddLabel && !this.mapAddShield && !this.colorEditMode && !this.resizeEditMode)
      {
        this.setState({potentialLayerLabel: newLabel});
      }
    }
  }

  turnOffLayerLabels = async(layer) => {
    let clClone = this.props.mapObj.customLayers;
    const ix = clClone.findIndex(cl=>cl.name===layer.name);
    clClone[ix].labelFeats=false;
    //find index here
    //            message={this.state.errorMessage}
    //open={this.state.errorBar}
    this.props.updateMapObj({customLayers:clClone, layerlabels: [],errorBar: true, errorMessage:'Too many labels turning off auto label'});
  }

  // addLayerLabel = async(label) => {
  //   const newLayerLabels = this.props.createNewLabel([label],true);
  //   this.props.updateMapObj({ layerlabels: newLayerLabels, popupOpen: false });    
  // }

  readdGapiLabel = async(labelid) => {
    const remvoedIx =  this.props.mapObj.gapiPointHideLabels.findIndex(l=>l===labelid);
    let hideLabels = this.props.mapObj.gapiPointHideLabels;
    hideLabels.splice(remvoedIx,1);
    this.props.updateMapObj({gapiPointHideLabels: hideLabels,removedGapiLabelId:null});
  }

  queryRoadLayer = async(latlng) => {
   
    const geom = {
      xmin: (latlng.lng - .001),
      ymin: (latlng.lat - .001),
      xmax: (latlng.lng + .001),
      ymax: (latlng.lat + .001)
    }

    const token = await this.props.retriveGCAtlasToken()

    // let url = process.env.REACT_APP_ROAD_LABEL_URL + '?layerDefs=*&geometry=' + JSON.stringify(geom) + '&geometryType=esriGeometryEnvelope';
    // url += '&outFields=StreetName,FuncClass&f=geojson&token=' + token;
    let url = process.env.REACT_APP_ROAD_LABEL_URL + '?geometry=' + encodeURIComponent(JSON.stringify(geom)) + '&geometryType=esriGeometryEnvelope';
    url += '&outFields=FULL_STREET_NAME,FUNCTIONAL_CLASS&f=geojson&token=' + token;


    const res = await axios.get(url);
    //console.log(res,'after axios');
    if(res.data.features){
      //const feats = res.data.features.sort((a,b) => a.properties.FuncClass - b.properties.FuncClass);
      const feats = res.data.features.sort((a,b) => a.properties.FUNCTIONAL_CLASS - b.properties.FUNCTIONAL_CLASS);
      const roadname = feats[0].properties.FULL_STREET_NAME;
      const stddist = .001;
      const roadClip = bboxClip(res.data.features[0],[latlng.lng - stddist,latlng.lat - stddist,latlng.lng + stddist,latlng.lat + stddist]);
      const coords = roadClip.geometry.type === 'LineString' ? roadClip.geometry.coordinates : roadClip.geometry.coordinates[0];
  
      const c1 = coords[0];
      const len = coords.length;
      const c2 = coords[len - 1];
  
      let angle = parseInt(bearing(c1[1],c1[0],c2[1],c2[0]));
  
      angle += 90;
  
      if(angle>90 && angle<270)
        angle = angle + 180;
  
      if(angle>360)
        angle = angle - 360;
 
      return {
        name: roadname,
        angle: parseInt(angle)
      }
    }
    return null;
  } 

  removeGapiLabel = async(label) => {
    let cLabels = this.props.mapObj.gapiPointCustomLabels;
    const ix = cLabels.findIndex(l=>l.content.id===label.content.id);
    if(ix>-1){
      cLabels.splice(ix,1);
    }
    else{
      let hLabels = this.props.mapObj.gapiPointHideLabels;
      hLabels.push(label.content.id);
      this.props.updateMapObj({gapiPointHideLabels: hLabels});
    }
    this.props.updateMapObj({gapiPointCustomLabels: cLabels});
  }

  updateGapiLabel = async(label) => {
    let cLabels = this.props.mapObj.gapiPointCustomLabels;
    const ix = cLabels.findIndex(l=>l.content.id===label.content.id);
    let icon = this.props.getLabelIcon(label);
    label.content.icon = icon;
    cLabels[ix] = label;
    this.props.updateMapObj({gapiPointCustomLabels: cLabels});
  }

  addGapiLabel = async(label) => {
    let icon = this.props.getLabelIcon(label);
    label.content.icon = icon;
    let cLabels = this.props.mapObj.gapiPointCustomLabels;
    cLabels.push(label);
    this.props.updateMapObj({gapiPointCustomLabels: cLabels});
  }

  addMarker = async (e) => {

    if(this.state.makeCalloutMode){
      this.setState({lastClick:e.latlng});
    }

    if (this.props.mapAddPt) {
      this.setState({
        selectedShape: []
      });
      const newPoint = {
        position: e.latlng,
        content: {
          accuracy: {
            match_type: "UserClick"
          }
        }
      }
      const legendClone = Array.from(this.props.mapObj.legend);
      if (this.props.points.length === 0) {
        legendClone[0].visible = true;
      }
      const newPoints = this.props.createNewPoint([newPoint]);
      this.props.updateMapObj({ mapPoints: newPoints, popupOpen: false, legend: legendClone });

      if(this.props.mapObj.mapAddPtRing){
        const ringObj = this.props.mapObj.mapAddPtRing;
        const newPoint = newPoints[newPoints.length - 1];
        const cloneRings = this.props.mapObj.rings;
        const cloneDriveTimes = this.props.mapObj.driveTimes;
        const _drawingId = GenerateRandomId();
        if(ringObj.ringType==='ring'){
          const unitText = this.props.mapObj.country === 'US' ? ' Mile' : ' KM';
          const unitDist = ringObj.ringValue; //this.props.mapObj.country === 'US' ? ringObj.ringValue : ringObj.ringValue/1.603;
          const ringLabel = ringObj.ringValue + (ringObj.ringValue > 1 ? unitText + 's' : unitText);
          const newring = {
            id: newPoint.content.id + "_1",
            pointId: newPoint.content.id,
            center:[newPoint.position.lat,newPoint.position.lng],
            drawingId: _drawingId,
            displayInLegend:false,
            fill: newPoint.content.pointColor,
            opacity: 0.5,
            radius: unitDist,
            stroke: newPoint.content.pointColor,
            strokeOpacity: 1,
            strokeWidth: 3,
            label: ringLabel,
            labelDirection: "bottom",
            labelFill:"#4a4a4d",
            labelStroke:"#ffffff",
            meterDistance:[0,0],
            labelWidth: 0
          }
          cloneRings.push(newring);
          this.props.updateMapObj({rings: cloneRings});
        } else {
          const drivetime = await this.props.getDriveTime(ringObj.ringValue ,newPoint.position.lng,newPoint.position.lat, 'driving_time');
          if(!drivetime){
            this.props.updateMapObj({errorBar: true, errorMessage:'There was a problem generating the drivetime'});
          }
          else{
            const ringLabel = ringObj.ringValue + (ringObj.ringValue > 1 ? ' Minutes' : ' Minute');
            const mdist = GetMeterDistanceForDT(drivetime[0]);
            const newDriveTime = {
              id: newPoint.content.id + "_1",
              pointId: newPoint.content.id,
              center: [newPoint.position.lat,newPoint.position.lng],
              displayInLegend: false,
              fill: newPoint.content.pointColor,
              opacity: 0.5,
              radius: this.state.ringValue,
              stroke: newPoint.content.pointColor,
              strokeOpacity: 1,
              strokeWidth: 3,
              label: ringLabel,
              labelDirection: "bottom",
              labelFill:"#4a4a4d",
              labelStroke:"#ffffff",
              type:"driving_time",
              polygon: drivetime[0],
              meterDistance: mdist
            };
            cloneDriveTimes.push(newDriveTime);
            this.props.updateMapObj({driveTimes: cloneDriveTimes});
          }
        }
      }


    } 
    else if (this.state.mapAddLabel) {

      if (this.props.mapObj.labelLayer) {
        const _url = this.props.mapObj.labelLayer;
        const map1 = this.refs.map.leafletElement;

        this.setState({
          selectedShape: []
        });
        const self = this;
        map1.eachLayer(function (layer) {
          if (layer.options.url === _url || layer.options.url === _url + '/') {
            if (_url.includes('MapServer')) {
              layer.identify()
                .on(map1)
                .at(e.latlng)
                .tolerance(10)
                .run(function (error, fc) {
                  if (fc.features.length > 0) {
                    var _text = fc.features[0].properties["Traffic1"];
                    _text = _text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    const newLabel = {
                      position: e.latlng,
                      content: {
                        text: _text,
                        color: self.state.mapAddLabelColor,
                        stroke: self.state.mapAddLabelStroke,
                        fontSize: self.state.mapAddLabelFontSize,
                        opacity: self.state.mapAddLabelOpacity,
                        font: self.state.mapAddLabelFont,
                        weight: self.state.mapAddLabelWeight
                      }
                    }
                    const newLabels = self.props.createNewLabel([newLabel]);
                    self.props.updateMapObj({ labels: newLabels, popupOpen: false });
                  }
                });
            }
            else if (_url.includes('FeatureServer')) {
              if (layer.query) {
                var query = layer.query(layer);
                query
                  .contains(e.latlng)
                  .where(layer.options.where)
                  .run(function (err, fc) {
                    if (fc.features.length > 0) {
                      var _text = fc.features[0].properties["name"];
                      const newLabel = {
                        position: e.latlng,
                        content: {
                          text: _text,
                          color: self.state.mapAddLabelColor,
                          stroke: self.state.mapAddLabelStroke,
                          fontSize: self.state.mapAddLabelFontSize,
                          opacity: self.state.mapAddLabelOpacity,
                          font: self.state.mapAddLabelFont,
                          weight: self.state.mapAddLabelWeight
                        }
                      }
                      const newLabels = self.props.createNewLabel([newLabel]);
                      self.props.updateMapObj({ labels: newLabels, popupOpen: false });
                    }
                  });
              }
            }
          }
        });
      }
      else if(this.props.mapObj.labelRoad){
        const res = await this.queryRoadLayer(e.latlng);
        //console.log(res,'res from queryRoadLayer');
        if(res){
          const newLabel = {
            position: e.latlng,
            content: {
              text: res.name,
              color: this.state.mapAddLabelColor,
              stroke: this.state.mapAddLabelStroke,
              fontSize: this.state.mapAddLabelFontSize,
              opacity: this.state.mapAddLabelOpacity,
              font: this.state.mapAddLabelFont,
              weight: this.state.mapAddLabelWeight,
              angle:res.angle
            }
          }
          const newbox = this.getLabelBoxPostions(newLabel);
          newLabel.box = newbox;
          const newLabels = this.props.createNewLabel([newLabel]);
          this.props.updateMapObj({ labels: newLabels, popupOpen: false });
        }
      }
      else {
        const newLabel = {
          position: e.latlng,
          content: {
            text: this.state.mapAddLabelText,
            color: this.state.mapAddLabelColor,
            stroke: this.state.mapAddLabelStroke,
            fontSize: this.state.mapAddLabelFontSize,
            opacity: this.state.mapAddLabelOpacity,
            font: this.state.mapAddLabelFont,
            weight: this.state.mapAddLabelWeight,
            angle: this.state.mapAddLabelAngle
          }
        }
        const newbox = this.getLabelBoxPostions(newLabel);
        newLabel.box = newbox;
        const newLabels = this.props.createNewLabel([newLabel]);
        this.props.updateMapObj({ labels: newLabels, popupOpen: false });
      }


    } else if (this.state.mapAddShield) {
      this.setState({
        selectedShape: []
      });

      //query shield here; update state, etc.
      const mapRef = this.refs.map.leafletElement;
      const mapZoom = mapRef.getZoom();
      const shieldData = this.state.shieldManualMode ? [{ routelabelnew: GetShieldTypeManual(this.state.mapAddShieldType) + ' ' + this.state.mapAddShieldText }] : await this.props.getShield(e.latlng, mapZoom);
      let uniqueArray = FormatShieldData(shieldData, e.latlng, this.state.mapAddShieldColor, this.state.mapAddShieldSize) || [];
      if (uniqueArray && uniqueArray.length > 0) {
        //if (uniqueArray.length === 1) {
        const newShield = {
          position: e.latlng,
          content: {
            text: GetShieldText(uniqueArray[0]),
            color: this.state.mapAddShieldColor,
            size: this.state.mapAddShieldSize,
            type: GetShieldType(uniqueArray[0])
          }
        }

        const newShields = this.props.createNewShield([newShield]);
        this.props.updateMapObj({ shields: newShields, popupOpen: false });
        //} else {
        //this.props.updateMapObj({ popupOpen: false });
        //}
      } else {
        this.props.updateMapObj({ popupOpen: false });
      }
    } else {
      this.props.updateMapObj({ popupOpen: false });
    }
  }

  deleteMarker(point) {
    this.props.deletePoint(point);
  }

  _checkGapiPoints() {
    const timeout = process.env.REACT_APP_GAPIPT_PAN_TIMEOUT || 2000;
    if(this.props.mapObj.gapiPointBounds && !this.props.mapObj.gettingGapiPoints){
      
      //console.log(retids);
      const map1 = this.refs.map.leafletElement;
      const mb = map1.getBounds();
      const db = this.props.mapObj.gapiPointBounds;
      if( mb._northEast.lat-db._northEast.lat > .02 || mb._northEast.lng-db._northEast.lng > .02
        ||  mb._southWest.lat - db._southWest.lat < -.02 || mb._southWest.lng-db._southWest.lng < -.02
        )
        {
          if(this.props.mapObj.loadingBar===false){
            this.props.updateMapObj({
              loadingBar: true,
              loadingMessage: 'Traffic Counts will update on map stop'
            });
          }
  
          this.panningTimeout = setTimeout(()=>{
            this._getGapiPoints();
          },timeout);
        }
    }
  }

  _getGapiPoints() {
    const map1 = this.refs.map.leafletElement;
    const mb = map1.getBounds();
    const db = this.props.mapObj.gapiPointBounds;
    if(this.props.mapObj.gapiPointsLoading){
      console.log('still getting Logos');
    }
    else {
      this.props.updateMapObj({isSaving:true, mapBounds: mb, loadingBar: false, loadingMessage: '..'});
      this.props.getGAPIPoints(null,mb);
      //const currentRets = this.props.mapObj.retailPoints
//.map(rp=>rp.hashid);
      //this.props.getRetailPoints(mb,this.props.mapObj.selectedRetailer,this.props.mapObj.selectedCategory,currentRets,db);
    }
  }

  _checkRetailPoints() {
    const timeout = process.env.REACT_APP_RETPT_PAN_TIMEOUT || 2000;
    if(this.props.mapObj.retailerBounds && !this.props.mapObj.gettingLogos){
      
      //console.log(retids);
      const map1 = this.refs.map.leafletElement;
      const mb = map1.getBounds();
      const db = this.props.mapObj.retailerBounds;
      if( mb._northEast.lat-db._northEast.lat > .02 || mb._northEast.lng-db._northEast.lng > .02
        ||  mb._southWest.lat - db._southWest.lat < -.02 || mb._southWest.lng-db._southWest.lng < -.02
        )
        {
          if(this.props.mapObj.loadingBar===false){
            this.props.updateMapObj({
              loadingBar: true,
              loadingMessage: 'Retail logos will update on map stop'
            });
          }
  
          this.panningTimeout = setTimeout(()=>{
            this._getRetailPoints();
          },timeout);
        }
    }
  }

  _getRetailPoints() {
    const map1 = this.refs.map.leafletElement;
    const mb = map1.getBounds();
    const db = this.props.mapObj.retailerBounds;
    if(this.props.mapObj.gettingLogos){
      console.log('still getting Logos');
    }
    else {
      this.props.updateMapObj({mapBounds: mb, gettingLogos: true, loadingBar: false, loadingMessage: '..'});
      const currentRets = this.props.mapObj.retailPoints.map(rp=>rp.hashid);
      this.props.getRetailPoints(mb,this.props.mapObj.selectedRetailer,this.props.mapObj.selectedCategory,currentRets,db);
    }
  }

  _getGAPIPolygons() {
    const map1 = this.refs.map.leafletElement;
    // const mb = map1.getBounds();
    // const mz = map1.getZoom();
    const featureRegion = this.props.mapObj.gapiPolygonSettings.featureRegion;  
    const featureName = this.props.mapObj.gapiPolygonSettings.featureName; 

    if(this.props.mapObj.gettingDemographics){
      console.log('still getting')
    }else {
      // this.setState({
      //   demographicChangeDetected: false
      // });
      //this.props.updateMapObj({mapBounds: mb, demographicBounds: mb, gettingDemographics: true});
      this.props.getGAPIPolygons(featureRegion,featureName);
    }
  }
  _getRealGapiPoints(mb) {
    this.props.getRealGAPIPoints(this.props.mapObj.realGapiPoints[0],mb);
  }

  _checkRealGapiPoints() {
    const timeout = process.env.REACT_APP_GAPIPT_PAN_TIMEOUT || 2000;
    if(this.props.mapObj.realGapiPointBounds){ // && !this.props.mapObj.gettingGapiPoints){
      
      //console.log(retids);
      const map1 = this.refs.map.leafletElement;
      const mb = map1.getBounds();
      const db = this.props.mapObj.realGapiPointBounds;
      if( mb._northEast.lat-db._northEast.lat > .02 || mb._northEast.lng-db._northEast.lng > .02
        ||  mb._southWest.lat - db._southWest.lat < -.02 || mb._southWest.lng-db._southWest.lng < -.02
        )
        {
          if(this.props.mapObj.loadingBar===false){
            this.props.updateMapObj({
              loadingBar: true,
              loadingMessage: 'Points will update on map stop'
            });
          }
  
          this.panningTimeout = setTimeout(()=>{
            this._getRealGapiPoints(mb);
          },timeout);
        }
    }
  }

  _checkGAPIPolygons() {
    const timeout = process.env.REACT_APP_DEMO_PAN_TIMEOUT || 2000;
    //if(this.props.mapObj.gapiPolygonBounds && !this.props.mapObj.gettingDemographics){
    if(this.props.mapObj.gapiPolygonBounds && !this.props.mapObj.gettingDemographics){
      const map1 = this.refs.map.leafletElement;
      const mb = map1.getBounds();
      const mz = map1.getZoom();
      const db = this.props.mapObj.gapiPolygonBounds;
      if( mb._northEast.lat-db._northEast.lat > .02 || mb._northEast.lng-db._northEast.lng > .02
        ||  mb._southWest.lat - db._southWest.lat < -.02 || mb._southWest.lng-db._southWest.lng < -.02
        )
      {
        if(this.props.mapObj.loadingBar===false){
          this.props.updateMapObj({
            loadingBar: true,
            loadingMessage: 'Polygons will update on map stop'
          });
        }
        // this.setState({
        //   demographicChangeDetected: true
        // });

        // if(this.props.mapObj.demoPanelOpen){
        //   this.props.updateMapObj({demoPanelRedraw: true});
        // }
        // else{
        //   if(this.props.mapObj.loadingBar===false){
        //     this.props.updateMapObj({
        //       loadingBar: true,
        //       loadingMessage: 'Demographics will update on map stop'
        //     });
        //   }
          this.panningTimeout = setTimeout(()=>{
            console.log('panning timeout');
            this._getGAPIPolygons();
          },timeout);

      }
      else {
        this.panningTimeout = setTimeout(()=>{
          console.log('panning timeout no redraw');
          this.props.updateMapObj({
            loadingBar: false,
            loadingMessage: '....',
            loadingPanel: false,
            loadingPanelMessage: '....'
          });
        },timeout);
      }
    }
  }

  _checkDemographics() {
    const timeout = process.env.REACT_APP_DEMO_PAN_TIMEOUT || 2000;
    if(this.props.mapObj.demographicBounds && !this.props.mapObj.gettingDemographics){
      const map1 = this.refs.map.leafletElement;
      const mb = map1.getBounds();
      // const mz = map1.getZoom();
      const db = this.props.mapObj.demographicBounds;
      if( mb._northEast.lat-db._northEast.lat > .02 || mb._northEast.lng-db._northEast.lng > .02
        ||  mb._southWest.lat - db._southWest.lat < -.02 || mb._southWest.lng-db._southWest.lng < -.02
        )
      {
        // this.setState({
        //   demographicChangeDetected: true
        // });

        if(this.props.mapObj.demoPanelOpen){
          this.props.updateMapObj({demoPanelRedraw: true});
        }
        else{
          if(this.props.mapObj.loadingBar===false){
            this.props.updateMapObj({
              loadingBar: true,
              loadingMessage: 'Demographics will update on map stop'
            });
          }
  
          this.panningTimeout = setTimeout(()=>{
            console.log('panning timeout');
            this._getDemographics();
          },timeout);
        }
      }
      else {
        this.panningTimeout = setTimeout(()=>{
          console.log('panning timeout no redraw');
          this.props.updateMapObj({
            loadingBar: false,
            loadingMessage: '....',
            loadingPanel: false,
            loadingPanelMessage: '....'
          });
        },timeout);
      }
    }
  }

  _getDemographics() {
    const map1 = this.refs.map.leafletElement;
    const mb = map1.getBounds();
    const mz = map1.getZoom();
    if(this.props.mapObj.gettingDemographics){
      console.log('still getting')
    }else {
      // this.setState({
      //   demographicChangeDetected: false
      // });
      this.props.updateMapObj({mapBounds: mb, demographicBounds: mb, gettingDemographics: true});
      this.props.getDemographics(mb, mz);
    }
  }

  hideRetailPoint = (hashid) => {
    this.popup.current.leafletElement.options.leaflet.map.closePopup();
    const rpClone = this.props.mapObj.retailPoints;
    for (const rp of rpClone) {
      if (rp.hashid === hashid) {
        rp.latitude = 0;
        rp.longitude = 0;
        rp.customlocation = true;
      }
    }
    this.props.updateMapObj({ retailPoints: rpClone });
  }
  
  updateRetailPoint = (hashid,newObject) => {
    const rpClone = this.props.mapObj.retailPoints;
    for (let rp of rpClone) {
      if (rp.hashid === hashid) {
        rp = newObject;
      }
    }
    this.props.updateMapObj({ retailPoints: rpClone });
  }

  updateRetailPointPosition = (e, hide) => {
    if(hide){
      this.props.updateMapObj({closePopup: true});
    }
    const currentRP = e.target.options;
    const newPos = hide ? { lat: 0, lng: 0 } : e.target.getLatLng();
    const rpClone = this.props.mapObj.retailPoints;
    for (const rp of rpClone) {
      if (rp.hashid === currentRP.hashid) {
        // rp.leaderline = !this.props.mapObj.retailerLeaderLine || hide ? null : 
        // {
        //   positions: [[{lat:rp.originallatitude,lng:rp.originallongitude},newPos]],
        //   color: rp.leaderline?.color || this.props.mapObj.retailerLeaderColor,
        //   width: rp.leaderline?.width || this.props.mapObj.retailerLeaderWidth,
        //   opacity: rp.leaderline?.opacity || this.props.mapObj.retailerLeaderOpacity,
        //   show: true
        // }
        rp.latitude = newPos.lat;
        rp.longitude = newPos.lng;
        rp.customlocation = true;
        break;
      }
    }

    this.props.updateMapObj({ retailPoints: rpClone });
  }

  _getPointTextLabelOffset = (point) => {
    const ret = getPointTextLabelOffset(point);
    //console.log(ret,'_getPointTextLabelOffset');
    return ret;
  }

  getPointTextLabel = (point) => {
    const field = point.content.pointTextLabelField;
    const text = point.content[field];
    return text;
    // if(point.content.pointTextLabelWeight === 'true' || point.content.pointTextLabelWeight === true){
    //   return 'bold';
    // }
    // return 'normal';
  }

  getPointTextLabelWeight = (point) => {
    if(point.content.pointTextLabelWeight === 'true' || point.content.pointTextLabelWeight === true){
      return 'bold';
    }
    return 'normal';
  }

  updateMarkerPosition = (e) => {
    const currentPoint = e.target.options.children[0].props.children.props.content;
    
    const newPosition = e.target.getLatLng();
    const oldPointLat = currentPoint.lat;
    const oldPointLng = currentPoint.lng;
    const oldPointId = currentPoint.id;
    const oldPointAcc = currentPoint.accuracy;

    const pointsArrayClone = Array.from(this.props.points);
    const ringsArrayClone = Array.from(this.props.rings);
    pointsArrayClone.forEach(point => {
      if (point.content.id === currentPoint.id) {
        point.position = newPosition;
        point.content.lastLeaderLine = null;
        point.content.accuracy = {
          match_type: "UserMove",
          score: 100
        };


        ////Add if label line enabled and doesn't exist
        if(this.getGroup(point).useGroupStyle ? this.getGroup(point).groupLeaderLine : point.content.useLeaderline){
          if(!point.content.orglat){
            point.content.orglat = point.content.lat;
            point.content.orglng = point.content.lng;
          }
          point.content.leaderline ={
            positions: [[{lat:point.content.orglat,lng:point.content.orglng},newPosition]],
            color: this.getGroup(point).useGroupStyle ? this.getGroup(point).groupLeaderColor : this.props.mapObj.pointLeaderColor || '#ffffff',
            width: this.getGroup(point).useGroupStyle ? this.getGroup(point).groupLeaderWidth : this.props.mapObj.pointLeaderWidth || 3,
            opacity: this.getGroup(point).useGroupStyle ? this.getGroup(point).groupLeaderOpacity : this.props.mapObj.pointLeaderOpacity || 1,
            anchor: this.getGroup(point).useGroupStyle ? this.getGroup(point).groupLeaderAnchor : this.props.mapObj.pointLeaderAnchor || false,
          }
        }
        else {
          point.content.leaderline = null;
          point.content.orglat = null;
          point.content.orglng = null;
        }

        point.content.lat = newPosition.lat;
        point.content.lng = newPosition.lng;


        //move any rings associated with point
        ringsArrayClone.forEach(ring => {
          if (ring.pointId === currentPoint.id) {
            ring.center = [newPosition.lat, newPosition.lng];
          }
        });
      }
    });

    this.props.updateMapObj({ mapPoints: pointsArrayClone, rings: ringsArrayClone, undoMoveLocation: { accuracy: oldPointAcc, id: oldPointId, lat: oldPointLat, lng: oldPointLng } });
  }

  updateLabelPosition = (e) => {
    const newPosition = e.target.getLatLng();
    const currentLabel = e.target.options;

    const labelsArrayClone = Array.from(this.props.mapObj.labels);
    labelsArrayClone.forEach(label => {
      if (label.content.id === currentLabel.id) {
        label.position = newPosition;
      }
    });

    this.props.updateMapObj({ labels: labelsArrayClone });

    this.updateLabelBoxes();

  }

  updateLayerLabelPosition = (e) => {
    const newPosition = e.target.getLatLng();
    const currentLabel = e.target.options;

    const labelsArrayClone = Array.from(this.props.mapObj.layerlabels);
    labelsArrayClone.forEach(label => {
      if (label.content.id === currentLabel.id) {
        label.position = newPosition;
      }
    });
    this.props.updateMapObj({ layerlabels: labelsArrayClone });
  }

  updateShieldPosition = (e) => {
    const newPosition = e.target.getLatLng();
    const currentShield = e.target.options;

    const shieldsArrayClone = Array.from(this.props.mapObj.shields);
    shieldsArrayClone.forEach(shield => {
      if (shield.content.id === currentShield.id) {
        shield.position = newPosition;
      }
    });

    this.props.updateMapObj({ shields: shieldsArrayClone });
  }

  getRingLabelOffset(radius, direction, isDrive, ringConstant) {
    const map1 = this.refs.map.leafletElement;
    const metersPerPixel = 40075016.686 * Math.abs(Math.cos(map1.getCenter().lat * Math.PI / 180)) / Math.pow(2, map1.getZoom() + 8);

    let inputRadius = radius;
    if (isDrive) {
      if (direction === "top" || direction === "bottom") {
        inputRadius = radius[0] / 2;
      } else {
        inputRadius = radius[1] / 2;
      }
    }

    let ringMeters = !isDrive ? inputRadius * ringConstant : inputRadius;

    let labelHeightOffset = ringMeters / metersPerPixel;
    let labelWidthOffset = 0;
    if (direction === "top") {
      labelHeightOffset = labelHeightOffset * -1;
    } else if (direction === "left") {
      labelHeightOffset = 0;
      labelWidthOffset = (ringMeters / metersPerPixel) * -1;
    } else if (direction === "right") {
      labelHeightOffset = 0;
      labelWidthOffset = ringMeters / metersPerPixel;
    }

    return [labelWidthOffset, labelHeightOffset];
  }

  getGroup(point) {
    const group = this.props.groups.filter(group => group.id === point.content.group);
    return group[0];
  }

  getGroupColor(point) {
    const group = this.props.groups.filter(group => group.id === point.content.group);
    return group[0].groupIconColor;
  }

  getGroupIcon(point) {
    const group = this.props.groups.filter(group => group.id === point.content.group);
    return group[0].groupIconName;
  }

  getGroupTextFill(point) {
    const group = this.props.groups.filter(group => group.id === point.content.group);
    return group[0].groupTextColor;
  }

  getGroupIconSize(point) {
    const group = this.props.groups.filter(group => group.id === point.content.group);
    return group[0].groupIconSize;
  }

  getGroupVisible(point) {
    const group = this.props.groups.filter(group => group.id === point.content.group);
    return group[0].visible;
  }

  getGroupVisibleRing(ring) {
    const point = this.props.points.filter(point => point.content.id === ring.pointId);
    if (point.length > 0) {
      const group = this.props.groups.filter(group => group.id === point[0].content.group);
      return group[0].visible;
    } else {
      return false;
    }
  }

  handleMapOnLoad() {
    //get pixel bounds for mask
    const map1 = this.refs.map.leafletElement;
    const pixelBounds = map1.getPixelBounds();
    this.props.updateMapObj({ pixelBounds: [Math.round(pixelBounds.max.x - pixelBounds.min.x), Math.round(pixelBounds.max.y - pixelBounds.min.y)], });
  }

  handleZoomEnd() {
    const map1 = this.refs.map.leafletElement;
    const mapZoom = map1.getZoom();
    this.setState({
      currentZoom: mapZoom
    });

    const x = document.getElementsByClassName("leaflet-control-scale-line")[0];
    if (x!==undefined){
      this.props.updateMapObj({scaleWidth: (x.offsetWidth -2), scaleText: x.innerText});
    }

    this.updateLabelBoxes();    

    if(this.state.rotateMode && this.props.mapObj.labels.length > 0){
      this.refs.map.leafletElement.pm.disableGlobalRotateMode();
      setTimeout(() => {
        this.refs.map.leafletElement.pm.enableGlobalRotateMode();
      }, 500);
    }

  }

  handleMoveEnd(){
    if(this.panningTimeout){
      clearTimeout(this.panningTimeout);
    }

    if(this.props.mapObj.realGapiPointBounds){
      this._checkRealGapiPoints();
    }

   if(this.props.mapObj.demographicBounds){
    this._checkDemographics();
   }

   if(this.props.mapObj.gapiPolygonBounds){
    this._checkGAPIPolygons();
   }

   if(this.props.mapObj.retailerBounds){
    this._checkRetailPoints();
   }

   if(this.props.mapObj.retailerBounds){
    this._checkRetailPoints();
   }

   if(this.props.mapObj.gapiPointBounds){
    this._checkGapiPoints();
   }


    const x = document.getElementsByClassName("leaflet-control-scale-line")[0];
    if (x!==undefined && this.props.mapObj.scaleText === null){
      this.props.updateMapObj({scaleWidth: (x.offsetWidth -2), scaleText: x.innerText});
    }   
    if (x!==undefined && this.props.mapObj.scaleWidth < 1){
      this.props.updateMapObj({scaleWidth: (x.offsetWidth -2), scaleText: x.innerText});
    }   
  }

  handleMapRightClick(e){
    const map1 = this.refs.map.leafletElement;
    let text = '<div>' + Math.round(e.latlng.lat * 1000000) / 1000000  + ',' +  Math.round(e.latlng.lng * 1000000) / 1000000 + '</div>';
    text += "<div><br/><a target='_blank' href='https://hydra.colliers.com/index.html?latitude=" + Math.round(e.latlng.lat * 1000000) / 1000000 + "&longitude=" +  Math.round(e.latlng.lng * 1000000) / 1000000 + "&title=Boom Import'>Hydra</a></div>";
    var popup = L.popup().setContent(text).setLatLng(e.latlng);
    map1.openPopup(popup);
  }

  handleMapResize(event) {
    this.props.updateMapObj({ pixelBounds: [event.newSize.x, event.newSize.y] });
  }

  _getMapZoom(mapRef) {
    const mapZoom = mapRef.getZoom();
    const mapCenter = mapRef.getCenter();
    this.props.updateMapCenterAndZoom(mapZoom, mapCenter);
  }

  onMapBoxGLLoaded() {
    this.setState({ mapBoxGLLoaded: true });
  }

  onEsriLeafletLoaded() {
    this.setState({ esriLeafletLoaded: true });
  }

  onEsriVectorLoaded() {
    this.setState({ esriVectorLoaded: true });

    const map1 = this.refs.map.leafletElement;
    this._setBasemap(map1);
  }

  onPopupOpen(event) {
    event.popup._closeButton.onclick = () => {
      this.props.updateMapObj({ popupOpen: false, isEditingRing: false, isStyling: false });
    }
    this.props.updateMapObj({ popupOpen: true, isEditingRing: false, isStyling: false });
  }

  onPopupClose(event) {
    //this.props.updateMapObj({   });
  }

  updateMapState(updateObj) {
    this.setState(updateObj);
  }

  _onCreated(e, cut, dragEdit) {
    const shapesArrayClone = Array.from(this.props.mapObj.shapes);
    let shapeType = e.shape;
    let eventLayerArray = [e.layer];
    let shapeFill = this.state.shapeFill;
    let shapeFillOpacity = this.state.shapeFillOpacity;
    let shapeStroke = this.state.shapeStroke;
    let shapeStrokeOpacity = this.state.shapeStrokeOpacity;
    let shapeStrokeWidth = this.state.shapeStrokeWidth;
    let currentShapeLayerId = this.props.mapObj.currentShapeLayer;


    if (cut) {
      let shapeToRemove = this._shapeToRemoveAfterCut(e, shapesArrayClone, dragEdit);
      if (shapeToRemove) {
        const removeIndex = shapesArrayClone.findIndex((shape) => shape.id === shapeToRemove.id);
        if (removeIndex > -1) {
          shapesArrayClone.splice(removeIndex, 1);
        }
      } else {
        return;
      }

      shapeType = shapeToRemove ? shapeToRemove.type : 'polygon';
      shapeFill = shapeToRemove ? shapeToRemove.fill : '#0c9ed9';
      shapeStroke = shapeToRemove ? shapeToRemove.stroke : '#0c9ed9';
      shapeFillOpacity = shapeToRemove ? shapeToRemove.opacity : 0.5;
      shapeStrokeOpacity = shapeToRemove ? shapeToRemove.strokeOpacity : 0.5;
      shapeStrokeWidth = shapeToRemove ? shapeToRemove.strokeWidth: 3;

      if (e.type === 'pm:cut') {
        const shapeLayers = this._getShapeLayers(e);
        eventLayerArray = Array.from(shapeLayers);
      }
    }

    for (const eLayer of eventLayerArray) {
      let position = null;
    
      if (this.state.makeCalloutMode){
        const lastClick = this.state.lastClick;
        const org_position = eLayer.getLatLngs ? eLayer.getLatLngs() : eLayer._latlngs;
        const callout_pos = [];

        const tail1 = .3;
        const tail2 = .7;
        const bottombox = .3;

        let direction = '';
        if(lastClick.lat > org_position[0][0].lat && lastClick.lng > org_position[0][0].lng){
          direction = 'upper-right';

          const org_box_ht = (org_position[0][1].lat - org_position[0][0].lat);
          const box_width = (org_position[0][3].lng - org_position[0][0].lng);
          const minlng = (org_position[0][0].lng);

          const bottom_box_lat = (org_position[0][0].lat) +  (org_box_ht * bottombox);
          let tail1_lng = minlng + (box_width * tail1);
          let tail2_lng = minlng + (box_width * tail2);

          let start_node = org_position[0][0];
          let top_node_1 = org_position[0][1];
          let top_node_2 = org_position[0][2];
          let top_lng = org_position[0][2].lng;

          callout_pos.push(start_node);
          callout_pos.push({lat: bottom_box_lat, lng: tail1_lng});
          callout_pos.push({lat: bottom_box_lat, lng: minlng});
          callout_pos.push(top_node_1);
          callout_pos.push(top_node_2);
          callout_pos.push({lat: bottom_box_lat, lng: top_lng});
          callout_pos.push({lat: bottom_box_lat, lng: tail2_lng});
          callout_pos.push(start_node);
        }
        else if(lastClick.lat > org_position[0][0].lat && lastClick.lng <= org_position[0][0].lng){
          direction = 'upper-left';
          const org_box_ht = (org_position[0][1].lat - org_position[0][0].lat);
          const box_width = (org_position[0][3].lng - org_position[0][0].lng);
          const minlng = (org_position[0][3].lng);

          const bottom_box_lat = (org_position[0][0].lat) +  (org_box_ht * bottombox);
          let tail1_lng = minlng - (box_width * tail1);
          let tail2_lng = minlng - (box_width * tail2);

          let start_node = org_position[0][3];
          let top_node_1 = org_position[0][2];
          let top_node_2 = org_position[0][1];
          let top_lng = org_position[0][1].lng;

          callout_pos.push(start_node);
          callout_pos.push({lat: bottom_box_lat, lng: tail1_lng});
          callout_pos.push({lat: bottom_box_lat, lng: minlng});
          callout_pos.push(top_node_1);
          callout_pos.push(top_node_2);
          callout_pos.push({lat: bottom_box_lat, lng: top_lng});
          callout_pos.push({lat: bottom_box_lat, lng: tail2_lng});
          callout_pos.push(start_node);
        }
        else if(lastClick.lat <= org_position[0][0].lat && lastClick.lng > org_position[0][0].lng){
          direction = 'lower-right';
          const org_box_ht = (org_position[0][1].lat - org_position[0][0].lat);
          const box_width = (org_position[0][3].lng - org_position[0][0].lng);
          const minlng = (org_position[0][0].lng);
          const maxlng = (org_position[0][3].lng);

          const bottom_box_lat = (org_position[0][3].lat) +  (org_box_ht * (1 - bottombox));
          let tail1_lng = minlng + (box_width * tail1);
          let tail2_lng = minlng + (box_width * tail2);

          let start_node = org_position[0][1];

          let top_node_1 = org_position[0][3];
          let top_node_2 = org_position[0][0];
          let top_lng = org_position[0][0].lng;

          callout_pos.push(start_node);
          callout_pos.push({lat: bottom_box_lat, lng: tail2_lng});
          callout_pos.push({lat: bottom_box_lat, lng: maxlng});
          callout_pos.push(top_node_1);
          callout_pos.push(top_node_2);
          callout_pos.push({lat: bottom_box_lat, lng: top_lng});
          callout_pos.push({lat: bottom_box_lat, lng: tail1_lng});
          callout_pos.push(start_node);
        }
        else {
          direction = 'lower-left';
            const org_box_ht = (org_position[0][1].lat - org_position[0][0].lat);
            const box_width = (org_position[0][3].lng - org_position[0][0].lng);
            const minlng = (org_position[0][0].lng);
            const maxlng = (org_position[0][3].lng);
  
            const bottom_box_lat = (org_position[0][3].lat) +  (org_box_ht * (1- bottombox));
            let tail1_lng = maxlng - (box_width * tail1);
            let tail2_lng = maxlng - (box_width * tail2);
  
            let start_node = org_position[0][2];
            let top_node_1 = org_position[0][3];
            let top_node_2 = org_position[0][0];
            let top_lng = org_position[0][0].lng;
  
            callout_pos.push(start_node);
            callout_pos.push({lat: bottom_box_lat, lng: tail1_lng});
            callout_pos.push({lat: bottom_box_lat, lng: maxlng});
            callout_pos.push(top_node_1);
            callout_pos.push(top_node_2);
            callout_pos.push({lat: bottom_box_lat, lng: top_lng});
            callout_pos.push({lat: bottom_box_lat, lng: tail2_lng});
            callout_pos.push(start_node);
        }
        shapesArrayClone.push({
          id: GenerateRandomId(),
          order: shapesArrayClone.length + 1,
          type: 'polygon',
          calloutDirection: direction,
          position: [callout_pos],
          fill: shapeFill,
          stroke: shapeStroke,
          opacity: shapeFillOpacity,
          strokeOpacity: shapeStrokeOpacity,
          width: shapeStrokeWidth
          // fill: '#ffffff',
          // stroke: '#000000',
          // opacity: 1,
          // strokeOpacity: 1,
          // width: 3
        });
        this.toggleCalloutMode(false);
      }
      else if (shapeType.toLowerCase() !== 'circle') {
        let direction = eLayer.options.calloutDirection || null;
        //copy the first point as the last point
        position = eLayer.getLatLngs ? eLayer.getLatLngs() : eLayer._latlngs;
        const firstPoint = position[0][0];

        if (shapeType.toLowerCase() === 'polygon' || shapeType.toLowerCase() === 'rectangle') { //for polygon
          shapeType = 'polygon';
          position[0].push(firstPoint);
        } else { //for polyline
          if (!dragEdit) {
            if (position[0] && !Array.isArray(position[0]))
              position = [position];
          }
          shapeType = 'polyline';
        }

        shapesArrayClone.push({
          id: GenerateRandomId(),
          order: shapesArrayClone.length + 1,
          type: shapeType,
          position: position,
          fill: shapeFill,
          stroke: shapeStroke,
          opacity: shapeFillOpacity,
          strokeOpacity: shapeStrokeOpacity,
          width: shapeStrokeWidth,
          pane: 'shapesPane',
          calloutDirection: direction,
          layerId: currentShapeLayerId
        });
      } else {
        shapesArrayClone.push({
          id: GenerateRandomId(),
          order: shapesArrayClone.length + 1,
          type: 'circle',
          radius: eLayer.getRadius(),
          center: eLayer.getLatLng(),
          fill: shapeFill,
          stroke: shapeStroke,
          opacity: shapeFillOpacity,
          strokeOpacity: shapeStrokeOpacity,
          width: shapeStrokeWidth,
          pane: 'shapesPane',
          layerId: currentShapeLayerId
        });
      }
    }

    this.updateShapeLegend(shapeType,currentShapeLayerId,shapeFill,shapeFillOpacity,shapeStroke,currentShapeLayerId);
    this.props.updateMapObj({shapes: shapesArrayClone });
    this.removeAllEditControlLayers();
  }

  _updateShapeLegend(shapeid){
    const shapes = this.props.mapObj.shapes;
    const found = shapes.find(s=>s.id===shapeid);
    if(found){
      this.updateShapeLegend(found.type,found.layerId,found.fill,found.opacity,found.stroke);
    }
  }

  updateShapeLegend(shapeType,currentShapeLayerId,shapeFill,shapeFillOpacity,shapeStroke) {
    let oldShapeFill = this.state.shapeFill;
    let oldOpacity = this.state.shapeFillOpacity;
    let oldStroke = this.state.shapeStroke;
    //let currentShapeLayerId = this.props.mapObj.currentShapeLayer;
    const shapeLegend = this.props.mapObj.shapeLegend;
    const layerix = shapeLegend.findIndex(o=>o.layerId===currentShapeLayerId);
    let layer = null;
    if(layerix>-1){
      layer = shapeLegend[layerix];
    }
    const styles = [];
    if(layer){
      ///if layer does not have styles property, this is the first simply add the style in the else clause
      if(layer.hasOwnProperty('styles')){
        const styles = layer.styles;
        const styleIndex = styles.findIndex(s=>s.type===shapeType.toLowerCase() && s.fill===shapeFill && s.stroke===shapeStroke && s.opacity === shapeFillOpacity);
        let style = null;
        if(styleIndex > -1){
          style = styles[styleIndex];
        }

        //if this style exists no need to change, other wise enter if statement
        if(!style && shapeType.toLowerCase() !== 'makecallout'){
          const shapes = this.props.mapObj.shapes;
          const foundshapes = shapes.filter(s=>s.type===shapeType.toLowerCase() && s.fill===oldShapeFill && s.stroke===oldStroke && s.opacity === oldOpacity);
          //check to see if any shapes still exist in the orignal style, if so add a new style at the bottom
          if (foundshapes.length > 0){
            const name = "Style " + (styles.length + 1);
            styles.push({
              type: shapeType.toLowerCase(),
              fill: shapeFill,
              stroke: shapeStroke,
              opacity: shapeFillOpacity,
              name: name
            });
          } else {
            const oldStyleIndex = styles.findIndex(s=>s.type===shapeType.toLowerCase() && s.fill===oldShapeFill && s.stroke===oldStroke && s.opacity === oldOpacity);
            //if orignal style exists add change the stule otherwise add a new one in the else clause
            if(oldStyleIndex > -1){
              const name = styles[oldStyleIndex].name;
              const newStyle = {
                type: shapeType.toLowerCase(),
                fill: shapeFill,
                stroke: shapeStroke,
                opacity: shapeFillOpacity,
                name: name
              }
              styles[oldStyleIndex] = newStyle;
            }
            else {
              const name = "Style " + (styles.length + 1);
              styles.push({
                type: shapeType.toLowerCase(),
                fill: shapeFill,
                stroke: shapeStroke,
                opacity: shapeFillOpacity,
                name: name
              });
            }
          }
        }
      }
      else {
        if(shapeType.toLowerCase()!=='makecallout'){
          styles.push({
            type: shapeType.toLowerCase(),
            fill: shapeFill,
            stroke: shapeStroke,
            opacity: shapeFillOpacity,
            name: "Style 1"
          });
          layer.styles = styles;
          shapeLegend[layerix] = layer;
        }
      }
    }

    const legendClone = Array.from(this.props.mapObj.legend);
    legendClone[0].visible = true;

    this.props.updateMapObj({shapeLegend: shapeLegend,legend: legendClone}); 
  }

  removeFromShapeLegend(remove) {
    const shapeLegend = this.props.mapObj.shapeLegend;
    const layerix = shapeLegend.findIndex(o=>o.layerId===remove.layerId);
    if(layerix===-1){
      return;
    }
    const styles = shapeLegend[layerix].styles;
    const stylesix = styles.findIndex(s=>s.type===remove.type && s.fill===remove.fill && s.stroke===remove.stroke && s.opacity === remove.opacity);
    const shapes = this.props.mapObj.shapes;
    const foundShapes = shapes.filter(s=>s.layerId===remove.layerId && s.type===remove.type && s.fill===remove.fill && s.stroke===remove.stroke && s.opacity === remove.opacity);
    if(foundShapes.length===1){
      const shapeLegend = this.props.mapObj.shapeLegend;
      const styles = shapeLegend[layerix].styles;
      styles.splice(stylesix, 1);
      shapeLegend[layerix].styles = styles;
      this.props.updateMapObj({shapeLegend: shapeLegend});
    }
  }

  _shapeToRemoveAfterCut(e, shapesArrayClone, dragEdit) {
    let origLayerLatLngs = [];
    if (e.originalLayer) {
      origLayerLatLngs = e.originalLayer.getLatLngs();
    } else {
      if (e.layer.options.positions) {
        origLayerLatLngs = e.layer.options.positions;
      } else if (e.layer.options.radius) {
        origLayerLatLngs = e.layer.options.radius;
      }
    }

    if (e.layer.feature && e.layer.feature.geometry && (e.layer.feature.geometry.type === 'Polygon' || e.layer.feature.geometry.type === 'Rectangle' || e.layer.feature.geometry.type === 'MultiPolygon')) {
      const firstPoint = origLayerLatLngs[0][0];
      origLayerLatLngs[0].push(firstPoint);
    }

    for (const shape of shapesArrayClone) {
      if (shape.radius && !Array.isArray(origLayerLatLngs)) {
        let layerCenter = null;
        if (e.layer.pm._centerMarker) {
          layerCenter = e.layer.pm._centerMarker._origLatLng;
        } else {
          layerCenter = this.state.shapeToEdit;
        }
        if (Math.round(origLayerLatLngs) === Math.round(shape.radius) && (JSON.stringify(layerCenter)) === (JSON.stringify(shape.center))) {
          return shape;
        }
      } else if (origLayerLatLngs[0] && shape.position && (shape.position[0].length === origLayerLatLngs[0].length)) {
        //round both objects
        let shapeArrayClone = Array.from(shape.position[0]);
        let origLayerArrayClone = Array.from(origLayerLatLngs[0]);
        let roundedObj = this._roundShapeArrays(shapeArrayClone, origLayerArrayClone);
        if (JSON.stringify(roundedObj.shapeArray) === JSON.stringify(roundedObj.origArray)) {
          return shape;
        }
      }
    }
  }

  deleteShapeFromEvent(e) {
    //if (e.layer.shape === "Circle") {
    if (e.shape === "Circle") {
      this.setState({ shapeToEdit: e.layer.getLatLng() });
    }

    const shapesArrayClone = Array.from(this.props.mapObj.shapes);
    let shapeToRemove = this._shapeToRemoveAfterCut(e, shapesArrayClone);
    this.removeFromShapeLegend(shapeToRemove);


    if (shapeToRemove) {
      const removeIndex = shapesArrayClone.findIndex((shape) => shape.id === shapeToRemove.id);
      if (removeIndex > -1) {
        shapesArrayClone.splice(removeIndex, 1);
      }
    } else {
      return;
    }

    this.props.updateMapObj({ shapes: shapesArrayClone });
  }

  _getShapeLayers(e) {
    let returnLayers = [];
    if (e.layer.pm._layers) {
      returnLayers = Array.from(e.layer.pm._layers);
    } else if (e.layer.feature.geometry.type === "MultiPolygon") {
      for (const coords of e.layer._latlngs) {
        returnLayers.push({ _latlngs: coords });
      }
    } else {
      returnLayers.push(e.layer);
    }
    return returnLayers;
  }

  _roundShapeArrays(shapeArrayClone, origLayerArrayClone) {
    let newShapeArray = [];
    let newOrigArray = [];
    for (let i = 0; i < shapeArrayClone.length; i++) {
      const shapeLatDecimals = CountDecimals(shapeArrayClone[i].lat);
      const origLayerLatDecimals = CountDecimals(origLayerArrayClone[i].lat);
      let latDecimals = Math.min(shapeLatDecimals, origLayerLatDecimals);
      const shapeLngDecimals = CountDecimals(shapeArrayClone[i].lng);
      const origLayerLngDecimals = CountDecimals(origLayerArrayClone[i].lng);
      let lngDecimals = Math.min(shapeLngDecimals, origLayerLngDecimals);
      //toFixed to a few decimals less than the lower number of decimal places to catch any rounding errors
      newShapeArray.push({ lat: shapeArrayClone[i].lat.toFixed(latDecimals - 2), lng: shapeArrayClone[i].lng.toFixed(lngDecimals - 2) });
      newOrigArray.push({ lat: origLayerArrayClone[i].lat.toFixed(latDecimals - 2), lng: origLayerArrayClone[i].lng.toFixed(lngDecimals - 2) });
    }

    const roundObj = {
      shapeArray: newShapeArray,
      origArray: newOrigArray
    };

    return roundObj;
  }

  _getDistanceMeasure(e) {
    const distanceMeasureClone = Array.from(this.state.distanceMeasurePts);
    const start = distanceMeasureClone[distanceMeasureClone.length - 1];
    const ringConstant = this.props.country === 'US' ? 1609.34 : 1000;
    let dist = this.refs.map.leafletElement.distance(start, e.latlng);
    let newDist = 0;
    for (const segment of this.state.distanceSegments) {
      newDist = newDist + segment;
    }
    newDist = newDist + dist;
    newDist = (newDist / ringConstant).toFixed(2); //convert to miles or km

    this.setState({ currentDistance: dist, distanceLabel: newDist });
    return newDist;
  }

  removeAllEditControlLayers() {
    const mapRef = this.refs.map;
    mapRef.leafletElement.eachLayer(layer => {
      if (layer._drawnByGeoman) {
        layer.remove();
      }
    });
  }

  toggleShapeStyle(bool, shape) {
    if (this.state.colorEditMode) {
      let selectedShapeArray = [];
      selectedShapeArray.push(shape);
      this.setState({
        shapeStylePanel: bool,
        selectedShape: selectedShapeArray
      });
    } else if (this.state.resizeEditMode) {
      let selectedShapeArray = [];
      let markerPtsArray = [];
      if (shape.type === 'polygon' && shape.position[0].length === 5) {
        selectedShapeArray.push(shape);
        if (bool) {
          markerPtsArray = shape.position[0];
        }
      }

      this.setState({
        resizeShapePanel: bool,
        resizeMarkerPoints: markerPtsArray,
        selectedShape: selectedShapeArray
      });
    }
  }

  toggleColorMode(bool) {
    let updateObj = {
      colorEditMode: bool,
      resizeEditMode: false,
      resizeShapePanel: false,
      resizeMarkerPoints: [],
      customLabelPanel: false,
      mapAddLabel: false,
      selectedLabel: null,
      selectedLayerLabel: null,
      customShieldPanel: false,
      mapAddShield: false,
      selectedShield: null
    };
    if (!bool) {
      if (this.refs.map.leafletElement.pm.Toolbar.getButtons().colorPicker._button.toggleStatus) {
        this.refs.map.leafletElement.pm.Toolbar.getButtons().colorPicker.toggle();
      }
      updateObj.shapeStylePanel = false;
    }
    this.setState(updateObj);
  }

  deleteAllShapes() {
    if (window.confirm("Do you want to Delete All Shapes?")) {
      this.props.updateMapObj({shapes: []});
    }
  }

  toggleCalloutMode(bool) {
    let updateObj = {
      makeCalloutMode: bool,
      colorEditMode: false,
      resizeEditMode: false,
      shapeStylePanel: false,
      customLabelPanel: false,
      mapAddLabel: false,
      selectedLabel: null,
      selectedLayerLabel: null,
      customShieldPanel: false,
      mapAddShield: false,
      selectedShield: null
    };
    if (!bool) {
      this.refs.map.leafletElement.pm.disableDraw();
    }
    this.setState(updateObj);
  }

  toggleResizeMode(bool) {
    let updateObj = {
      colorEditMode: false,
      resizeEditMode: bool,
      shapeStylePanel: false,
      customLabelPanel: false,
      mapAddLabel: false,
      selectedLabel: null,
      selectedLayerLabel: null,
      customShieldPanel: false,
      mapAddShield: false,
      selectedShield: null
    };
    if (!bool) {
      if (this.refs.map.leafletElement.pm.Toolbar.getButtons().polygonResize._button.toggleStatus) {
        this.refs.map.leafletElement.pm.Toolbar.getButtons().polygonResize.toggle();
      }
      updateObj.resizeShapePanel = false;
      updateObj.resizeMarkerPoints = [];
    }
    this.setState(updateObj);
  }

  toggleMapAddLabel() {
    this.setState({
      customLabelPanel: false,
      mapAddLabel: false,
      selectedLabel: null,
      selectedLayerLabel: null
    });
  }

  toggleGapiPointLabel(bool,label) {
    const thisLabel = this.props.mapObj.gapiPointSettings[0]?.displayLabels===true
    ? (this.props.mapObj.gapiPointLabels || []).find(l=>l.content.id===label.content.id)
    : (this.props.mapObj.displayLabels || []).find(l=>l.content.id===label.content.id);

    if(thisLabel!==undefined){
      const remvoed =  this.props.mapObj.gapiPointHideLabels.findIndex(l=>l===label.content.id);
      const removedid = remvoed === -1 ? null : label.content.id;
      this.setState({selectedGapiLabel: thisLabel, removedGapiLabelId: removedid});
    }
    else{
      const count = label.content.Traffic1;
      const text = count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      label.content.count = count;
      label.content.text = text;
      this.setState({potentialGapiLabel: label, removedGapiLabelId: null});
    }
  }

  toggleGapiLabelStyle(bool,label) {
    this.setState({selectedGapiLabel: label, removedGapiLabelId: null});
  }

  toggleLabelStyle(bool, label) {
    this.setState({ selectedLabel: null, customLabelPanel: false, mapAddLabel: false });
    let updateObj = {};
    if (bool) {
      updateObj.selectedLabel = label;
    } else {
      updateObj.selectedLabel = null;
    }
    this.setState(updateObj);
  }

  toggleMapAddShield() {
    this.setState({
      customShieldPanel: false,
      mapAddShield: false,
      selectedShield: null
    });
  }

  toggleShieldStyle(bool, shield) {
    this.setState({ selectedShield: null, customShieldPanel: false, mapAddShield: false });
    let updateObj = {};
    if (bool) {
      updateObj.selectedShield = shield;
    } else {
      updateObj.selectedShield = null;
    }
    this.setState(updateObj);
  }

  toggleShieldManualMode(bool) {
    this.setState({ shieldManualMode: bool });
  }

  endResizeShape(e, marker) {
    const markerCoord = marker;
    const dragCoord = e.target.getLatLng();

    //calculate new bounds and update selected shape
    const boundsObj = GetFourCornerBounds(this.state.selectedShape[0].position[0]);
    const corner = GetCornerDirection(markerCoord, boundsObj);
    const newPosition = GetNewPositionArray(boundsObj, corner, dragCoord);

    //update main shapes array
    let shapesArrayClone = Array.from(this.props.mapObj.shapes);
    for (const shape of shapesArrayClone) {
      if (shape.id === this.state.selectedShape[0].id) {
        shape.position = [newPosition];
      }
    }

    shapesArrayClone.sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));
    this.props.updateMapObj({
      shapes: shapesArrayClone
    });

    //update resizeMarkers
    this.setState({
      resizeMarkerPoints: newPosition
    });
  }

  getFillColor(demo) {

    const demoColors = this.props.mapObj.demographicColors || 'd3d9e9,a8b3d2,7c8cbc,5166a5,25408f';
    const demoRanges = this.props.mapObj.demographicRanges;
    const demoVar = this.props.mapObj.demographicVariable.toLowerCase();

    if(demoVar==='random_color'){
      return demo.data.color;
    }

    // demographicRangeSetting: null,
        // demographicColors: 'd3d9e9,a8b3d2,7c8cbc,5166a5,25408f', //ed1b34,f68b1f,ffd400,c59fc5,85bcb8',
        // demographicGeography: "TRACT",
        // demographicRanges: null,
        // demographicRangeSetting: "custom",
        // demographicStroke: '#cccdd5',
    const _dc = demoColors.split(',');
    const _dr = demoRanges.split(',');
    const value = demo.data[demoVar];

    let range = 4;

    if(value < _dr[0])
      range = 0
    else if(value  < _dr[1])
      range = 1
    else if(value < _dr[2])
      range = 2
    else if(value < _dr[3])
      range = 3



  

    ///const _dc = demoColors.split(',');
    return '#' + _dc[range];
  }

  updateLabelBoxes() {
    const labelClone = this.props.mapObj.labels;
    const newLabels = [];

    labelClone.forEach(label=>{
      const lbox = this.getLabelBoxPostions(label);
      label.box = lbox;
      newLabels.push(label);
    });
    this.props.updateMapObj({labels:newLabels});
  }

  updateDemoPaneOpacity(opacity) {
    const dpane = document.getElementsByClassName("leaflet-transdemos-pane");
    if(dpane.length>0){
      dpane[0].setAttribute('style','opacity:' + opacity + '; z-Index:151;');
    }
  }

  getLabelBoxPostions(label){
    const angle = parseInt(label.content.angle) || 0;
    const lsize = getLabelSize(label);
    const pxs = LatLongToPixelXY(label.position.lat,label.position.lng,this.state.currentZoom);

      const angle0 = angle * -1; // + 90;
      const boxFactor = 1.2;
      const c1 = cogo(pxs[0],pxs[1],angle0,lsize[0]*(boxFactor*.5));
      const pos1 = pixelXYToLatLong(c1[0],c1[1],this.state.currentZoom);

      const newangle = angle0 + 90;
      const c2 = cogo(c1[0],c1[1],newangle,lsize[1]*(boxFactor*.5));
      const pos2 = pixelXYToLatLong(c2[0],c2[1],this.state.currentZoom);

      const angle3 = newangle - 270; // + 180;
      const c3 = cogo(c2[0],c2[1],angle3,lsize[0]*boxFactor);
      const pos3 = pixelXYToLatLong(c3[0],c3[1],this.state.currentZoom);

      const angle4 = angle3 + 90;
      const c4 = cogo(c3[0],c3[1],angle4,lsize[1]*boxFactor);
      const pos4 = pixelXYToLatLong(c4[0],c4[1],this.state.currentZoom);

      const c5 = cogo(c4[0],c4[1],angle0,lsize[0]*boxFactor);
      const pos5 = pixelXYToLatLong(c5[0],c5[1],this.state.currentZoom);


      // const positions = [
      //   {lat:pos1[0],lng:pos1[1]},
      //   {lat:pos2[0],lng:pos2[1]},
      //   {lat:pos3[0],lng:pos3[1]},
      //   {lat:pos4[0],lng:pos4[1]},
      //   {lat:pos5[0],lng:pos5[1]},
      //   {lat:pos1[0],lng:pos1[1]}
      // ];

      const positions = [
        {lat:pos2[0],lng:pos2[1]},
        {lat:pos3[0],lng:pos3[1]},
        {lat:pos4[0],lng:pos4[1]},
        {lat:pos5[0],lng:pos5[1]},
        {lat:pos2[0],lng:pos2[1]}
      ];



      return positions;

  }

  closeBanner() {
    this.setState({
      showBanner: false,
      updateBanner: false
    });
  }

  closeMapMessage(bool) {
    this.setState({
      closeMapMessage: bool
    });
  }

  getShapeColor(shape){
    return shape.color;
  }

  render() {
    const rps = this.props.mapObj.retailPoints;
    let points = this.props.points.filter(this.getGroupVisible);
    const rings = this.props.rings.filter(this.getGroupVisibleRing);
    const drivetimes = this.props.driveTimes.filter(this.getGroupVisibleRing);
    const polylineShapes = this.props.mapObj.shapes.filter(shape => shape.type === 'polyline');
    const polygonShapes = this.props.mapObj.shapes.filter(shape => shape.type === 'polygon');
    const circleShapes = this.props.mapObj.shapes.filter(shape => shape.type === 'circle');
    const ringConstant = this.props.country === 'US' ? 1609.34 : 1000;
    const demoFillOpacity = this.props.mapObj.demographicFillOpacity;
    const demoStroke = this.props.mapObj.demographicStroke;
    const demoStrokeOpacity = this.props.mapObj.demographicStrokeOpacity;
    const demoWidth = this.props.mapObj.demographicWidth;
    let rpLeaderlines = []; //rps.filter(rp => rp.leaderline);
    const pointLeaderLines = points.filter(p => p.content.leaderline);
    let gapiPolys = this.props.mapObj.gapiPolygons;
    let gapiHighlightPolys = [];
    let gapiPolyLabels = this.props.mapObj.gapiPolygonSettings?.displayLabels ? this.props.mapObj.gapiPolygonLabels : [];
    const gapiIcon = this.props.mapObj.gapiPointIcons[0]?.icon || null; //Set up to handle multiple point styles

    let flatGapiPoints = [];
    if(this.props.mapObj.realGapiPoints){
      
      const rgpLayers = this.props.mapObj.realGapiPoints;
      for(let i in rgpLayers){
        
        const rgpl = rgpLayers[i];
        let _html = ResizeLogo(ReactDOMServer.renderToString(<SVGIconComponent name={rgpl.iconName} size={"default"}/>),rgpl.logoSize);
        if(!rgpl.useLogo){
          _html = ReactDOMServer.renderToString(<SVGIconComponent color={rgpl.pointStyle?.pointColor||'#cccccc'} name={rgpl.pointStyle?.pointIconName||'circleIcon'} size={rgpl.pointStyle?.pointIconSize||"default"}/>);
        }
    
        const _iconOffset = GetIconOffset(_html);
        const rgplpts = rgpl.points.map((p)=>{
          return {
            position: p.position,
            //content: {iconName: rgpl.iconName, logoSize: rgpl.logoSize},
            html: _html,
            iconOffset: _iconOffset
          }
        })
        flatGapiPoints = flatGapiPoints.concat(rgplpts);
      }
    }

    if(this.props.mapObj.gapiPolygonSettings?.displayNames.length > 0){
      gapiPolys = gapiPolys.filter(p=>this.props.mapObj.gapiPolygonSettings.displayNames.includes(p.data.displayname));
      gapiPolyLabels = gapiPolyLabels.filter(l=>this.props.mapObj.gapiPolygonSettings.displayNames.includes(l.content.text));
    }

    if(this.props.mapObj.gapiPolygonSettings?.highlightNames?.length > 0){
      gapiHighlightPolys = gapiPolys.filter(p=>this.props.mapObj.gapiPolygonSettings.highlightNames.includes(p.data.displayname));
      gapiPolys = gapiPolys.filter(p=>!this.props.mapObj.gapiPolygonSettings.highlightNames.includes(p.data.displayname));
      gapiPolyLabels = gapiPolyLabels.filter(l=>this.props.mapObj.gapiPolygonSettings.highlightNames.includes(l.content.text));
    }

    const them7 = this.props.mapObj.gapiPolygonSettings.displayThem7 || false;

    const _gapiPoints = this.props.mapObj.gapiPoints;
    let gapiPoints = [];
    let gapiPointLabels = [];

    if(!isNullorEmptyArray(_gapiPoints)){
      const gapiPointFilter = this.props.mapObj.gapiPointSettings[0]?.minDisplayValue || 10000;
      const gapiAllLabels = this.props.mapObj.gapiPointSettings[0]?.displayLabels;
      const gapiCustomLabels = this.props.mapObj.gapiPointCustomLabels;
      const _gapiPointLabels = gapiAllLabels ? this.props.mapObj.gapiPointLabels : gapiCustomLabels;
      gapiPointLabels = _gapiPointLabels.filter(p=>parseInt(p.content.count) > gapiPointFilter);
  
      gapiPointLabels  = gapiPointLabels.map(g1=>{
        const g2 = gapiCustomLabels.find(g=> g.content.id===g1.content.id);
        return g2 ? g2 : g1;
      });
  
      const gapiPointHideLabels = this.props.mapObj.gapiPointHideLabels || [];
      gapiPointLabels = gapiPointLabels.filter(p=>gapiPointHideLabels.includes(p.content.id)===false);  
      gapiPoints = _gapiPoints.filter(p=>(p.content.Traffic1 || p.content.traffic_co) > gapiPointFilter);
    }



    let layerLabels = [];
    let layerzoom = false;
    for(let i=0;i<this.props.mapObj.customLayers.length;i++){
      const cl = this.props.mapObj.customLayers[i];
      if((this.state.currentZoom || 14)>cl.minZoom){
        this.updateMapZoom();
        const thisLabel = this.props.mapObj.layerlabels.filter(l=>
          l.content.customlayer===cl.name
        );
        layerLabels = layerLabels.concat(thisLabel);
      }
      else{
        if (!layerzoom)
          layerzoom = '';
        else
          layerzoom += ', ';

        layerzoom += cl.label;
      }
      if(this.state.closeMapMessage)
        layerzoom = false;
    }
    rpLeaderlines = rps.map(rp => {
      if(parseFloat(rp.latitude)>18 && parseFloat(rp.longitude)<-50){
        
        let show = this.props.mapObj.retailerLeaderLine;
        if(rp.leaderline){
          if(rp.leaderline?.show === false){
            show = false;
          }
          else if(rp.leaderline?.show === true){
            show = true;
          }
        }
          if (rp.originallatitude === rp.latitude && rp.originallongitude === rp.longitude)
            show = false;

        let anchor =  rp.leaderline?.anchor;
        if(anchor===undefined)
          anchor = this.props.mapObj.retailerLeaderLineAnchor;

          return {
              leaderline:{
                positions: [[{lat:parseFloat(rp.originallatitude),lng:parseFloat(rp.originallongitude)},{lat:parseFloat(rp.latitude),lng:parseFloat(rp.longitude)}]],
                color: rp.leaderline?.color || this.props.mapObj.retailerLeaderColor,
                width: rp.leaderline?.width || this.props.mapObj.retailerLeaderWidth,
                opacity: rp.leaderline?.opacity || this.props.mapObj.retailerLeaderOpacity,
                anchor: anchor,
                show: show
              }
            }
      }
      else {
        return {
          leaderline: {
            show: false
          }
        }
      }
    });

    //add custom location here?
    rpLeaderlines = rpLeaderlines.filter(rp => rp.leaderline.show === true);

    //Pacific Hack
    //ResizeLogo
   
    if(this.props.mapObj?.mapBounds?._southWest?.lng < -180 && this.props.mapObj?.mapBounds?._northEast?.lng > -180 
      && (this.props.mapObj.country == 'AUS' || this.props.mapObj.country ==='JPN')){
      points = points.map(pt => {
        if(pt.position.lng>0){
          let newlng = 180 - pt.position.lng;
          newlng = -180 - newlng;
          return {
            position: {
              lat: pt.position.lat,
              lng: newlng
            },
            content: pt.content
          }
        }
        else {
          return pt;
        }
      });
    }

    return (
      <div className="mapContainer" id="mapDiv" ref={el => (this.container = el)}>
        <Script
          url={'https://colliers-boom-docs.s3.us-east-2.amazonaws.com/mapbox-gl.js'}
          onLoad={this.onMapBoxGLLoaded.bind(this)}
        />
        {this.state.mapBoxGLLoaded ?
          <Script
            key="esriLeaflet"
            url={'https://colliers-boom-docs.s3.us-east-2.amazonaws.com/esri-leaflet_orig.js'}
            onLoad={this.onEsriLeafletLoaded.bind(this)}
          />
          : null}

        {this.state.esriLeafletLoaded ?
          <div>
            <Script
              key="esriVector"
              url={'https://colliers-boom-docs.s3.us-east-2.amazonaws.com/esri-leaflet-vector_orig.js'}
              onLoad={this.onEsriVectorLoaded.bind(this)}
            />
            <Script
              key="esriRenderers"
              url={'https://colliers-boom-docs.s3.us-east-2.amazonaws.com/esri-renderers.js'}
              onLoad={this.onEsriVectorLoaded.bind(this)}
            />
          </div>
          : null
        }
        <Map
          className="mapDiv"
          zoom={this.state.prevMapZoom}
          center={this.state.prevMapCenter}
          onclick={this.addMarker}
          ref='map'
          zoomControl={false}
          zoomSnap={0.1}
          zoomDelta={0.25}
          onLoad={(e) => { this.handleMapOnLoad(e) }}
          onZoomEnd={this.handleZoomEnd}
          onMoveEnd={this.handleMoveEnd}
          onResize={(e) => this.handleMapResize(e)}
          onPopupClose={(e) => { this.onPopupClose(e) }}
          onPopupOpen={(e) => { this.onPopupOpen(e) }}
          onContextmenu={(e) => {this.handleMapRightClick(e)}}
        >
          {/* <div style={{ position: 'absolute', zIndex: 1000, left: '500px' }}><FindAddressOrPlace {...this.props} refs={this.refs} /> </div> */}
          {
            this.props.mapObj.verifyPoint ?
            <Marker
              position={this.props.mapObj.verifyPoint.position}
              icon={L.divIcon({ className: 'custom-icon',
              html:ReactDOMServer.renderToString(<SVGIconComponent color={this.props.mapObj.verifyPoint.color} name={"bullseyeIcon"} size={"default"}/>),
              iconAnchor:[18,18]})}
            />
            : null
          }
         {gapiHighlightPolys.map((poly,idx) =>
          <Polygon
            key={poly.id}
            positions={poly.position}
            fillColor={this.props.mapObj.gapiPolygonSettings.highlightPolygonStyle.fill}
            //fillColor={this.props.mapObj.gapiPolygonSettings.polygonStyle.fill}
            color={this.props.mapObj.gapiPolygonSettings.highlightPolygonStyle.stroke}
            weight={this.props.mapObj.gapiPolygonSettings.highlightPolygonStyle.width}
            fillOpacity={this.props.mapObj.gapiPolygonSettings.highlightPolygonStyle.opacity}
            opacity={this.props.mapObj.gapiPolygonSettings.highlightPolygonStyle.strokeOpacity}
            // ref={demo.id}
            pane={'gapipolysPane'}
            pmIgnore={true}
          ></Polygon>
         )}
         {gapiPolys.map((poly, idx) =>
            <Polygon
            key={poly.id}
            positions={poly.position}
            fillColor={them7 ? poly.data.color : this.props.mapObj.gapiPolygonSettings.polygonStyle.fill}
            //fillColor={this.props.mapObj.gapiPolygonSettings.polygonStyle.fill}
            color={this.props.mapObj.gapiPolygonSettings.polygonStyle.stroke}
            weight={this.props.mapObj.gapiPolygonSettings.polygonStyle.width}
            fillOpacity={this.props.mapObj.gapiPolygonSettings.polygonStyle.opacity}
            opacity={this.props.mapObj.gapiPolygonSettings.polygonStyle.strokeOpacity}
            // ref={demo.id}
            pane={'gapipolysPane'}
            pmIgnore={true}
            ></Polygon>
          )}     
          {this.props.mapObj.demographics && this.props.mapObj.demographicStroke !== 'transparent' ?
            this.props.mapObj.demographics.map((demo, idx) =>
              <Polygon
                key={demo.id}
                positions={demo.position}
                fillColor={this.getFillColor(demo)}
                color={demoStroke}
                weight={demoWidth}
                fillOpacity={demoFillOpacity}
                opacity={demoStrokeOpacity}
                ref={demo.id}
                pane={'demosPane'}
                pmIgnore={true}
              ></Polygon>
            )
          : null
          }
          {this.props.mapObj.demographics && this.props.mapObj.demographicStroke === 'transparent' ?
            this.props.mapObj.demographics.map((demo, idx) =>
              <Polygon
                key={demo.id}
                positions={demo.position}
                fillColor={this.getFillColor(demo)}
                color={this.getFillColor(demo)}
                weight={demoWidth}
                fillOpacity={1}
                opacity={1}
                ref={demo.id}
                pane={'transdemosPane'}
                pmIgnore={true}
              ></Polygon>
            )
          : null
          }
          {flatGapiPoints.map((fgp,idx) => 
            <Marker
            key={'fgp_' + idx}
            position={fgp.position}
            icon={L.divIcon({ className: 'custom-icon',
              html: fgp.html,
              iconAnchor: fgp.iconOffset})}
              pane={'rgapiPtPane'}
            //onClick={this.toggleGapiPointLabel.bind(this, true, gp)}
            >
            </Marker>
          )}
          {gapiPoints.map((gp,idx) =>
            <Marker
            key={gp.content.id}
            position={gp.position}
            icon={gapiIcon || gp.content.icon}
            pane={'layerPtPane'}
            onClick={this.toggleGapiPointLabel.bind(this, true, gp)}
            >
            </Marker>
          )}
          {points.map((point, idx) =>
            <Marker
              key={point.content.id + "_" + point.content.group + "_" + point.content.zIndex + "_" + point.content.order + "_" + point.position.lat + "_" + point.content.label + "_" + point.content.pointColor + "_" + point.content.useGroupStyle + "_" + point.content.useCustomLabel + "_" + this.getGroupColor(point) + "_" + point.content.pointIconName + "_" + this.getGroupIcon(point) + "_" + this.getGroupTextFill(point) + "_" + this.getGroupIconSize(point) + "_" + point.content.pointIconSize + "_" + this.getGroupVisible(point)}
              //key={'point_' + idx}
              position={point.position}
              icon={point.content.icon}
              zIndexOffset={point.content.zIndex}
              draggable={'true'}
              onDragend={this.updateMarkerPosition}
              autoPan={false}
              //attribution={'boomPoint'}
              pmIgnore={true}
              pane={'pointsPane'}
            >
              <Popup>
                <PopupContent
                  content={point.content}
                  deletePoint={this.deleteMarker}
                  updateMapObj={this.props.updateMapObj}
                  groupName={GetGroupName(this.props.groups, point.content.group)}
                  autoPan={false}
                  points={this.props.points}
                  groups={this.props.groups}
                  updateOrderAndIcon={this.props.updateOrderAndIcon}
                  pointLeaderColor={this.props.pointLeaderColor}
                  pointLeaderWidth={this.props.pointLeaderWidth}
                  pointLeaderOpacity={this.props.pointLeaderOpacity}
                  pointLeaderAnchor={this.props.pointLeaderAnchor}
                  secyGeoName={this.props.secyGeoName}
                  translate={this.props.translate}
                >
                </PopupContent>
              </Popup>
              {
                point.content.pointTextLabel ? 
                //{this._getPointTextLabelOffset(point)}
                <Tooltip key={"key_pt_tt" + idx} direction={"center"} opacity={point.content.pointTextLabelOpacity} offset={this._getPointTextLabelOffset(point)} permanent className="pointTextLabel">
                  <span style={{fontFamily: point.content.pointTextLabelFont, fontSize: point.content.pointTextLabelFontSize, fontWeight: this.getPointTextLabelWeight(point), color: point.content.pointTextLabelColor , textShadow: '-1px -1px 0 ' + point.content.pointTextLabelStroke  + ',1px -1px 0 ' + point.content.pointTextLabelStroke + ',-1px 1px 0 ' + point.content.pointTextLabelStroke  + ',1px 1px 0 ' + point.content.pointTextLabelStroke}}>{this.getPointTextLabel(point)}</span>
                </Tooltip>
                : null
              }
            </Marker>
          )}
          {pointLeaderLines.map((point,idx) => 
            <div>
              <Polyline
               key={"point_leaderline" + idx}
               positions={point.content.leaderline.positions}
               color={point.content.leaderline.color}
               weight={point.content.leaderline.width}
               opacity={point.content.leaderline.opacity}
               pmIgnore={true}
               pane={'pointLinesPane'}
              />
              {point.content.leaderline.anchor ?
                <Marker
                key={"pt_ll_point_" + idx}
                position={point.content.leaderline.positions[0][0]}
                icon={L.divIcon({ className: 'custom-icon',
                 html:ReactDOMServer.renderToString(<SVGIconComponent color={point.content.leaderline.color} name={"circleIcon"} size={"tiny"}/>),
                 iconAnchor:[6,6]})}
                draggable={'false'}
                autoPan={false}
                pmIgnore={true}
                pane={'ptLineAnchorPane'}
                />
                : null}
            </div>
          )}
          {rps.map((rp, idx) =>
            <Marker
              key={'key_' + rp.hashid}
              hashid={rp.hashid}
              position={{ lat: rp.latitude, lng: rp.longitude }}
              icon={L.divIcon({ className: 'custom-icon', html: rp.fullcolorsvg, iconAnchor: rp.iconOffset })}
              draggable={'true'}
              autoPan={false}
              attribution={"ChainXY Solutions Inc"}
              onDragend={this.updateRetailPointPosition}
              pmIgnore={true}
              onContextmenu={e => { this.updateRetailPointPosition(e, true) }}
              pane={'rpPane'}
            >
              <Popup
                key={'rp_popup_' + rp.hashid}
                ref={this.popup}
              >
                {/* retailerLeaderLineAnchor */}
                <RetailPopupContent
                 key={'rp_pu_contet_' + rp.hashid}
                  retailPoint={rp}
                  hideRetailPoint={this.hideRetailPoint}
                  mapRetailerLeaderLine={this.props.mapObj.retailerLeaderLine}
                  mapRetailerLeaderColor={this.props.mapObj.retailerLeaderColor}
                  mapRetailerLeaderOpacity={this.props.mapObj.retailerLeaderOpacity}
                  mapRetailerLeaderWidth={this.props.mapObj.retailerLeaderWidth}
                  mapRetailerLeaderLineAnchor={this.props.mapObj.retailerLeaderLineAnchor}
                  updateRetailPoint={this.updateRetailPoint}
                />
              </Popup>
            </Marker>
          )}
          {rpLeaderlines.map((line,idx) => 
              <div>
              <Polyline
               key={"leader_line" + idx}
               positions={line.leaderline.positions}
               color={line.leaderline.color}
               weight={line.leaderline.width}
               opacity={line.leaderline.opacity}
               pmIgnore={true}
               pane={'rpLinePane'}
              />
              {line.leaderline.anchor ?
                <Marker
                key={"rp_ll_point_" + idx}
                position={line.leaderline.positions[0][0]}
                icon={L.divIcon({ className: 'custom-icon',
                 html:ReactDOMServer.renderToString(<SVGIconComponent color={line.leaderline.color} name={"circleIcon"} size={"tiny"}/>),
                 iconAnchor:[6,6]})}
                draggable={'false'}
                autoPan={false}
                pmIgnore={true}
                pane={'rpLineAnchorPane'}
                />
              : null}
            </div>
          )}
          {rings.map((ring, idx) =>
            <Circle
              key={ring.id + "_" + ring.fill + "_" + ring.stroke + "_" + ring.opacity + "_" + ring.strokeOpacity + "_" + this.props.rings.length + "_" + ring.labelStroke + "_" + ring.labelFill + "_" + ring.radius + "_" + ring.labelDirection + ring.drawingId}
              center={ring.center}
              radius={ring.radius * ringConstant}
              fillColor={ring.fill}
              color={ring.stroke}
              fillOpacity={ring.opacity}
              opacity={ring.strokeOpacity}
              ref={"circle" + ring.id}
              pmIgnore={true}
              weight={ring.strokeWidth || 3}
              pane={'ringPane'}
            >
              <Tooltip key={"key" + this.state.currentZoom} direction={ring.labelDirection} offset={this.getRingLabelOffset(ring.radius, ring.labelDirection, false, ringConstant)} opacity={1} permanent className="ringLabel">
                <span style={{ color: ring.labelFill, textShadow: '-1px -1px 0 ' + ring.labelStroke + ',1px -1px 0 ' + ring.labelStroke + ',-1px 1px 0 ' + ring.labelStroke + ',1px 1px 0 ' + ring.labelStroke }}>{ring.label}</span>
              </Tooltip>
            </Circle>
          )}
          {drivetimes.map((ring, idx) =>
            <div key={ring.id + "_" + ring.fill + "_" + ring.stroke + "_" + ring.opacity + "_" + ring.strokeOpacity + "_" + this.props.driveTimes.length + "_" + ring.labelStroke + "_" + ring.labelFill + "_" + ring.radius + "_" + ring.labelDirection + ring.drawingId + "dtKey"}>
              <Polygon
                key={ring.id + "_driveRing"}
                positions={ring.polygon}
                fillColor={ring.fill}
                color={ring.stroke}
                fillOpacity={ring.opacity}
                opacity={ring.strokeOpacity}
                pmIgnore={true}
                weight={ring.strokeWidth || 3}
                pane={'ringPane'}
              ></Polygon>
              <Circle
                key={ring.id + "_fakeCircle"}
                center={GetGeoCenter(ring.polygon)}
                radius={GetRingRadius(ring)}
                fillColor={'transparent'}
                color={'transparent'}
                fillOpacity={0}
                ref={"route" + ring.id}
                pmIgnore={true}
                weight={ring.strokeWidth || 3}
                pane={'ringPane'}
              >
                <Tooltip key={"key" + this.state.currentZoom} direction={ring.labelDirection} offset={this.getRingLabelOffset(ring.meterDistance, ring.labelDirection, true, ringConstant)} opacity={1} permanent className="ringLabel">
                  <span style={{ color: ring.labelFill, textShadow: '-1px -1px 0 ' + ring.labelStroke + ',1px -1px 0 ' + ring.labelStroke + ',-1px 1px 0 ' + ring.labelStroke + ',1px 1px 0 ' + ring.labelStroke }}>{ring.label}</span>
                </Tooltip>
              </Circle>
            </div>
          )}
          {polylineShapes.map((shape) =>
            <Polyline
              key={shape.id + shape.fillColor + shape.color + shape.fillOpacity + shape.opacity + "_line"}
              positions={shape.position}
              fillColor={shape.fill}
              color={shape.stroke}
              fillOpacity={shape.opacity}
              opacity={shape.strokeOpacity}
              ref={"shape" + shape.id}
              onClick={this.toggleShapeStyle.bind(this, true, shape)}
              attribution={"shapeItem"}
              width={shape.width || 3}
              pane={'shapesPane'}
            ></Polyline>
          )}
          {polygonShapes.map((shape) =>
            <Polygon
              key={shape.id + shape.fillColor + shape.color + shape.fillOpacity + shape.opacity + "_poly"}
              positions={shape.position}
              fillColor={shape.fill}
              color={shape.stroke}
              fillOpacity={shape.opacity}
              opacity={shape.strokeOpacity}
              ref={"shape" + shape.id}
              onClick={this.toggleShapeStyle.bind(this, true, shape)}
              attribution={"shapeItem"}
              calloutDirection={shape.calloutDirection}
              weight={shape.width || 3}
              pane={'shapesPane'}
              //strokeLinejoin="bevel"
            ></Polygon>
          )}
          {circleShapes.map((shape) =>
            <Circle
              key={shape.id + shape.fillColor + shape.color + shape.fillOpacity + shape.opacity + "_circle"}
              center={shape.center}
              radius={shape.radius}
              fillColor={shape.fill}
              color={shape.stroke}
              fillOpacity={shape.opacity}
              opacity={shape.strokeOpacity}
              width={shape.width || 3}
              ref={"shape" + shape.id}
              onClick={this.toggleShapeStyle.bind(this, true, shape)}
              attribution={"shapeItem"}
              pane={'shapesPane'}
            ></Circle>
          )}
          {this.state.resizeMarkerPoints.map((marker, idx) =>
            <Marker
              key={'key_' + idx}
              position={{ lat: marker.lat, lng: marker.lng }}
              icon={L.divIcon({ className: 'custom-icon', html: '<svg viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg" height="10px"><rect fill="#ffffff" stroke="#3388ff" stroke-width="2" width="10" height="10" /></svg>', iconAnchor: [5, 8] })}
              draggable={'true'}
              autoPan={false}
              pmIgnore={true}
              onDragend={e => { this.endResizeShape(e, marker) }}
            />
          )}
          {
          layerLabels.map((label,ix) =>
            <Marker
              key={label.content.id + "_" + label.content.stroke + "_" + label.content.color + "_" + label.content.text + "_" + label.content.weight + "_" + label.position.lat + "_" + label.content.fontSize + "_" + label.content.font + "_" + label.content.opacity}
              id={label.content.id}
              position={label.position}
              icon={label.content.icon}
              draggable={label.content.draggable}
              onDragend={this.updateLayerLabelPosition}
              ref={"customlayerlabel" + label.content.id}
              pmIgnore={true}
              //onClick={this.toggleLayerLabelStyle.bind(this, true, label)}
              pane={"labelPane"}
              >
            </Marker>
          )
          }
          {gapiPolyLabels.map((label, idx)=> 
            <Marker
            key={'gapi_poly_label' + idx}
            id={idx}
            position={label.position}
            icon={label.content.icon}
            draggable={'true'}
            onDragend={(e) => this.props.updateGAPIPolygonLabel(e,label)}
            // ref={"customlabel" + label.content.id}
            pmIgnore={true}
            onClick={this.toggleGapiLabelStyle.bind(this, true, label)}
            pane={'labelPane'}
          >
          </Marker>
          )}
          {gapiPointLabels.map((label,idx)=>
            <Marker
            key={'gapi_point_label' + idx}
            id={idx}
            position={label.position}
            icon={label.content.icon}
            //draggable={'true'}
            //onDragend={(e) => this.props.updateGAPIPolygonLabel(e,label)}
            // ref={"customlabel" + label.content.id}
            //pmIgnore={true}
            onClick={this.toggleGapiLabelStyle.bind(this, true, label)}
            pane={'labelPane'}
          >
          </Marker> 
          )
          }
          {this.props.mapObj.labels.map((label, idx) =>
            <Polygon
              key={"Label_Box_" + label.content.id + "_" + label.content.stroke + "_" + label.content.color + "_" + label.content.text + "_" + label.content.weight + "_" + label.position.lat + "_" + label.content.fontSize + "_" + label.content.font + "_" + label.content.opacity}
              id={"Label_Box_" + label.content.id }
              //position={label.box}
              // draggable={'true'}
              // onDragend={this.updateLabelPosition}
              //pmIgnore={true}
              positions={label.box}
              fillColor={'#cccccc'}
              color={'#cc0000'}
              fillOpacity={this.state.rotateMode ? .2 : 0}
              opacity={this.state.rotateMode ? 1 : 0}
              pane={'labelBoxPane'}
              //onClick={this.toggleLabelStyle.bind(this, true, label)}
            >
            </Polygon>
          )}
          {this.props.mapObj.labels.map((label, idx) =>
            <Marker
              key={label.content.id + "_" + label.content.stroke + "_" + label.content.color + "_" + label.content.text + "_" + label.content.weight + "_" + label.position.lat + "_" + label.content.fontSize + "_" + label.content.font + "_" + label.content.opacity}
              id={label.content.id}
              position={label.position}
              icon={label.content.icon}
              draggable={'true'}
              onDragend={this.updateLabelPosition}
              ref={"customlabel" + label.content.id}
              pmIgnore={true}
              onClick={this.toggleLabelStyle.bind(this, true, label)}
              pane={'labelPane'}
            >
            </Marker>
          )}
          {this.props.mapObj.shields.map((shield, idx) =>
            <Marker
              key={shield.content.id + "_" + shield.content.type + "_" + shield.content.color + "_" + shield.content.text + "_" + shield.content.size + "_" + shield.position.lat}
              id={shield.content.id}
              position={shield.position}
              icon={shield.content.icon}
              draggable={'true'}
              onDragend={this.updateShieldPosition}
              ref={"customshield" + shield.content.id}
              pmIgnore={true}
              onClick={this.toggleShieldStyle.bind(this, true, shield)}
              pane={'shieldsPane'}
            >
            </Marker>
          )}
          <ZoomControl position="topright" />
        </Map>
        {
        }
        <CustomMapBtns
          updateMapState={this.updateMapState}
          updateMapObj={this.props.updateMapObj}
          currentZoom={this.state.currentZoom}
          mapObj={this.props.mapObj}
          customLabelPanelBool={this.state.customLabelPanel}
          customShieldPanelBool={this.state.customShieldPanel}
        />
        {layerzoom === false ?
        null
        :
        <div>
          <Message
            closeMapMessage={this.closeMapMessage}
            updateMapZoom={this.updateMapZoom}
            text={'Zoom in to see:' + layerzoom}
            show={true}
          />
        </div>
        }
        {this.state.showBanner && this.props.country !== 'JPN' ?
          <SimpleBanner
            closeBanner={this.closeBanner}
            text={this.state.bannertext}
          />
          : null
        }
        {this.state.updateBanner ?
          <UpdateBanner
            closeBanner={this.closeBanner}
          />
          : null
        }

        {this.state.shapeStylePanel ?
          <ShapeStylePanel
            currentShape={this.state.selectedShape[0] ? this.state.selectedShape[0] : null}
            updateMapState={this.updateMapState}
            toggleShapeStyle={this.toggleShapeStyle}
            updateMapObj={this.props.updateMapObj}
            mapObj={this.props.mapObj}
            translate={this.props.translate}
            updateShapeLegend={this._updateShapeLegend}
          />
          : null
        }
        {this.state.resizeShapePanel ?
          <ResizeShapePanel
            currentShapeFill={this.state.shapeFill}
            currentShapeFillOpacity={this.state.shapeFillOpacity}
            currentShapeStroke={this.state.shapeStroke}
            currentShapeStrokeOpacity={this.state.shapeStrokeOpacity}
            currentShapeStrokeWidth={this.state.shapeStrokeWidth}
            currentShape={this.state.selectedShape[0] ? this.state.selectedShape[0] : null}
            updateMapState={this.updateMapState}
            toggleShapeStyle={this.toggleShapeStyle}
            updateMapObj={this.props.updateMapObj}
            mapObj={this.props.mapObj}
            translate={this.props.translate}
          />
          : null
        }
        {this.state.customLabelPanel ?
          <CustomLabelPanel
            updateMapState={this.updateMapState}
            updateMapObj={this.props.updateMapObj}
            toggleMapAddLabel={this.toggleMapAddLabel}
            mapAddLabelText={this.state.mapAddLabelText}
            mapAddLabelColor={this.state.mapAddLabelColor}
            mapAddLabelStroke={this.state.mapAddLabelStroke}
            mapAddLabelFontSize={this.state.mapAddLabelFontSize}
            mapAddLabelOpacity={this.state.mapAddLabelOpacity}
            mapAddLabelFont={this.state.mapAddLabelFont}
            mapAddLabelWeight={this.state.mapAddLabelWeight}
            mapAddLabelAngle={this.state.mapAddLabelAngle}
            labels={this.props.mapObj.labels}
            getLabelIcon={this.props.getLabelIcon}
            layers={this.props.mapObj.customLayers}
            country={this.props.mapObj.country}
            translate={this.props.translate}
            labelRoad={this.props.mapObj.labelRoad}
            getLabelBoxPostions={this.getLabelBoxPostions}
          />
          : null
        }
        {this.state.selectedLabel ?
          <CustomLabelStyle
            currentLabel={this.state.selectedLabel}
            updateMapState={this.updateMapState}
            updateMapObj={this.props.updateMapObj}
            toggleLabelStyle={this.toggleLabelStyle}
            individualStyle={true}
            labels={this.props.mapObj.labels}
            getLabelIcon={this.props.getLabelIcon}
            getLabelBoxPostions={this.getLabelBoxPostions}
            translate={this.props.translate}
          />
          : null
        }
        {
          this.state.potentialGapiLabel || this.state.selectedGapiLabel ? 
          <AddGapiLabelPanel
            potentialGapiLabel={this.state.potentialGapiLabel || null}
            selectedGapiLabel={this.state.selectedGapiLabel || null}
            removedGapiLabelId={this.state.removedGapiLabelId || null}
            addGapiLabel={this.addGapiLabel}
            updateGapiLabel={this.updateGapiLabel}
            removeGapiLabel={this.removeGapiLabel}
            readdGapiLabel={this.readdGapiLabel}
            closePanel={this.closePotentialGapiLabel}
            labelStyle={this.props.mapObj.gapiPointSettings[0].labelStyle || null}
          />
          : null
        }
        {
          this.state.selectedLayerLabel ? 
          <CustomLabelStyle
            currentLabel={this.state.selectedLayerLabel}
            updateMapState={this.updateMapState}
            updateMapObj={this.props.updateMapObj}
            toggleLabelStyle={this.toggleLayerLabelStyle}
            individualStyle={true}
            labels={this.props.mapObj.layerlabels}
            getLabelIcon={this.props.getLabelIcon}
            layers={this.props.mapObj.customLayers}
            translate={this.props.translate}
          />
          : null
        }
        {this.state.customShieldPanel ?
          <CustomShieldPanel
            updateMapState={this.updateMapState}
            updateMapObj={this.props.updateMapObj}
            toggleMapAddShield={this.toggleMapAddShield}
            mapAddShieldText={this.state.mapAddShieldText}
            mapAddShieldColor={this.state.mapAddShieldColor}
            mapAddShieldSize={this.state.mapAddShieldSize}
            mapAddShieldType={this.state.mapAddShieldType}
            shields={this.props.mapObj.shields}
            getShieldIcon={this.props.getShieldIcon}
            shieldManualMode={this.state.shieldManualMode}
            toggleShieldManualMode={this.toggleShieldManualMode}
            country={this.props.country}
            translate={this.props.translate}
          />
          : null
        }
        {this.state.selectedShield ?
          <CustomShieldStyle
            currentShield={this.state.selectedShield}
            updateMapState={this.updateMapState}
            updateMapObj={this.props.updateMapObj}
            toggleShieldStyle={this.toggleShieldStyle}
            individualStyle={true}
            shields={this.props.mapObj.shields}
            getShieldIcon={this.props.getShieldIcon}
            country={this.props.country}
            translate={this.props.translate}
          />
          : null
        }
        {this.state.currentDistance > 0 ?
          <div className="currentDistanceDiv">
            {translateObj.distance[this.props.translate]}: <span className="currentDistanceSpan">{this.state.distanceLabel} {this.state.distanceLabel.toString() === '1.00' ? (this.props.country === 'US' ? ' mile' : ' km') : (this.props.country === 'US' ? ' miles' : ' kms')}</span>
          </div>
          : null
        }
      </div>
    );
  }
}

export default MapComponent;