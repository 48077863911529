import React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { Droppable } from 'react-beautiful-dnd';
import { Draggable } from 'react-beautiful-dnd';
import Tooltip from '@material-ui/core/Tooltip';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import ShapeComponent from './ShapeComponent';
import ShapeLayerComponent from './ShapeLayerComponent';
import { GenerateRandomId } from '../../../utils/tools';
import shape from '@material-ui/core/styles/shape';

class ShapeLegendPanel extends React.Component {
    constructor(props) {
        super(props);
        // this.state = {
        //   editing: false
        // }
        this.setShapeLegend = this.setShapeLegend.bind(this);
        this.updateShapeText = this.updateShapeText.bind(this);
        this.updateShapeEditing = this.updateShapeEditing.bind(this);
        this.toggleNameVisibility = this.toggleNameVisibility.bind(this);
        this.addShapeLayer = this.addShapeLayer.bind(this);
        this.deleteShapeLayer = this.deleteShapeLayer.bind(this);
        this.editShapeStyle = this.editShapeStyle.bind(this);
        this.closeShapeStyle = this.closeShapeStyle.bind(this);
        this.deleteShapeStyle = this.deleteShapeStyle.bind(this);
    }

    deleteShapeLayer(layer){
      this.props.updateLegendObj({shapeDeleteShow:true,shapeLayerIndex:layer,shapeIndex:null});
    }

    closeShapeStyle() {
      this.props.updateLegendObj({editShapeShow:false});
    }

    deleteShapeStyle(ix,layerIx){
      this.props.updateLegendObj({shapeDeleteShow:true,shapeLayerIndex:layerIx,shapeIndex:ix});
    }

    editShapeStyle(ix,layerIx){
      this.props.updateLegendObj({editShapeShow:true,shapeLayerIndex:layerIx,shapeIndex:ix});
    }

    updateShapeEditing(bool){
      this.setState({shapeEditing:bool});
    }
  
    updateShapeText(ix,layerix,val){
      const updateGroups = this.props.shapeLegend;
      if(ix===null){
        updateGroups[layerix].name = val;
        //const layerId = updateGroups[layerix].layerId;
        //const shapeLayerIx = updateGroups.findIndex(s=>s.layerId=layerId);
        //updateGroups[shapeLayerIx].name = val;
        this.props.updateMapObj({shapeLegend: updateGroups}); //,shapeLayers: shapeLegend});
  
      }
      else {
        updateGroups[layerix].styles[ix].name = val;
        this.props.updateMapObj({shapeLegend: updateGroups});
      }
    }
    
    addShapeLayer(){
      const newShapeId = GenerateRandomId();
      const currentShapeLegend = this.props.shapeLegend;
      const newShapeName = "Shape Layer " + (currentShapeLegend.length + 1);
      currentShapeLegend.push({
        layerId: newShapeId,
        name: newShapeName,
        styles: [],
        displayName: true
      });
  
      this.props.updateMapObj({shapeLegend: currentShapeLegend,currentShapeLayer:newShapeId});
    }

    toggleShapeLegend() {
      const currentState = this.props.showShapeLegend;
      this.props.updateMapObj({showShapeLegend: !currentState});
      if(!currentState === true){
        this.setShapeLegend();
      }
    }

    setShapeLegend(){
      const legendClone = Array.from(this.props.legend);
      legendClone[0].visible = true;
      this.props.updateMapObj({legend: legendClone});
    }

    getStyleIndex(ix2,ix3) {
      let ret = 0;
      for (let i=0; (i+1)<ix2; i++){
        ret += this.props.shapeLegend[i].styles.length;
      }
      if(ix3!==null)
        ret += ix3 + 1
  
      return ret;
    }
  
    getIndex(ix2,ix3) {
      let previous = 0;
      for (let i=0; (i+1)<ix2; i++){
        previous += this.props.shapeLegend[i].styles.length;
      }
  
      const ret = ix3 - previous - 1;
  
      return ret;
    }

    onShapeDragEnd = result => {
      if(this.props.shapeEditing===true){
        return
      }
      else {
        const { destination, source } = result;
        const destLayerIx = parseInt(destination.droppableId.replace("droppable_shape",""));
        const sourceLayerIx = parseInt(source.droppableId.replace("droppable_shape",""));
        const sourceIndex = this.getIndex(sourceLayerIx,source.index);
        const destIndex = this.getIndex(destLayerIx,destination.index);
  
        let newLegend = this.props.shapeLegend || null;
  
        if(sourceIndex===-1 || destIndex===-1){
          //console.log('layer move');
          let element = newLegend[sourceLayerIx];
          newLegend.splice(sourceLayerIx,1);
          newLegend.splice(destLayerIx,0,element);
        }
        else if(sourceIndex===-1 || destIndex===-1){
          console.log('do nothing');
        }
        else if(destLayerIx===sourceLayerIx){
          //console.log('same layer');
          let newStyles = this.props.shapeLegend[destLayerIx].styles;
          let element = newStyles[sourceIndex];
          newStyles.splice(sourceIndex,1);
          newStyles.splice(destIndex,0,element);
          newLegend[destLayerIx].styles = newStyles;
        }
        else {
          //console.log('moved to new layer');

          let destStyles = this.props.shapeLegend[destLayerIx].styles;
          let sourceStyles = this.props.shapeLegend[sourceLayerIx].styles;
          let element = sourceStyles[sourceIndex];

          const sourceLayerId = this.props.shapeLegend[sourceLayerIx].layerId;
          const destLayerId = this.props.shapeLegend[destLayerIx].layerId;

          // no need to update mapObject???
          this.props.shapes.map(s=>{
            if(s.type===element.type && s.fill===element.fill && s.stroke === element.stroke && s.opacity === element.opacity && s.layerId === sourceLayerId){
              s.layerId = destLayerId; //newLegend[destLayerIx].layerId;
            }
            return s;
          });

          //check to see if style exists already
          const found = destStyles.find(s=>s.type===element.type && s.fill===element.fill && s.stroke === element.stroke && s.opacity === element.opacity);

          if(found){
            console.log('found!!!');
            sourceStyles.splice(sourceIndex,1);
          }
          else {
            element.layerId =  this.props.shapeLegend[destLayerIx].layerId;
            destStyles.splice(destIndex,0,element);
            sourceStyles.splice(sourceIndex,1);
    
            newLegend[destLayerIx].styles = destStyles;
            newLegend[sourceLayerIx].styles = sourceStyles;
          }


        }
        this.props.updateMapObj({shapeLegend: newLegend});
      }
    }

    toggleNameVisibility(layerix){
      const updateGroups = this.props.shapeLegend;
      const layer = updateGroups[layerix];
      layer.displayName = !layer.displayName;
      updateGroups[layerix] = layer;
      this.props.updateMapObj({shapeLegend: updateGroups});
    }
    
    render(){
      let _shapeLegend = this.props.shapeLegend;
      let shapeLegend = _shapeLegend.filter(s=>s.styles!=null);

        return(
            <div className="legendListDiv">
            <div className="groupNameDiv">
              <div>Shape Legend</div>
              <button className="geocodeBtnSmall margin-l5" onClick={this.addShapeLayer}>{"Add Shape Layer"}</button>
              <div style={{ marginLeft: 'auto' }}>
              <Tooltip title="Toggle Legend Visibility" placement="top">
                  <div onClick={this.toggleShapeLegend.bind(this)}>
                    {this.props.showShapeLegend ?
                    <VisibilityIcon  style={{ fontSize: 20, cursor: 'pointer' }}/> 
                    :
                    <VisibilityOffIcon  style={{ fontSize: 20, cursor: 'pointer' }}/> 
                    }
                  </div>
                </Tooltip>
              </div>
            </div>
            <div>
            <DragDropContext onDragEnd={this.onShapeDragEnd}>  
            {shapeLegend.map((sl,ix2)=>
            <div key={"shapeLegend_" + ix2}>
              <Droppable droppableId={"droppable_shape" + ix2} >  
              {(provided, snapshot) => (
              <div  
                  {...provided.droppableProps}  
                  ref={provided.innerRef}  
              > 
                <div key={"groupLegend_" + ix2}>
                <Draggable key={ix2} draggableId={"Group" + ix2} index={this.getStyleIndex(ix2,null)}>
                {(provided, snapshot) => (  
                  <ShapeLayerComponent
                  provided={provided}
                  snapshot={snapshot}
                  key={"shapename_comp_" + ix2}
                  name={sl.name}
                  layerIndex={ix2}
                  visible={sl.displayName}
                  updateShapeText={this.updateShapeText}
                  updateShapeEditing={this.updateShapeEditing}
                  toggleNameVisibility={this.toggleNameVisibility }
                  deleteShapeLayer={this.deleteShapeLayer}
                  />
                )}
                </Draggable>
                </div>

              {sl.styles.map((pg, ix3) =>
                <div key={"shapeLegend_" + ix2 + "_" + ix3}>
                <Draggable key={ix2} draggableId={"draggable_" + ix2 + "_" + ix3} index={this.getStyleIndex(ix2,ix3)}>  
                  {(provided, snapshot) => (  
                    <ShapeComponent
                    provided={provided}
                    snapshot={snapshot}
                    key={"shape_comp_" + ix3}
                    shapeGroup={pg}
                    layerIndex={ix2}
                    index={ix3}
                    updateShapeText={this.updateShapeText}
                    updateShapeEditing={this.updateShapeEditing}
                    editShapeStyle={this.editShapeStyle}
                    deleteShapeStyle={this.deleteShapeStyle}
                    />
                  )}
                </Draggable>
                </div>
              )}
              {provided.placeholder}
             </div>
              )}
            </Droppable>
            </div>
            )}
            </DragDropContext>  
            </div>
          </div>
        )
    }
}

export default ShapeLegendPanel;