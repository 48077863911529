import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import ColorPickerMenu from '../SideBar/ColorPicker/ColorPickerMenu';
import TextField from '@material-ui/core/TextField';

class CustomPolygonStyle extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultLayerStyle: true
        }
    }
    setFillColor = (e)=>{
        this.props.changeFill('fill',e);
    }
    setStrokeColor = (e) => {
        this.props.changeFill('stroke',e);
    }
    render() {
        return (
          <div className="panel-body">
            <div>
              {!this.props.defaultLayerStyle ?
              <div>    
                <div className="add-ring-row-div">
                  <div className="add-ring-row">
                    <div>
                      <span className="textUnderline">Polygon</span><span className="paddingL15">fill</span>
                    </div>
                    <div style={{ paddingLeft: '5px' }}>
                      <ColorPickerMenu
                        squareColor={this.props.currentFill.fill}
                        setFillColor={this.setFillColor}
                        addTransparent={true}
                      />
                    </div>
                  </div>
                  <div className="add-ring-row paddingL15">
                    <div style={{ width: '80px', paddingLeft: '5px' }}>
                      <TextField
                        label={"Opacity"}
                        id={"ringOpacity" + this.props.idx}
                        value={this.props.currentFill.opacity}
                        variant="outlined"
                        size="small"
                        type="number"
                        InputProps={{ inputProps: { min: 0, max: 1, step: 0.1 } }}
                        onChange={(e) => { this.props.changeFill('opacity',e.target.value) }}
                      />
                    </div>
                  </div>
                </div>
                <div className="add-ring-row-div padding-t10">
                  <div className="add-ring-row paddingL50">
                    <div>outline</div>
                    <div style={{ paddingLeft: '5px' }}>
                      <ColorPickerMenu
                        squareColor={this.props.currentFill.stroke}
                        setFillColor={this.setStrokeColor}
                        addTransparent={true}
                      />
                    </div>
                  </div>
                  <div className="add-ring-row paddingL15">
                    <div style={{ width: '80px', paddingLeft: '5px' }}>
                      <TextField
                        label={"Opacity"}
                        id={"strokeOpacity" + this.props.idx}
                        value={this.props.currentFill.strokeOpacity}
                        variant="outlined"
                        size="small"
                        type="number"
                        InputProps={{ inputProps: { min: 0, max: 1, step: 0.1 } }}
                        onChange={(e) => { this.props.changeFill('strokeOpacity',e.target.value) }}
                      />
                    </div>
                  </div>
                  <div className="add-ring-row paddingL15">
                    <div style={{ width: '80px', paddingLeft: '5px' }}>
                      <TextField
                        label={"Width"}
                        id={"strokeOpacity" + this.props.idx}
                        value={this.props.currentFill.width}
                        variant="outlined"
                        size="small"
                        type="number"
                        InputProps={{ inputProps: { min: 1, max: 5, step: 1 } }}
                        onChange={(e) => { this.props.changeFill('width',e.target.value) }}
                      />
                    </div>
                  </div>
                </div>
              </div>
             : null }
            </div>
          </div>
        );
    }
}

export default CustomPolygonStyle