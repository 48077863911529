import React from 'react';
import LegendPrintView from '../SideBar/LegendPanel/LegendPrintView';
import SVGIconComponent from '../SVGIconComponent/SVGIconComponent';
import TypeAddress, { FindAddressOrPlace } from '../SideBar/AddPtsPanel/TypeAddress/TypeAddress';
///note: 8.5 x 11 in pixels (96 dpi) is 816 x 1056
/// landscape dark blue map area: 680 x 997 or 510 x 748
/// portrait dark blue map area: 770 x 906 or 578 x 680

const wrapper = {
  width: '100%',
  height: '100%',
  pointerEvents: 'none',
}

class MaskComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mapHeight: this.props.pixelBounds[1],
      mapWidth: this.props.pixelBounds[0],
      maskWidth: 0,
      maskHeight: 0,
      offsetX: 0,
      offsetY: 0,
      rectWidth: 0,
      rectHeight: 0,
      scale: 1,
      prevMapLoad: this.props.mapLoaded,
      prevCustomWidth: this.props.mapObj.customLayoutDimensions[0],
      prevCustomHeight: this.props.mapObj.customLayoutDimensions[1]
    }

    this.getMaskDimensions = this.getMaskDimensions.bind(this);
  }

  componentDidMount() {
    const maskDimensionsObj = this.getMaskDimensions();
    this.setState({
      maskWidth: maskDimensionsObj.width,
      maskHeight: maskDimensionsObj.height,
      offsetX: maskDimensionsObj.offsetX,
      offsetY: maskDimensionsObj.offsetY,
      rectWidth: maskDimensionsObj.rectWidth,
      rectHeight: maskDimensionsObj.rectHeight,
      scale: maskDimensionsObj.maskScale
    });
  }

  componentDidUpdate() {
    // console.log(this.state,'cdu mask comp');
    // console.log(this.props.mapObj.maskBounds,'cdu mask comp maskBounds');
    if (this.state.mapHeight !== this.props.pixelBounds[1] || this.state.mapWidth !== this.props.pixelBounds[0] || this.state.prevMapLoad !== this.props.mapLoaded) {
      this.setState({
        mapHeight: this.props.pixelBounds[1],
        mapWidth: this.props.pixelBounds[0],
        prevMapLoad: this.props.mapLoaded
      });
      this.getMaskDimensions();
    }

    if (this.state.prevCustomHeight !== this.props.mapObj.customLayoutDimensions[1] || this.state.prevCustomWidth !== this.props.mapObj.customLayoutDimensions[0]) {
      const maskDimensionsObj = this.getMaskDimensions();
      this.setState({
        maskWidth: maskDimensionsObj.width,
        maskHeight: maskDimensionsObj.height,
        offsetX: maskDimensionsObj.offsetX,
        offsetY: maskDimensionsObj.offsetY,
        rectWidth: maskDimensionsObj.rectWidth,
        rectHeight: maskDimensionsObj.rectHeight,
        scale: maskDimensionsObj.maskScale,
        prevCustomHeight: this.props.mapObj.customLayoutDimensions[1],
        prevCustomWidth: this.props.mapObj.customLayoutDimensions[0]
      });
    }
    if(!this.props.mapObj.maskBounds){
      console.log('no mask bounds');
      this.getMaskDimensions();
    }

  }

  getMaskDimensions() {
    let maskDimensions = {};
    switch (this.props.layout) {
      case 'none':
        this.props.updateMapObj({ maskBounds: [0, 0] });
        return 0;
      case 'landscape':
        maskDimensions = this._getMaskDimensions(997, 680, 'landscape');
        return maskDimensions;
      case 'portrait':
        maskDimensions = this._getMaskDimensions(770, 906, 'portrait');
        return maskDimensions;
      case 'landscape_tabloid':
        maskDimensions = this._getMaskDimensions(1578, 901, 'landscape');
        return maskDimensions;
      case 'portrait_tabloid':
        maskDimensions = this._getMaskDimensions(1008, 1493, 'portrait');
        return maskDimensions;
      case 'custom':
        const customLayoutWidth = this.props.mapObj.customLayoutDimensions[0];
        const customLayoutHeight = this.props.mapObj.customLayoutDimensions[1];
        maskDimensions = this._getMaskDimensions(customLayoutWidth * 96, customLayoutHeight * 96, 'custom');
        return maskDimensions;
      default:
        return 800;
    }
  }

  _getMaskDimensions(width, height, type) {
    const widthDifference = width - (this.state.mapWidth - 20);
    const heightDifference = height - this.state.mapHeight;
    let calcWidth = width;
    let calcHeight = height;
    let calcOffsetX = 4;
    let calcOffsetY = 4;
    let calcRectWidth = width - 8;
    let calcRectHeight = height - 8;

    //if width and height difference are negative, use original dimensions of mask
    if (widthDifference < 0 && heightDifference < 0) {
      calcOffsetX = Math.round(calcOffsetX + (Math.abs(widthDifference / 2)));
      calcOffsetY = Math.round(calcOffsetY + (Math.abs(heightDifference / 2)));
    } else { //if either is positive
      if (widthDifference > heightDifference) { //fit to width
        const widthRatio = width / (this.state.mapWidth - 50);
        calcWidth = this.state.mapWidth - 50;

        if (heightDifference > 0) {
          calcHeight = height / widthRatio;
        } else {
          calcHeight = height / widthRatio;
        }

        if (calcHeight > this.state.mapHeight) {
          calcHeight = this.state.mapHeight;
        }

        calcRectWidth = calcWidth - 8;
        calcRectHeight = calcHeight - 8;
        calcOffsetX = Math.round(calcOffsetX + (Math.abs((calcWidth - (this.state.mapWidth)) / 2)));
        calcOffsetY = Math.round(calcOffsetY + (Math.abs((calcHeight - this.state.mapHeight) / 2)));
      } else if (heightDifference > widthDifference) { //fit to height
        const heightRatio = height / this.state.mapHeight;
        calcHeight = this.state.mapHeight;

        if (widthDifference > 0) {
          calcWidth = width / heightRatio;
        } else {
          calcWidth = width / heightRatio;
        }

        calcRectWidth = calcWidth - 8;
        calcRectHeight = calcHeight - 8;
        calcOffsetX = Math.round(calcOffsetX + (Math.abs((calcWidth - (this.state.mapWidth)) / 2)));
        calcOffsetY = Math.round(calcOffsetY + (Math.abs((calcHeight - this.state.mapHeight) / 2)));
      } else {
        //what to do if square //fit height for now
        const heightRatio = height / this.state.mapHeight;
        calcHeight = this.state.mapHeight;

        if (widthDifference > 0) {
          calcWidth = width / heightRatio;
        } else {
          calcWidth = width / heightRatio;
        }

        calcRectWidth = calcWidth - 8;
        calcRectHeight = calcHeight - 8;
        calcOffsetX = Math.round(calcOffsetX + (Math.abs((calcWidth - (this.state.mapWidth)) / 2)));
        calcOffsetY = Math.round(calcOffsetY + (Math.abs((calcHeight - this.state.mapHeight) / 2)));
      }
    }

    const maskScale = this._getMaskScale(width, calcRectWidth);

    const dimensionObj = {
      width: calcWidth,
      height: calcHeight,
      offsetX: calcOffsetX,
      offsetY: calcOffsetY,
      rectWidth: calcRectWidth,
      rectHeight: calcRectHeight,
      maskScale: maskScale
    };
    this.props.updateMapObj({ maskBounds: [calcWidth, calcHeight], maskScale: maskScale });
    return dimensionObj;
  }

  _getMaskScale(width, rectWidth) {
    const legendScaleFactor = this.props.legend[0] ? this.props.legend[0].scale : 1;
    let scale = 1;
    if (width > rectWidth) {
      scale = rectWidth / width;
    }
    return scale / 1.5 / legendScaleFactor;
  }

  render() {
    const legendScaleFactor = this.props.legend[0] ? this.props.legend[0].scale : 1;
    //const scalePath= "M 1 1 V 20 H " + this.props.scaleWidth + " V 1 H " + (this.props.scaleWidth - 5) +" V 15 H 6 V 1 Z";
    const scalePath= "M 1 1 V 20 H " + (this.props.scaleWidth + 3) + " V 1 H " + (this.props.scaleWidth - 1) +" V 16 H 5 V 1 Z";
    //const scaleSVGY = this.state.offsetY + this.state.rectHeight-30;
    const scaleSVGY = this.props.scaleLocation?.startsWith('top') ? this.state.offsetY + 10 : this.state.offsetY + this.state.rectHeight-30;
    const scaleSVGX = this.props.scaleLocation?.endsWith('left') ? this.state.offsetX + 5 : this.state.offsetX + this.state.rectWidth - this.props.scaleWidth - 11;

    //console.log(this.props.legend[0].northArrowPosition[1]);
    
    const northArrowSVGY = this.props.legend[0] !== undefined ? (this.props.legend[0].northArrowPosition[1] ==='top' ? this.state.offsetY + 10 : this.state.offsetY + this.state.rectHeight - (50 * this.state.scale)) : 0;
    const northArrowSVGX = this.props.legend[0] !== undefined ? (this.props.legend[0].northArrowPosition[0] ==='left' ? this.state.offsetX + 8 : this.state.offsetX + this.state.rectWidth- (50 * this.state.scale)) : 0;
    const naTransText = 'scale(' + (this.state.scale) + ')';

    // if(this.props.legend.length>0){
    //   console.log(this.props.legend[0].position[1],'this.props.legend[0].position[1]');
    //   console.log(this.props.legend[0].position[0],'this.props.legend[0].position[0]');
    // }


    // console.log(this.state.offsetY,'this.state.offsetY');
    // console.log(this.state.offsetX,'this.state.offsetX');
    // console.log(this.state.scale,'this.state.scale');

    // console.log(this.state.scale < (.66/legendScaleFactor) ? '0px' : '20px',"'this.state.scale < (.66/legendScaleFactor) ? '0px' : '20px'");
    return (
      <div style={wrapper}>
        <div style={{ position: 'absolute', zIndex: 1000, left: '10px' }}><FindAddressOrPlace {...this.props} refs={this.refs} /> </div>
        {this.props.legend[0] && this.props.legend[0].visible ?
          <div style={{ position: 'absolute', [this.props.legend[0].position[1]]: this.state.offsetY + 'px', [this.props.legend[0].position[0]]: (this.state.offsetX + 10) + 'px', transform: 'scale(' + this.state.scale + ')', transformOrigin: [this.props.legend[0].position[1]] + ' ' + [this.props.legend[0].position[0]], marginRight: this.state.scale < (.66/legendScaleFactor) ? '0px' : '20px' }}>
            <LegendPrintView
              groups={this.props.mapObj.mapGroups}
              points={this.props.mapObj.mapPoints}
              rings={this.props.mapObj.rings}
              driveTimes={this.props.mapObj.driveTimes}
              isSaving={this.props.mapObj.isSaving}
              legend={this.props.legend}
              updateMapObj={this.props.updateMapObj}
              demographicLegend={this.props.mapObj.demographicLegend}
              demographicColors={this.props.mapObj.vtDemographicSettings.demographicColors}
              demographicFillOpacity={this.props.mapObj.vtDemographicSettings.demographicFillOpacity}
              demographicStrokeOpacity={this.props.mapObj.vtDemographicSettings.demographicStrokeOpacity}
              outlineColor={this.props.mapObj.demographicStroke}
              customLayers={this.props.mapObj.customLayers}
              //demographicLength={this.props.demographicLength}
              hasDemographics={this.props.mapObj.hasDemographics}
              mapZoom={this.props.mapObj.mapZoom}
              gapiPolygonSettings={this.props.mapObj.gapiPolygonSettings}
              earthquakeLayer={this.props.mapObj.earthquakeLayer}
              gapiPointLength={this.props.gapiPointLength}
              gapiPointSettings={this.props.mapObj.gapiPointSettings}
              showShapeLegend={this.props.mapObj.showShapeLegend || false}
              polygonLegend={this.props.mapObj.shapeLegend || null}
              shapes={this.props.mapObj.shapes}
              realGapiPoints={this.props.mapObj.realGapiPoints}
              mapOptionsLayout={this.props.mapObj.mapOptionsLayout}
            />
          </div>
          : null
        }
        <svg viewBox={"0 0 " + this.state.mapWidth + " " + this.state.mapHeight} width={this.state.mapWidth} height={this.state.mapHeight} version="1.1" xmlns="http://www.w3.org/2000/svg">
          <rect x={this.state.offsetX} y={this.state.offsetY} fill="none" stroke="#f68b1f" strokeWidth="8" width={this.state.rectWidth} height={this.state.rectHeight} />
          {this.props.scaleLocation ?
          <svg>
          <svg x={scaleSVGX} y={scaleSVGY}>
          <path  d={scalePath} className="scalePath"/>
          </svg>
          <text x={scaleSVGX + 7} y={scaleSVGY + 13} className="scaleText">{this.props.scaleText}</text>
          </svg>
          : null}
          {this.props.legend[0] && this.props.legend[0].northArrowVisible ?
          <svg x={northArrowSVGX} y={northArrowSVGY} id="northArrowIcon" xmlns="http://www.w3.org/2000/svg" height="40px" >
              <g transform={naTransText}>
                <path d="M158,78c-8.27,0-15,7.13-15,15.9s6.73,15.91,15,15.91,15-7.14,15-15.91S166.31,78,158,78Z" transform="translate(-143.04 -70.18)" fill="#fff"/>
                <polygon points="16.78 12.16 15 9.19 13.22 12.16 9.16 9.72 14.99 0 20.84 9.73 16.78 12.16" fill="#fff"/>
              </g>
              <g transform={naTransText}>
                <path d="M158,108.27c-7.4,0-13.43-6.43-13.43-14.33s6-14.33,13.43-14.33S171.46,86,171.46,93.94,165.44,108.27,158,108.27Zm0-27.08c-6.54,0-11.85,5.72-11.85,12.75S151.5,106.7,158,106.7s11.85-5.72,11.85-12.76S164.57,81.19,158,81.19Z" transform="translate(-143.04 -70.18)" fill="#00438c"/>
                <path d="M161.26,97.83h-2l-3.15-5.49h0c.06,1,.09,1.66.09,2.08v3.41h-1.37V90.58h1.94L159.91,96h0c0-.94-.07-1.61-.07-2V90.58h1.39Z" transform="translate(-143.04 -70.18)" fill="#00438c"/>
                <polygon points="18.13 11.35 15 6.13 11.87 11.35 10.52 10.54 15 3.06 19.48 10.54 18.13 11.35" fill="#00438c"/>
              </g>
            </svg>
            : null}
          </svg>
      </div>
    );
  }
}
//this.state.offsetY + this.state.rectHeight-17
//this.state.offsetX + this.state.rectWidth - this.props.scaleWidth - 4
export default MaskComponent;