import React from 'react';
import MainPage from './MainPage/MainPage';
import Support from './Support/Support';
import RouteError from './RouteError/RouteError';
import { useAuth0, User } from './react-auth0-spa';
import { Route, Switch, useLocation } from 'react-router-dom';
import './App.css';
import './Mobile.css';
import FullStory, { FullStoryAPI as fullStoryAPI } from "react-fullstory";
import queryString from 'query-string';
import { RegisterUser } from '@colliers-international/usage-tracker';

function App() {
  const { isAuthenticated, loginWithRedirect, loading, getTokenSilently, user } = useAuth0();

  const { search } = useLocation();
  const values = queryString.parse(search);
  const uploadPts = values.uploadpoints || null;
  const forceCountry = values.makeme || null;
  if (uploadPts!==null){
    window.sessionStorage.setItem("upload_points", uploadPts);
  }
  if (forceCountry!==null){
    window.sessionStorage.setItem("force_country", forceCountry);
  }
  if (loading) {
    return <div className="loader"></div>;
  }
  if (isAuthenticated) {
    const authUser = {
      userId: user.sub,
      displayName: user.name,
      email: user.email.toLowerCase(),
      userVars: { ...user }
    };
    return (
      <RegisterUser
      enableTracking={true}
      matomoSiteId={7}
      fullstoryOrgId={'E3EXV'}
      user={authUser}
      >
        <Switch>
          <Route exact path="/" render={(props) => <MainPage {...props} loginWithRedirect={loginWithRedirect} uploadPoints={uploadPts} getTokenSilently={getTokenSilently} user={user} />} />
          <Route path="/support" component={Support} />
          <Route component={RouteError} />
        </Switch>
      </RegisterUser>
    );
  } else {
    let redirectOptions = {};
    if ((window.location.pathname).includes('support')) {
      redirectOptions.redirect_uri = window.location.origin + window.location.pathname;
    }
    loginWithRedirect(redirectOptions);
    return (
      <div className="loader"></div>
    )
  }
}

export default App;
