import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import SVGIconComponent from '../../SVGIconComponent/SVGIconComponent';
import axios from 'axios';
import Select from '@material-ui/core/Select';
import ThematicColorPickerMenu from '../ColorPicker/ThematicColorPickerMenu';
import ColorPickerMenu from '../ColorPicker/ColorPickerMenu';
import { TextField } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import gs from 'geostats';
import Checkbox from '@material-ui/core/Checkbox';
import { update } from 'lodash';

class DemographicsFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            gettingDemographics: false,
            demoFieldList : [
                {id:1, column:"population_density", legendTitle: "CY Population Density", label:"Population Density", defaultRanges:'100,1000,2500,5000', lowDefaultRanges:'100,500,1000,2000', highDefaultRanges:'1000,2500,5000,10000', linetwo: 'Persons per Sq. Mile'},
                {id:2, column:"median_household_income", prefix: '$', legendTitle: "CY Median Household Income" , label:"Median Household Income",lowDefaultRanges:'20000,40000,60000,80000', defaultRanges:'25000,50000,75000,100000',highDefaultRanges:'75000,100000,125000,150000'},
                {id:3, column:"daytime_population_density", legendTitle: "CY Daytime Population Density" ,label:"Daytime Population Density",defaultRanges:'100,1000,2500,5000',lowDefaultRanges:'50,500,2000,4000', highDefaultRanges:'1000,2500,5000,10000', linetwo: 'Persons per Sq. Mile'},
                {id:4, column:"median_age", decimals: 1, legendTitle: "CY Median Age" , label:"Median Age",defaultRanges:'25,30,35,40',highDefaultRanges:'30,35,40,45',lowDefaultRanges:'20,25,30,35'},
                {id:5, column:"median_home_value", prefix: '$', legendTitle: "CY Median Home Value" , label:"Median Home Value", defaultRanges:"200000,300000,400000,500000",lowDefaultRanges:"150000,250000,350000,450000", highDefaultRanges:"250000,500000,750000,1000000"},
                {id:6, column:"projected_growth_rate_population", suffix: '%', decimals: 1, legendTitle: "CY-FY Projected Population Growth Rate" , label: "Projected Population Growth",defaultRanges:"-2.0,-1.0,1.0,2.0",lowDefaultRanges:'-1.0,0.5,0.5,1.0',highDefaultRanges:"-4.0,-2.0,2.0,4.0", linetwo: 'Annual Growth'},
                {id:7, column:"historic_growth_rate_population", suffix: '%', decimals: 1,  legendTitle: "BY-CY Population Growth Rate" , label: "Historical Population Growth",defaultRanges:"-2.0,-1.0,1.0,2.0",lowDefaultRanges:'-1.0,0.5,0.5,1.0',highDefaultRanges:"-4.0,-2.0,2.0,4.0", linetwo: 'Annual Growth'}
            ],
            geographyList : [
                {id:1, value:"states", label:"State"},
                {id:2, value:"counties", label:"County"},
                {id:3, value:"zip_codes", label:"Zip Codes"},
                {id:4, value:"census_tracts", label:"Census Tracts"},
                {id:5, value:"blockgroups", label:"Block Groups"}
                //,{id:5, value:"places", label:"Places"}
            ],
            orgRangeTypeList: [
                {id:0, value: "default", label:"Default"},
                {id:1, value: "equalcount", label:"Equal Count"},
                {id:2, value: "equalrange", label:"Equal Range"},
                {id:3, value: "curve", label:"Curve"},
                {id:4, value: "naturalbreak", label: "Natural Break"},
            ],
            rangeTypeList: [
                {id:0, value: "default", label:"Default"},
                {id: 5,value: 'highdefault', label: 'High Default'},
                {id: 6,value: 'lowdefault', label: 'Low Default'},
                {id:1, value: "equalcount", label:"Equal Count"},
                {id:2, value: "equalrange", label:"Equal Range"},
                {id:3, value: "curve", label:"Curve"},
                {id:4, value: "naturalbreak", label: "Natural Break"}
            ],
            allTypeList: [
                {id:0, value: "default", label:"Default"},
                {id: 5,value: 'highdefault', label: 'High Default'},
                {id: 6,value: 'lowdefault', label: 'Low Default'},
                {id:1, value: "equalcount", label:"Equal Count"},
                {id:2, value: "equalrange", label:"Equal Range"},
                {id:3, value: "curve", label:"Curve"},
                {id:4, value: "naturalbreak", label: "Natural Break"}
            ],
            onlyHighRangeTypeList: [
                {id:0, value: "default", label:"Default"},
                {id: 5,value: 'highdefault', label: 'High Default'},
                {id:1, value: "equalcount", label:"Equal Count"},
                {id:2, value: "equalrange", label:"Equal Range"},
                {id:3, value: "curve", label:"Curve"},
                {id:4, value: "naturalbreak", label: "Natural Break"}
            ],
            onlyLowRangeTypeList: [
                {id:0, value: "default", label:"Default"},
                {id: 6,value: 'lowdefault', label: 'Low Default'},
                {id:1, value: "equalcount", label:"Equal Count"},
                {id:2, value: "equalrange", label:"Equal Range"},
                {id:3, value: "curve", label:"Curve"},
                {id:4, value: "naturalbreak", label: "Natural Break"}
            ],
            selectedFieldID : 1,
            selectedGeoValue: "census_tracts",
            selectedRangeValue: "default",
            demographicsLoading: false,
            isError: false,
            //thematicColors: 'ed1b34,f68b1f,ffd400,c59fc5,85bcb8',
            prevMapBounds: this.props.mapObj.mapBounds,
            showHelp: false,
            anchorEl: null,
            seletedDecimals: 0,
            selectedPrefix: null,
            selectedSuffix: null,
            roundRanges: false,
            closeWhenDone: false
        }
        this.closePanel = this.closePanel.bind(this);
        this.getDemographics = this.getDemographics.bind(this);
        this.selectDemo = this.selectDemo.bind(this);
        this.selectGeo = this.selectGeo.bind(this);
        this.selectRange = this.selectRange.bind(this);
        this.selectThematic = this.selectThematic.bind(this);
        this.selectOutline = this.selectOutline.bind(this);
        this.backToAddData = this.backToAddData.bind(this);
        this.clearDemographics = this.clearDemographics.bind(this);
        this.roundRanges = this.roundRanges.bind(this);
        this._setRanges = this._setRanges.bind(this);
        this.toggleRoundRanges = this.toggleRoundRanges.bind(this);
    }

    componentDidMount() {
        this.props.updateMapObj({demoPanelOpen: true, updateMapBounds: true});

        const selDemoID = this.state.demoFieldList.find((el)=>{
            if(el.column == this.props.mapObj.demographicVariable)
                return el;
        });
        let _fid = 1;
        if(selDemoID!==undefined)
            _fid = selDemoID.id

        const existingVar = this.state.demoFieldList.find((el)=>el.column===this.props.mapObj.vtDemographicSettings.demographicVariable);

        if(existingVar){
            const ssuf = existingVar.suffix || null;
            const spre = existingVar.prefix || null;
            const sdec = existingVar.decimals || 0;
            this.setState({
                selectedSuffix: ssuf,
                selectedPrefix: spre,
                seletedDecimals: sdec
            });
        }

        this.setState({
            selectedGeoValue: this.props.mapObj.vtDemographicSettings.demographicGeography,
            selectedRangeValue: this.props.mapObj.vtDemographicSettings.demographicRangeSetting,
            selectedFieldID: _fid,
            selOutline: this.props.mapObj.vtDemographicSettings.demographicStroke,
            roundRanges: this.props.mapObj.vtDemographicSettings.demogrpahicRangeRound
        });
        let demoVTSettings = this.props.mapObj.vtDemographicSettings;

        if (this.props.country == 'CA') {
            this.setState({
                geographyList : [            
                    {id:1, value:"dissemination_areas", label:"Dissemination Areas"},
                    {id:2, value:"census_tracts", label:"Census Tracts"}
                ],
                demoFieldList: [
                {id:1, column:"population_density", legendTitle: "CY Population Density", label:"Population Density", defaultRanges:'100,1000,2500,5000', lowDefaultRanges:'100,500,1000,2000', highDefaultRanges:'1000,2500,5000,10000', linetwo: 'Persons per Sq. KM'},
                {id:2, column:"projected_population_density", legendTitle: "FY Projected Population Density", label:"Projected Population Density", defaultRanges:'100,1000,2500,5000', lowDefaultRanges:'100,500,1000,2000', highDefaultRanges:'1000,2500,5000,10000', linetwo: 'Persons per Sq. KM'},
                {id:3, column:"daytime_population_density", label: "Daytime Population", defaultRanges:'100,1000,2500,5000', lowDefaultRanges:'100,500,1000,2000', highDefaultRanges:'1000,2500,5000,10000', linetwo: 'Persons per Sq. KM'},
                {id:4, column:"median_household_income", prefix: "$", label:"Median Household Income", lowDefaultRanges:'20000,40000,60000,80000', defaultRanges:'25000,50000,75000,100000',highDefaultRanges:'75000,100000,125000,150000'},
                {id:5, column:"average_household_income",  prefix: "$", label:"Average Household Income", lowDefaultRanges:'20000,40000,60000,80000', defaultRanges:'25000,50000,75000,100000',highDefaultRanges:'75000,100000,125000,150000'}
            ],
            selectedGeoValue: "census_tracts"
            });
        }
        if (this.props.country == 'AUS') {
            this.setState({
                geographyList : [     
                    {id:1, value:"statistical_area_level_1", label:"Statistical Areas Level 1"},       
                    {id:2, value:"statistical_area_level_2", label:"Statistical Areas Level 2"}
                ],
                demoFieldList: [
                {id:1, column:"cy_population_density", legendTitle: "CY Population Density", label:"Population Density", defaultRanges:'1000,2500,5000,7500', lowDefaultRanges:'100,500,1000,2000', highDefaultRanges:'1000,2500,5000,10000',linetwo: 'Persons per Sq. KM'},
                {id:2, column:"pct_age_15_64",  suffix: '%', decimals: 1, label:"Percent Age 15-64", defaultRanges:'55,65,75,85',lowDefaultRanges:'45,55,65,75', highDefaultRanges:'65,75,85,95'},
                {id:3, column:"pct_age_65_and_over",  suffix: '%', decimals: 1, label:"Percent Age 65+", defaultRanges:'5,10,15,25', lowDefaultRanges:'2,5,10,15', highDefaultRanges:'10,15,25,35'},
                {id:4, column:"pct_australian_citizen",  suffix: '%', decimals: 1, label:"Percent Australian Citizens", defaultRanges:'40,50,60,70',lowDefaultRanges:'30,40,50,60',highDefaultRanges:'50,60,70,80'},
                {id:5, column:"average_household_size", label:"Average Household Size", defaultRanges:'1.75,2.25,2.75,3.25',lowDefaultRanges:'1.25,1.75,2.25,2.75',highDefaultRanges:'2.25,2.75,3.25,3.75'},
                {id:6, column:"median_age", label:"Median Age", defaultRanges:'32,37,42,47',lowDefaultRanges:'27,32,37,42',highDefaultRanges:'37,42,47,52'},
                {id:7, column:"median_monthly_mortgage_payment", prefix: "$", label:"Median Monthly Mortgage Payment", defaultRanges:'1500,2000,2500,3500',lowDefaultRanges:'1000,1500,2000,2500',highDefaultRanges:'1500,2000,2500,3000'},
                {id:8, column:"median_weekly_rent", prefix: "$", label:"Median Weekly Rent", defaultRanges:'250,400,550,700',lowDefaultRanges:'200,350,500,650',highDefaultRanges:'300,450,600,750'},
                {id:9, column:"pct_family_households",  suffix: '%', decimals: 1, label:"Percent Family Households", defaultRanges:'35,50,65,80', lowDefaultRanges:'20,35,50,65',highDefaultRanges:'50,65,70,85'},
                {id:10, column:"median_household_income", prefix: "$", label:"Median Household Income", defaultRanges:'75000,100000,150000,200000', lowDefaultRanges:'50000,75000,100000,150000',highDefaultRanges:'100000,150000,200000,250000'},
            ],
            selectedGeoValue: "statistical_area_level_2"
            });
            demoVTSettings.demographicGeography="statistical_area_level_2";
            demoVTSettings.demographicVariable="cy_population_density";
            
        }
        const ev2 = this.state.demoFieldList.find((el)=>el.id===this.state.selectedFieldID);

        if(demoVTSettings.demographicVariable===null){
            demoVTSettings.demographicVariable = ev2.column;
        }

        if(demoVTSettings.demographicRanges===null){
            demoVTSettings.demographicRanges=ev2.defaultRanges;
        }

        if(demoVTSettings.demogrpahicTitle==null){
            const demoTitle = this._getTitle(ev2.legendTitle || ev2.label);
            demoVTSettings.demogrpahicTitle=demoTitle;
        }

        if(demoVTSettings.demographicLineTwo==null){
            demoVTSettings.demographicLineTwo=ev2.linetwo || '';
        }

        if(demoVTSettings.demographicPrefix==null){
            demoVTSettings.demographicPrefix=ev2.prefix || ''
        }

        if(demoVTSettings.demographicSuffix==null){
            demoVTSettings.demographicPrefix=ev2.suffix || ''
        }

        if(demoVTSettings.demographicDecimals==null){
            demoVTSettings.demographicDecimals=ev2.decimals || 0;
        }

        this.props.updateMapObj({vtDemographicSettings:demoVTSettings});
    }
    
    componentDidUpdate() {
        if (this.state.prevMapBounds !== this.props.mapObj.mapBounds) {
          this.setState({
            prevMapBounds: this.props.mapObj.mapBounds
          });
       }
    }
    
    componentWillUnmount() {
        this.props.updateMapObj({demoPanelOpen: false,demoPanelRedraw: false});
    }

    closePanel() {
        if(this.props.mapObj.demoPanelRedraw){
            this.setState({closeWhenDone: true})
            this.getDemographics();
        }
        else{
            this.props.updateMapObj({demoPanelOpen: false,demoPanelRedraw: false});
            this.props.togglePanel("addData");
        }
    }

    clearDemographics() {
        //var upObj = {demoPanelRedraw: false, updateBasemap: true, forceDemos:false, clearDemos: true,demographicLegend: null, demographicBounds: null, hasDemographics: false}
        var upObj = {demographics: [], updateBasemap: true, forceDemos:false, clearDemos: true, demographicLegend: null, hasDemographics: false}
        if(this.props.mapObj.mapPoints.length===0){
            const legendClone = Array.from(this.props.mapObj.legend);
            legendClone[0].visible = false;
            upObj.legend = legendClone;
        }
        this.props.updateMapObj(upObj);
    }

    toggleRoundRanges(bool){
        this.setState({roundRanges: bool});
        this._updateSettings('demogrpahicRangeRound',bool);
    }

    async getDemographics() {
        const legendClone = Array.from(this.props.mapObj.legend);
        legendClone[0].visible = true;
        let demoVTSettings = this.props.mapObj.vtDemographicSettings;
        if(this.state.selectedRangeValue.includes("default")){
            const ranges = await this._setRanges(this.state.selectedRangeValue,this.state.roundRanges);
            demoVTSettings.demographicRanges = ranges;
        }
        this.props.updateMapObj({demoPanelRedraw: false, getVTDemographics:true, forceDemos: true,legend:legendClone,vtDemographicSettings:demoVTSettings });
    }

    _setLegendRanges(ranges, dec, prefix, suffix) {
        const dispranges = [
            "Under " + this._formatRange(ranges[0], dec, prefix, suffix),
            this._formatRange(ranges[0], dec, prefix, suffix) + " - " +  this._formatRange(ranges[1], dec, prefix, suffix),
            this._formatRange(ranges[1], dec, prefix, suffix) + " - " +  this._formatRange(ranges[2], dec, prefix, suffix),
            this._formatRange(ranges[2], dec, prefix, suffix) + " - " +  this._formatRange(ranges[3], dec, prefix, suffix),
            "Over " +  this._formatRange(ranges[3], dec, prefix, suffix)
        ]
        return dispranges;
    }

    _formatRange(val,dec, prefix, suffix){
        const _dec = dec || 0;
        let newval = parseFloat(val).toFixed(_dec).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        if(prefix)
            newval = prefix + ' ' + newval;
        if(suffix)
            newval = newval + ' ' + suffix;
        return newval;
    }

    _getTitle(legendTitle) {
        const defaultCY = this.props.country === 'US' ? (process.env.REACT_APP_US_DEMOS_CURRENT_YEAR || 'Current') : 'Current';
        const defaultFY = this.props.country === 'US' ? (process.env.REACT_APP_US_DEMOS_PROJECTED_YEAR || 'Projected') : 'Projected';
        const defaultBY = this.props.country === 'US' ? (process.env.REACT_APP_US_DEMOS_BASE_YEAR || 'Base') : 'Base';

        const CY = this.props.mapObj.demographicYears?.cy || defaultCY
        const FY = this.props.mapObj.demographicYears?.fy || defaultFY;
        const BY = this.props.mapObj.demographicYears?.by || defaultBY;

        return legendTitle.replace("CY", CY).replaceAll("FY", FY).replaceAll("BY", BY);
    }

    selectDemo(event) {
        const val = event.target.value;
        
        this.setState({selectedFieldID: val,
            selectedRangeValue: 'default'
        });

        const selDemo = this.state.demoFieldList.find((el)=>{
            if(el.id ==  event.target.value)
                return el;
        });

        this.setState({seletedDecimals: selDemo.decimals || 0,
            selectedPrefix: selDemo.prefix,
            selectedSuffix: selDemo.suffix
        });
        
        let ranges = selDemo.defaultRanges;

        if (this.state.selectedRangeValue==='highdefault'){ 
            ranges = selDemo.highDefaultRanges;
        }
        if (this.state.selectedRangeValue==='lowdefault'){
            ranges = selDemo.lowDefaultRanges;
        }
            
        console.log(this.state.selectedRangeValue);

        const demoTitle = this._getTitle(selDemo.legendTitle || selDemo.label);
        const demoLineTwo = selDemo.linetwo || '';
        const demoPrefix = selDemo.prefix || '';
        const demoSuffix = selDemo.suffix || '';
        const demoDec = selDemo.decimals || 0;

        let demoVTSettings = this.props.mapObj.vtDemographicSettings;
        demoVTSettings.demographicVariable = selDemo.column;
        demoVTSettings.demographicRanges = ranges;
        demoVTSettings.demogrpahicTitle = demoTitle;
        demoVTSettings.demographicLineTwo = demoLineTwo;
        demoVTSettings.demographicPrefix = demoPrefix;
        demoVTSettings.demographicSuffix = demoSuffix;
        demoVTSettings.demographicDecimals = demoDec;
        demoVTSettings.demographicRangeType =this.state.selectedRangeValue;

        const getDemos = this.props.mapObj.demographics.length > 0 ? true : false;
        this.props.updateMapObj({getVTDemographics: getDemos, vtDemographicSettings:demoVTSettings});
    }

    selectThematic(val) {
        this._updateSettings('demographicColors',val);
    }

    selectOutline(val) {
        this._updateSettings('demographicStroke',val);
    }

    selectRange(event) {
        const val = event.target.value;
        this._selectRange(val,this.state.roundRanges);
    }

    async _selectRange(val,round) {   
        this.setState({selectedRangeValue: val});
        this._updateSettings('demographicRangeSetting',val);
    }
 
    async _setRanges(range,round){
        let ranges = '';
        if(range==='default' || range==='custom'){
            const fieldID = this.state.selectedFieldID;
            const selDemo = this.state.demoFieldList.find((el)=>{
            if(el.id ==  fieldID)
                return el;
            });
            ranges = selDemo.defaultRanges;
        }
        else if(range==='highdefault'){
            const fieldID = this.state.selectedFieldID;
            const selDemo = this.state.demoFieldList.find((el)=>{
            if(el.id ==  fieldID)
                return el;
            });
            ranges = selDemo.highDefaultRanges;
        }
        else if(range==='lowdefault'){
            const fieldID = this.state.selectedFieldID;
            const selDemo = this.state.demoFieldList.find((el)=>{
            if(el.id ==  fieldID)
                return el;
            });
            ranges = selDemo.lowDefaultRanges;
        }
        return ranges;
    }

    roundRanges(ranges,roundIt){
        if(roundIt){
            let roundNum = Math.pow(10,parseInt(ranges[0]).toString().replace('-','').length-1);
            let rounded=[];
            let redo= false;

            for(let i=0;i<4;i++){
                rounded.push(parseInt(ranges[i] / roundNum) * roundNum);
                if(i>0){
                    if(rounded[i]===rounded[i-1])
                        redo = true;
                }
            }
            if(redo){
                redo = false;
                rounded = [];
                roundNum = Math.pow(10,parseInt(ranges[0]).toString().replace('-','').length-2);
                for(let i=0;i<4;i++){
                    rounded.push(parseInt(ranges[i] / roundNum) * roundNum);
                    if(i>0){
                        if(rounded[i]===rounded[i-1])
                            redo = true;
                    }
                }
            }
            if(redo){
                return ranges.toString();
            }
            else{
                const rstr = rounded.toString();
                return rstr;
            }
        }
        else{
            return ranges.toString();
        }
    }

    async selectGeo(event) {
        const val = event.target.value;
        if(val!==this.state.selectedGeoValue){
            this.setState({selectedGeoValue: val,mapBounds: null});
            this.props.updateMapObj({demographics: []});
            await new Promise(resolve => setTimeout(resolve, 500));
            this._updateSettings('demographicGeography',val);
        }
    }
    
    timeout(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async _updateSettings(key,val) {
        const getDemos = this.props.mapObj.demographics.length > 0 ? true : false;
        let demoVTSettings = this.props.mapObj.vtDemographicSettings;
        demoVTSettings[key] = val;
        let updateDemoRanges = false;
        if(key==='demographicGeography'){
            if(this.state.selectedRangeValue.includes("default")){
                const ranges = await this._setRanges(this.state.selectedRangeValue,this.state.roundRanges);
                demoVTSettings.demographicRanges = ranges;
            }
        }
        else if(key==='demographicRangeSetting'){
            if(val.includes("default")){
                const ranges = await this._setRanges(val,this.state.roundRanges);
                demoVTSettings.demographicRanges = ranges;
            }
            else{
                updateDemoRanges = true;
            }
        }
        else if(key==='demogrpahicRangeRound'){
            console.log(demoVTSettings.demographicRangeSetting);
            if(!demoVTSettings.demographicRangeSetting.includes("default")){
                updateDemoRanges = true;
            }
        }
        this.props.updateMapObj({vtDemographicSettings: demoVTSettings, getVTDemographics: getDemos, updateDemoRanges: updateDemoRanges});
    }

    setStrokeWidth(val) {
        this._updateSettings('demographicWidth',parseInt(val));
    }

    setStrokeOpacity(val) {
        this._updateSettings('demographicStrokeOpacity',parseInt(val));
    }
    
    setFillOpacity(val) {
        this._updateSettings('demographicFillOpacity',val);
    }

    backToAddData() {
        this.props.toggleDemographicsFilter(true, false);
    }

    toggleHelpPanel(e) {
        const _help = !this.state.showHelp;
        this.setState({showHelp:_help,
            anchorEl: e.currentTarget,
        });
    }

    render() {
        return (
        <div>
            <h2 className="panel-title">
            <span className="panel-icon-span">
                <SVGIconComponent name={'retailFilterIcon'} color={'#0c9ed9'} size={'16px'} />
            </span>
            Add Demographics
            <span className="panel-close closeStyle"><CloseIcon onClick={this.closePanel} fontSize="small" /></span>
            </h2>
            <div className="panel-body">
            {this.state.showHelp ?
            <Menu
             id="legendOpts-menu"
             anchorEl={this.state.anchorEl}
             keepMounted
             open={this.state.showHelp}
             onClose={this.toggleHelpPanel.bind(this, { currentTarget: null }, false)}
            >
            <div className="updateMenuDiv">
              <div className="updateMenuTitle">Demographics Help</div>
              <div className="updateMenuBody">
              <div className="updateMenuFeatureName">
                  <div className="updateMenuFeatureSVG">
                    <SVGIconComponent name="boomFavIcon" size={12} fill={'#25408f'} />
                  </div>
                  <div className="updateMenuFeatureLabel">Range</div>
                </div>
                <div className="updateMenuFeatureDesc">
                <span className="updateHighlightSpan">Default:</span>&nbsp;&nbsp;Predetermined ranges by GIS staff
                <br/><span className="updateHighlightSpan">Equal Count:</span>&nbsp;&nbsp;Equal number of areas per range
                <br/><span className="updateHighlightSpan">Equal Range:</span>&nbsp;&nbsp;Equal values per range
                <br/><span className="updateHighlightSpan">Curve:</span>&nbsp;&nbsp;More Areas in middle range, fewer areas on low and high ranges (10%-15%-50%-15%-10%)
                <br/><span className="updateHighlightSpan">Natural Break:</span>&nbsp;&nbsp;Using the Jenks natural breaks optimization which seeks to reduce the variance within classes and maximize the variance between classes
                </div>
                <div className="updateMenuFeatureName">
                  <div className="updateMenuFeatureSVG">
                    <SVGIconComponent name="boomFavIcon" size={12} fill={'#25408f'} />
                  </div>
                  <div className="updateMenuFeatureLabel">Geogrpahy</div>
                </div>
                <div className="updateMenuFeatureDesc">
                    The areas that will be returned.  It will return areas slightly larger then the current map view.  You may need to redraw the area if the map area changes. 
                </div>

                <div className="updateMenuFeatureName">
                  <div className="updateMenuFeatureSVG">
                    <SVGIconComponent name="boomFavIcon" size={12} fill={'#25408f'} />
                  </div>
                  <div className="updateMenuFeatureLabel">Variable</div>
                </div>
                <div className="updateMenuFeatureDesc">
                    <br/><span className="updateHighlightSpan">Population Density:</span>&nbsp;&nbsp;The population divided by the square miles of the area
                    <br/><span className="updateHighlightSpan">Median Household Income:</span>
                    <br/><span className="updateHighlightSpan">Daytime Population Density:</span>&nbsp;&nbsp;The number of workers and residents at home divided by the square miles of the area
                    <br/><span className="updateHighlightSpan">Median Age</span>
                    <br/><span className="updateHighlightSpan">Median Home Value</span>
                    <br/><span className="updateHighlightSpan">Historical Population Growth:</span>&nbsp;&nbsp;The annual growth from 2010 to the current year
                    <br/><span className="updateHighlightSpan">Projected Population Growth:</span>&nbsp;&nbsp;The projected annual growth for the next 5 years
                </div>


            </div>
            </div>
            </Menu>
                : null
            }
            {
                this.state.demographicsLoading ?
                <div className="panel-loader"></div>
                :
                <div>
                    <div className="paddingR10"><button className="geocodeBtnSmall" onClick={(e) => this.toggleHelpPanel(e, true)}>Help</button></div>
                    <div className="retailerOuterDiv padding-t10">
                        <div className="retailerLabel">Range: </div>
                        <div style={{ flexGrow: 2 }}>
                        <div>
                            <Select
                            native
                            labelId="geo-field-select"
                            id="demo-field-select"
                            onChange={this.selectRange}
                            value={this.state.selectedRangeValue}
                            variant="outlined"
                            autoWidth={true}
                            >
                            {this.state.rangeTypeList.map((rangeField, idx) =>
                                <option key={'rangeField_' + idx} value={rangeField.value}>{rangeField.label}</option>
                            )}
                            </Select>
                        </div>
                        </div>
                    </div>
                    <div className="retailerOuterDiv padding-t10">
                    Round Ranges:
                        <Checkbox
                            color="default"
                            value={this.state.roundRanges}
                            inputProps={{ 'aria-label': 'use layer style' }}
                            onChange={(event) => this.toggleRoundRanges(event.target.checked)}
                            checked={this.state.roundRanges}
                            //disableRipple
                        />
                    </div>
                     <div className="retailerOuterDiv padding-t10">
                        <div className="retailerLabel">Geography: </div>
                        <div style={{ flexGrow: 2 }}>
                        <div>
                            <Select
                            native
                            labelId="geo-field-select"
                            id="demo-field-select"
                            onChange={this.selectGeo}
                            value={this.state.selectedGeoValue || "census_tracts"}
                            variant="outlined"
                            autoWidth={true}
                            >
                            {this.state.geographyList.map((demoField, idx) =>
                                <option key={'geoField_' + idx} value={demoField.value}>{demoField.label}</option>
                            )}
                            </Select>
                        </div>
                        </div>
                    </div>
                    <div className="retailerOuterDiv padding-t20">
                        <div className="retailerLabel">Variable: </div>
                        <div style={{ flexGrow: 2 }}>
                        <div>
                            <Select
                            native
                            labelId="demo-field-select"
                            id="demo-field-select"
                            onChange={this.selectDemo}
                            value={this.state.selectedFieldID}
                            variant="outlined"
                            autoWidth={true}
                            >
                            {this.state.demoFieldList.map((demoField, idx) =>
                                <option key={'demoField_' + idx} value={demoField.id}>{demoField.label}</option>
                            )}
                            </Select>
                        </div>
                        </div>
                    </div>
                    {this.props.mapObj.demographics.length > 0 ?
                            null :
                            <div className="geocodeDiv flex">
                                <button className="geocodeBtn" onClick={this.getDemographics}>Get Demographics</button>
                            </div>
                    }
                    <div>
                        <div className="retailerOuterDiv padding-t20">
                            <div className="retailerLabel">Colors: </div>
                            <div style={{ flexGrow: 2 }}>
                            <div>
                                <ThematicColorPickerMenu
                                    thematicColors={this.props.mapObj.vtDemographicSettings.demographicColors || 'd3d9e9,a8b3d2,7c8cbc,5166a5,25408f'}
                                    selectThematic={this.selectThematic}
                                />
                            </div>
                            </div>
                            <div className="retailerLabel">Opacity:</div>
                            <div style={{ width: '80px', paddingLeft: '5px' }}>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    type="number"
                                    value={this.props.mapObj.vtDemographicSettings.demographicFillOpacity || .5 }
                                    InputProps={{ inputProps: { min: 0, max: 1, step: 0.1 } }}
                                    onChange={(e) => { this.setFillOpacity(e.target.value) }}
                                />
                            </div>
                        </div>
                        <div className="retailerOuterDiv padding-t20">
                            <div className="retailerLabel">Outline:</div>
                            <div style={{ flexGrow: 2 }}>
                            <ColorPickerMenu
                                squareColor={this.props.mapObj.vtDemographicSettings.demographicStroke || '#cccdd5'}
                                addTransparent={true}
                                setFillColor={this.selectOutline}
                            />
                            </div>
                            {/* <div className="retailerLabel">Opacity:</div>
                            <div style={{ width: '80px', paddingLeft: '5px' }}>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    type="number"
                                    value={this.props.mapObj.vtDemographicSettings.demographicStrokeOpacity || 1 }
                                    InputProps={{ inputProps: { min: 0, max: 1, step: 0.1 } }}
                                    onChange={(e) => { this.setStrokeOpacity(e.target.value) }}
                                />
                            </div> */}
                            <div className="retailerLabel">Width:</div>
                            <div style={{ width: '80px', paddingLeft: '5px' }}>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    type="number"
                                    value={this.props.mapObj.vtDemographicSettings.demographicWidth || 2 }
                                    InputProps={{ inputProps: { min: 1, max: 9, step: 1 } }}
                                    onChange={(e) => { this.setStrokeWidth(e.target.value) }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="geocodeDiv flex">
                        <button className="geocodeBtn" onClick={this.backToAddData}>Back</button>
                        {this.props.mapObj.hasDemographics === true ?
                            <button className="geocodeBtn" onClick={this.clearDemographics}>Clear All</button>
                            : null
                        }
                    </div>
                </div>
            }
            {this.state.isError ?
                  <div className="errorText padding-t10">
                    {this.state.errorType === 'generic' ? 'There was an error' : this.state.errorMessage }
                  </div>
                  : null
            }
            </div>
        </div>
        );
    }
}

export default DemographicsFilter