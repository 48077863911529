import React from 'react';
import { GenerateRandomId, GetMeterDistanceForDT, GetCurrentGroupOrPointRings, GetDriveTimeMode, GetDriveTimeModeInteger } from '../../../utils/tools';
import axios from 'axios';
import AddRingForm from './AddRingForm';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import translateObj from '../../../utils/translate';

let CancelToken = axios.CancelToken;
let source = CancelToken.source();

class AddRing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rings: [],
      polygon: [],
      error: false,
      isDriveError: false,
      isDrive: this.props.isDrive,
      isLoading: false,
      tabValue: 0
    }

    this.updateRingState = this.updateRingState.bind(this);
    this.saveRingRoute = this.saveRingRoute.bind(this);
    this._getRingLabel = this._getRingLabel.bind(this);
    this._getNewDriveTime = this._getNewDriveTime.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.setRingLabel = this.setRingLabel.bind(this);
    this.addNew = this.addNew.bind(this);
    this.closePanel = this.closePanel.bind(this);
  }

  componentDidMount() {
    const ringClone = this.props.isDrive ? JSON.parse(JSON.stringify(this.props.driveTimes)) : JSON.parse(JSON.stringify(this.props.rings));
    const currentPointRings = GetCurrentGroupOrPointRings(this.props.isGroup, this.props.currentPoint, ringClone, this.props.points, this.props.currentGroup);
    this.setState({
      rings: currentPointRings
    });
  }

  updateRingState(updateObj) {
    this.setState(updateObj);
  }

  async saveRingRoute() {
    let finalArray = [];
    const ringRouteClone = this.state.isDrive ? JSON.parse(JSON.stringify(this.props.driveTimes)) : JSON.parse(JSON.stringify(this.props.rings));
    let ringsToAppend = [];
    const groupPoints = this.props.isGroup ? Array.from(this.props.points).filter(point => point.content.group === this.props.currentGroup) : [];
    if (!this.state.isDrive) {
      if (!this.props.isGroup) {
        ringsToAppend = Array.from(ringRouteClone).filter(ring => ring.pointId !== this.props.currentPoint.id);
      } else {
        const ringsInGroup = this._getRingsInCurrentGroup(ringRouteClone);
        const ringsNotInGroup = this._getRingsNotInCurrentGroup(ringRouteClone, ringsInGroup);
        const groupRingsNotInState = this._getRingsInGroupButNotInState(ringsInGroup);
        const groupAfterNotInState = this._getRingsAppliedToWholeGroup(groupRingsNotInState, groupPoints);
        ringsToAppend = Array.from(groupAfterNotInState).concat(ringsNotInGroup);
      }
      const newRings = this._saveRing(groupPoints);
      finalArray = ringsToAppend.concat(newRings);
    } else if (this.state.isDrive) {
      if (!this.props.isGroup) {
        ringsToAppend = Array.from(ringRouteClone).filter(ring => ring.pointId !== this.props.currentPoint.id);
      } else {
        const ringsInGroup = this._getRingsInCurrentGroup(ringRouteClone);
        const ringsNotInGroup = this._getRingsNotInCurrentGroup(ringRouteClone, ringsInGroup);
        const groupRingsNotInState = this._getRingsInGroupButNotInState(ringsInGroup);
        const groupAfterNotInState = this._getRingsAppliedToWholeGroup(groupRingsNotInState, groupPoints);
        ringsToAppend = Array.from(groupAfterNotInState).concat(ringsNotInGroup);
      }
      const newRings = await this._saveRoute(groupPoints);
      finalArray = ringsToAppend.concat(newRings);
    }

    finalArray.sort((a, b) => (b.radius > a.radius) ? 1 : ((a.radius > b.radius) ? -1 : 0));

    //add id that changes everytime to cause all rings to re-render on a change to 
    // any of them; to preserve drawing order
    for (const ring of finalArray) {
      ring.drawingId = GenerateRandomId();
    }

    const keyType = this.state.isDrive ? 'driveTimes' : 'rings';
    this.props.updateMapObj({
      [keyType]: finalArray
    });

    this.props.toggleAddRing(false, true);
  }

  _getRingsInCurrentGroup(ringRouteClone) {
    let ringsInGroup = [];
    for (const ring of ringRouteClone) { //get rings belonging to current group
      for (const point of this.props.points) {
        if (ring.pointId === point.content.id && point.content.group === this.props.currentGroup) {
          ringsInGroup.push(ring);
        }
      }
    }
    return ringsInGroup;
  }

  _getRingsNotInCurrentGroup(ringRouteClone, ringsInGroup) {
    let ringsNotInGroup = JSON.parse(JSON.stringify(ringRouteClone));
    for (const ring of ringRouteClone) { //get rings not belonging to current group
      for (const groupRing of ringsInGroup) {
        if (ring.id === groupRing.id) {
          const index = ringsNotInGroup.findIndex(item => item.id === ring.id);
          ringsNotInGroup.splice(index, 1);
        }
      }
    }
    return ringsNotInGroup;
  }

  _getRingsInGroupButNotInState(ringsInGroup) {
    const distinctStateRadii = [...new Set(this.state.rings.map(ring => ring.radius))]; //get unique radii from state.rings
    let groupRingsNotInState = JSON.parse(JSON.stringify(ringsInGroup));
    for (const rad of distinctStateRadii) {
      for (const ring of ringsInGroup) {
        const idRad = ring.id.split("_");
        const idRadNum = parseFloat(idRad[1]);
        if (idRadNum === rad) {
          const index = groupRingsNotInState.findIndex(item => item.id === ring.id);
          groupRingsNotInState.splice(index, 1);
        }
      }
    }
    return groupRingsNotInState;
  }

  _getRingsAppliedToWholeGroup(groupRingsNotInState, groupPoints) {
    let groupAfterNotInState = JSON.parse(JSON.stringify(groupRingsNotInState));
    const distinctRadiiNotState = [...new Set(groupRingsNotInState.map(ring => ring.radius))]; //get unique radii for rings not in state
    for (const rad of distinctRadiiNotState) {
      let ringRadii = Array.from(groupRingsNotInState).filter(item => {
        const idRad = item.id.split("_");
        const idRadNum = parseFloat(idRad[1]);
        return idRadNum === rad;
      });
      if (ringRadii.length === groupPoints.length) {
        groupAfterNotInState = groupAfterNotInState.filter(item => {
          const idRad = item.id.split("_");
          const idRadNum = parseFloat(idRad[1]);
          return idRadNum !== rad;
        });
      }
    }
    return groupAfterNotInState;
  }

  _saveRing(groupPoints) {
    console.log(this.props.currentPoint);
    let newRings = [];
    for (const ring of this.state.rings) {
      if (this.props.isGroup) {
        for (const point of groupPoints) {
          const found = newRings.some(r => r.id === point.content.id + "_" + ring.radius);
          if (!found) {
            const ringObj = this._addToNewRingArray(ring, point.content);
            newRings.push(ringObj);
          }
        }
      } else {
        const found = newRings.some(r => r.id === this.props.currentPoint.id + "_" + ring.radius);
        if (!found) {
          const ringObj = this._addToNewRingArray(ring, this.props.currentPoint);
          newRings.push(ringObj);
        }
      }
    }

    return newRings;
  }

  _addToNewRingArray(ring, point) {
    const newIdNum = point.id + "_" + ring.radius;

    let drivePoly = [];
    let meterDistance = [0, 0];

    let ringObj = {
      id: newIdNum,
      pointId: point.id,
      label: ring.label,
      radius: ring.radius,
      opacity: ring.opacity,
      fill: ring.fill,
      stroke: ring.stroke,
      strokeOpacity: ring.strokeOpacity,
      strokeWidth: ring.strokeWidth,
      center: [point.lat, point.lng],
      labelFill: ring.labelFill,
      labelStroke: ring.labelStroke,
      labelDirection: ring.labelDirection,
      labelWidth: 0,
      drawingId: null,
      polygon: drivePoly,
      meterDistance: meterDistance,
      type: ring.type,
      displayInLegend: this.props.displayInLegend
    };

    return ringObj;
  }

  async _saveRoute(groupPoints) {



    let stateRingsClone = Array.from(this.state.rings);
    //sort rings by radius before running drive times
    stateRingsClone.sort((a, b) => (b.radius < a.radius) ? 1 : ((a.radius < b.radius) ? -1 : 0));
    //de-dupe
    stateRingsClone = stateRingsClone.filter((stateRing, index, self) =>
      index === self.findIndex((r) => (
        r.radius === stateRing.radius
      ))
    );

    let driveTimesToRun = [];
    let radiusArray = stateRingsClone.map(ring => ring.radius);
    let radiusString = radiusArray.join(",");
    if (!this.props.isGroup) {
      driveTimesToRun.push({
        radiusArray: radiusArray,
        radiusString: radiusString,
        center: [this.props.currentPoint.lat, this.props.currentPoint.lng],
        pointId: this.props.currentPoint.id,
        polygon: []
      });
    } else {
      for (const pt of groupPoints) {
        driveTimesToRun.push({
          radiusArray: radiusArray,
          radiusString: radiusString,
          center: [pt.content.lat, pt.content.lng],
          pointId: pt.content.id
        });
      }
    }

    const rArr = driveTimesToRun[0].radiusArray;
    const check = this.props.driveTimes.filter(dt=>dt.pointId===this.props.currentPoint.id&&rArr.includes(dt.radius));

    if(check.length===rArr.length){
      // for(var i =0; i< stateRingsClone.length;i++){
      //   const oldDT = check.find(c=>c.radius===stateRingsClone[i].radius);
      // }
      return stateRingsClone;
    }

    for (const dtToRun of driveTimesToRun) {
      if (dtToRun.radiusArray.length > 0) {
        const newDrivePoly = await this._getNewDriveTime(dtToRun.radiusString, dtToRun.center[0], dtToRun.center[1]);
        dtToRun.polygon = newDrivePoly;
      }
    }
    
    //make rings out of anything in drivePolyArray
    //const reverseDriveArray = drivePolyArray.length > 0 ? drivePolyArray[0].reverse() : [];
    if (driveTimesToRun.length > 0) {
      for (const dtToRun of driveTimesToRun) {
        if (dtToRun.polygon && dtToRun.polygon[0] && dtToRun.polygon[0].length > 0) {
          dtToRun.polygon = dtToRun.polygon[0].reverse();
        }
      }
    }

    let newRings = [];
    for (const route of driveTimesToRun) {
      if (route.polygon && route.polygon.length > 0) {
        for (const [index, poly] of route.polygon.entries()) {
          newRings.push({
            id: route.pointId + "_" + stateRingsClone[index].radius,
            pointId: route.pointId,
            label: stateRingsClone[index].label,
            radius: stateRingsClone[index].radius,
            opacity: stateRingsClone[index].opacity,
            fill: stateRingsClone[index].fill,
            stroke: stateRingsClone[index].stroke,
            strokeOpacity: stateRingsClone[index].strokeOpacity,
            strokeWidth: stateRingsClone[index].strokeWidth,
            center: [route.center[0], route.center[1]],
            labelFill: stateRingsClone[index].labelFill,
            labelStroke: stateRingsClone[index].labelStroke,
            labelDirection: stateRingsClone[index].labelDirection,
            labelWidth: 0,
            drawingId: null,
            polygon: [poly],
            meterDistance: GetMeterDistanceForDT([poly]),
            type: stateRingsClone[index].type,
            displayInLegend: this.props.displayInLegend
          });
        }
      }
    }
    console.log(newRings,'newRings');
    return newRings;
  }

  _getRingLabel(radius, drive, toggle, index, type) {
    if (this.state && this.state.rings[index] && !this.state.rings[index].autoLabel && !toggle) {
      return this.state.rings[index].label;
    }
    const rad = radius || this.state.rings[index].radius;
    let mileText = this.props.country === 'US' ? ' Miles' : ' KMs';

    const isDrive = drive;

    if (isDrive) {
      if (!type) {
        mileText = ' Minutes';
      } else if (type === 'driving_time' || type === 'trucking_time' || type === 'walking_time') {
        mileText = ' Minutes';
      } else {
        mileText = this.props.country === 'US' ? ' Miles' : ' KMs';
      }
    }

    if (rad === 1) {
      mileText = this.props.country === 'US' ? ' Mile' : ' KM';
      if (isDrive) {
        if (!type) {
          mileText = ' Minute';
        } else if (type === 'driving_time' || type === 'trucking_time' || type === 'walking_time') {
          mileText = ' Minute';
        } else {
          mileText = this.props.country === 'US' ? ' Mile' : ' KM';
        }
      }
    }

    const label = rad + mileText;
    return label;
  }

  async _getNewDriveTime(radius, lat, lng) {
    const use_us_api = process.env.REACT_APP_USE_USDTAPI || 'true';
    if(this.props.country === 'US' && use_us_api === 'true'){
      return await this._getNewDriveTime_US(radius,lat,lng);
    }
    return await this._getNewDriveTime_Intl(radius,lat,lng);
  }

  async _getNewDriveTime_US(radius, lat, lng) {
    console.log('_getNewDriverTime_US');
    this.setState({
      isLoading: true,
      isDriveError: false,
      isError: false
    });
    this.props.updateStatus('isLoadingHistory', true);

    if(this.state.rings[0].type.includes('_distance')){
      let splitRad = radius.split(',');
      splitRad = splitRad.map(r=>r*1.603);
      radius = splitRad.join();
    }

    const driveTimeMode = GetDriveTimeModeInteger(this.state.rings[0]);
    const gcAtlasToken = await this.props.retriveGCAtlasToken();
    console.log(gcAtlasToken,'gcAtlasToken');
    
    const dtUrl = process.env.REACT_APP_USDTAPI;
    const dtQry = 'f=json&outSr=4326&defaultBreaks=' + radius + '&facilities=' + lng + ',' + lat
      + '&travelMode=' + driveTimeMode + '&splitPolygonsAtBreaks=false&trimOuterPolygon=true&trimPolygonDistance=100&trimPolygonDistanceUnits=esriMeters&useHierarchy=true'
      + '&token=' + gcAtlasToken; // this.props.gcAtlasToken;
    //const agent = new https.Agent({ rejectUnauthorized: false });
    //let res = await axios.post(dtUrl,dtQry,{httpsAgent: agent}).then(result=>{
    let returnRoutes = await axios.post(dtUrl,dtQry).then(result=>{
      if (result.status === 200 && result.data) {
        let features = result.data.saPolygons.features;
        
        //reverse arrays so they are lat/lng, not lnt/lat
        let reverseRoutes = [];
        let finalArray = [];
        //sort features so the larger rings are first
        features.sort((a, b) => parseFloat(b.attributes.AREA_DESC) - parseFloat(a.attributes.AREA_DESC));
        for (const feature of features) {
          //let featureArray = feature.geometry.rings;

          ///// BUG FIX
          ///// some dt return multiple polygons, this will take the polygon with the most nodes and use that.
          ///// TODO:   handle multiple polygons (42 Cedar Ln. Stafford VA was the sample )

          let rte = feature.geometry.rings.reduce(function (prev,current) { return (prev.length > current.legnth) ? prev : current });
          const reverseArray = rte.map(innerArray => innerArray.reverse());
          reverseRoutes.push(reverseArray);

        }

        finalArray.push(reverseRoutes);

        this.setState({
          isLoading: false,
        });
        this.props.updateStatus('isLoadingHistory', false);
        return finalArray;
      } else {
        this.setState({
          isLoading: false,
          isDriveError: true,
          isError: true
        });
        this.props.updateStatus('isLoadingHistory', false);
        return false;
      }
    }).catch((error) => {
      console.log(error);
      this.setState({
        isLoading: false,
        isDriveError: true,
        isError: true
      });
      this.props.updateStatus('isLoadingHistory', false);
      return false;
    });

    return returnRoutes;
  }

  async _getNewDriveTime_Intl(radius, lat, lng) {
    this.setState({
      isLoading: true,
      isDriveError: false,
      isError: false
    });
    this.props.updateStatus('isLoadingHistory', true);

    const isIntl = this.props.country === 'US' ? false : true;
    const driveTimeMode = GetDriveTimeMode(this.state.rings[0],isIntl);
    const returnRoutes = await axios({
      method: 'get',
      url: process.env.REACT_APP_HYDRAAPI + '/drawStudyAreas?lat=' + lat + '&lng=' + lng + '&rings=[' + radius + ']&atlasmode=' + driveTimeMode + '&isintl=' + isIntl + '&country=' + this.props.country,
      headers: this.props.headers,
      cancelToken: source.token
    }).then((result) => {
      if (result.status === 200 && result.data) {
        //const routes = result.data[0].featureset.features[0].geometry.rings;
        let features = result.data[0].featureset.features;
        //reverse arrays so they are lat/lng, not lnt/lat
        let reverseRoutes = [];
        let finalArray = [];
        //sort features so the larger rings are first
        features.sort((a, b) => parseFloat(b.attributes.AREA_DESC) - parseFloat(a.attributes.AREA_DESC));
        for (const feature of features) {

          let biggestringlen = 0;
          let biggestringix = -1;
          for(let i=0;i<feature.geometry.rings.length;i++){
            if(feature.geometry.rings[i].length > biggestringlen){
              biggestringlen = feature.geometry.rings[i].length;
              biggestringix = i;
            }
          }
          let rte = feature.geometry.rings[biggestringix];

          
          const reverseArray = rte.map(innerArray => innerArray.reverse());
          reverseRoutes.push(reverseArray);

          // for (const rte of featureArray) {
          //   console.log(rte,'rte');
          //   const reverseArray = rte.map(innerArray => innerArray.reverse());
          //   reverseRoutes.push(reverseArray);
          // }
        }

        finalArray.push(reverseRoutes);

        this.setState({
          isLoading: false,
        });
        this.props.updateStatus('isLoadingHistory', false);
        return finalArray;
      } else {
        this.setState({
          isLoading: false,
          isDriveError: true,
          isError: true
        });
        this.props.updateStatus('isLoadingHistory', false);
        return false;
      }
    }).catch((error) => {
      console.log(error);
      this.setState({
        isLoading: false,
        isDriveError: true,
        isError: true
      });
      this.props.updateStatus('isLoadingHistory', false);
      return false;
    });

    return returnRoutes;
  }

  handleTabChange(e, value) {
    this.setState({
      tabValue: value
    });
  }

  addNew() {
    console.log('addNew');
    const ringArrayClone = Array.from(this.state.rings);
    //new radius
    const ringArrayLength = this.state.rings.length;
    const lastRadVal = ringArrayLength === 0 ? 0 : ringArrayClone[ringArrayLength - 1].radius;

    ringArrayClone.push({
      radius: lastRadVal + 1,
      label: this._getRingLabel(lastRadVal + 1, this.props.isDrive, true, 0, ringArrayLength > 0 ? ringArrayClone[ringArrayLength - 1].type : 'driving_time'),
      fill: ringArrayLength > 0 ? ringArrayClone[ringArrayLength - 1].fill : '#0c9ed9',
      stroke: ringArrayLength > 0 ? ringArrayClone[ringArrayLength - 1].stroke : '#0c9ed9',
      strokeOpacity: ringArrayLength > 0 ? ringArrayClone[ringArrayLength - 1].strokeOpacity : 1,
      strokeWidth: ringArrayLength > 0 ? ringArrayClone[ringArrayLength - 1].strokeWidth : 3,
      labelFill: ringArrayLength > 0 ? ringArrayClone[ringArrayLength - 1].labelFill : '#4a4a4d',
      labelStroke: ringArrayLength > 0 ? ringArrayClone[ringArrayLength - 1].labelStroke : '#ffffff',
      labelDirection: ringArrayLength > 0 ? ringArrayClone[ringArrayLength - 1].labelDirection : 'bottom',
      opacity: ringArrayLength > 0 ? ringArrayClone[ringArrayLength - 1].opacity : 0.5,
      autoLabel: true,
      type: ringArrayLength > 0 ? ringArrayClone[ringArrayLength - 1].type : 'driving_time',
      displayInLegend: this.props.displayInLegend
    });

    this.setState({
      rings: ringArrayClone,
      tabValue: ringArrayClone.length - 1
    });
  }

  cancelDriveTime() {
    source.cancel('Operation canceled by the user.');
    CancelToken = axios.CancelToken;
    source = CancelToken.source();
  }

  setRingLabel(isDrive, type) {
    if (!isDrive) {
      return null;
    } else {
      if (type === 'driving_time' || type === 'trucking_time' || type === 'walking_time') {
        return 'Minutes';
      } else {
        return this.props.country === 'US' ? 'Miles' : 'KMs';
      }
    }
  }

  closePanel() {
    this.props.toggleAddRing(false, true);
  }

  render() {
    const ringsArray = this.state.rings;
    return (
      <div>
        {this.state.isLoading ?
          <div>
            <div className="panel-loader"></div>
            <div><button className="geocodeBtn" onClick={this.cancelDriveTime}>{translateObj.cancelBtn[this.props.translate]}</button></div>
          </div>
          :
          <div>
            <div style={{ maxWidth: '380px', display: 'flex', alignItems: 'center' }}>
              <Tabs
                variant="scrollable"
                scrollButtons="auto"
                value={this.state.tabValue}
                aria-label="simple tabs example"
                onChange={this.handleTabChange}
              >
                {ringsArray.map((ring, idx) =>
                  <Tab
                    key={'tabKey' + idx}
                    label={ring.radius + (this.state.isDrive ? ' ' + this.setRingLabel(true, ring.type) : (this.props.country === 'US' ? ' Miles' : ' KMs'))}
                  />
                )}
              </Tabs>
              <div style={{ cursor: 'pointer' }}>
                <AddCircleIcon fontSize="small" htmlColor="#25408f" onClick={this.addNew} />
              </div>
            </div>
            {ringsArray.map((ring, idx) =>
              <AddRingForm
                key={'addRingKey' + idx}
                idx={idx}
                tabValue={this.state.tabValue}
                ring={ring}
                rings={this.state.rings}
                updateRingState={this.updateRingState}
                _getRingLabel={this._getRingLabel}
                isDrive={this.props.isDrive}
                setRingLabel={this.setRingLabel}
                country={this.props.country}
                translate={this.props.translate}
              />
            )}
            <div className="geocodeDiv flex padding-t20 padding-20b">
              <button
                className="geocodeBtn"
                onClick={this.closePanel}
              >
                {translateObj.backBtn[this.props.translate]}
              </button>
              <button
                className="geocodeBtn"
                onClick={this.saveRingRoute}
              >
                {translateObj.saveBtn[this.props.translate]}
              </button>
            </div>
            {this.state.error ?
              <div>
                {this.state.isDriveError ?
                  <div className="errorMsgDiv">{translateObj.oopsWrongTryAgain[this.props.translate]}</div>
                  :
                  <div className="errorMsgDiv">This {this.state.isDrive ? 'route' : 'ring'} already exists.</div>
                }
              </div>
              : null
            }
          </div>
        }
      </div>
    );
  }
}

export default AddRing;