
import React from 'react';
import Menu from '@material-ui/core/Menu';
import Tooltip from '@material-ui/core/Tooltip';
import { TextField } from '@material-ui/core';

class LogoSize extends React.Component {
    render() {
        return (
            // <Menu
            // // id="logoSize-menu"
            // // anchorEl={this.state.anchorEl}
            // // keepMounted
            // // open={this.state.showOptions}
            // // onClose={this.toggleOptionsPanel.bind(this, { currentTarget: null }, false)}
            // >
            <div className="logoSizeMenuDiv">
            <span>Choose Size:</span>
            <div className="colorPickerDiv">
                <div className="colorPickerRow">
                <Tooltip title="Smaller (75%)" placement="top">
                    <div className="colorPickerCell" onClick={this.props.updateCustomLogoSize.bind(this, { target: { value: 75 } })}>
                    <svg height="15px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <circle fill="#9ea2a2" stroke="#ffffff" cx="10" cy="10" r="8" />
                    </svg>
                    </div>
                </Tooltip>
                <Tooltip title="Default (100%)" placement="top">
                    <div className="colorPickerCell" onClick={this.props.updateCustomLogoSize.bind(this, { target: { value: 100 } })}>
                    <svg height="30px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <circle fill="#9ea2a2" stroke="#ffffff" cx="10" cy="10" r="8" />
                    </svg>
                    </div>
                </Tooltip>
                <Tooltip title="Larger (125%)" placement="top">
                    <div className="colorPickerCell" onClick={this.props.updateCustomLogoSize.bind(this, { target: { value: 125 } })}>
                    <svg height="45px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <circle fill="#9ea2a2" stroke="#ffffff" cx="10" cy="10" r="8" />
                    </svg>
                    </div>
                </Tooltip>
                </div>
            </div>
            <div className="retailerOuterDiv">
                <div className="retailerLabel">Custom:</div>
                <div style={{ width: '100px', display: 'flex', alignItems: 'center' }}>
                <TextField
                    id="customLogoSizeNum"
                    value={this.props.logoSize}
                    variant="outlined"
                    size="small"
                    type="number"
                    InputProps={{ inputProps: { min: 0, step: 1 } }}
                    onChange={(event) => this.props.updateCustomLogoSize(event)}
                />
                <div style={{ paddingLeft: '2px' }}>%</div>
                </div>
            </div>
            </div>
            // </Menu>
        );
    }
}

export default LogoSize;