import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { TextField } from '@material-ui/core';
import ColorPickerMenu from '../SideBar/ColorPicker/ColorPickerMenu';
import translateObj from '../../utils/translate';

class ShapeStylePanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isIniting: true
    }

    this.setFillColor = this.setFillColor.bind(this);
    this.setStrokeColor = this.setStrokeColor.bind(this);
    this.setFillOpacity = this.setFillOpacity.bind(this);
    this.setStrokeOpacity = this.setStrokeOpacity.bind(this);
  }

  componentDidMount() {
    this.setState({isIniting: true});
    if (this.props.currentShape) {
      this.setFillColor(this.props.currentShape.fill);
      this.setStrokeColor(this.props.currentShape.stroke);
      this.setFillOpacity(this.props.currentShape.opacity);
      this.setStrokeOpacity(this.props.currentShape.strokeOpacity);
    }
    this.setState({isIniting: false});
  }

  setFillColor(fill) {
    if (this.props.currentShape) {
      this._updateShapesArray('fill', fill);
    }

    this.props.updateMapState({
      shapeFill: fill
    });
  }

  setStrokeColor(stroke) {
    if (this.props.currentShape) {
      this._updateShapesArray('stroke', stroke);
    }

    this.props.updateMapState({
      shapeStroke: stroke
    });
  }

  setFillOpacity(value) {
    let userVal = value || 0;
    if (value > 1) {
      userVal = 1;
    } else if (value < 0) {
      userVal = 0;
    }

    const num = Math.round((userVal) * 100) / 100;
    if (this.props.currentShape) {
      this._updateShapesArray('opacity', num);
    }

    this.props.updateMapState({
      shapeFillOpacity: num
    });
  }

  setWidth(value) {
    let num = parseInt(value);

    if(value<0){
      num = 1
    }
    else if(value>5){
      num = 5
    }

    if (this.props.currentShape) {
      this._updateShapesArray('width', num);
    }

    this.props.updateMapState({
      shapeStrokeWidth: num
    });
  }

  setStrokeOpacity(value) {
    let userVal = value || 0;
    if (value > 1) {
      userVal = 1;
    } else if (value < 0) {
      userVal = 0;
    }

    const num = Math.round((userVal) * 100) / 100;
    if (this.props.currentShape) {
      this._updateShapesArray('strokeOpacity', num);
    }

    this.props.updateMapState({
      shapeStrokeOpacity: num
    });
  }

  _updateShapesArray(field, value) {

    let shapesArrayClone = Array.from(this.props.mapObj.shapes);
    for (const shape of shapesArrayClone) {
      if (shape.id === this.props.currentShape.id) {
        shape[field] = value;
      }
    }

    shapesArrayClone.sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));
    this.props.updateMapObj({
      shapes: shapesArrayClone
    });

    if(!this.state.isIniting){
      this.props.updateShapeLegend(this.props.currentShape.id);
    }

  }
  
  render() {
    return (
      <div>
        <div className="styleShapePanel">
          <h2 className="panel-title">
            <span className="panel-icon-span">
              <svg id="styleIconGroups" xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 0 88.62 94.1">
                <line id="Line_20" stroke="#0c9ed9" fill="none" strokeWidth="4" y1="92.85" x2="88.62" y2="92.85" />
                <path id="Path_4362" fill="none" stroke="#0c9ed9" strokeWidth="4" d="M24.45-22.9,7.23-35A4.68,4.68,0,0,0,.72-33.89a4.48,4.48,0,0,0-.58,1.13L-3.51-22.3-30.89,5.46a4.65,4.65,0,0,0-1.34,3.23V56.11" transform="translate(34.31 37.14)" />
                <path id="Path_4363" fill="none" stroke="#0c9ed9" strokeWidth="4" d="M-28.37,55.58,13.52,40.67a4.6,4.6,0,0,0,2.57-2.37L32.84,3l8.58-7a4.68,4.68,0,0,0,.66-6.59,4.62,4.62,0,0,0-.92-.85" transform="translate(34.31 37.14)" />
                <circle id="Ellipse_2" fill="none" stroke="#0c9ed9" strokeWidth="4" cx="33.22" cy="52.86" r="5.67" />
                <line id="Line_21" fill="none" stroke="#0c9ed9" strokeWidth="4" x1="3.37" y1="92.08" x2="30.5" y2="56.12" />
              </svg>
            </span>
            {translateObj.styleShapesTitle[this.props.translate]}
            <span className="panel-close closeStyle"><CloseIcon onClick={this.props.toggleShapeStyle.bind(this, false)} fontSize="small" /></span>
          </h2>
          <div className="panel-body">
            <div className="add-ring-row-div paddingTB10">
              <div className="add-ring-row">
                <div className="paddingL15">{translateObj.outline[this.props.translate]}</div>
                <div style={{ paddingLeft: '5px' }}>
                  <ColorPickerMenu
                    squareColor={this.props.currentShape ? this.props.currentShape.stroke : '#0c9ed9'}
                    addTransparent={true}
                    setFillColor={this.setStrokeColor}
                  />
                </div>
              </div>
              <div className="add-ring-row paddingL15">
                {translateObj.outlineOpacity[this.props.translate]}
                <div style={{ width: '80px', paddingLeft: '5px' }}>
                  <TextField
                    label="Opacity"
                    variant="outlined"
                    size="small"
                    type="number"
                    value={this.props.currentShape ? this.props.currentShape.strokeOpacity : 1}
                    InputProps={{ inputProps: { min: 0, max: 1, step: 0.1 } }}
                    onChange={(e) => { this.setStrokeOpacity(e.target.value) }}
                  />
                </div>
              </div>

              <div className="add-ring-row paddingL15">
                <div style={{ width: '80px', paddingLeft: '5px' }}>
                  <TextField
                    label="Width"
                    variant="outlined"
                    size="small"
                    type="number"
                    value={this.props.currentShape ? this.props.currentShape.width : 3}
                    InputProps={{ inputProps: { min: 0, max: 5, step: 1 } }}
                    onChange={(e) => { this.setWidth(e.target.value) }}
                  />
                </div>
              </div>


            </div>
            {this.props.currentShape && this.props.currentShape.type !== 'polyline' ?
              <div className="add-ring-row-div">
                <div className="add-ring-row">
                  <div>
                    <span className="paddingL15">{translateObj.fill[this.props.translate]}</span>
                  </div>
                  <div style={{ paddingLeft: '5px' }}>
                    <ColorPickerMenu
                      squareColor={this.props.currentShape ? this.props.currentShape.fill : '#0c9ed9'}
                      setFillColor={this.setFillColor}
                      addTransparent={true}
                    />
                  </div>
                </div>
                <div className="add-ring-row paddingL15">
                  {translateObj.fillOpacity[this.props.translate]}
                  <div style={{ width: '80px', paddingLeft: '5px' }}>
                    <TextField
                      label="Opacity"
                      variant="outlined"
                      size="small"
                      type="number"
                      value={this.props.currentShape ? this.props.currentShape.opacity : 1}
                      InputProps={{ inputProps: { min: 0, max: 1, step: 0.1 } }}
                      onChange={(e) => { this.setFillOpacity(e.target.value) }}
                    />
                  </div>
                </div>
              </div>
              : null
            }
          </div>
        </div>
      </div>
    );
  }
}

export default ShapeStylePanel;