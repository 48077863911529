import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
// import AddRings from '../IncludeRings/IncludeRings'
import translateObj from '../../../../utils/translate';
import EditAddedPoint from '../TypeAddress/EditAddedPoint';

class ClickMap extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // showRings: false,
      // runRings: false,
      // ringType: 'ring',
      // ringValue: 1,
      loading: null,
      showText: true
    }

    this.backToAddPts = this.backToAddPts.bind(this);
    this.closePanel = this.closePanel.bind(this);
    this.editPointInfo = this.editPointInfo.bind(this);
    this.toggleText = this.toggleText.bind(this);
    this.deletePoint = this.deletePoint.bind(this);
    
    // this.ringEnter = this.ringEnter.bind(this);
    // this.selectRingType = this.selectRingType.bind(this);
    // this.toggleRunRings = this.toggleRunRings.bind(this);
  }

  deletePoint(point) {
    const clonePts = this.props.points;
    const delIx = clonePts.findIndex(p=>p.content.id===point.content.id);
    clonePts.splice(delIx,1);
    this.props.updateMapObj({mapPoints:clonePts,  addedPoint:null});
    this.setState({showEditButtons: false});
  }

  toggleText(bool){
    this.setState({showText:bool});
  }

  editPointInfo(info) {
    const clonePts = this.props.points;
    const editIx = clonePts.findIndex(p=>p.content.id===info.id);
    clonePts[editIx].content.address = info.pointAddress;
    clonePts[editIx].content.city = info.pointCity;
    clonePts[editIx].content.label = info.pointLabel;
    clonePts[editIx].content.legenddata = info.pointLegenddata;
    clonePts[editIx].content.name = info.pointName;
    clonePts[editIx].content.state = info.pointState;
    clonePts[editIx].content.zip = info.pointZip;
  }


  componentDidMount() {
    this.props.updateMapObj({mapAddPt: true});
    document.getElementsByClassName('leaflet-container')[0].classList.add('mapClickCursor');
  }

  backToAddPts() {
    this.props.toggleClickMap(false, true);
  }

  closePanel() {
    this.props.togglePanel('addPts');
  }

  componentWillUnmount() {
    this.props.updateMapObj({mapAddPt: false, addedPoint: null});
    document.getElementsByClassName('leaflet-container')[0].classList.remove('mapClickCursor');
  }

  render() {
    return (
      <div>
        {this.state.showText ? 
        <h2 className="panel-title">
          <span className="panel-icon-span">
            <svg id="starIcon" xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 94.63 94.63">
              <path id="Path_4164" d="M16.51,3.68c-7.1-20.22-5.4-19.73-12.33,0-21.68.43-20.6-1-3.82,11.63-6.27,20.5-7.32,19.1,10,7.18,17.77,12.23,16.1,12.8,10-7.18C37.61,2.35,37.56,4.1,16.51,3.68Z" transform="translate(37.42 37.22)" fill="none" stroke="#0c9ed9" strokeWidth="4" />
              <path id="Path_4165" d="M9.83-36A46.07,46.07,0,1,1-25-19.93" transform="translate(37.42 37.22)" fill="none" stroke="#0c9ed9" strokeWidth="4" />
            </svg>
          </span>
          {translateObj.clickOnMapTitle[this.props.translate]}
          <span className="panel-close closeStyle"><CloseIcon onClick={this.closePanel} fontSize="small" /></span>
        </h2>
        : null }
        <div className="panel-body">
        {this.props.gettingDriveTimes ?  <div className="panel-loader"></div> :
        <div>
          {this.state.showText ? 
          <div className="textAreaDiv">
            {translateObj.clickOnMapDesc[this.props.translate]}
          </div>
          : null}

          {this.props.addedPoint ? 
            <div>
            {this.state.showText ? 
            <div className="flex">
            <div style={{marginLeft: 'auto', fontSize: '12px', paddingBottom: '2px'}}>Edit Point</div>
            </div>
            : null }
            <EditAddedPoint
              addedPoint={this.props.addedPoint}
              updateMapObj={this.props.updateMapObj}
              translate={this.props.translate}
              deletePoint={this.deletePoint}
              editPointInfo={this.editPointInfo}
              toggleText={this.toggleText}
            />
            </div>
          : null}
          {this.state.showText ?
          <div className="geocodeDiv flexRight">
            <button
              className="geocodeBtn"
              onClick={this.backToAddPts}
            >
              {translateObj.doneBtn[this.props.translate]}
            </button>
          </div>
          : null }
        </div>
        }
        </div>
      </div>
    );
  }
}

export default ClickMap;