import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteMapComponent from './DeleteMapComponent';
import { GenerateRandomId, GetIconOffset, InitialMapZoom, ResizeLogo } from '../../../utils/tools';
import axios from 'axios';
import SortTableHeader from './SortTableHeader';
import translateObj from '../../../utils/translate';

class HistoryPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentMapId: '',
      page: 0,
      rowsPerPage: 5,
      isLoading: true,
      isError: false,
      showDelete: false,
      deleteMapId: null,
      isDeleteError: false,
      isLoadingError: false
    }

    this.closePanel = this.closePanel.bind(this);
    this.setMapId = this.setMapId.bind(this);
    this.loadMap = this.loadMap.bind(this);
    this.deleteMap = this.deleteMap.bind(this);
    this.showDeletePanel = this.showDeletePanel.bind(this);
  }

  componentDidMount() {
    //get all maps by user ID
    const userId = this.props.mapObj.userId;
    this.props.updateIsLoadingHistory('isLoadingHistory', true);
    if (this.props.mapHistoryData.length === 0) {
      this.loadHistory(userId);
    } else {
      this.setState({
        isLoading: false,
        isError: false
      });
      this.props.updateIsLoadingHistory('isLoadingHistory', false);
    }
  }

  async loadHistory(userId,searchTerm) {
    let url = process.env.REACT_APP_APIURL + "/maps/" + userId;
    if(searchTerm!==undefined)
      url+= "/" + searchTerm;

    axios.get(url, { headers: this.props.headers })
      .then((result) => {
        result.data.maps.sort((a, b) => (new Date(b.lastsave)).getTime() - (new Date(a.lastsave)).getTime());
        this.props.updateSidebarObj({ mapHistoryData: result.data.maps });
        this.setState({
          isLoading: false,
          isError: false
        });
        this.props.updateIsLoadingHistory('isLoadingHistory', false);
      }).catch((error) => {
        this.setState({
          isLoading: false,
          isError: true
        });
        this.props.updateIsLoadingHistory('isLoadingHistory', false);
      });
  }

  closePanel() {
    this.props.togglePanel('history');
  }

  setMapId(e) {
    this.setState({
      currentMapId: e.target.value,
      isLoadingError: false
    });
  }

  searchTerms(e){
    this.loadHistory(this.props.mapObj.userId,e.target.value);
  }

  loadMap() {
    const customDims = this.props.mapObj.country === 'US' ? [4, 4] : [3.937, 3.937];

    this.props.updateMapObj({
      isLoading: true
    });

    //this.props.updateMapHistoryData([]);

    axios.get(process.env.REACT_APP_APIURL + "/map?id=" + this.state.currentMapId + "&pixelbounds=" + this.props.mapObj.pixelBounds, { headers: this.props.headers })
      .then(async (result) => {
        this.props.updateMapObj({
          mapId: result.data.map.id,
          mapZoom: result.data.map.mapZoom,
          mapCenter: result.data.map.mapCenter,
          mapOptionsTitle: result.data.map.mapOptionsTitle,
          mapOptionsProject: result.data.map.mapOptionsProject,
          mapOptionsBasemap: result.data.map.mapOptionsBasemap,
          mapOptionsLayout: result.data.map.mapOptionsLayout,
          mapGroups: result.data.map.mapGroups,
          mapPoints: [], //remove any map points first to prevent error on map
          currentGroup: result.data.map.mapGroups[0].id,
          currentPoint: null,
          isStyling: false,
          isSaving: false,
          popupOpen: false,
          isEditingRing: false,
          geocodingSuccess: false,
          overlapDetected: false,
          legendOverlapDetected: false,
          checkOverlap: false,
          maskBounds: null,
          rings: result.data.map.rings || [],
          driveTimes: result.data.map.drivetimes || [],
          legend: result.data.map.legend,
          outputType: 'png',
          mapLoaded: true,
          shapes: result.data.map.shapes || [],
          labels: [], //we set the icons below
          shields: [], //we set the icons below
          customLayoutDimensions: result.data.map.customLayoutDimensions || customDims,
          retailPoints: [], //we set the sizes below
          selectedCategory: result.data.map.selectedCategory || [],
          selectedRetailer: result.data.map.selectedRetailer || [],
          logoSize: result.data.map.logoSize || 100,
          tooManyLogos: false,
          hasDemographics: false,
          demographicVariable: result.data.map.demographicVariable,
          demographicRangeSetting: result.data.map.demographicRangeSetting,
          demographicColors: result.data.map.demographicColors,
          demographicGeography: result.data.map.demographicGeography,
          demographicRanges: result.data.map.demographicRanges,
          demographicStrokeOpacity: result.data.map.demographicStrokeOpacity,
          demographicFillOpacity: result.data.map.demographicFillOpacity,
          demographics: result.data.map.demographics || [],
          demographicLegend: result.data.map.demographicLegend || null,
          scaleLocation: result.data.map.scaleLocation,
          gapiPolygonSettings: result.data.map.gapiPolygonSettings,
          gapiPointSettings: [result.data.map.gapiPointSettings],
          gapiPoints: [],
          gapiPointLabels: [],
          gapiPointCustomLabels: [],
          gapiPointHideLabels: [],
          gapiPointIcons: [],
          retailerBounds: null,
          demographicBounds: null,
          showShapeLegend: result.data.map.showShapeLegend,
          shapeLegend: result.data.map.shapeLegend,
          realGapiPoints: null,
          clearVTPolys: true,
          clearDemos: true,
          updateBasemap: true
        });

        let retailPtsWithSize = await this.getRetailPtsWithSize(result.data.map.retailPoints, result.data.map.logoSize);
        let newRetailerBounds = await this.getRetailBounds(retailPtsWithSize);
        let labelsWithIcons = await this.setLabelIcons(result.data.map.labels);
        let layerlabelsWithIcons = await this.setLabelIcons(result.data.map.layerlabels);
        let shieldsWithIcons = await this.setShieldIcons(result.data.map.shields);
        
        //add a loop if we add more layers......
        if(result.data.map.customLayers){
          this.props.updateMapObj({
            updateLayers: result.data.map.customLayers,
            gettingLayers: true,
            layerlabels: layerlabelsWithIcons,
          });
        }

        if(result.data.map.gapiPolygonLabels && result.data.map.gapiPolygonSettings?.displayLabels){
          this.props.updateMapObj({updateGapiLabels:result.data.map.gapiPolygonLabels});
        }
        //gapiPointBounds: result.data.map.gapiPointBounds
        if(result.data.map.gapiPointBounds){
          this.props.updateMapObj({
            addGapiPoints: result.data.map.gapiPointSettings,
            loadedCustomGapiLabels: result.data.map.gapiPointCustomLabels,
            gapiPointHideLabels: result.data.map.gapiPointHideLabels || []
          });
        }

        if(result.data.map.gapiPolygonSettings?.selectedGapiPolygon){
          //this.props.updateMapObj({updateGapiPolygonFeatureId:result.data.map.gapiPolygonSettings.selectedGapiPolygon});
          
          this.props.updateMapObj({getVTPolygonOverlays:true});
        }
        else{
          this.props.updateMapObj({gapiPolygonSettings:{
            displayInLegend: true,
            displayThem7: false,
            resultNames: [],
            displayNames: [],
            selectedGapiPolygon: false,
            selectedGapiPolygonLabel: '',
            gettingGapiPolygons: false,
            polygonStyle: {
              fill: '#0c9ed9',
              opacity: 0.5,
              stroke: '#ffffff',
              strokeOpacity: 1,
              width: 3
            },
            displayLabels: false,
            labelStyle: {
              mapAddLabelStroke: '#4a4a4d',
              mapAddLabelColor: '#ffffff',
              mapAddLabelFontSize: 12,
              mapAddLabelOpacity: 1,
              mapAddLabelFont: 'Open Sans',
              mapAddLabelWeight: true,
            }
          },
          gapiPolygons: [],
          gapiPolygonLabels: [],
          gapiPolygonBounds: null
          });
        }

        if(result.data.map.vtDemographicSettings){
          setTimeout(() => {
            this.props.updateMapObj(
              {vtDemographicSettings:result.data.map.vtDemographicSettings,
                getVTDemographics:true, updateBasemap: true, forceDemos: true
              });
          }, 2000);
        }


        let pointsWithIcons; //get and style points
        if (result.data.map.mapPoints && result.data.map.mapPoints.length > 0) {
          pointsWithIcons = this.props.setPointIcons(result.data.map.mapPoints, result.data.map.mapGroups);
          this.props.updateMapObj({
            retailPoints: retailPtsWithSize,
            retailerBounds: newRetailerBounds,
            labels: labelsWithIcons,
            shields: shieldsWithIcons,
            mapPoints: pointsWithIcons,
            isLoading: false
          });
        } else {
          this.props.updateMapObj({
            retailPoints: retailPtsWithSize,
            retailerBounds: newRetailerBounds,
            labels: labelsWithIcons,
            shields: shieldsWithIcons,
            mapPoints: [],
            isLoading: false
          });
        }
        if(result.data.map.shapeLegend){
          let currentShapeLayerId = null;
          if(result.data.map.shapeLegend.length > 0 ){
            currentShapeLayerId = result.data.map.shapeLegend[0].layerId || null;
          }
          if(currentShapeLayerId===null){
            currentShapeLayerId = GenerateRandomId();
          }
          this.props.updateMapObj({shapeLegend: result.data.map.shapeLegend, currentShapeLayer:currentShapeLayerId }); //, shapeLayers:shapeLayers});
        }
        else if(result.data.map.shapes){
          const shapeLegend = [];
          const g = Object.groupBy(result.data.map.shapes, ({ layerId }) =>  layerId);
          for (var prop in g) {
            shapeLegend.push({
              layerId: prop,
              name: "Custom Shapes"
            });
          }
          this.props.updateMapObj({shapeLegend:shapeLegend});
        }
        else {
          console.log('make a shape legend');
          const shapeLegend = [
            {
              layerId: GenerateRandomId(),
              name: "Custom Shapes",
              displayName: true
            }
          ];
          this.props.updateMapObj({shapeLegend:shapeLegend});
        }

        if(result.data.map.realGapiPoints!==undefined){
          this.props.updateMapObj({addRealGapiPoints:result.data.map.realGapiPoints[0]});
        }
        

        // shapeLegend: [
        //   {
        //     layerId: firstLayerIdNum,
        //     name: "Custom Shapes",
        //     displayName: true
        //   }
        // ],


        this.closePanel();
      }).catch((error) => {
        this.props.updateMapObj({
          isLoading: false
        });
        this.setState({
          isLoadingError: true
        });
        console.log(error);
      });
  }

  handleChangePage(e, newPage) {
    this.setState({
      page: newPage
    });
  }

  handleChangeRowsPerPage(e) {
    this.setState({
      page: 0,
      rowsPerPage: parseInt(e.target.value, 10)
    });
  }

  async setLabelIcons(labels) {
    if (!labels) {
      return [];
    }

    let labelsArrayClone = Array.from(labels);
    for (const lbl of labels) {
      const newIcon = this.props.getLabelIcon(lbl);
      lbl.content.icon = newIcon;
    }

    return labelsArrayClone;
  }

  async setShieldIcons(shields) {
    if (!shields) {
      return [];
    }

    let shieldsArrayClone = Array.from(shields);
    for (const shld of shields) {
      const newIcon = this.props.getShieldIcon(shld);
      shld.content.icon = newIcon;
    }

    return shieldsArrayClone;
  }

  async getRetailPtsWithSize(retailPts, logoSize) {
    if (!retailPts) {
      return [];
    }
    const newLogoSize = logoSize || 100;
    let retailPtsClone = retailPts ? (Array.isArray(retailPts) ? Array.from(retailPts) : []) : [];
    if (!Array.isArray(retailPts)) {
      this.props.updateMapObj({ geocodingSuccess: true, tooManyLogos: true });
    }
    for (const retPt of retailPtsClone) {
      retPt.fullcolorsvg = ResizeLogo(retPt.fullcolorsvg, newLogoSize);
    }
    //icon offset
    for (const point of retailPtsClone) {
      point.iconOffset = GetIconOffset(point.fullcolorsvg, 'logo');
    }

    return retailPtsClone;
  }

  async getRetailBounds(retailPts) {
    if(retailPts===null){
      return null;
    }
    const tempBounds = retailPts.reduce((acc,item) => {
      if(parseFloat(item.latitude)<acc.miny||acc.miny===undefined){
        acc.miny = parseFloat(item.latitude);
      }
      if(parseFloat(item.latitude)>acc.maxy||acc.maxy===undefined){
        acc.maxy = parseFloat(item.latitude);
      }
      if(parseFloat(item.longitude)<acc.minx||acc.minx===undefined){
        acc.minx = parseFloat(item.longitude);
      }
      if(parseFloat(item.longitude)>acc.maxx||acc.maxx===undefined){
        acc.maxx = parseFloat(item.longitude);
      }
      return acc;
    },{});

    return {
      _southWest:{
        lat: tempBounds.miny,
        lng: tempBounds.minx
      },
      _northEast:{
        lat: tempBounds.maxy,
        lng: tempBounds.maxx
      }
    };
  }

  async goToMapId(mapId) {
    await this.setState({
      currentMapId: mapId
    });

    this.loadMap();
  }

  deleteMap(mapId) {
    const customDims = this.props.mapObj.country === 'US' ? [4, 4] : [3.937, 3.937];
    
    this.props.updateIsLoadingHistory('isLoadingHistory', true);
    this.setState({
      isLoading: true,
      isDeleteError: false
    });
    axios.put(process.env.REACT_APP_APIURL + "/map/" + mapId + "/true", this.props.mapObj, { headers: this.props.headers })
      .then((result) => {
        if (this.props.mapObj.mapId !== mapId) {  //if not deleting current map
          this.loadHistory(this.props.mapObj.userId);
          this.setState({
            deleteMapId: null,
            showDelete: false
          });
        } else {
          const newIdNum = GenerateRandomId();

          this.props.updateMapObj({
            isLoading: true
          });

          this.props.updateSidebarObj({ mapHistoryData: [] });

          let defaultPointColor = '#0c9ed9';
          if (this.props.mapObj?.country === 'JPN') {
            defaultPointColor = '#25408f';
          }

          this.props.updateMapObj({
            mapId: null,
            mapZoom: InitialMapZoom(this.props.mapObj?.country),
            mapOptionsTitle: '',
            mapOptionsProject: '',
            mapOptionsBasemap: 'colliers-grey',
            mapOptionsLayout: 'landscape',
            mapGroups: [{
              id: newIdNum,
              order: 1,
              name: 'Layer 1',
              useGroupStyle: false,
              groupIconName: 'circleIcon',
              groupIconColor: defaultPointColor,
              groupTextColor: '#ffffff',
              groupIconSize: 'default',
              startNumber: 1,
              visible: true,
              nameInLegend: true,
              pointsInLegend: true,
              groupInLegend: true,
              icon: '',
              showLegendData: false,
              useCustomLabel: false
            }],
            mapPoints: [],
            currentGroup: newIdNum,
            currentPoint: null,
            isStyling: false,
            isSaving: false,
            popupOpen: false,
            isEditingRing: false,
            geocodingSuccess: false,
            overlapDetected: false,
            legendOverlapDetected: false,
            checkOverlap: false,
            maskBounds: null,
            rings: [],
            driveTimes: [],
            legend: [{
              height: 42,
              width: 85,
              visible: false,
              scale: 1,
              columns: 1,
              position: ['right', 'top'],
              northArrowVisible: true,
              northArrowPosition: ['left', 'bottom']
            }],
            outputType: 'png',
            mapLoaded: true,
            shapes: [],
            labels: [],
            shields: [],
            customLayoutDimensions: customDims,
            retailPoints: [],
            selectedCategory: [],
            selectedRetailer: [],
            logoSize: 100,
            tooManyLogos: false,
            retailerBounds: null,
            demographicBounds: null
          });

          setTimeout(() => {
            this.props.updateMapObj({
              isLoading: false
            });
          }, 1000);

          this.props.updateIsLoadingHistory('isLoadingHistory', false);
          this.closePanel();
        }
      }).catch((error) => {
        console.log(error);
        this.props.updateIsLoadingHistory('isLoadingHistory', false);
        this.setState({
          isDeleteError: true,
          isLoading: false,
          deleteMapId: null
        });
      });
  }

  showDeletePanel(bool, mapId) {
    this.setState({
      showDelete: bool,
      deleteMapId: mapId,
      isDeleteError: false,
      isLoadingError: false
    });
  }

  sortColumns(column) {
    let historyDataArrayClone = Array.from(this.props.mapHistoryData);
    let updateAttribute = 'historyProjectSort';
    let updateValue = 'ascending';
    if (column === 'lastsave') {
      updateAttribute = 'historySaveSort';
    } else if (column === 'id') {
      updateAttribute = 'historyIdSort';
    }
    if (this.props[updateAttribute] === 'ascending') {
      historyDataArrayClone.sort((a, b) => (a[column] > b[column]) ? 1 : ((b[column] > a[column]) ? -1 : 0));
      updateValue = 'descending';
    } else {
      historyDataArrayClone.sort((a, b) => (a[column] < b[column]) ? 1 : ((b[column] < a[column]) ? -1 : 0));
      updateValue = 'ascending';
    }
    this.props.updateSidebarObj({ mapHistoryData: historyDataArrayClone, [updateAttribute]: updateValue });
  }

  formatDate(date) {
    const dateObj = new Date(date);
    const formattedDate = new Intl.DateTimeFormat('en-US').format(dateObj);
    return formattedDate;
  }

  render() {
    const emptyRows = this.state.rowsPerPage - Math.min(this.state.rowsPerPage, this.props.mapHistoryData.length - this.state.page * this.state.rowsPerPage);
    return (
      <div className="sidePanelStyle">
        <h2 className="panel-title">
          <span className="panel-icon-span">
            <svg id="historyIconSmall" xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 94.5 82.46">
              <line x1="13.31" y1="31.95" x2="33.29" y2="31.95" fill="none" stroke='#0c9ed9' strokeMiterlimit="10" strokeWidth="4" />
              <line x1="13.31" y1="45.11" x2="33.29" y2="45.11" fill="none" stroke='#0c9ed9' strokeMiterlimit="10" strokeWidth="4" />
              <line x1="13.31" y1="58.27" x2="33.29" y2="58.27" fill="none" stroke='#0c9ed9' strokeMiterlimit="10" strokeWidth="4" />
              <path d="M8.29,49.86V-15.36c0-4.05-9.41-7.33-22-7.33s-22,3.28-22,7.33V49.86a126.06,126.06,0,0,1,22-2C-5.39,47.86,4.29,48.59,8.29,49.86Z" transform="translate(36.96 31.35)" fill="none" stroke='#0c9ed9' strokeLinejoin="round" strokeWidth="4" />
              <path d="M56.29,36V-15.36c0-2.77-4.94-5.19-12-6.43" transform="translate(36.96 31.35)" fill="none" stroke='#0c9ed9' strokeLinejoin="round" strokeWidth="4" />
              <path d="M20.29-21.79c-7.33,1.22-12,3.59-12,6.43V49.86c12-1.27,15.68-2,24-2a149.82,149.82,0,0,1,24,2" transform="translate(36.96 31.35)" fill="none" stroke='#0c9ed9' strokeLinejoin="round" strokeWidth="4" />
              <path d="M41.19,15.58l-7.4-6.86a1.62,1.62,0,0,0-2.18,0l-8,6.93c-1,1-3.27.17-3.27-1.31V-30.1h24V14.29A1.91,1.91,0,0,1,41.19,15.58Z" transform="translate(36.96 31.35)" fill="none" stroke='#0c9ed9' strokeMiterlimit="10" strokeWidth="4" />
            </svg>
          </span>
          {translateObj.mapHistoryTitle[this.props.translate]}
          <span className="panel-close closeStyle"><CloseIcon onClick={this.closePanel} fontSize="small" /></span>
        </h2>
        <div className="panel-body">
          {this.state.isLoading ?
            <div className="panel-loader"></div>
            :
            <div>
              {!this.state.showDelete ?
                <div>
                  {this.state.isError ?
                    <div className="errorText">{translateObj.errorLoadingMapHistory[this.props.translate]}</div>
                    :
                    <div>
                      <TableContainer className="history-table">
                        <Table size="small" aria-label="map history table" style={{ cursor: 'default' }}>
                          <TableHead>
                            <TableRow>
                              <TableCell onClick={this.sortColumns.bind(this, 'mapOptionsProject')} style={{ paddingLeft: '10px', paddingRight: '16px' }}>
                                <SortTableHeader text={'Project'} />
                              </TableCell>
                              <TableCell onClick={this.sortColumns.bind(this, 'lastsave')} align="center" style={{ paddingLeft: '13px', paddingRight: '13px', whiteSpace: 'nowrap', overflow: 'hidden'}}>
                                <SortTableHeader text={'Last Saved'} />
                              </TableCell>
                              <TableCell onClick={this.sortColumns.bind(this, 'id')} align="left" colSpan={2} style={{ paddingLeft: '10px', paddingRight: '16px' }}>
                                <SortTableHeader text={'Map Id'} />
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {(this.state.rowsPerPage > 0
                              ? this.props.mapHistoryData.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                              : this.props.mapHistoryData
                            ).map((row) => (
                              <TableRow key={row.id} style={{ cursor: 'pointer' }}>
                                <TableCell component="th" scope="row" onClick={this.goToMapId.bind(this, row.id)} style={{ maxWidth: '100px', overflow: 'hidden', paddingLeft: '10px', paddingRight: '16px'}} title={row.mapOptionsProject}>
                                    {row.mapOptionsProject}
                                </TableCell>
                                <TableCell align="center" onClick={this.goToMapId.bind(this, row.id)} style={{ paddingLeft: '10px', paddingRight: '16px' }}>
                                  {this.formatDate(row.lastsave)}
                                </TableCell>
                                <TableCell align="right" onClick={this.goToMapId.bind(this, row.id)} style={{ paddingLeft: '10px', paddingRight: '16px' }}>
                                  {row.id}
                                </TableCell>
                                <TableCell align="right" onClick={this.showDeletePanel.bind(this, true, row.id)} style={{ paddingLeft: '10px', paddingRight: '16px' }}>
                                  <DeleteIcon style={{ fontSize: "16px" }} />
                                </TableCell>
                              </TableRow>
                            ))}

                            {emptyRows > 0 && (
                              <TableRow style={{ height: 33 * emptyRows }}>
                                <TableCell colSpan={3} />
                              </TableRow>
                            )}
                          </TableBody>
                          <TableFooter>
                            <TableRow>
                              <TablePagination
                                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                colSpan={4}
                                count={this.props.mapHistoryData.length}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.page}
                                SelectProps={{
                                  inputProps: { 'aria-label': 'rows per page' },
                                  native: true,
                                }}
                                onPageChange={(e, newPage) => { this.handleChangePage(e, newPage) }}
                                //onChangeRowsPerPage
                                onRowsPerPageChange={(e) => { this.handleChangeRowsPerPage(e) }}
                                style={{ border: 'none' }}
                              />
                            </TableRow>
                          </TableFooter>
                        </Table>
                      </TableContainer>
                    </div>
                  }
                  <div className="padding-t20">
                    <div className="padding-6b">
                      <TextField
                        id="mapIdInput"
                        label={translateObj.enterMapId[this.props.translate]}
                        variant="outlined"
                        size="small"
                        onChange={e => this.setMapId(e)}
                        fullWidth
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            this.loadMap()
                          }
                        }}
                      />
                    </div>
                    <div className="padding-6b">
                      <TextField
                        id="searchInput"
                        label={translateObj.enterSearchTerm[this.props.translate]}
                        variant="outlined"
                        size="small"
                        //onChange={e => this.setMapId(e)}
                        fullWidth
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            this.searchTerms(e)
                          }
                        }}
                      />
                    </div>
                    {this.state.isLoadingError ?
                      <div className="errorText">
                        {translateObj.errorLoadingMap[this.props.translate]} {this.state.currentMapId}.
                      </div>
                      : null
                    }
                    <div className="geocodeDiv flex">
                      <button className="geocodeBtn" onClick={this.closePanel}>{translateObj.backBtn[this.props.translate]}</button>
                      <button className="geocodeBtn" onClick={this.loadMap}>{translateObj.loadMapBtn[this.props.translate]}</button>
                    </div>
                  </div>
                </div>
                :
                <DeleteMapComponent
                  showDeletePanel={this.showDeletePanel}
                  deleteMap={this.deleteMap}
                  deleteMapId={this.state.deleteMapId}
                  isDeleteError={this.state.isDeleteError}
                />
              }
            </div>
          }
        </div>
      </div>
    );
  }
}

export default HistoryPanel;