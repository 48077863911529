import React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { Droppable } from 'react-beautiful-dnd';
import { Draggable } from 'react-beautiful-dnd';
import CloseIcon from '@material-ui/icons/Close';
import StyleGroups from '../StyleGroups/StyleGroups';
import DeleteGroup from './DeleteGroup/DeleteGroup';
import GroupComponent from './GroupComponent/GroupComponent';
import LegendPrintView from './LegendPrintView';
import DemographicsFilter from '../AddDataPanel/DemographicsFilter';
import LegendOptions from './LegendOptions';
import LegendPointRow from './LegendPointRow';
import EditPointAttributes from './EditPointAttributes';
import GroupRings from './GroupRings';
import EditRings from '../StylePoints/EditRings';
import StylePoints from '../StylePoints/StylePoints';
import StylePointLabels from  '../StylePoints/StylePointLabels';
import { GetUpdatedZIndex } from '../../../utils/tools';
import SVGIconComponent from '../../SVGIconComponent/SVGIconComponent';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteCustomLayer from './DeleteCustomLayer';
import PaletteIcon from '@material-ui/icons/Palette';
import TrafficCountsFilter from '../AddDataPanel/TrafficCountsFilter';
import TypeAddress from '../AddPtsPanel/TypeAddress/TypeAddress';
import GapiPolygonPanel from '../AddDataPanel/GapiPolygonPanel';
import translateObj from '../../../utils/translate';
import ShapeLegendPanel from './ShapeLegendPanel';
import EditShapeAttributes from './EditShapeAttributes';
import DeleteShapes from './DeleteShapes';
import AddGapiPointsPanel from '../AddDataPanel/AddGapiPointsPanel';

const textDiv = {
  paddingTop: '5px',
  paddingBottom: '10px'
}

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

class LegendPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      groupStyleShow: false,
      groupDeleteShow: false,
      pointAttributeShow: false,
      groupRingsShow: false,
      groupVerifyShow: false,
      legendView: 'mapView',
      viewButtonText: 'Print View',
      isEditingRings: false,
      isStylingPoints: false,
      isLabeling: false,
      groupLabelShow: false,
      customLayerDeleteShow: false,
      deleteLayer: null,
      showShapeLegend: false,
      shapeEditing: false,
      editShapeShow: false,
      editRealGapiPointShow: false
    };
    this.closePanel = this.closePanel.bind(this);
    this.closeOtherPointPanel = this.closeOtherPointPanel.bind(this);
    this.closeOtherPolyPanel = this.closeOtherPolyPanel.bind(this);
    this.toggleGroupPanel = this.toggleGroupPanel.bind(this);
    this.toggleGroupLabelPanel = this.toggleGroupLabelPanel.bind(this);
    this.toggleDeleteGroup = this.toggleDeleteGroup.bind(this);
    this.togglePointAttribute = this.togglePointAttribute.bind(this);
    this.toggleGroupRingsPanel = this.toggleGroupRingsPanel.bind(this);
    this.updateLegendObj = this.updateLegendObj.bind(this);
    this.toggleLabelPanel = this.toggleLabelPanel.bind(this);
    this.toggleDeleteCustomLayer = this.toggleDeleteCustomLayer.bind(this);
    this.toggleVerifyPoints = this.toggleVerifyPoints.bind(this);
    this.deleteGapiPolygon = this.deleteGapiPolygon.bind(this);
    this.editGapiPolygon = this.editGapiPolygon.bind(this);
    this.closeGapiPolygon = this.closeGapiPolygon.bind(this);
    this.toggleGapiPolygonVisibility = this.toggleGapiPolygonVisibility.bind(this);
    this.editDemographics = this.editDemographics.bind(this);
    this.toggleDemographicsFilter = this.toggleDemographicsFilter.bind(this);
    this.toggleEditShape = this.toggleEditShape.bind(this);
    this.toggleDeleteShapes = this.toggleDeleteShapes.bind(this);
    this.toggleLegendPanel = this.toggleLegendPanel.bind(this);
  }

  componentDidMount() {
    this.setState({showShapeLegend:this.props.mapObj.showShapeLegend || false});
  }

  componentDidUpdate() {
    if (this.props.popupOpen) {
      if (this.state.groupDeleteShow === true) {
        this.setState({
          groupDeleteShow: false
        });
      }
    } 
  }


  toggleLegendPanel() {
    this.setState({editRealGapiPointShow: false});
  }

  closePanel() {
    this.props.togglePanel('legend');
  }

  updateLegendObj(updateObj) {
    this.setState(updateObj);
  }

  onDragEnd = result => {
    const { destination, source } = result;

    const groups = this.props.groups;
    
    if (!destination) {
      return;
    }

    if (result.type === 'droppableGroup') {
      const groupsArrayClone = Array.from(this.props.groups);
      const sourceClone = reorder(groupsArrayClone, result.source.index, result.destination.index);

      sourceClone.forEach((group, index) => {
        group.order = index + 1;
      });

      const newPoints = GetUpdatedZIndex(sourceClone, this.props.points);

      this.props.updateMapObj({ mapGroups: sourceClone, points: newPoints });
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    if (destination.droppableId === source.droppableId) {
      const sourceId = source.droppableId;
      const sourceArray = this.props.points.filter(point => point.content.group === sourceId);
      const leftoverArray = this.props.points.filter(point => point.content.group !== sourceId);
      const leftoverClone = Array.from(leftoverArray);
      const sourceClone = reorder(sourceArray, result.source.index, result.destination.index);

      const orderedArray = this.props.updateOrderAndIcon(groups, sourceId, sourceClone);
      const newPointsArray = orderedArray.concat(leftoverClone);
      this.props.updateMapObj({ mapPoints: newPointsArray });
    } else {
      const sourceId = source.droppableId;
      const sourceArray = this.props.points.filter(point => point.content.group === sourceId);

      const destinationId = destination.droppableId;
      const destinationArray = this.props.points.filter(point => point.content.group === destinationId);

      const leftoverArray = this.props.points.filter(point => point.content.group !== sourceId && point.content.group !== destinationId);
      const leftoverClone = Array.from(leftoverArray);
      const sourceClone = Array.from(sourceArray);
      const destClone = Array.from(destinationArray);

      const [removed] = sourceClone.splice(source.index, 1);
      removed.content.group = destinationId;
      destClone.splice(destination.index, 0, removed);

      const orderedDestArray = this.props.updateOrderAndIcon(groups, destinationId, destClone);
      const orderedSourceArray = this.props.updateOrderAndIcon(groups, sourceId, sourceClone);

      const newPointsArray = orderedSourceArray.concat(orderedDestArray, leftoverClone);

      this.props.updateMapObj({ mapPoints: newPointsArray });
    }
  }

  deleteCustomLayer(layer) {
    this.setState({customLayerDeleteShow: true,
      deleteLayer: layer
    });
  }

  deleteGapiPolygon(){
    this.props.updateMapObj(
      {gapiPolygons: [],
       gapiPolygonLabels: [],
       gapiPolygonSettings:{
        resultNames: [],
        displayNames: [],
        selectedGapiPolygon: false,
        selectedGapiPolygonLabel: '',
        gettingGapiPolygons: false,
        polygonStyle: {
          fill: '#0c9ed9',
          opacity: 0.5,
          stroke: '#ffffff',
          strokeOpacity: 1,
          width: 3
        },
        displayLabels: false,
        labelStyle: {
          mapAddLabelStroke: '#4a4a4d',
          mapAddLabelColor: '#ffffff',
          mapAddLabelFontSize: 12,
          mapAddLabelOpacity: 1,
          mapAddLabelFont: 'Open Sans',
          mapAddLabelWeight: true,
        }
      }
      });
  }

  toggleGapiPolygonVisibility(){
    let settings =this.props.gapiPolygonSettings;
    const displayInLegend = settings.displayInLegend;
    settings.displayInLegend = !displayInLegend;
    this.props.updateMapObj({gapiPolygonSettings: settings});
  }

  editGapiPolygon(){
    this.setState({gapiPolygonShow: true});
  }

  closeGapiPolygon() {
    this.setState({gapiPolygonShow: false});
  }
  ///// this should be edit custom layer  and edit gapi point  some day.....
  editCustomLayer(layer) {
    if(layer==='traffic-counts'){
      this.setState({otherPointShow: true});
    }
    else{
      this.setState({
        selectedLayer: {
          label:'Submarkets',
          name:'submarkets',
          url:'https://atlas.colliers.com/server/rest/services/Hosted/_ColliersSubmarkets/FeatureServer/1',
          type:'polygon',
  
          filters: [
            {order: 0, value:'type',label:"Type",type:"select",values:['','Office','Industrial','Retail'],selectedValue:''},
            {order: 1, value:'market',label:"Market",type:"select",values:[], valuesFromTable: true, selectedValue:''},
            {order: 2, value:'name',label:"Name",type:"select",values:[], valuesFromTable: true, selectedValue:''}
          ],
          labelColumn: 'name',
          minZoom: 6
        },
        otherPolyShow: true
      });
      // this.setState({
      //   selectedLayer: {
      //     label:'Submarkets',
      //     name:'submarkets',
      //     url:'https://atlas.colliers.com/server/rest/services/Hosted/_ColliersSubmarkets/FeatureServer/1',
      //     type:'polygon',
      //     filter:{value:'type',label:"Type",type:"select",values:['Office','Industrial','Retail']},
      //     labelColumn: 'name',
      //     minZoom: 10
      //   },
      //   otherPolyShow: true});
    }
  }

  closeOtherPointPanel() {
    this.setState({otherPointShow: false});
  }

  closeOtherPolyPanel() {
    this.setState({otherPolyShow: false});
  }

  toggleDeleteCustomLayer() {
    this.setState({customLayerDeleteShow: false,
      deleteLayer: null
    });
  }

  toggleDeleteShapes() {
    this.setState({shapeDeleteShow: false,shapeLayerIndex:null,shapeIndex:null});
  }

  ///// this should be custom layer visibility and gapi point visiblity some day.....
  toggleCustomLayerVisibility(layer) {
    if(layer==='traffic-counts'){
      const gapiPointSettings = this.props.mapObj.gapiPointSettings[0];
      let status = gapiPointSettings.showInLegend;
      console.log(status);
      gapiPointSettings.showInLegend = !status;
      this.props.updateMapObj({ gapiPointSettings: [gapiPointSettings] });
    }
    else {
      const cls = this.props.customLayers
      const clix = cls.findIndex(cl=>cl.name=layer);
      let status = cls[clix].showInLegend;
      cls[clix].showInLegend = !status;
      this.props.updateMapObj({ customLayers: cls });
    }
  }

  toggleRealGapiPointVisibility(ix) {
    console.log(ix);
    const gapiPointSettings = this.props.mapObj.realGapiPoints;
    //gapiPointSettings[ix].showInLegend
    gapiPointSettings[ix].showInLegend = !gapiPointSettings[ix].showInLegend;
    this.props.updateMapObj({ realGapiPoints: gapiPointSettings });
  }

  toggleGroupLabelPanel(state, groupId) {
    this.setState({
      groupLabelShow: state,
    });
    this.props.updateMapObj({ currentGroup: groupId });
  }

  toggleLabelPanel(state) {
    this.setState({
      isLabeling: state,
    });
  }

  toggleGroupPanel(state, groupId) {
    this.setState({
      groupStyleShow: state,
    });
    this.props.updateMapObj({ currentGroup: groupId });
  }

  toggleDeleteGroup(bool, groupId) {
    if (this.props.popupOpen) {

    } else {
      this.setState({
        groupDeleteShow: bool
      });
      if (groupId !== 'none')
        this.props.updateMapObj({ currentGroup: groupId });
    }
  }

  togglePointAttribute(bool, point) {
    this.setState({
      pointAttributeShow: bool
    });

    if (bool === true) {
      this.props.updateMapObj({ currentPoint: point.content });
    }
  }

  toggleGroupRingsPanel(bool, groupId) {
    this.setState({
      groupRingsShow: bool
    });
    this.props.updateMapObj({ currentGroup: groupId });
  }

  toggleEditShape(bool){
    this.setState({
      editShapeShow: bool
    });
  }

  toggleVerifyPoints(bool, groupId) {
    this.setState({
      groupVerifyShow: bool
    });
    
    if(groupId){
      this.props.updateMapObj({ currentGroup: groupId });
    }
  }

  editRealGapiPoints(ix) {
    this.setState({editRealGapiPointShow: true})
  }

  deleteRealGapiPoints(ix){
    const rgp = this.props.mapObj.realGapiPoints;
    rgp.splice(ix,1);
    this.props.updateMapObj({realGapiPoints: rgp});
  }

  editDemographics() {
    this.setState({demographicsShow: true});
  }

  toggleDemographicsFilter() {
    this.setState({demographicsShow: false});
  }

  deleteDemographics() {
    var upObj = {demoPanelRedraw: false, updateBasemap: true, forceDemos:false, clearDemos: true ,demographicLegend: null, demographicBounds: null}
    if(this.props.mapObj.mapPoints.length===0){
        const legendClone = Array.from(this.props.mapObj.legend);
        legendClone[0].visible = false;
        upObj.legend = legendClone;
    }
    this.props.updateMapObj(upObj);
  }

  render() {
    const customLayers = this.props.customLayers;
    const gapiPointLength = this.props.mapObj.gapiPoints.length;

    const gapiPointSettings = this.props.mapObj.gapiPointSettings || null;
    //const clPolys = customLayers.filter(cl => cl.type === 'polygon');
    const clPoints = customLayers.filter(cl => cl.type === 'point');

    const renderGroupPoints = (g) => {
      const filteredPoints = this.props.points.filter(point => point.content.group === g.id);

      return filteredPoints.map((point, i) => (
        <Draggable
          draggableId={point.content.id}
          index={i}
          key={point.content.group + "_" + point.content.order + "_" + point.position.lat + "_" + point.content.pointColor + "_" + point.content.id}
        >
          {(provided, snapshot) => (
            <LegendPointRow
              provided={provided}
              snapshot={snapshot}
              point={point}
              points={this.props.points}
              groups={this.props.groups}
              deletePoint={this.props.deletePoint}
              togglePointAttribute={this.togglePointAttribute}
              updateMapObj={this.props.updateMapObj}
              updateLegendObj={this.updateLegendObj}
              translate={this.props.translate}
            />
          )}
        </Draggable>
      ));
    }

    return (
      <div className="sidePanelStyle">
        {!this.state.demographicsShow && !this.state.groupVerifyShow && !this.state.otherPointShow && !this.state.otherPolyShow 
        && !this.state.groupLabelShow && !this.state.groupStyleShow && !this.state.groupDeleteShow && !this.state.pointAttributeShow 
        && !this.state.groupRingsShow && !this.state.isEditingRings && !this.state.isStylingPoints && !this.state.isLabeling 
        && !this.state.deleteLayer &&! this.state.gapiPolygonShow && !this.state.editShapeShow && !this.state.shapeDeleteShow
        && !this.state.editRealGapiPointShow
          ? <div>
            <h2 className="panel-title">
              <span className="panel-icon-span">
                <svg id="legendIconSmall" xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 94.5 94.5">
                  <path id="Path_4151" d="M55.9-26.44a9.46,9.46,0,0,0-9.46-9.46H-26.64a9.45,9.45,0,0,0-9.46,9.46V46.64a9.46,9.46,0,0,0,9.46,9.46H46.44a9.47,9.47,0,0,0,9.46-9.46h0V.15" transform="translate(37.35 37.15)" fill="none" stroke="#0c9ed9" strokeWidth="4" />
                  <path id="Path_4152" d="M36-11.9h-28" transform="translate(37.35 37.15)" fill="none" stroke="#0c9ed9" strokeWidth="4" />
                  <path id="Path_4153" d="M36,12.1h-28" transform="translate(37.35 37.15)" fill="none" stroke="#0c9ed9" strokeWidth="4" />
                  <path id="Path_4154" d="M36,36.1h-28" transform="translate(37.35 37.15)" fill="none" stroke="#0c9ed9" strokeWidth="4" />
                  <path id="Path_4157" d="M-14.11,40.07a6,6,0,0,0,6-6,6,6,0,0,0-6-6,6,6,0,0,0-5.95,5.95,6,6,0,0,0,5.95,6Z" transform="translate(37.35 37.15)" fill="none" stroke="#0c9ed9" strokeWidth="4" />
                  <path id="Path_4158" d="M-19.81-12.55l3.7,4.62,8-10" transform="translate(37.35 37.15)" fill="none" stroke="#0c9ed9" strokeWidth="4" />
                  <path id="Path_4159" d="M-19.81,10.45l3.7,4.62,8-10" transform="translate(37.35 37.15)" fill="none" stroke="#0c9ed9" strokeWidth="4" />
                </svg>
              </span>
              <span>{translateObj.formatLegendTitle[this.props.translate]}</span>
              <span className="panel-close closeStyle"><CloseIcon onClick={this.closePanel} fontSize="small" /></span>
            </h2>
            <div className="panel-body">
              <LegendOptions
                updateLegendObj={this.updateLegendObj}
                viewButtonText={this.state.viewButtonText}
                legendView={this.state.legendView}
                updateMapObj={this.props.updateMapObj}
                legend={this.props.legend}
                scaleLocation={this.props.scaleLocation}
                translate={this.props.translate}
              />
              {this.state.legendView === 'mapView' ?
                <div style={textDiv}>
                  <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="droppable" type="droppableGroup">
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                        >
                          {this.props.groups.map((g, i) => (
                            <Draggable key={g.id} draggableId={g.id.toString()} index={i}>
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <Droppable
                                    droppableId={g.id.toString()}
                                    index={i}
                                    key={g.id + "_" + i}
                                  >
                                    {provided => (
                                      <GroupComponent
                                        provided={provided}
                                        g={g}
                                        groups={this.props.groups}
                                        points={this.props.points}
                                        updateOrderAndIcon={this.props.updateOrderAndIcon}
                                        updateMapObj={this.props.updateMapObj}
                                        toggleGroupPanel={this.toggleGroupPanel}
                                        toggleGroupLabelPanel={this.toggleGroupLabelPanel}
                                        toggleDeleteGroup={this.toggleDeleteGroup}
                                        renderGroupPoints={renderGroupPoints}
                                        toggleGroupRingsPanel={this.toggleGroupRingsPanel}
                                        toggleVerifyPoints={this.toggleVerifyPoints}
                                        translate={this.props.translate}
                                      />
                                    )}
                                  </Droppable>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                {gapiPointLength > 0 ?
                  <div className="legendView-customlayer">
                  <SVGIconComponent
                    color={gapiPointSettings[0].pointStyle.pointColor}
                    name={gapiPointSettings[0].pointStyle.pointIconName}
                    size={gapiPointSettings[0].pointStyle.pointIconSize}
                  />
                  <span className={"legendView-customlayer-name"}>{"Traffic Counts"}</span>
                  <div style={{ marginLeft: 'auto'}} onClick={() => this.editCustomLayer('traffic-counts')}>
                    <Tooltip title="Edit layer style" placement="top">
                      <PaletteIcon style={{ fontSize: 20, cursor: 'pointer' }} />
                    </Tooltip>
                  </div>
                  <Tooltip title="Delete layer" placement="top">
                  <div onClick={this.deleteCustomLayer.bind(this, 'traffic-counts')}>
                    <DeleteIcon style={{ fontSize: 20, cursor: 'pointer' }} />
                  </div>
                  </Tooltip>
                  <Tooltip title="Toggle Legend Visibility" placement="top">
                    <div onClick={this.toggleCustomLayerVisibility.bind(this, 'traffic-counts')}>
                      {this.props.mapObj.gapiPointSettings[0].showInLegend ?
                        <VisibilityIcon  style={{ fontSize: 20, cursor: 'pointer' }}/>
                        :
                        <VisibilityOffIcon  style={{ fontSize: 20, cursor: 'pointer' }}/>
                      }
                    </div>
                  </Tooltip>
                  </div>
                  : 
                  null }
                {this.props.mapObj.realGapiPoints?.length > 0 ?
                <div>
                {this.props.mapObj.realGapiPoints.map((rgp,ix) =>
                <div key={'rgp_' + ix} className="legendView-customlayer">
                  {rgp.useLogo ? null :
                  <SVGIconComponent
                    color={rgp.pointStyle.pointColor}
                    name={rgp.pointStyle.pointIconName}
                    size={rgp.pointStyle.pointIconSize}
                  />
                  }
                <span className={"legendView-customlayer-name"}>{rgp.layerTitle}</span>
                <div style={{ marginLeft: 'auto'}} onClick={() => this.editRealGapiPoints(ix)}>
                    <Tooltip title="Edit layer style" placement="top">
                      <PaletteIcon style={{ fontSize: 20, cursor: 'pointer' }} />
                    </Tooltip>
                  </div>
                <Tooltip title="Delete layer" placement="top">
                  <div onClick={this.deleteRealGapiPoints.bind(this, ix)}>
                    <DeleteIcon style={{ fontSize: 20, cursor: 'pointer' }} />
                  </div>
                </Tooltip>
                <Tooltip title="Toggle Legend Visibility" placement="top">
                    <div onClick={this.toggleRealGapiPointVisibility.bind(this, ix)}>
                      { rgp.showInLegend ?
                      <VisibilityIcon style={{ fontSize: 20, cursor: 'pointer' }}/>
                      :
                      <VisibilityOffIcon style={{ fontSize: 20, cursor: 'pointer' }}/>
                      }
                    </div>
                  </Tooltip>
                </div>
                )}
                </div>
                : null}
                {customLayers.length > 0 ? 
                  <div sytle="legendListDiv">
                  <div className="legendView-title">Custom Layers</div>
                  {clPoints.map((cl, i) =>
                     <div className="legendView-customlayer">
                      <SVGIconComponent
                        color={cl.pointStyle.pointColor}
                        name={cl.pointStyle.pointIconName}
                        size={cl.pointStyle.pointIconSize}
                      />
                      <span className={"legendView-customlayer-name"}>{cl.label}</span>
                      <div style={{ marginLeft: 'auto'}} onClick={() => this.editCustomLayer(cl.name)}>
                        <Tooltip title="Edit layer style" placement="top">
                          <PaletteIcon style={{ fontSize: 20, cursor: 'pointer' }} />
                        </Tooltip>
                      </div>
                      <Tooltip title="Delete layer" placement="top">
                      <div onClick={this.deleteCustomLayer.bind(this, cl.name)}>
                        <DeleteIcon style={{ fontSize: 20, cursor: 'pointer' }} />
                      </div>
                      </Tooltip>
                      <Tooltip title="Toggle Legend Visibility" placement="top">
                        <div onClick={this.toggleCustomLayerVisibility.bind(this, cl.name)}>
                          {cl.showInLegend ?
                          <VisibilityIcon  style={{ fontSize: 20, cursor: 'pointer' }}/> 
                          :
                          <VisibilityOffIcon  style={{ fontSize: 20, cursor: 'pointer' }}/> 
                          }
                        </div>
                      </Tooltip>
                      </div>
                  )}
                </div>
                : null }
                {this.props.gapiPolygonSettings.selectedGapiPolygon ?
                  <div className="legendView-customlayer">
                  <svg height="30px" viewBox="0 0 20 20">
                  <rect x={1} y={1} width={16} height={16} fill={this.props.gapiPolygonSettings.polygonStyle.fill}
                  strokeWidth={2} stroke={this.props.gapiPolygonSettings.polygonStyle.stroke} />
                  </svg>
                  <span className={"legendView-customlayer-name"}>{this.props.gapiPolygonSettings.selectedGapiPolygonLabel}</span>
                  <div style={{ marginLeft: 'auto'}} onClick={() => this.editGapiPolygon('HI')}>
                    <Tooltip title="Edit layer style" placement="top">
                      <PaletteIcon style={{ fontSize: 20, cursor: 'pointer' }} />
                    </Tooltip>
                  </div>
                  <Tooltip title="Delete layer" placement="top">
                  <div onClick={this.deleteGapiPolygon.bind(this, 'HI')}>
                    <DeleteIcon style={{ fontSize: 20, cursor: 'pointer' }} />
                  </div>
                  </Tooltip>
                  <Tooltip title="Toggle Legend Visibility" placement="top">
                    <div onClick={this.toggleGapiPolygonVisibility.bind(this, 'HI')}>
                      { this.props.gapiPolygonSettings.displayInLegend ?
                      <VisibilityIcon style={{ fontSize: 20, cursor: 'pointer' }}/>
                      :
                      <VisibilityOffIcon style={{ fontSize: 20, cursor: 'pointer' }}/>
                      }
                    </div>
                  </Tooltip>
                  </div>
                : null }
                {this.props.mapObj.shapes.length > 0 ?
                <ShapeLegendPanel
                  showShapeLegend={this.props.mapObj.showShapeLegend}
                  updateMapObj={this.props.updateMapObj}
                  shapeLegend={this.props.mapObj.shapeLegend}
                  legend={this.props.mapObj.legend}
                  shapeEditing={this.state.shapeEditing}
                  shapes={this.props.mapObj.shapes}
                  currentShapeLayer={this.props.mapObj.currentShapeLayer}
                  updateLegendObj={this.updateLegendObj}
                />
                : null }
                {this.props.demographicLegend ?
                <div className="legendView-customlayer">
                  <div>Demographics</div>

                  <div style={{ marginLeft: 'auto'}} onClick={() => this.editDemographics()}>
                      <Tooltip title="Edit demographics" placement="top">
                        <PaletteIcon style={{ fontSize: 20, cursor: 'pointer' }} />
                      </Tooltip>
                    </div>
                    <div onClick={() => this.deleteDemographics()}>
                      <Tooltip title="Delete demogrpahics" placement="top">
                      <DeleteIcon style={{ fontSize: 20, cursor: 'pointer' }} />
                      </Tooltip>
                    </div>
                </div>
                : null}
                </div>
                :
                <LegendPrintView
                  groups={this.props.groups}
                  points={this.props.points}
                  rings={this.props.mapObj.rings}
                  realGapiPoints={this.props.mapObj.realGapiPoints}                  
                  driveTimes={this.props.mapObj.driveTimes}
                  isSaving={this.props.isSaving}
                  legend={this.props.legend}
                  updateMapObj={this.props.updateMapObj}
                  demographicLegend={this.props.demographicLegend}
                  customLayers={this.props.customLayers}
                  demographicLength={this.props.mapObj.demographics.length}
                  demographicFillOpacity={this.props.mapObj.demographicFillOpacity}
                  demographicStrokeOpacity={this.props.mapObj.demographicStrokeOpacity}
                  mapZoom={this.props.mapObj.mapZoom}
                  gapiPolygonSettings={this.props.gapiPolygonSettings}
                  gapiPointSettings={this.props.mapObj.gapiPointSettings}
                  gapiPointLength={this.props.mapObj.gapiPoints.length}
                  earthquakeLayer={this.props.mapObj.earthquakeLayer}
                  showShapeLegend={this.props.mapObj.showShapeLegend}
                  polygonLegend={this.props.mapObj.shapeLegend || null}
                  mapOptionsLayout={this.props.mapObj.mapOptionsLayout}
                />
              }
            </div>
          </div>
          : null
        }
        <div>
          {
            this.props.badGeocodes ?
            <div> Bad Geocodes</div>
            : null
          }
          {
            this.state.groupVerifyShow ?
            <TypeAddress
            toggleTypeAddress={this.toggleVerifyPoints}
            updateMapObj={this.props.updateMapObj}
            currentGroup={this.props.currentGroup}
            points={this.props.points}
            verifyMode={true}
            undoMoveLocation={this.props.undoMoveLocation}
            translate={this.props.translate}
            />
            :null
          }
          {this.state.editShapeShow ?
            <EditShapeAttributes
            toggleEditShape={this.toggleEditShape}
            translate={this.props.translate}
            shapeLayerIndex={this.state.shapeLayerIndex}
            shapeIndex={this.state.shapeIndex}
            shapeLegend={this.props.mapObj.shapeLegend}
            shapes={this.props.mapObj.shapes}
            updateMapObj={this.props.updateMapObj}
            />
          : null
          }
          {
            this.state.groupLabelShow
            ? <StylePointLabels
              toggleGroupLabelPanel={this.toggleGroupLabelPanel}
              currentGroup={this.props.currentGroup}
              points={this.props.points}
              groups={this.props.groups}
              updateMapObj={this.props.updateMapObj}
              translate={this.props.translate}
            />
            : null
          }
          {this.state.groupStyleShow
            ? <StyleGroups
              points={this.props.points}
              groups={this.props.groups}
              currentGroup={this.props.currentGroup}
              updateMapObj={this.props.updateMapObj}
              toggleGroupPanel={this.toggleGroupPanel}
              updateOrderAndIcon={this.props.updateOrderAndIcon}
              translate={this.props.translate}
            />
            : null
          }
          {this.state.groupDeleteShow && !this.props.popupOpen
            ? <DeleteGroup
              rings={this.props.rings}
              driveTimes={this.props.driveTimes}
              points={this.props.points}
              groups={this.props.groups}
              currentGroup={this.props.currentGroup}
              updateMapObj={this.props.updateMapObj}
              toggleDeleteGroup={this.toggleDeleteGroup}
              legend={this.props.legend}
              translate={this.props.translate}
            />
            : null
          }
          {this.state.pointAttributeShow
            ? <EditPointAttributes
              currentPoint={this.props.currentPoint}
              points={this.props.points}
              updateMapObj={this.props.updateMapObj}
              togglePointAttribute={this.togglePointAttribute}
              groups={this.props.groups}
              updateOrderAndIcon={this.props.updateOrderAndIcon}
              secyGeoName={this.props.secyGeoName}
              translate={this.props.translate}
            />
            : null
          }
          {this.state.groupRingsShow
            ? <GroupRings
              updateMapObj={this.props.updateMapObj}
              toggleGroupRingsPanel={this.toggleGroupRingsPanel}
              currentGroup={this.props.currentGroup}
              points={this.props.points}
              currentPoint={this.props.currentPoint}
              rings={this.props.rings}
              driveTimes={this.props.driveTimes}
              headers={this.props.headers}
              isDrive={this.state.isDrive}
              updateStatus={this.props.updateStatus}
              isLoadingHistory={this.props.isLoadingHistory}
              country={this.props.country}
              translate={this.props.translate}
              retriveGCAtlasToken={this.props.retriveGCAtlasToken}
            />
            : null
          }
          {this.state.isEditingRings
            ? <EditRings
              updateMapObj={this.props.updateMapObj}
              currentPoint={this.props.currentPoint}
              rings={this.props.rings}
              driveTimes={this.props.driveTimes}
              headers={this.props.headers}
              updateStatus={this.props.updateStatus}
              isLoadingHistory={this.props.isLoadingHistory}
              fromLegend={true}
              updateLegendObj={this.updateLegendObj}
              country={this.props.country}
              translate={this.props.translate}
              gcAtlasToken={this.props.mapObj.gcAtlasToken}
              retriveGCAtlasToken={this.props.retriveGCAtlasToken}
            />
            : null}
          {this.state.isStylingPoints
            ? <StylePoints
              points={this.props.points}
              groups={this.props.groups}
              currentGroup={this.props.currentGroup}
              updateMapObj={this.props.updateMapObj}
              togglePanel={this.togglePanel}
              currentPoint={this.props.currentPoint}
              isEditingRing={this.props.isEditingRing}
              rings={this.props.rings}
              driveTimes={this.props.driveTimes}
              updateOrderAndIcon={this.props.updateOrderAndIcon}
              headers={this.props.headers}
              updateStatus={this.updateStatus}
              isLoadingHistory={this.state.isLoadingHistory}
              isFromLegend={true}
              updateLegendObj={this.updateLegendObj}
              country={this.props.country}
              translate={this.props.translate}
              pointLeaderColor={this.props.pointLeaderColor}
              pointLeaderWidth={this.props.pointLeaderWidth}
              pointLeaderOpacity={this.props.pointLeaderOpacity}
              pointLeaderAnchor={this.props.pointLeaderAnchor}
              isStyling={this.state.isStylingPoints}
            />
            : null}
          {this.state.isLabeling
            ?<StylePointLabels
            toggleGroupLabelPanel={this.toggleLabelPanel}
            currentGroup={this.props.currentGroup}
            points={this.props.points}
            groups={this.props.groups}
            updateMapObj={this.props.updateMapObj}
            currentPoint={this.props.currentPoint}
            translate={this.props.translate}
          />
          : null}
          {this.state.customLayerDeleteShow ? 
          <DeleteCustomLayer
            updateMapObj={this.props.updateMapObj}
            deleteLayer={this.state.deleteLayer}
            toggleDeleteCustomLayer={this.toggleDeleteCustomLayer}
          />
          : null}
          {this.state.shapeDeleteShow ?
          <DeleteShapes
            toggleDeleteShapes={this.toggleDeleteShapes}
            shapeLayerIndex={this.state.shapeLayerIndex}
            shapeIndex={this.state.shapeIndex}
            shapeLegend={this.props.mapObj.shapeLegend}
            shapes={this.props.mapObj.shapes}
            updateMapObj={this.props.updateMapObj}
            currentShapeLayerId={this.props.mapObj.currentShapeLayer}
          />
          : null}
          {this.state.otherPointShow ?
            <TrafficCountsFilter

            togglePanel={this.props.togglePanel}
            updateMapObj={this.props.updateMapObj}
            updateIsSaving={this.props.updateIsSaving}
            closePanel={this.closePanel}
            setGapiIcon={this.props.setGapiIcon}
            setGAPILabelStyle={this.props.setGAPILabelStyle}
            gapiPointSettings={this.props.mapObj.gapiPointSettings}
            setGapiPointFilter={this.props.setGapiPointFilter}
            gapiPointsLoading={this.props.mapObj.gapiPointsLoading}
            gapiPointsErrorMessage={this.props.mapObj.gapiPointsErrorMessage}
            hasGapiPoints={this.props.mapObj.gapiPoints.length > 0 ? true : false}
            />
          : null}
          {this.state.gapiPolygonShow ?
            <GapiPolygonPanel
            closePanel={this.closeGapiPolygon}

            getGAPIPolygons={this.props.getGAPIPolygons}
            gapiPolygonSettings={this.props.gapiPolygonSettings}
            updateGAPIPolygonLabels={this.props.updateGAPIPolygonLabels}
            zoomToGAPIPolygon={this.props.zoomToGAPIPolygon}
            updateMapObj={this.props.updateMapObj}
            gapiTypes={this.props.mapObj.gapiPolygonType.types}
            // getGAPIPolygons={this.props.getGAPIPolygons}
            
            // gapiPolygonSettings={this.props.gapiPolygonSettings}
            // updateGAPIPolygonLabels={this.props.updateGAPIPolygonLabels}
            // zoomToGAPIPolygon={this.props.zoomToGAPIPolygon}
            />
            : null}
          {this.state.editRealGapiPointShow ?
          // this could be reading a selected index instead of hard coded
          <AddGapiPointsPanel
            layerName={"ColliersOffice"}
            layerTitle={"Colliers Offices"}
            iconName={"colliersLogo"}
            title={"Add Colliers Offices"}
            text="Add current Colliers offices as logos"
            togglePanel={this.toggleLegendPanel}
            toggleChild={this.toggleLegendPanel}
            mapObj={this.props.mapObj}
            updateMapObj={this.props.updateMapObj}
            updateIsSaving={this.props.updateIsSaving}
            headers={this.props.headers}
            translate={this.props.translate}
            legend={this.props.legend}
            setGapiIcon={this.props.setGapiIcon}
          />
          : null }
          {this.state.demographicsShow ?
            <DemographicsFilter 
            togglePanel={this.props.togglePanel}
            toggleDemographicsFilter={this.toggleDemographicsFilter}
            mapObj={this.props.mapObj}
            updateMapObj={this.props.updateMapObj}
            updateIsSaving={this.props.updateStatus}
            headers={this.props.headers}
            country={this.props.country}
            getDemographics={this.props.getDemographics}
            
            //redrawDemos={this.props.redrawDemos}
            />
          : null}
        </div>
      </div>
    );
  }
}

export default LegendPanel;