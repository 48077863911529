import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CloseIcon from '@material-ui/icons/Close';
import UploadPanel from './UploadPanel/UploadPanel';
import AddGrpPanel from './AddGrpPanel/AddGrpPanel';
import ClickMap from './ClickMap/ClickMap';
import TypeAddress from './TypeAddress/TypeAddress';
import TypeCoords from './TypeCoords/TypeCoords';
import translateObj from '../../../utils/translate';

class AddPtsPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ptsPanelShow: true,
      grpPanelShow: false,
      clickMapShow: false,
      badGeocodesShow: false,
      verifyShow: false,
      uploadPanelShow: false,
      typeAddressShow: false, 
      typeCoordShow: false,
    };

    this.closePanel = this.closePanel.bind(this);
    this.toggleClickMap = this.toggleClickMap.bind(this);
    this.showGroupPanel = this.showGroupPanel.bind(this);
    this.hideGroupPanel = this.hideGroupPanel.bind(this);
    this.saveAddGroup = this.saveAddGroup.bind(this);
    this.updateCurrentGroupChange = this.updateCurrentGroupChange.bind(this);
    this.toggleUploadPanel = this.toggleUploadPanel.bind(this);
    this.toggleTypeAddress = this.toggleTypeAddress.bind(this);
    this.toggleTypeCoord = this.toggleTypeCoord.bind(this);
    this.noVerify = this.noVerify.bind(this);
    this.verifyPoints = this.verifyPoints.bind(this);
    this.toggleVerifyShow = this.toggleVerifyShow.bind(this);
  }

  componentDidUpdate() {
    if(this.props.badGeocodes){
      if(!this.state.badGeocodesShow){
        this.setState({
          badGeocodesShow: true,
          ptsPanelShow: false
        });
      }
    }
  }

  verifyPoints() {
    this.toggleVerifyShow(true,false);
  }

  noVerify(){
    this.setState({
      badGeocodesShow: false,
      ptsPanelShow: true
    });
    this.props.updateMapObj({badGeocodes: false});
  }

  closePanel() {
    this.props.togglePanel('addPts');
  }

  toggleClickMap(bool1, bool2) {
    this.props.updateMapObj({addedPoint: null});
    this.setState({
      clickMapShow: bool1,
      ptsPanelShow: bool2
    });
  }
  
  toggleTypeAddress(bool1, bool2) {
    this.setState({
      typeAddressShow: bool1,
      ptsPanelShow: bool2
    });

    if(!bool1)
      this.props.togglePanel('addPts');
  }

  toggleVerifyShow(bool1, bool2){
    this.setState({
      badGeocodesShow: bool1,
      verifyShow: bool1,
      ptsPanelShow: bool2
    });
  }

  toggleTypeCoord(bool1, bool2) {
    this.setState({
      typeCoordShow: bool1,
      ptsPanelShow: bool2
    });
  }

  updateCurrentGroupChange(event) {
    this.props.updateMapObj({currentGroup: event.target.value});
  };

  showGroupPanel() {
    this.setState({
      ptsPanelShow: false,
      grpPanelShow: true,
    });
  }

  hideGroupPanel() {
    this.setState({
      ptsPanelShow: true,
      grpPanelShow: false,
    });
  }

  saveAddGroup(groupName) {
    this.props.addNewGroup(groupName);
    this.hideGroupPanel();
  }

  toggleUploadPanel(bool, ptsBool) {
    this.setState({
      uploadPanelShow: bool,
      ptsPanelShow: ptsBool
    });
  }

  render() {
    const groups = this.props.groups || [];

    const groupMenuItems = groups.map((group, index) => (
      <MenuItem key={index} value={group.id}>{group.name}</MenuItem>
    ));
    return (
      <div className="sidePanelStyle">
        {this.state.ptsPanelShow ?
          <div>
            <h2 className="panel-title">
              <span className="panel-icon-span">
                <svg id="addPtsIconSmall" xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 94.5 93.99">
                  <path id="Path_4109" fill="none" stroke="#0c9ed9" strokeWidth="4" d="M52.11,29l3.88.73V-31.65L31.45-35.78,7.76-24.86l-20-9.21L-36-25V36.31l23.8-9L2.13,33.75" transform="translate(37.26 37.08)" />
                  <path id="Path_4110" fill="none" stroke="#0c9ed9" strokeWidth="4" d="M-12.21-22.44V16" transform="translate(37.26 37.08)" />
                  <path id="Path_4111" fill="none" stroke="#0c9ed9" strokeWidth="4" d="M7.76-14.76V-.09" transform="translate(37.26 37.08)" />
                  <path id="Path_4112" fill="none" stroke="#0c9ed9" strokeWidth="4" d="M31.88-25.17V-5.74" transform="translate(37.26 37.08)" />
                  <path id="Path_4114" fill="none" stroke="#0c9ed9" strokeWidth="4" d="M25.86,24.24a4.12,4.12,0,1,0-4.15-4.12,4.12,4.12,0,0,0,4.15,4.12Z" transform="translate(37.26 37.08)" />
                  <path id="Path_4115" fill="none" stroke="#0c9ed9" strokeWidth="4" d="M26,56.22S9.21,30.88,9.21,21.67A16.71,16.71,0,0,1,26,5c8.75,0,17.27,7.8,16.76,16.67-.25,4.34-2.94,10.4-5.57,15.6-2.47,4.89-4.88,8.84-4.88,8.84" transform="translate(37.26 37.08)" />
                </svg>
              </span>
          {translateObj.addPointsTitle[this.props.translate]}
          <span className="panel-close closeStyle"><CloseIcon onClick={this.closePanel} fontSize="small" /></span>
            </h2>
            {this.state.badGeocodesShow ? 
            <div>
                <div className="paddingTB10">
                <div className="verifyPointsTitleText"><span >Geocode Results</span></div>
                <div className="verifyPoints"><span className="gText">Good Matches: {this.props.badGeocodes.green}</span></div>
                <div className="verifyPoints"><span className="qText">Questionable Matches: {this.props.badGeocodes.yellow}</span></div>
                <div className="verifyPoints"><span className="dText">Doubtful Matches: {this.props.badGeocodes.red}</span></div>
                <div className="verifyPoints"><span className="smallText">The geocoder can not verify the accuracy of your data</span></div>
                </div>

                <div className="geocodeDiv flex">
                  <button className="geocodeBtn" onClick={this.noVerify}>Skip and Continue</button>
                  <button className="geocodeBtn" onClick={this.verifyPoints}>Verify Points</button>
                </div>

            </div>
            :
            <div id="addPtsDiv" className="panel-body">
              <div className="targetLayerOuterDiv">
                <div className="targetLayerLabel">{translateObj.targetLayer[this.props.translate]}:</div>
                <div className="targetLayerSelectDiv">
                  <Select
                    labelId="addPtGroup-select"
                    id="addPtGroup-select"
                    onChange={this.updateCurrentGroupChange}
                    value={this.props.currentGroup}
                    variant="outlined"
                  >
                    {groupMenuItems}
                  </Select>
                </div>
              </div>
              <div className="addPtsBtnsDiv">
                <div className="addPtsBtnDiv">
                  <button className="geocodeBtn" onClick={this.showGroupPanel}>{translateObj.addLayerBtn[this.props.translate]}</button>
                </div>
                <div className="addPtsBtnDiv">
                  <button className="geocodeBtn" onClick={this.toggleClickMap.bind(this, true, false)}>{translateObj.clickOnMapBtn[this.props.translate]}</button>
                </div>
                <div className="addPtsBtnDiv">
<button className="geocodeBtn" onClick={this.toggleUploadPanel.bind(this, true, false)}>{translateObj.uploadLocationsBtn[this.props.translate]}</button>
                </div>
                <div className="addPtsBtnDiv">
                  <button className="geocodeBtn" onClick={this.toggleTypeAddress.bind(this, true, false)}>{translateObj.typeAddressBtn[this.props.translate]}</button>
                </div>
                <div className="addPtsBtnDiv">
                  <button className="geocodeBtn" onClick={this.toggleTypeCoord.bind(this, true, false)}>{translateObj.typeCoordsBtn[this.props.translate]}</button>
                </div>
                <div className="addPtsBtnDiv">
                  <button className="geocodeBtn" onClick={this.toggleVerifyShow.bind(this, true, false)}>Verify Points</button>
                </div>
              </div>
            </div>
            }
          </div>
          : null
        }
        {this.state.clickMapShow ?
          <ClickMap
            togglePanel={this.props.togglePanel}
            toggleClickMap={this.toggleClickMap}
            updateMapObj={this.props.updateMapObj}
            translate={this.props.translate}
            country={this.props.country}
            gettingDriveTimes={this.props.gettingDriveTimes}
            addedPoint={this.props.addedPoint}
            points={this.props.points}
          />
          : null
        }
        {this.state.grpPanelShow ?
          <AddGrpPanel
            togglePanel={this.props.togglePanel}
            groups={this.props.groups}
            saveAddGroup={this.saveAddGroup}
            hideGroupPanel={this.hideGroupPanel}
            translate={this.props.translate}
          />
          : null
        }
        {this.state.uploadPanelShow ?
          <UploadPanel
            togglePanel={this.props.togglePanel}
            toggleUploadPanel={this.toggleUploadPanel}
            updateMapObj={this.props.updateMapObj}
            groups={this.props.groups}
            addGroup={this.saveAddGroup}
            currentGroup={this.props.currentGroup}
            points={this.props.points}
            createNewPoint={this.props.createNewPoint}
            updateStatus={this.props.updateStatus}
            legend={this.props.legend}
            headers={this.props.headers}
            country={this.props.country}
            secyGeoName={this.props.secyGeoName}
            translate={this.props.translate}
            supergeo={this.props.supergeo}
          ></UploadPanel>
          : null
        }
        {this.state.verifyShow ?
                      <TypeAddress
                      toggleTypeAddress={this.toggleVerifyShow}
                      updateMapObj={this.props.updateMapObj}
                      currentGroup={this.props.currentGroup}
                      points={this.props.points}
                      verifyMode={true}
                      undoMoveLocation={this.props.undoMoveLocation}
                      translate={this.props.translate}
                      />
                      : null
        }
        {this.state.typeAddressShow ?
          <TypeAddress 
            createNewPoint={this.props.createNewPoint}
            updateMapObj={this.props.updateMapObj}
            toggleTypeAddress={this.toggleTypeAddress}
            points={this.props.points}
            legend={this.props.legend}
            headers={this.props.headers}
            translate={this.props.translate}
            country={this.props.country}
            mapCenter={this.props.mapCenter}
            undoMoveLocation={this.props.undoMoveLocation}
            rings={this.props.rings}
            driveTimes={this.props.driveTimes}
            getDriveTime={this.props.getDriveTime}
            showRings={true}
          />
          : null
        }
        {
          this.state.typeCoordShow ?
          <TypeCoords
            createNewPoint={this.props.createNewPoint}
            toggleTypeCoord={this.toggleTypeCoord}
            updateMapObj={this.props.updateMapObj}
            points={this.props.points}
            legend={this.props.legend}
            headers={this.props.headers}
            translate={this.props.translate}
          />
          : null
        }
      </div>
    );
  }
}

export default AddPtsPanel;