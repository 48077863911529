import React from 'react';
import GroupNameComponent from '../GroupNameComponent/GroupNameComponent';
import Checkbox from '@material-ui/core/Checkbox';
import Collapse from '@material-ui/core/Collapse';
import DeleteIcon from '@material-ui/icons/Delete';
import PaletteIcon from '@material-ui/icons/Palette';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import PublicIcon from '@material-ui/icons/Public';
import Menu from '@material-ui/core/Menu';
import { TextField } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import translateObj from '../../../../utils/translate';

class GroupComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
      showOptions: false,
      anchorEl: null,
      startNumber: this.props.g.startNumber,
      changingNumber: false,
      originalPoints: false
    };

    this.collapseGroup = this.collapseGroup.bind(this);
    this.toggleOptionsPanel = this.toggleOptionsPanel.bind(this);
    this.updateGroupStartNumber = this.updateGroupStartNumber.bind(this);
    this.saveGroupStartNumber = this.saveGroupStartNumber.bind(this);
    this.editStartNumber = this.editStartNumber.bind(this);
    this.sortPoints = this.sortPoints.bind(this);
    this.arrangePoints = this.arrangePoints.bind(this);
    this.undoPoints = this.undoPoints.bind(this);
  }

  collapseGroup() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  toggleOptionsPanel(e, bool) {
    this.setState({
      anchorEl: e.currentTarget,
      showOptions: bool
    });
  }

  toggleGroupStyling(groupId, e) {
    //update current group's useGroupStyle property
    const groupsArrayClone = Array.from(this.props.groups);

    for (let group of groupsArrayClone) {
      if (group.id === groupId) {
        group.useGroupStyle = e.target.checked;
      }
    }

    //update icon color of points in group if necessary
    const pointsArrayClone = Array.from(this.props.points).filter(point => point.content.group === groupId);
    const leftOverArray = Array.from(this.props.points).filter(point => point.content.group !== groupId);
    const styledArray = this.props.updateOrderAndIcon(groupsArrayClone, groupId, pointsArrayClone);
    const combinedArray = styledArray.concat(leftOverArray);

    this.props.updateMapObj({
      mapGroups: groupsArrayClone,
      mapPoints: combinedArray
    });
  }

  toggleGroupVisible(groupId, e) {
    const groupsArrayClone = Array.from(this.props.groups);
    for (let group of groupsArrayClone) {
      if (group.id === groupId) {
        group.visible = e.target.checked;
      }
    }
    this.props.updateMapObj({ mapGroups: groupsArrayClone });
  }

  toggleNameInLegend(groupId, e) {
    const groupsArrayClone = Array.from(this.props.groups);

    for (let group of groupsArrayClone) {
      if (group.id === groupId) {
        group.nameInLegend = e.target.checked;
      }
    }
    this.props.updateMapObj({ mapGroups: groupsArrayClone });
  }

  togglePointsInLegend(groupId, e) {
    const groupsArrayClone = Array.from(this.props.groups);

    for (let group of groupsArrayClone) {
      if (group.id === groupId) {
        group.pointsInLegend = e.target.checked;
      }
    }
    this.props.updateMapObj({ mapGroups: groupsArrayClone });
  }

  toggleGroupInLegend(groupId, e) {
    const groupsArrayClone = Array.from(this.props.groups);

    for (let group of groupsArrayClone) {
      if (group.id === groupId) {
        group.groupInLegend = e.target.checked;
      }
    }
    this.props.updateMapObj({ mapGroups: groupsArrayClone });
  }

  toggleLegendData(groupId, legendData, e) {
    const groupsArrayClone = Array.from(this.props.groups);
    for (let group of groupsArrayClone) {
      if (group.id === groupId) {
        group.showLegendData = e.target.checked;
      }
    }
    this.props.updateMapObj({ mapGroups: groupsArrayClone });
  }

  editStartNumber() {
    this.setState({
      changingNumber: true
    });
  }

  updateGroupStartNumber(e) {
    const rounded = Math.round(e.target.value);
    this.setState({
      startNumber: rounded
    });
  }

  saveGroupStartNumber() {
    //update group start number
    const groupsArrayClone = Array.from(this.props.groups);
    for (const group of groupsArrayClone) {
      if (group.id === this.props.g.id) {
        group.startNumber = this.state.startNumber;
      }
    }

    //update order and icon of points
    const newPoints = Array.from(this.props.points).filter(point => point.content.group === this.props.g.id);
    const leftOverArray = Array.from(this.props.points).filter(point => point.content.group !== this.props.g.id);
    const styledArray = this.props.updateOrderAndIcon(groupsArrayClone, this.props.g.id, newPoints);
    const combinedArray = styledArray.concat(leftOverArray);

    this.props.updateMapObj({
      mapGroups: groupsArrayClone,
      mapPoints: combinedArray
    });

    this.setState({
      changingNumber: false
    });
  }

  undoPoints() {
    const groupsArrayClone = Array.from(this.props.groups);
    let undoPoints = Array.from(this.state.originalPoints).filter(point => point.content.group === this.props.g.id);
    const leftOverArray = Array.from(this.props.points).filter(point => point.content.group !== this.props.g.id);
    const newArray = this.props.updateOrderAndIcon(groupsArrayClone, this.props.g.id, undoPoints);
    const combinedArray = newArray.concat(leftOverArray);

    this.props.updateMapObj({
      mapPoints: combinedArray
    });
    this.state.originalPoints = false;
  }

  arrangePoints() {
    this.setState({originalPoints: this.props.points});
    let existingPoints = Array.from(this.props.points).filter(point => point.content.group === this.props.g.id);

    const newPoints = []; 
    let curLat = 90;
    let curLng = -180;

    const newLen = existingPoints.length;

    for(let i=0;i<newLen;i++){
      existingPoints.sort((a, b) => 
      (((Math.abs(curLat - a.position.lat) + Math.abs(curLng - a.position.lng)) > (Math.abs(curLat -b.position.lat) + Math.abs(curLng -b.position.lng))) 
      ? 1 : 
       ((Math.abs(curLat -b.position.lat) + Math.abs(curLng -b.position.lng)) > (Math.abs(curLat - a.position.lat) + Math.abs(curLng - a.position.lng)))
      ? -1 : 0));

      let addPt = existingPoints[0];
      addPt.content.order = (i+1);

      curLat = addPt.position.lat;
      curLng = addPt.position.lng;
      newPoints.push(addPt);
      existingPoints.shift();
    }

    const groupsArrayClone = Array.from(this.props.groups);
    const leftOverArray = Array.from(this.props.points).filter(point => point.content.group !== this.props.g.id);
    const styledArray = this.props.updateOrderAndIcon(groupsArrayClone, this.props.g.id, newPoints);
    const combinedArray = styledArray.concat(leftOverArray);
    this.props.updateMapObj({
      mapPoints: combinedArray
    });
  }

  sortPoints() {
    this.setState({originalPoints: this.props.points});
    const newPoints = Array.from(this.props.points).filter(point => point.content.group === this.props.g.id);
    newPoints.sort((a, b) => (a.content.name > b.content.name) ? 1 : ((b.content.name > a.content.name) ? -1 : 0));
    let o = 1;
    for(let i=0;i<newPoints.length;i++){
      newPoints[i].content.order=o;
      o++;
    }

    //update order and icon of points
    const groupsArrayClone = Array.from(this.props.groups);
    const leftOverArray = Array.from(this.props.points).filter(point => point.content.group !== this.props.g.id);
    const styledArray = this.props.updateOrderAndIcon(groupsArrayClone, this.props.g.id, newPoints);
    const combinedArray = styledArray.concat(leftOverArray);

    this.props.updateMapObj({
      mapPoints: combinedArray
    });

  }

  render() {
    const g = this.props.g;
    return (
      <Collapse className="legendListDiv"
        collapsedSize={50}
        ref={this.props.provided.innerRef}
        {...this.props.provided.droppableProps}
        in={this.state.collapsed}
      >
        <div>
          <div className="groupNameDiv">
            <div style={{ overflow: 'hidden' }}>
              <GroupNameComponent
                g={this.props.g}
                groups={this.props.groups}
                updateMapObj={this.props.updateMapObj}
                translate={this.props.translate}
                sortPoints={this.sortPoints}
                arrangePoints={this.arrangePoints}
                originalPoints={this.state.originalPoints}
                undoPoints={this.undoPoints}
              />
            </div>
            <div style={{ marginLeft: 'auto' }} onClick={() => this.props.toggleVerifyPoints(true, g.id)}>
              <Tooltip title="Verify Points" placement="top">
                <PublicIcon style={{ fontSize: 16, cursor: 'pointer' }} />
              </Tooltip>
            </div>
            <div onClick={() => this.props.toggleGroupRingsPanel(true, g.id)}>
              <Tooltip title={translateObj.addEditLayerRingsTooltip[this.props.translate]} placement="top">
                <RadioButtonCheckedIcon style={{ fontSize: 16, cursor: 'pointer' }} />
              </Tooltip>
            </div>
            <div onClick={() => this.props.toggleGroupPanel(true, g.id)}>
              <Tooltip title={translateObj.editLayerStyleTooltip[this.props.translate]} placement="top">
                <PaletteIcon style={{ fontSize: 16, cursor: 'pointer' }} />
              </Tooltip>
            </div>
            <div onClick={() => this.props.toggleGroupLabelPanel(true, g.id)}>
              <Tooltip title="Add/Edit Label" placement="top">
                <TextFieldsIcon style={{ fontSize: 16, cursor: 'pointer' }} />
              </Tooltip>
            </div>
            <div onClick={() => this.props.toggleDeleteGroup(true, g.id)}>
              <Tooltip title={translateObj.deleteLayerTooltip[this.props.translate]} placement="top">
                <DeleteIcon style={{ fontSize: 16, cursor: 'pointer' }} />
              </Tooltip>
            </div>
            <div onClick={(e) => this.toggleOptionsPanel(e, true)}>
              <Tooltip title={translateObj.moreLayerOptionsTooltip[this.props.translate]} placement="top">
                <MoreVertIcon style={{ fontSize: 16, cursor: 'pointer' }} />
              </Tooltip>
            </div>
            {this.state.showOptions ?
              <Menu
                id="simple-menu"
                anchorEl={this.state.anchorEl}
                keepMounted
                open={this.state.showOptions}
                onClose={this.toggleOptionsPanel.bind(this, { currentTarget: null }, false)}
              >
                <div className="legendOptsMenuDiv">
                  <div className="useGroupStyleCheckBox">
                    {translateObj.useLayerStyle[this.props.translate]}
                    <Checkbox
                      color="default"
                      value="default"
                      inputProps={{ 'aria-label': 'checkbox with default color' }}
                      onChange={(event) => this.toggleGroupStyling(g.id, event)}
                      checked={g.useGroupStyle}
                      disableRipple
                    />
                  </div>
                  <div className="legendOptsCheckBox">
                    {translateObj.layerNameInLegend[this.props.translate]}
                    <Checkbox
                      color="default"
                      value="default"
                      inputProps={{ 'aria-label': 'checkbox with default color' }}
                      onChange={(event) => this.toggleNameInLegend(g.id, event)}
                      checked={g.nameInLegend}
                      disableRipple
                    />
                  </div>
                  <div className="legendOptsCheckBox">
                    {translateObj.pointsInLegend[this.props.translate]}
                    <Checkbox
                      color="default"
                      value="default"
                      inputProps={{ 'aria-label': 'checkbox with default color' }}
                      onChange={(event) => this.togglePointsInLegend(g.id, event)}
                      checked={g.pointsInLegend}
                      disableRipple
                    />
                  </div>
                  <div className="legendOptsCheckBox">
                    {translateObj.layerInLegend[this.props.translate]}
                    <Checkbox
                      color="default"
                      value="default"
                      inputProps={{ 'aria-label': 'checkbox with default color' }}
                      onChange={(event) => this.toggleGroupInLegend(g.id, event)}
                      checked={g.groupInLegend}
                      disableRipple
                    />
                  </div>
                  <div className="legendOptsCheckBox">
                    {translateObj.showOnMap[this.props.translate]}
                    <Checkbox
                      color="default"
                      value="default"
                      inputProps={{ 'aria-label': 'checkbox with default color' }}
                      onChange={(event) => this.toggleGroupVisible(g.id, event)}
                      checked={g.visible}
                      disableRipple
                    />
                  </div>
                  <div className="legendOptsCheckBox">
                    {translateObj.showLegendDataColumn[this.props.translate]}
                    <Checkbox
                      color="default"
                      value="default"
                      inputProps={{ 'aria-label': 'checkbox with default color' }}
                      onChange={(event) => this.toggleLegendData(g.id, g.showLegendData, event)}
                      checked={g.showLegendData}
                      disableRipple
                    />
                  </div>
                  <div className="legendStartNumDiv">
                    {translateObj.startNumberingAt[this.props.translate]}
                  </div>
                  {this.state.changingNumber ?
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div style={{ width: '80px' }}>
                        <TextField
                          id="layerStartNum"
                          defaultValue={g.startNumber}
                          variant="outlined"
                          size="small"
                          type="number"
                          InputProps={{ inputProps: { min: 0, step: 1 } }}
                          onChange={(event) => this.updateGroupStartNumber(event)}
                        />
                      </div>
                      <button
                        className="geocodeBtnSmall margin-l8 height-21"
                        onClick={this.saveGroupStartNumber}
                      >
                        {translateObj.saveBtn[this.props.translate]}
                      </button>
                    </div>
                    :
                    <div className="startNumberDivNotEdit">
                      <div className="startNumberText">{g.startNumber}</div>
                      <button
                        className="geocodeBtnSmall margin-l8"
                        onClick={this.editStartNumber}
                      >
                        {translateObj.editBtn[this.props.translate]}
                      </button>
                    </div>
                  }
                </div>
              </Menu>
              : null
            }
            <div>
              <span onClick={this.collapseGroup} style={{ cursor: 'pointer' }}>
                {this.state.collapsed ?
                  <Tooltip title={translateObj.collapseLayerTooltip[this.props.translate]} placement="top">
                    <ExpandLessIcon />
                  </Tooltip>
                  :
                  <Tooltip title={translateObj.expandLayerTooltip[this.props.translate]} placement="top">
                    <ExpandMoreIcon />
                  </Tooltip>
                }
              </span>
            </div>
          </div>
        </div>
        {this.props.renderGroupPoints(g)}
        {this.props.provided.placeholder}
      </Collapse>
    );
  }
}

export default GroupComponent;