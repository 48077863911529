import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import translateObj from '../../../utils/translate';
import SVGIconComponent from '../../SVGIconComponent/SVGIconComponent';
import LogoSize from './LogoSize';
import CustomPointStyle  from '../../MapComponent/CustomPointStyle';
import Checkbox from '@material-ui/core/Checkbox';
// import L from 'leaflet';
// import ReactDOMServer from 'react-dom/server';
// import { GetIconOffset } from '../../../utils/tools';

class AddGapiPointsPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logoSize: 100,
      layerName: '',
      title: 'Add Gapi Points',
      text: 'Sample Text',
      iconName: null,
      layerTitle: '',
      pointStyle:{
        pointColor: "#0c9ed9",
        pointIconName: "circleIcon",
        pointIconSize: "default",
        pointTextColor: "#ffffff"
      },
      useLogo: false
    }
    this.updateCustomLogoSize = this.updateCustomLogoSize.bind(this);
    this.changeColorOrIcon = this.changeColorOrIcon.bind(this);
  }

  componentDidMount = () => {
    this.setState({
      layerName: this.props.layerName,
      iconName: this.props.iconName,
      title: this.props.title,
      text: this.props.text,
      layerTitle: this.props.layerTitle
    });

    if(this.props.mapObj.realGapiPoints){
      if(this.props.mapObj.realGapiPoints.length>0){
        this.setState({useLogo:this.props.mapObj.realGapiPoints[0].useLogo,
          pointStyle:this.props.mapObj.realGapiPoints[0].pointStyle
        });
      }
    }

  }

  closePanel = () => {
    this.props.togglePanel("addData");
  }

  backToAddData = () => {
    this.props.toggleChild(true, false);
  }

  handleToggleLayer = (action) => {
    if (action === 'add') {
        let updateObj = {
          addRealGapiPoints:  {layer:this.state.layerName,iconName:this.state.iconName,
            logoSize:this.state.logoSize,
            useLogo:this.state.useLogo,
            pointStyle:this.state.pointStyle,showInLegend: true,
            layerTitle:this.state.layerTitle
          }
        };
        this.props.updateMapObj(updateObj);
    }
    else if(action === 'remove'){
      const gapiPoints = this.props.mapObj.realGapiPoints;
      const ix = gapiPoints.findIndex(x => x.layer === this.state.layerName);
      if (ix >= 0)
        gapiPoints.splice(ix, 1);

      const updateObj = {realGapiPoints: gapiPoints};
      this.props.updateMapObj(updateObj);
    }
  }

  updateCustomLogoSize = (event) => {
    this.setState({logoSize:event.target.value});
    const gapiPoints = this.props.mapObj.realGapiPoints;
    
    if(gapiPoints==null)
      return;

    const ix = gapiPoints.findIndex(x => x.layer === this.state.layerName);
    if (ix >= 0){
      gapiPoints[ix].logoSize = event.target.value;
      const updateObj = {realGapiPoints: gapiPoints};
      this.props.updateMapObj(updateObj);
    }
  }

  toggleLogo (chk) {
    this.setState({useLogo: chk});
    const gapiPoints = this.props.mapObj.realGapiPoints;

    const ix = gapiPoints.findIndex(x => x.layer === this.state.layerName);
    if (ix >= 0){
      gapiPoints[ix].useLogo = chk;
      const updateObj = {realGapiPoints: gapiPoints};
      this.props.updateMapObj(updateObj);
    }
  }

  changeColorOrIcon(type, newStyle) {
    let newPoint = this.state.pointStyle || null;
    if(newPoint === null)
        newPoint = {
            pointColor: '#0c9ed9',
            pointIconName: 'circleIcon',
            pointIconSize: 'default',
            pointTextColor: '#ffffff'
        };
    
    if (type === 'color') {
        newPoint.pointColor = newStyle;
    }
    if (type === 'size'){
        newPoint.pointIconSize = newStyle;
    }
    if (type === 'icon'){
        newPoint.pointIconName = newStyle;
    }
    this.setState({ pointStyle: newPoint });


    const gapiPoints = this.props.mapObj.realGapiPoints;
    
    if(gapiPoints==null)
      return;

    const ix = gapiPoints.findIndex(x => x.layer === this.state.layerName);
    if (ix >= 0){
      gapiPoints[ix].pointStyle = newPoint;
      const updateObj = {realGapiPoints: gapiPoints};
      this.props.updateMapObj(updateObj);
    }
    //this.props.setGapiIcon(newPoint);
  }

  render() {
    const gapiPoints = this.props.mapObj.realGapiPoints || [];
    const coIndex = gapiPoints.findIndex(x => x.layer == this.state.layerName);
    return (
      <div>
        <h2 className="panel-title">
          <span className="panel-icon-span">
            <SVGIconComponent name={'addDataBtnIcon'} color={'#0c9ed9'} size={'16px'} />
          </span>
          {this.state.title}
          <span className="panel-close closeStyle"><CloseIcon onClick={this.closePanel} fontSize="small" /></span>
        </h2>
        <div className="panel-body">
          <div className="panel-body-small-text">
          {this.state.text}
          </div>
          Use Logo:
            <Checkbox
                color="default"
                value={this.state.useLogo}
                inputProps={{ 'aria-label': 'use layer style' }}
                onChange={(event) => this.toggleLogo(event.target.checked)}
                checked={this.state.useLogo}
            />

          {this.state.useLogo ?
          <div className="paddingTB10">
          <LogoSize
            updateCustomLogoSize={this.updateCustomLogoSize}
            logoSize={this.state.logoSize}
          />
          </div>
          :
          <CustomPointStyle
                defaultLayerStyle={this.state.defaultLayerStyle}
                currentPoint={this.state.pointStyle}
                changeColorOrIcon={this.changeColorOrIcon}
                toggleDefaultStyling={this.toggleDefaultStyling}
                hidePOIs={true}
                bluesOnly={true}
          />
          }

          <div className="geocodeDiv flex">
            <button
              className="geocodeBtn"
              onClick={this.backToAddData}
            >
              {translateObj.backBtn[this.props.translate]}
            </button>
            {
              coIndex >= 0 ?
              <button
              className="geocodeBtn"
              onClick={() => this.handleToggleLayer('remove')}
              >{translateObj.removeLayerBtn[this.props.translate]}</button>
              :
              <button
              className="geocodeBtn"
              onClick={() => this.handleToggleLayer('add')}
              >{translateObj.addLayerBtn[this.props.translate]}</button>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default AddGapiPointsPanel;