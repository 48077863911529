import React from 'react';
import Menu from '@material-ui/core/Menu';
import Tooltip from '@material-ui/core/Tooltip';
import { TextField } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import CloseIcon from '@material-ui/icons/Close'
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import axios from 'axios';

class LogoSizeMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      showOptions: false,
      showRequestForm: false,
      logoRequestType: 'missing',
      logoMissingOrIncorrect: 'missing',
      logoRequestRetailer: '',
      //logoRetailers: null,
      logoRetailerSearchVal: '',
      logoRetailerSearchId: null,
      logoRetailerSuggest: null,
      //allRetailers: null,
      //noLogoRetailers: null,
      allRetailerSearchVal: '',
      allRetailerSearchId: null,
      allRetailerSuggest: null,
      step: 'one',
      potlocs: [],
      searchingLocs: false,
      missingLocation: '',
      additonalText: ''
    }
    this.nextStep = this.nextStep.bind(this);
    this.toggleRequestPanel = this.toggleRequestPanel.bind(this);
    this.closeRequest = this.closeRequest.bind(this);
    this.startOver = this.startOver.bind(this);
  }

  toggleOptionsPanel(e, bool) {
    this.setState({
      anchorEl: e.currentTarget,
      showOptions: bool,
      showRequestForm: false
    });
  }

  toggleRequestPanel(e, bool) {

    this.setState({
      anchorEl: e.currentTarget,
      showOptions: false,
      showRequestForm: bool
    });
    if(bool===true){
      this.startOver();
    }

  }

  updateCustomLogoSize(event) {
    this.props.updateMapObj({ logoSize: event.target.value });
  }

  updateLogoRequestRetailer(e) {
    let text = e.target.value;
    this.setState({
      logoRequestRetailer: text
    });
  }

  updateAllRequestRetailer(e) {
    let text = e.target.value;
    this.setState({
      allRequestRetailer: text
    });
  }

  setSelectedAllRetailer(e, suggestion) {
    this.setState({allRetailerSearchVal: suggestion.chainname,
      allRetailerSearchId: suggestion.chainid,
      allRetailerSuggest: []
    })
  }


  setSelectedLogoRetailer(e, suggestion) {
    this.setState({logoRetailerSearchVal: suggestion.chainname,
      logoRetailerSearchId: suggestion.chainid,
      logoRetailerSuggest: []
    })
    // this.setState({ isError: false });
    // let selectedRetailersNew = Array.from(this.props.mapObj.selectedRetailer);
    // let add = true;
    // for (const retailer of selectedRetailersNew) { //don't add dupes
    //   if (suggestion.chainname === retailer.chainname) {
    //     add = false;
    //     break;
    //   }
    // }

    // if (add) { //add and sort
    //   selectedRetailersNew.push(suggestion);
    //   selectedRetailersNew.sort((a, b) => (a.chainname > b.chainname) ? 1 : ((b.chainname > a.chainname) ? -1 : 0));
    // }

    // this.props.updateMapObj({
    //   selectedRetailer: selectedRetailersNew
    // });

  }

  setLogoRetailerSearch(e) {
    //this.setState({ isError: false });
    const searchVal = e.target.value;
    this.setState({
      logoRetailerSearchVal: searchVal
    });
    this.onChangeDebounced(searchVal);
  }

  setLocationSearch(e) {
    const searchVal = e.target.value;
    this.setState({
      locationVal: searchVal
    });
  }

  setAllRetailerSearch(e) {
    //this.setState({ isError: false });
    const searchVal = e.target.value;
    this.setState({
      allRetailerSearchVal: searchVal
    });
    this.onAllChangeDebounced(searchVal);
  }

  onAllChangeDebounced = (searchVal) => {
    let resultsList = [];
    if (searchVal !== '' && searchVal !== " ") {
      for (const retailer of this.props.noLogoRetailers) {
        if (retailer.chainname.toLowerCase().startsWith(searchVal.toLowerCase()) || retailer.chainname.toLowerCase().startsWith('the ' + searchVal.toLowerCase())) {
          resultsList.push(retailer);
        }
      }
    }
    this.setState({
      allRetailerSuggest: resultsList
    });
  }

  onChangeDebounced = (searchVal) => {
    let resultsList = [];
    if (searchVal !== '' && searchVal !== " ") {
      for (const retailer of this.props.logoRetailers) {
        if (retailer.chainname.toLowerCase().startsWith(searchVal.toLowerCase()) || retailer.chainname.toLowerCase().startsWith('the ' + searchVal.toLowerCase())) {
          resultsList.push(retailer);
        }
      }
    }
    this.setState({
      logoRetailerSuggest: resultsList
    });
  }


  logoRequestTypeChange(e) {
    let newType = e.target.value;
    this.setState({
      logoMissingOrIncorrect: newType
    });
  }

  startOver() {
    this.setState({
      step: 'one',
      logoRetailerSearchVal: '',
      logoRetailerSearchId: null,
      logoRetailerSuggest: null,
      allRetailerSearchVal: '',
      allRetailerSearchId: null,
      allRetailerSuggest: null,
      locationVal: ''
    });
  }

  closeRequest() {
    this.setState({showRequestForm: null});
  }

  _submitRequest(userid,rid,rname,type,loc) {
    const url = process.env.REACT_APP_APIURL + '/requestlogo?userid=' + (userid || '') + '&retailerid=' + (rid || '')
    + '&retailername=' + (rname || '') + '&requesttype=' + (type || '') + '&requestlocation=' + (loc || '') + '&mapbounds=' + JSON.stringify(this.props.mapObj.mapBounds);
    axios.get(url, { headers: this.props.headers });
  }

  _checkLocs(){
    this.setState({searchingLocs: true});
    const sendObj = {
      mapbounds: this.props.mapObj.mapBounds,
      retailerid: this.state.logoRetailerSearchId
    };
    // this.props.updateLogoState({
    //   retailerLoading: true,
    //   isError: false,
    //   gettingSearchAreaLogos: false
    // });

    axios.post(process.env.REACT_APP_APIURL + "/retlocsfrombounds", sendObj, { headers: this.props.headers })
      .then((result) => {
        this.setState({searchingLocs:null, potlocs: result.data});
        // this.props.updateLogoState({ retailerLoading: false, showSearchAreaLogoDiv: true, searchAreaList: result.data });
        // this.props.updateIsSaving('isSaving', false);
        // this.props.updateMapObj({ isSaving: false });
      }).catch(error => {
        console.log(error);
        // this.props.updateLogoState({
        //   retailerLoading: false,
        //   isError: true,
        //   errorType: 'generic'
        // });
        // this.props.updateIsSaving('isSaving', false);
        // this.props.updateMapObj({ isSaving: false });
      });
  }
  
  // else if(this.state.step==='bad_logo'){

  // }

  nextStep() {
    let addText = '';

    if(this.state.step==='one'){
      if(this.state.logoRetailerSearchId !== null){
        if(this.state.logoMissingOrIncorrect==='incorrect'){
          this._submitRequest(this.props.mapObj.userId,this.state.logoRetailerSearchId || '0',this.state.logoRetailerSearchVal || '', 'Incorrect Logo','NA');
          this.setState({step:'email'
            ,logoRequestRetailer: this.state.logoRetailerSearchVal
            ,logoRequestType: 'Incorrect Logo'
            ,additonalText: 'If you have the logo, please attach to this email.  '
          });
        }
        else{
          this._checkLocs();
          this.setState({step: 'missing_loc'});
        }
      }
      else{
        const inAll = this.props.noLogoRetailers.filter(r => r.chainname == this.state.logoRetailerSearchVal);
        let newid = null;
        if(inAll.length>0){
          newid = inAll[0].chainid
        }
        this.setState({
          step: 'serach_all',
          allRetailerSearchVal: this.state.logoRetailerSearchVal,
          allRetailerSearchId: newid
        })
      }
    }
    else if(this.state.step==='serach_all'){
      let type = 'Missing Retailer';
      let retserachid = this.state.allRetailerSearchId;

      if(this.state.allRetailerSearchId===null){
        const checkAll = this.props.allRetailers.filter(r => r.chainname == this.state.allRetailerSearchVal);
        if(checkAll.length>0){
          this.setState({
            allRetailerSearchId: checkAll[0].chainid
          });
          type = 'Missing Logo';
          retserachid = checkAll[0].chainid;
          addText = 'If you have the logo, please attach to this email.  ';
        }
      }
      else {
        type = 'Missing Logo';
        addText = 'If you have the logo, please attach to this email.  ';
      }
      
      this._submitRequest(this.props.mapObj.userId,retserachid || '0',this.state.allRetailerSearchVal || '', type,'NA');
      this.setState({step:'email'
        ,logoRequestRetailer: this.state.allRetailerSearchVal
        ,logoRequestType: type
        ,additonalText: addText
      });
    }
    else if(this.state.step==='missing_loc'){  
      this._submitRequest(this.props.mapObj.userId,this.state.logoRetailerSearchId || '0',this.state.logoRetailerSearchVal || '', 'Missing Location',this.state.locationVal);
      this.setState({step:'email'
        ,logoRequestRetailer: this.state.logoRetailerSearchVal
        ,logoRequestType: 'Missing Location'
        ,additonalText: 'Submitted Location: ' + (this.state.locationVal || 'None') + '  '
      });
    }
  }

  render() {
    const potlocs = this.state.potlocs.length > 0 ? true : false;
    return (
      <div className="retailerOuterDiv">
        <div className="retailerLabel">Logo Size: </div>
        <div className="logoSizeLabel">{this.props.mapObj.logoSize}%</div>
        <div>
          <button className="geocodeBtnSmall" onClick={(e) => this.toggleOptionsPanel(e, true)}>Options</button>
        </div>
        <div className="pushLeft">
          <button className="logoRequestBtn" onClick={(e) => this.toggleRequestPanel(e, true)}>Request missing logo</button>
        </div>
        {this.state.showOptions ?
          <Menu
            id="logoSize-menu"
            anchorEl={this.state.anchorEl}
            keepMounted
            open={this.state.showOptions}
            onClose={this.toggleOptionsPanel.bind(this, { currentTarget: null }, false)}
          >
            <div className="logoSizeMenuDiv">
              <span>Choose Size:</span>
              <div className="colorPickerDiv">
                <div className="colorPickerRow">
                  <Tooltip title="Smaller (75%)" placement="top">
                    <div className="colorPickerCell" onClick={this.updateCustomLogoSize.bind(this, { target: { value: 75 } })}>
                      <svg height="15px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <circle fill="#9ea2a2" stroke="#ffffff" cx="10" cy="10" r="8" />
                      </svg>
                    </div>
                  </Tooltip>
                  <Tooltip title="Default (100%)" placement="top">
                    <div className="colorPickerCell" onClick={this.updateCustomLogoSize.bind(this, { target: { value: 100 } })}>
                      <svg height="30px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <circle fill="#9ea2a2" stroke="#ffffff" cx="10" cy="10" r="8" />
                      </svg>
                    </div>
                  </Tooltip>
                  <Tooltip title="Larger (125%)" placement="top">
                    <div className="colorPickerCell" onClick={this.updateCustomLogoSize.bind(this, { target: { value: 125 } })}>
                      <svg height="45px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <circle fill="#9ea2a2" stroke="#ffffff" cx="10" cy="10" r="8" />
                      </svg>
                    </div>
                  </Tooltip>
                </div>
              </div>
              <div className="retailerOuterDiv">
                <div className="retailerLabel">Custom:</div>
                <div style={{ width: '100px', display: 'flex', alignItems: 'center' }}>
                  <TextField
                    id="customLogoSizeNum"
                    value={this.props.mapObj.logoSize}
                    variant="outlined"
                    size="small"
                    type="number"
                    InputProps={{ inputProps: { min: 0, step: 1 } }}
                    onChange={(event) => this.updateCustomLogoSize(event)}
                  />
                  <div style={{ paddingLeft: '2px' }}>%</div>
                </div>
              </div>
            </div>
          </Menu>
          : null
        }
        {this.state.showRequestForm ?
          <Menu
            id="requestLogo-menu"
            anchorEl={this.state.anchorEl}
            keepMounted
            open={this.state.showRequestForm}
            onClose={this.toggleOptionsPanel.bind(this, { currentTarget: null }, false)}
          >
            <div className="logoRequestMenuDiv">
            <span className="panel-close closeStyle"><CloseIcon onClick={this.closeRequest} fontSize="small" /></span>
              <div>Logo Request Form</div>
              <div className="logoRequestText">Please fill out the following form to provide information about the logo you'd like to see in BOOM.  Logos are generated based on retailers available in Chain XY data, but we do not have logos for all Chain XY retailers.  Also note that not all small retailers are available, and not all logo requests can be accommodated.  Providing the details below will alert us to missing logos in our database, and we will follow up with our ability and timing to complete your request.</div>

              {this.state.step === 'one' ?

              <div>
              <div className="logoRequestRadioDiv">
              <div>Reason for request:</div>
              <div>
                <RadioGroup aria-label="logo request type" name="logoRequestRadioGrp" value={this.state.logoMissingOrIncorrect} onChange={(e) => { this.logoRequestTypeChange(e) }}>
                  <FormControlLabel classes={{label:'logoRequestRadioLabel'}} value="missing" control={<Radio color="default" disableRipple={true} size="small" />} label="Missing logo" />
                  <FormControlLabel classes={{label:'logoRequestRadioLabel'}} value="incorrect" control={<Radio color="default" disableRipple={true} size="small" />} label="Incorrect logo" />
                </RadioGroup>
              </div>
              </div>
                      <div className="retailerOuterDiv">
                      {this.state.logoMissingOrIncorrect==='incorrect' 
                      ? 
                      <div className="logoRequestText">The textbox below contains all retailers with logos.  Please select the retailer with the logo that is incorrect and select Next Step to continue.</div>
                      :
                      <div className="logoRequestText">The textbox below contains all retailers with logos.  Please search to confirm your retailer does not exist.  If it does exist the location may be missing.  In this case click Next Step to submit a missing location.</div>
                      }
                      </div>
                      {this.props.logoRetailers ?
                      <div style={{ flexGrow: 2 }}>
                        <div>
                          <TextField
                            id="retailer-input"
                            label="Type to search retailers"
                            variant="outlined"
                            value={this.state.logoRetailerSearchVal}
                            size="small"
                            fullWidth
                            onChange={(e) => { this.setLogoRetailerSearch(e) }}
                          />
                        </div>
                        <div className="retailSuggestionDiv">
                          {this.state.logoRetailerSuggest ?
                            <ul className="retailListUL">
                              {this.state.logoRetailerSuggest.map(suggestion => (
                                <li
                                  className="search"
                                  id={suggestion.chainid}
                                  key={suggestion.chainid}
                                  value={suggestion.text}
                                  onClick={(e) => { this.setSelectedLogoRetailer(e, suggestion) }}
                                >
                                  {suggestion.chainname}
                                </li>
                              ))}
                            </ul>
                            : null}
                        </div>
                      </div>
                      : <div className="logoRequestText">Retailers are Loading, please wait</div>}
              </div>
              : null }
              {this.state.step === 'missing_loc' ?
              <div>
                <div className="retailerOuterDiv">
                  <div className="logoRequestSelectedText">Retailer Selected: {this.state.logoRetailerSearchVal}</div>
                </div>

                {this.state.searchingLocs ? <div className="logoRequestText">Seraching for Locations</div>
                :
                  <div>
                  {potlocs ? 
                    <div className="logoRequestText">The locations below were found in your map window.  If your location is listed please add retailer to the search criteria and try again.</div> 
                    :
                    <div className="logoRequestText">No locations were found in the map window.</div>
                  }
                  {this.state.potlocs.map((pl, idx) =>
                    <div className="logoRequestText" key={'pl_' + idx}>{pl.address + " " + pl.city + ", " + pl.state + " " + pl.postalcode}</div>
                  ) 
                }
                <div className="logoRequestText">Please enter address or any other information about the missing location and click Next Step</div>
                  <div>
                  <TextField
                            id="location-input"
                            label="Enter Address"
                            variant="outlined"
                            value={this.state.locationVal}
                            size="small"
                            fullWidth
                            onChange={(e) => { this.setLocationSearch(e) }}
                  />
                  </div>
                </div>
                }    
              </div>
              : null}
              {this.state.step === 'serach_all' ?
              <div>
                <div className="retailerOuterDiv">
                  <div className="logoRequestText">The search box below contains all retailers in our database.  Please search to see if your retailer exists.  If it is in this list the GIS staff will research if an appropriate logo is available to add.</div>
                </div>

                <div className="retailerOuterDiv">
                  <div className="logoRequestSelectedText">Previous Selection: {this.state.logoRetailerSearchVal}</div>
                </div>

                <div style={{ flexGrow: 2 }}>
                  <div>
                    <TextField
                      id="retailer-input"
                      label="Type to search retailers"
                      variant="outlined"
                      value={this.state.allRetailerSearchVal}
                      size="small"
                      fullWidth
                      onChange={(e) => { this.setAllRetailerSearch(e) }}
                    />
                  </div>
                  <div className="retailSuggestionDiv">
                    {this.state.allRetailerSuggest ?
                      <ul className="retailListUL">
                        {this.state.allRetailerSuggest.map(suggestion => (
                          <li
                            className="search"
                            id={suggestion.chainid}
                            key={suggestion.chainid}
                            value={suggestion.text}
                            onClick={(e) => { this.setSelectedAllRetailer(e, suggestion) }}
                          >
                            {suggestion.chainname}
                          </li>
                        ))}
                      </ul>
                      : null}
                  </div>
                </div>
              </div>
              : null}
              {this.state.step === 'three' ?
              <div>
                <div>
                  {this.state.missingLocation ? <div>Your location was not found</div>
                  :
                  <div>
                  {this.state.allRetailerSearchId ? 
                    <div>Your Retailer was found in the ChainXY database but there is no logo.  Please select next to confrim</div>
                    : <div>Your Retailer was found in the ChainXY database but there is no logo.  Please select next to confrim</div>
                  }
                  </div>
                  }
                </div>

              <div>Submit Retailer</div>
              </div>
              : null
              }
              {this.state.step === 'email' ?
                <div>
                  <div className="retailerOuterDiv">
                    <div className="logoRequestSelectedText">{this.state.logoRequestRetailer}</div>
                  </div>
                  <div>
                  {this.state.logoRequestType === 'Incorrect Logo' ? 
                  <div className="logoRequestText">You have requested an update to a logo.  Include the logo if you have it.  Please select next to email GIS staff.</div>
                  : null
                  }

                  {this.state.logoRequestType === 'Missing Location' ? 
                  <div className="logoRequestText">You requsted to add a missing Location.  Please include any information you have.  Please select next to email GIS staff.</div>
                  : null
                  }

                  {this.state.logoRequestType === 'Missing Logo' ? 
                  <div className="logoRequestText">Your Retailer was found in the ChainXY database but there is no logo.  Include the logo if you have it.  Please select next to email GIS staff.</div>
                  : null
                  }

                  {this.state.logoRequestType === 'Missing Retailer' ? 
                  <div className="logoRequestText">Your Retailer was not found in the ChainXY database.  Please select next to email GIS staff.</div>
                  : null
                  }
                  </div>

                  <a className="textDecorationNone" target="hidden-iframe" href={"mailto:colliers.gis@colliers.com?subject=BOOM%20-%20Logo%20Request&Body=Retailer:%20" +
                    this.state.logoRequestRetailer + "%0A%0AType:%20" + this.state.logoRequestType + "%0A%0A" + this.state.additonalText + "Please leave any additional comments here."}>   
                    <span className="geocodeBtnSmall">Next (Go to Email)</span>
                  </a>
                  <iframe title="logo_request_iframe" name="hidden-iframe" style={{visibility:'hidden', position:'absolute'}}></iframe>
                </div>
              : null}
              <div className="flexRight padding-t10">
              <button className="geocodeBtnSmall" onClick={this.startOver}>Start Over</button>
              &nbsp;
              {this.state.step !== 'email' && this.props.logoRetailers ?
              <button className="geocodeBtnSmall" onClick={this.nextStep}>Next Step</button>
              : null }
              </div>
            </div>
          </Menu>
          : null
        }
      </div>
    );
  }
}

export default LogoSizeMenu;