import React from 'react';
import ReactDOMServer from 'react-dom/server';
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios';
import FileSaver from 'file-saver';
import SVGIconComponent from '../../SVGIconComponent/SVGIconComponent';
import { GetTextColor, isNullorEmptyArray,GetIconOffset,ResizeLogo } from '../../../utils/tools';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SaveInput from '../SavePanel/SaveInput';
import {getPointTextLabelOffset} from '../../../../src/utils/tools';
import translateObj from '../../../utils/translate';
class PrintPanel extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      prevMapCenter: this.props.mapObj.mapCenter,
      prevMapZoom: this.props.mapObj.mapZoom,
      error: false,
      errorMsg: translateObj.genericPrintError[this.props.translate||'eng'],
      fileName: this.props.mapObj.outputType,
      saveMessage: false,
      isDoneSaving: false,
      isPrinting: false,
      toggleCancel: false,
      printingStatusMessage: 'Getting Ready...',
      displayImageQ: false,
      imageQ: "3",
      ret3ErrorCount: 0
    };

    this.closePanel = this.closePanel.bind(this);
    this.printMap = this.printMap.bind(this);
    this.showSaveMessage = this.showSaveMessage.bind(this);
    this.cancelPrint = this.cancelPrint.bind(this);
  }

  componentDidUpdate() {
    if ((this.state.prevMapZoom !== this.props.mapObj.mapZoom || this.state.prevMapCenter !== this.props.mapObj.mapCenter) && this.state.isDoneSaving) {
      this.sendPrintInfoToAPI();
    }
  }

  componentDidMount() {
    const displayImageryQ = process.env.REACT_APP_DISPLAY_IMG_Q || 'false';
    //if(displayImageryQ === 'true' && (this.props.mapObj.mapOptionsBasemap.startsWith('hybrid')||this.props.mapObj.mapOptionsBasemap==='aerial'||this.props.mapObj.mapOptionsBasemap.startsWith('naip-')))
    if(displayImageryQ === 'true' && (this.props.mapObj.mapOptionsBasemap.startsWith('hybrid')||this.props.mapObj.mapOptionsBasemap.startsWith('naip-hybrid')))  
    {
      this.setState({displayImageQ:true})
    }
  }

  updateIsPrinting(val,bool) {
    this.props.updateIsPrinting(val, bool);
    this.setState({isPrinting: bool});
  }

  async sendPrintInfoToAPI() {
    this.updateIsPrinting('isPrinting', true);
    this.setState({
      prevMapCenter: this.props.mapObj.mapCenter,
      prevMapZoom: this.props.mapObj.mapZoom,
      isDoneSaving: false
    });

    const printID = await this.getPrintID();
    let count = 0;
    if (printID) { // && printID instanceof Blob) { //only move forward if we don't get an output png/pdf
      // let printObjNew = JSON.parse(JSON.stringify(this.props.mapObj));
      // printObjNew.message = "PrintID" + printID;
      // axios.post(process.env.REACT_APP_PRINTURL + "/error", printObjNew).then((result) => {
      //   //this._getPrintError();
      //   return false;
      // }).catch(error => {
      //   //this._getPrintError();
      //   return false;
      // });
      // const idFromBlob = await printID.text();
      // const idNumber = Number(idFromBlob);

      const idNumber = printID;

      this.interval = setInterval(() => {
        count++;
        if(count > 120){
          this.interval = clearInterval(this.interval);
          this._getPrintError();
        }
        this.retrievePrint(idNumber); 
      }, 5000);
    } else if (printID === 'All done') {
      return;
    } else {
      this._getPrintError();
    }
  }


  isSomething(objArray) {
    let isSomething = false;
    objArray.forEach(obj=>{
      let isnothing = false;
      if(obj===0)
        isnothing = true;
      else if(obj==='0')
        isnothing = true;
      else if(obj===null)
        isnothing = true;
      else if(obj===undefined)
        isnothing = true;
      else if(obj===false)
        isnothing = true;

      if(!isnothing)
        isSomething = true;

    })
    return isSomething;
  }

  getFillColor(demo) {
    const demoColors = this.props.mapObj.demographicColors || 'd3d9e9,a8b3d2,7c8cbc,5166a5,25408f';
    const demoRanges = this.props.mapObj.demographicRanges;
    const demoVar = this.props.mapObj.demographicVariable.toLowerCase();
    // demographicRangeSetting: null,
        // demographicColors: 'd3d9e9,a8b3d2,7c8cbc,5166a5,25408f', //ed1b34,f68b1f,ffd400,c59fc5,85bcb8',
        // demographicGeography: "TRACT",
        // demographicRanges: null,
        // demographicRangeSetting: "custom",
        // demographicStroke: '#cccdd5',
    const _dc = demoColors.split(',');
    const _dr = demoRanges.split(',');
    const value = demo.data[demoVar];

    let range = 4;

    if(value < _dr[0])
      range = 0
    else if(value  < _dr[1])
      range = 1
    else if(value < _dr[2])
      range = 2
    else if(value < _dr[3])
      range = 3
    ///const _dc = demoColors.split(',');
    return '#' + _dc[range];
  }

  getPrintID = async () => {
    this.state.printingStatusMessage = 'Getting Ready...';
    if(this.props.mapObj.outputType==='xlsx' && this.props.mapObj.mapPoints.length === 0 
      && this.props.mapObj.retailPoints.length === 0 && this.props.mapObj.customLayers.length === 0
      && this.props.mapObj.gapiPoints.length === 0){
      alert('No Point Data to Export');
      this.props.updateMapObj({
        isSaving: false,
        isPrinting: false
      });
      this.updateIsPrinting('isPrinting', false);
      return 'All done';
    }

    let printObj = JSON.parse(JSON.stringify(this.props.mapObj));
    let oldreps = this.props.mapObj.retailPoints;
    let oldpoints = this.props.mapObj.mapPoints;
    printObj.gapiPolygons = null;
    
    //const doesOverlap = L.latLngBounds(mapBounds).overlaps(myCircle.getBounds());

    let olddemos = this.props.mapObj.demoPrintShapes || [];

    let newdemos = olddemos.map(d=>{
      return{
        position: d.position,
        fill: this.getFillColor(d)
      }
    });

    printObj.demographics = newdemos;

    let newpoints = [];
    for(var i = 0;i < oldpoints.length; i++){
      let newpt = oldpoints[i];
      if(newpt.content.pointTextLabel){
         const ofset = getPointTextLabelOffset(newpt);
         newpt.content.labelOffset = ofset;
      }
      newpoints.push(newpt);
    }
    printObj.mapPoint = newpoints;

    let newrps = [];

    const realGapiPoints = this.props.mapObj.realGapiPoints;

    let newregps = [];
    if(!isNullorEmptyArray(realGapiPoints)){
      for(let i in realGapiPoints){
        const rgpl = realGapiPoints[i];

        let _html = ResizeLogo(ReactDOMServer.renderToString(<SVGIconComponent name={rgpl.iconName} size={"default"}/>),rgpl.logoSize);
        if(!rgpl.useLogo){
          _html = ReactDOMServer.renderToString(<SVGIconComponent color={rgpl.pointStyle?.pointColor||'#cccccc'} name={rgpl.pointStyle?.pointIconName||'circleIcon'} size={rgpl.pointStyle?.pointIconSize||"default"}/>);
        }

        const _iconOffset = GetIconOffset(_html);

        //const _icon = ReactDOMServer.renderToString(<SVGIconComponent name={realGapiPoints[i].iconName} size={"default"}/>);
        // const _icon = {L.divIcon({ className: 'custom-icon',
        //   html:ReactDOMServer.renderToString(<SVGIconComponent name={fgp.content.iconName} size={"default"}/>),
        //   iconAnchor:[18,18]})}
        newregps.push({
          points: rgpl.points,
          html: _html,
          iconOffset: _iconOffset,
          showInLegend: rgpl.showInLegend,
          layerTitle: rgpl.layerTitle,
          useLogo: rgpl.useLogo
        })
      }
    }

    const _gapiPoints = this.props.mapObj.gapiPoints;
    const gapiPointFilter = this.props.mapObj.gapiPointSettings[0]?.minDisplayValue || 10000;
    const gapiPoints = _gapiPoints.filter(p=>(p.content.Traffic1 || p.content.traffic_co) > gapiPointFilter);
    const gapiPointIcon = this.props.mapObj.gapiPointIcons[0]?.icon;

    printObj.gapiPoints = gapiPoints;
    printObj.gapiPointIcon = gapiPointIcon;
    
    const gapiAllLabels = this.props.mapObj.gapiPointSettings[0]?.displayLabels;
    const gapiCustomLabels = this.props.mapObj.gapiPointCustomLabels;
    const _gapiPointLabels = gapiAllLabels ? this.props.mapObj.gapiPointLabels : gapiCustomLabels;
    let gapiPointLabels = _gapiPointLabels.filter(p=>parseInt(p.content.count) > gapiPointFilter);
    const gapiPointHideLabels = this.props.mapObj.gapiPointHideLabels || [];
    gapiPointLabels = gapiPointLabels.filter(p=>gapiPointHideLabels.includes(p.content.id)===false);

    printObj.gapiPointLabels = gapiPointLabels;

    if(this.props.mapObj.outputType === 'xlsx' || this.props.mapObj.outputType === 'geojson'){
      printObj.retailPoints =  this.props.mapObj.retailPoints;
    }
    else {
      //check output type here for png pdf
      for (var i = 0; i < oldreps.length; i++){
        let rp = oldreps[i];
        newrps.push({
          chainid: rp.chainid,
          latitude: rp.latitude,
          longitude: rp.longitude,
          iconoffset: rp.iconOffset,
          leaderline: rp.leaderline,
          originallatitude: rp.originallatitude,
          originallongitude: rp.originallongitude
        });
      }

      let newGapiPolyLabels = this.props.mapObj.gapiPolygonLabels || [];
      //let newGapiPolygons = this.props.mapObj.gapiPolygons || [];
      if(this.props.mapObj.gapiPolygons.length > 0){
        
        if(this.props.mapObj.gapiPolygonSettings.displayNames.length > 0){
          //newGapiPolygons = newGapiPolygons.filter(p=>this.props.mapObj.gapiPolygonSettings.displayNames.includes(p.data.displayname));
          newGapiPolyLabels = newGapiPolyLabels.filter(l=>this.props.mapObj.gapiPolygonSettings.displayNames.includes(l.content.text));
        }
  
        //let gapiHighlightPolys = null;
        if(this.props.mapObj.gapiPolygonSettings?.highlightNames?.length > 0){
          //gapiHighlightPolys = newGapiPolygons.filter(p=>this.props.mapObj.gapiPolygonSettings.highlightNames.includes(p.data.displayname));
          //newGapiPolygons = newGapiPolygons.filter(p=>!this.props.mapObj.gapiPolygonSettings.highlightNames.includes(p.data.displayname));
          newGapiPolyLabels = newGapiPolyLabels.filter(l=>this.props.mapObj.gapiPolygonSettings.highlightNames.includes(l.content.text));
        }
    
        if(this.props.mapObj.gapiPolygonSettings.displayThem7){ 
          const them7Colors = this.props.mapObj.gapiPolygons.map(gp=>{return {displayname:gp.displayname,them7Color:gp.fillColor}});
          printObj.gapiThem7Colors = them7Colors;
        }
      }
      else {
        printObj.gapiPolygonSettings = null;
      }


      
      // let newGapiShapes = [];
      // if(this.props.mapObj.gapPrintShapes !== undefined)
      // {
      //   newGapiShapes = this.props.mapObj.gapPrintShapes; //printShapes.filter(ps => ps.id.startsWith('gapipoly_'));
      // }

      // newGapiShapes = newGapiShapes.map(p=>{
      //   return {
      //     id: p.id,
      //     fill: this.props.mapObj.gapiPolygonSettings.polygonStyle.fill,
      //     opacity: this.props.mapObj.gapiPolygonSettings.polygonStyle.opacity,
      //     position: p.position,
      //     stroke: this.props.mapObj.gapiPolygonSettings.polygonStyle.stroke,
      //     strokeOpacity: this.props.mapObj.gapiPolygonSettings.polygonStyle.strokeOpacity,
      //     type: 'polygon',
      //     width: this.props.mapObj.gapiPolygonSettings.polygonStyle.width
      //   }
      // });
      //const them7 = this.props.mapObj.gapiPolygonSettings.displayThem7 || false;
      // newGapiPolygons = newGapiPolygons.map(p=>{
      //   const fillcolor = them7 ? p.data.color : this.props.mapObj.gapiPolygonSettings.polygonStyle.fill;
      //     return {
      //       id: p.id,
      //       fill: fillcolor, //this.props.mapObj.gapiPolygonSettings.polygonStyle.fill,
      //       opacity: this.props.mapObj.gapiPolygonSettings.polygonStyle.opacity,
      //       position: [p.position],
      //       stroke: this.props.mapObj.gapiPolygonSettings.polygonStyle.stroke,
      //       strokeOpacity: this.props.mapObj.gapiPolygonSettings.polygonStyle.strokeOpacity,
      //       type: 'polygon',
      //       width: this.props.mapObj.gapiPolygonSettings.polygonStyle.width
      //     }
      // });
      // if(gapiHighlightPolys){
      //   gapiHighlightPolys.forEach(p => {
      //     newGapiPolygons.push({
      //       id: p.id,
      //       fill: this.props.mapObj.gapiPolygonSettings.highlightPolygonStyle.fill,
      //       opacity: this.props.mapObj.gapiPolygonSettings.highlightPolygonStyle.opacity,
      //       position: [p.position],
      //       stroke: this.props.mapObj.gapiPolygonSettings.highlightPolygonStyle.stroke,
      //       strokeOpacity: this.props.mapObj.gapiPolygonSettings.highlightPolygonStyle.strokeOpacity,
      //       type: 'polygon',
      //       width: this.props.mapObj.gapiPolygonSettings.highlightPolygonStyle.width
      //     });
      //   });

      // }

      //printObj.gapiPolygons = newGapiPolygons;
      printObj.gapiPolygonLabels = newGapiPolyLabels;
      //printObj.gapiPrintShapes = newGapiShapes;
      printObj.retailPoints = newrps;
      printObj.realGapiPoints = newregps;
    }

    printObj.imageQ = this.state.imageQ;

    const stuff = [];
    const visgroups = printObj.mapGroups.filter(g=>g.groupInLegend===true);
    const vis_tc = printObj.gapiPointSettings[0]?.showInLegend;
    //const _realGapiPoints =  this.props.realGapiPoints || [];
    const realGapiLegend = realGapiPoints === undefined || realGapiPoints === null ? [] : realGapiPoints.filter(r=>r.showInLegend===true);

    stuff.push(printObj.mapPoints.length===0 ? 0 : visgroups.length);
    stuff.push(printObj.gapiPoints.length === 0 ? 0 : vis_tc);
    stuff.push(printObj.demographicLength);
    stuff.push(printObj.demographicLegend);
    stuff.push(printObj.earthquakeLayer);
    stuff.push(printObj.showShapeLegend && printObj.shapes.length>0);
    stuff.push(printObj.gapiPolygonSettings?.selectedGapiPolygon && printObj.gapiPolygonSettings?.displayInLegend);
    stuff.push(realGapiLegend.length);
    const isSomething = this.isSomething(stuff);
    if (!isSomething){
      printObj.legend[0].visible = false;
    }
    //printObj.gapiPolygons = null;
    // let _shapeLegend = this.props.polygonLegend || [];
    // let polygonLegend = _shapeLegend.filter(s=>s.styles!=null);


    //this.state.printingStatusMessage = 'Processing...';
    this.setState({printingStatusMessage:'Processing...' });
    const printID = await axios({
      method: 'post',
      url: process.env.REACT_APP_PRINTURL + "/startMap",
      data: printObj,
      headers: this.props.headers//,
     // responseType: 'blob'
    }).then((result) => {
      if (result.status === 200) {
        //console.log(result);
        if(result.headers["content-type"]==="text/plain; charset=utf-8"){
         // console.log('hi');
          //result.data.text().then(t=>{console.log(t)}); we could try to read this here
          //this._getPrintError(translateObj.serverBusyError[this.props.translate||'eng']);
          return result.data;
        }

        //"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        if (result.headers["content-type"] === "application/pdf" || result.headers["content-type"] === "image/png" 
          || result.headers["content-type"] === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          || result.headers["content-type"] === "application/json") {
          //this.state.printingStatusMessage = 'Downloading...';
          this.setState({printingStatusMessage:'Downloading...' });
          this.props.updateMapObj({
            isSaving: false,
            isPrinting: false
          });
          this.updateIsPrinting('isPrinting', false);

          const dateObj = new Date();

          //const formattedDate = new Intl.DateTimeFormat('en-US').format(dateObj);

          let _fileName = 'Boom - ' + dateObj.toLocaleString().replaceAll('/','-').replaceAll(':','-').replaceAll(',','');
          if(this.props.mapObj.mapId!==null){
            _fileName = 'Boom - MapId -' + this.props.mapObj.mapId;
          }
          FileSaver.saveAs(result.data, _fileName + '.' + this.state.fileName);
          return 'All done';
        } else {
          return result.data;
        }
      } else {
        let printObjNew = JSON.parse(JSON.stringify(this.props.mapObj));
        printObjNew.message = translateObj.startMapError[this.props.translate||'eng'];
        axios.post(process.env.REACT_APP_PRINTURL + "/error", printObjNew, {headers: this.props.headers}).then((result) => {
          this._getPrintError();
          return false;
        }).catch(error => {
          this._getPrintError();
          return false;
        });
        //this._getPrintError();
        //return false;
      }
    }).catch((error) => {
      let errorMessage = JSON.parse(JSON.stringify(error));
      //this.props.mapObj
      errorMessage.message = 'st|' + error.message;
      errorMessage.userId = this.props.mapObj.userId;
      errorMessage.mapId = this.props.mapObj.mapId;

      axios.post(process.env.REACT_APP_PRINTURL + "/error", errorMessage, {headers: this.props.headers}).then((result) => {
        this._getPrintError();
        return false;
      }).catch(error => {
        this._getPrintError();
        return false;
      });
      //this._getPrintError();
      //return false;
    });
    //console.log(printID,'printID');
    return printID;
  }

  _getPrintError(msg) {
    console.log(msg,'Print Error');
    console.log(this.props.mapObj);
    const errorobj = {
      mapobj: this.props.mapObj,
      error: msg
    }
    axios.post(process.env.REACT_APP_APIURL + "/errormap", errorobj, { headers: this.props.headers });


    if(msg){
      this.setState({
        error: true,
        errorMsg: msg
      });
    }
    else{
      this.setState({
        error: true
      });
    }

    this.props.updateMapObj({
      isSaving: false,
      isPrinting: false
    });
    this.updateIsPrinting('isPrinting', false);
  }

  retrievePrint = async (printID) => {
    if (printID) {
      const retrievedMap = await axios({
        method: 'get',
        url: process.env.REACT_APP_RETURL + "/retrieveMap?outputid=" + printID,
        //headers: this.props.headers,
        responseType: 'blob',
        timeout: 300000
      }).then((result) => {
        if (result) {
          if (result.status < 399) {
            if (result.data.type && !result.data.type.includes("text/plain") && this.interval) {
              this.setState({printingStatusMessage:'Downloading...' });
              this.interval = clearInterval(this.interval);
              this.props.updateMapObj({
                isSaving: false,
                isPrinting: false
              });
              this.updateIsPrinting('isPrinting', false);
              const dateObj = new Date();
              let _fileName = 'Boom - ' + dateObj.toLocaleString().replaceAll('/','-').replaceAll(':','-').replaceAll(',','');
              if(this.props.mapObj.mapId!==null){
                _fileName = 'Boom - MapId -' + this.props.mapObj.mapId;
              }
              FileSaver.saveAs(result.data, _fileName);// + this.state.fileName);
            }
            else {
              const _text = (result?.data)?.text().then((t)=>{
                if(t==='Error'){
                  this.interval = clearInterval(this.interval);
                  this._getPrintError();
                }
                if(t==='Timeout'){
                  this.interval = clearInterval(this.interval);
                  this._getPrintError();
                }
                if(t==='Already Done....'){
                  this.setState({printingStatusMessage:'Downloading...' });
                }
              });
            }
          } else {
            // let printObjNew = JSON.parse(JSON.stringify(this.props.mapObj));
            // printObjNew.message = "Error in retrieveMap. Result succeeded, but status not OK.";
            //console.log(error);
            let errorMessage = {}; //JSON.parse(JSON.stringify(error));
            //this.props.mapObj
            errorMessage.message = 'ret1|' + result.status;
            errorMessage.userId = this.props.mapObj.userId;
            errorMessage.mapId = this.props.mapObj.mapId;

            axios.post(process.env.REACT_APP_PRINTURL + "/error", errorMessage).then((result) => {
              this.interval = clearInterval(this.interval);
              this._getPrintError();
            }).catch(error => {
              this.interval = clearInterval(this.interval);
              this._getPrintError();
            });
          }
        } else {
          //let printObjNew = JSON.parse(JSON.stringify(this.props.mapObj));
          //printObjNew.message = "Error in retrieveMap. Result succeeded, but status not OK.";

          let errorMessage = {}; //JSON.parse(JSON.stringify(error));
          //this.props.mapObj
          errorMessage.message = 'ret2|' + result.status;
          errorMessage.userId = this.props.mapObj.userId;
          errorMessage.mapId = this.props.mapObj.mapId;

          axios.post(process.env.REACT_APP_PRINTURL + "/error", errorMessage).then((result) => {
            this.interval = clearInterval(this.interval);
            this._getPrintError();
          }).catch(error => {
            this.interval = clearInterval(this.interval);
            this._getPrintError();
          });
          //this.interval = clearInterval(this.interval);
          //this._getPrintError();
        }
        return result;
      }).catch((error) => {
        //send error message as message, mapid as mapid
        console.log(error);
        //let printObjNew = JSON.parse(JSON.stringify(this.props.mapObj));
        //printObjNew.message = "Error in retrieveMap. No result.";
        //let errorMessage = {}; //JSON.parse(JSON.stringify(error));
        //this.props.mapObj
        //errorMessage.message = 'ret3' + error.message;

        let errorCount = this.state.ret3ErrorCount;
        errorCount++;
        this.setState({ret3ErrorCount:errorCount});

        let errorMessage = JSON.parse(JSON.stringify(error));
        errorMessage.message = 'ret3|' + errorCount + '|' + error.message;
        errorMessage.userId = this.props.mapObj.userId;
        errorMessage.mapId = this.props.mapObj.mapId;

        if(errorCount>5){
          this.setState({ret3ErrorCount:0});
          this.interval = clearInterval(this.interval);
          this._getPrintError();
        }

        axios.post(process.env.REACT_APP_PRINTURL + "/error", errorMessage).then((result) => {
          // this.interval = clearInterval(this.interval);
          // this._getPrintError();
        }).catch(error => {
          // this.interval = clearInterval(this.interval);
          // this._getPrintError();
        });


        //this.interval = clearInterval(this.interval);
        //this._getPrintError();
      });
      return retrievedMap;
    } else {
      let printObjNew = JSON.parse(JSON.stringify(this.props.mapObj));
      printObjNew.message = "No print id.";
      axios.post(process.env.REACT_APP_PRINTURL + "/error", printObjNew).then((result) => {
        this.interval = clearInterval(this.interval);
        this._getPrintError();
        return false
      }).catch(error => {
        this.interval = clearInterval(this.interval);
        this._getPrintError();
        return false;
      });
      //this.interval = clearInterval(this.interval);
      //this._getPrintError();
      //return false;
    }
  }

  showSaveMessage() {
    this.setState({
      saveMessage: true
    });
  }

  printMap() {
    //get group icons
    const newGroups = this._getGroupIcons();
    //remove blank shields
    const newShields = this._removeBlankShields();

    this.setState({
      error: false,
      isDoneSaving: true,
      saveMessage: false
    });

    this.props.updateMapObj({
      mapGroups: newGroups,
      shields: newShields,
      isSaving: true,
      isPrinting: true
    });
  }

  _getGroupIcons() {
    const groupsArrayClone = Array.from(this.props.mapObj.mapGroups);
    for (const group of groupsArrayClone) {
      //if group uses group style
      const textColor = GetTextColor(groupsArrayClone, group, true);

      let icon = ReactDOMServer.renderToString(<SVGIconComponent outline={group.groupIconColor === 'transparent' ? 'transparent' : null} color={group.groupIconColor} label={0} name={group.groupIconName} textFill={textColor} size={group.groupIconSize} />);
      group.icon = icon;
    }

    return groupsArrayClone;
  }

  _removeBlankShields() {
    const shieldsArrayClone = Array.from(this.props.mapObj.shields);
    let newShields = [];
    for (const shield of shieldsArrayClone) {
      const svgString = shield.content.icon.options.html;
      const split1 = svgString.split('data-reactroot="">');
      if (split1[1] !== '</div>') {
        newShields.push(shield);
      }
    }

    return newShields;
  }

  closePanel() {
    if(this.state.isPrinting){
      this.setState({toggleCancel: true});
    }
    else {
      this.props.togglePanel('print');
    }
  }

  cancelPrint(){
    this.interval = clearInterval(this.interval);
    this.setState({toggleCancel: false});
    this.updateIsPrinting('isPrinting', false);
    this.props.updateMapObj({
      isSaving: false,
      isPrinting: false
    });
    this.props.togglePanel('print');
  }


  outputQualChange(e) {
    this.setState({
      imageQ: e.target.value
    });
  }

  outputTypeChange(e) {
    this.props.updateMapObj({ outputType: e.target.value });
    this.setState({
      fileName: e.target.value
    });
    // if (e.target.value === 'pdf') {
    //   this.setState({
    //     fileName: 'pdf'
    //   });
    // } else if (e.target.value === 'png'){
    //   this.setState({
    //     fileName: 'png'
    //   });
    // }
    // else if (e.target.value === 'xlsx'){
    //   this.setState({
    //     fileName: 'xlsx'
    //   });
    // }
    // else
  }

  render() {
    return (
      <div className="sidePanelStyle">
        <h2 className="panel-title">
          <span className="panel-icon-span">
            <svg id="printIconSmall" xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 99.24 90">
              <path d="M302.31,129.81a8,8,0,0,0-8-8H246.2a8,8,0,0,0-8,8v21.34a.19.19,0,0,0,.18.19h63.84a.1.1,0,0,0,.09-.1Z" transform="translate(-220.63 -120.56)" fill="none" stroke="#0c9ed9" strokeMiterlimit="5" strokeWidth="4" />
              <rect x="17.56" y="58.9" width="64.12" height="28.12" fill="none" stroke="#0c9ed9" strokeMiterlimit="5" strokeWidth="4" />
              <polygon points="1.25 30.78 1.25 71.09 17.56 71.09 17.56 58.9 81.68 58.9 81.68 71.09 97.99 71.09 97.99 30.78 1.25 30.78" fill="none" stroke="#0c9ed9" strokeMiterlimit="5" strokeWidth="4" />
              <line x1="30.08" y1="68.74" x2="69.17" y2="68.74" fill="none" stroke="#0c9ed9" strokeMiterlimit="5" strokeWidth="4" />
              <line x1="30.08" y1="77.48" x2="69.17" y2="77.48" fill="none" stroke="#0c9ed9" strokeMiterlimit="5" strokeWidth="4" />
            </svg>
          </span>
              {translateObj.printMapTitle[this.props.translate||'eng']}
              <span className="panel-close closeStyle"><CloseIcon onClick={this.closePanel} fontSize="small" /></span>
        </h2>
        <div className="panel-body">

        {
            this.state.toggleCancel ? 
            <div>
            <div>Are you sure you want to cancel export?</div>
              <div className="flex margin-t15">
                <button className="geocodeBtn" onClick={this.cancelPrint}>Cancel</button>
              </div>
            </div>
            : null
            }

          <div className={this.props.mapObj.isSaving ? 'loaderShow' : 'loaderHide'}>
            <div className="panel-loader"></div>
            <div>{this.state.printingStatusMessage}</div>
          </div>
          <div className={this.props.mapObj.isSaving ? 'loaderHide' : 'loaderShow'}>
            <div>
              <div>{translateObj.outputType[this.props.translate||'eng']}</div>
              <div style={{ paddingLeft: '10px' }}>
                <RadioGroup aria-label="output type" name="outputRadioGrp" value={this.props.mapObj.outputType} onChange={(e) => { this.outputTypeChange(e) }}>
                  {this.props.mapObj.mapOptionsLayout !== 'none' && this.props.mapObj.mapOptionsLayout !== 'custom' ?
                    <FormControlLabel value="pdf" control={<Radio color="default" disableRipple={true} size="small" />} label="PDF" />
                    : null
                  }
                  <FormControlLabel value="png" control={<Radio color="default" disableRipple={true} size="small" />} label="PNG" />
                  <FormControlLabel value="xlsx" control={<Radio color="default" disableRipple={true} size="small" />} label={translateObj.excelPrintOption[this.props.translate||'eng']} />
                  <FormControlLabel value="geojson" control={<Radio color="default" disableRipple={true} size="small" />} label="GeoJson" />
                </RadioGroup>
              </div>
              {this.state.displayImageQ ?
              <div>
                <div>Imagery Quality:</div>
                <div style={{ paddingLeft: '10px' }}>
                  <RadioGroup aria-label="output quaility" name="imageQRadioGrp" value={this.state.imageQ} onChange={(e) => { this.outputQualChange(e) }}>
                    <FormControlLabel value="3" control={<Radio color="default" disableRipple={true} size="small" />} label="Best" />
                    <FormControlLabel value="2" control={<Radio color="default" disableRipple={true} size="small" />} label="Better" />
                    <FormControlLabel value="1" control={<Radio color="default" disableRipple={true} size="small" />} label="Screen" />
                  </RadioGroup>
                </div>
              </div>
              : null
              }
            </div>
            <div>{translateObj.doYouNeedToSave[this.props.translate||'eng']}</div>
            {this.state.saveMessage ?
              <SaveInput
                mapObj={this.props.mapObj}
                updateMapObj={this.props.updateMapObj}
                headers={this.props.headers}
                updateSidebarObj={this.props.updateSidebarObj}
                updateIsSaving={this.props.updateIsPrinting}
                closePanel={this.printMap}
                translate={this.props.translate}
              />
              :
              <div className="flex margin-t15">
                <button className="geocodeBtn" onClick={this.showSaveMessage}>
                  {translateObj.yesBtn[this.props.translate||'eng']}
                </button>
                <button className="geocodeBtn" onClick={this.printMap}>
                  {translateObj.noPrintMapBtn[this.props.translate||'eng']}
                </button>
              </div>
            }
            {this.state.error ?
              <div className="errorMsgDiv">{this.state.errorMsg}</div>
              : null
            }
          </div>
        </div>
      </div>
    );
  }
}

export default PrintPanel;