import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import SVGIconComponent from '../../SVGIconComponent/SVGIconComponent';
import SaveInput from '../SavePanel/SaveInput';
import { GenerateRandomId } from '../../../utils/tools';
import translateObj from '../../../utils/translate';

class StartOverPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      saveMessage: false,
      loadingStarted: false
    };

    this.showSaveMessage = this.showSaveMessage.bind(this);
    this.startOver = this.startOver.bind(this);
    this.closePanel = this.closePanel.bind(this);
  }

  showSaveMessage() {
    this.setState({
      saveMessage: true
    });
  }

  startOver() {
    const newIdNum = GenerateRandomId();
    const newLayerIdNum = GenerateRandomId();
    const customDims = this.props.mapObj.country === 'US' ? [4, 4] : [3.937, 3.937];

    this.setState({
      loadingStarted: true
    });

    this.props.updateMapObj({
      isLoading: true
    });

    this.props.updateSidebarObj({ mapHistoryData: [] });

    let defaultPointColor = '#0c9ed9';
    if (this.props.mapObj?.country === 'JPN') {
      defaultPointColor = '#25408f';
    }

    this.props.updateMapObj({
      mapId: null,
      //mapZoom: 14,
      mapOptionsTitle: '',
      mapOptionsProject: '',
      mapOptionsBasemap: 'colliers-grey',
      mapOptionsLayout: 'landscape',
      mapGroups: [{
        id: newIdNum,
        order: 1,
        name: 'Layer 1',
        useGroupStyle: false,
        groupIconName: 'circleIcon',
        groupIconColor: defaultPointColor,
        groupTextColor: '#ffffff',
        groupIconSize: 'default',
        startNumber: 1,
        visible: true,
        nameInLegend: true,
        pointsInLegend: true,
        groupInLegend: true,
        icon: '',
        showLegendData: false,
        useCustomLabel: false,
        showLegendData: false,
        groupLeaderLine: false,
        groupLeaderColor: '#ffffff',
        groupLeaderWidth: 3,
        groupLeaderOpacity: 1,
        groupLeaderAnchor: false,
        groupTextLabel: false,
        groupTextLabelStroke: '#4a4a4d',
        groupTextLabelColor: '#ffffff',
        groupTextLabelFontSize: 14,
        groupTextLabelOpacity: 1,
        groupTextLabelFont: 'Open Sans',
        groupTextLabelWeight: true,
        groupTextLabelPosition: 'upper-right',
        groupTextLabelField: 'name'

      }],
      mapPoints: [],
      currentGroup: newIdNum,
      currentPoint: null,
      isStyling: false,
      isStylingFromAdd: false,
      isStylingFromRing: false,
      isSaving: false,
      popupOpen: false,
      isEditingRing: false,
      isEditingRingFromAdd: false,
      geocodingSuccess: false,
      overlapDetected: false,
      badGeocodes: false,
      legendOverlapDetected: false,
      checkOverlap: false,
      maskBounds: null,
      rings: [],
      driveTimes: [],
      legend: [{
        height: 42,
        width: 85,
        visible: false,
        scale: 1,
        columns: 1,
        position: ['right', 'top'],
        northArrowVisible: true,
        northArrowPosition: ['left', 'bottom']
      }],
      outputType: 'png',
      mapLoaded: true,
      shapes: [],
      customLayoutDimensions: customDims,
      retailPoints: [],
      selectedCategory: [],
      selectedRetailer: [],
      logoSize: 100,
      labels: [],
      shields: [],
      tooManyLogos: false,
      demographics: [],
      hasDemographics: false,
      vtDemographicSettings:{
        demographicVariable: null,
        demographicColors: 'd3d9e9,a8b3d2,7c8cbc,5166a5,25408f', //ed1b34,f68b1f,ffd400,c59fc5,85bcb8',
        demographicGeography: "census_tracts",
        demographicRanges: null,
        demographicRangeSetting: "default",
        demogrpahicRangeRound: false,
        demographicStroke: 'transparent',
        demographicStrokeOpacity: 1,
        demographicWidth: 2,
        demographicFillOpacity: 0.5
      },
      demographicBounds: null,
      updateBasemap: true,
      customLayers: [],
      gettingLayers: true,
      labelLayer: null,
      labelRoad: null,
      startOver: true,
      layerlabels: [],
      scaleLocation: 'bottom_right',
      retailerBounds: null,
      retailerLeaderLine: false,
      retailerLeaderLineAnchor: false,
      retailerLeaderColor: '#ffffff',
      retailerLeaderOpacity: 1,
      retailerLeaderWidth: 3,
      pointLeaderColor: '#ffffff',
      pointLeaderWidth: 3,
      loadingBar: false,
      loadingMessage: '',
      errorBar: false,
      errorMessage: '',
      noPointsInLegend: false,
      zoomToExtent: null,
      verifyPoint: false,
      closePopup: false,
      infoBar: false,
      infoMessage: '',
      secyGeoName: 'State',
      loadingPanel: false,
      loadingPanelMessage: '',
      updateMapBounds: false,
      gapiPolygons: [],
      gapiPolygonLabels: [],
      gapiPolygonSettings:{
        displayThem7: false,
        displayInLegend: true,
        resultNames: [],
        displayNames: [],
        selectedGapiPolygon: false,
        selectedGapiPolygonLabel: '',
        gettingGapiPolygons: false,
        polygonStyle: {
          fill: '#0c9ed9',
          opacity: 0.5,
          stroke: '#ffffff',
          strokeOpacity: 1,
          width: 3
        },
        displayLabels: false,
        labelStyle: {
          mapAddLabelStroke: '#4a4a4d',
          mapAddLabelColor: '#ffffff',
          mapAddLabelFontSize: 12,
          mapAddLabelOpacity: 1,
          mapAddLabelFont: 'Open Sans',
          mapAddLabelWeight: true,
        }
      },
      gapiPolygonBounds: null,
      updateGapiLabels: null,
      loadedGapiLabels: null,
      updateGapiPolygonFeatureId: null,
      demoPanelOpen: false,
      demoPanelRedraw: false,
      maskScale: null,
      gapiPointsLoading: false,
      gapiPointsErrorMessage: null,
      gapiPoints: [],
      gapiPointLabels: [],
      gapiPointCustomLabels: [],
      gapiPointHideLabels: [],
      gapiPointSettings: [{
        displayLabels: false,
        minValue: 10000,
        minDisplayValue: 10000,
        labelStyle: {
          labelStroke: '#4a4a4d',
          labelColor: '#ffffff',
          labelFontSize: 12,
          labelOpacity: 1,
          labelFont: 'Open Sans',
          labelWeight: true,
          labelOpacity: 1
        },
        pointStyle:{
          pointColor: "#0c9ed9",
          pointIconName: "circleIcon",
          pointIconSize: "default",
          pointTextColor: "#ffffff"
        },
        showInLegend: true
      }],
      gapiPointIcons: [],
      gapiPointBounds: null,
      realGapiPoints: null,
      realGapiPointBounds: null,
      loadedCustomGapiLabels: null,
      addGapiPoints: null,
      showShapeLegend: true,
      shapeLegend: [
        {
          layerId: newLayerIdNum,
          name: "Custom Shapes",
          displayName: true
        }
      ],
      currentShapeLayer: newLayerIdNum,
      mapAddPtRing: null,
      clearVTPolys: true,
      clearDemos: true
    });

    setTimeout(() => {
      this.props.updateMapObj({
        isLoading: false
      });
    }, 1000);

    this.closePanel();
  }

  closePanel() {
    this.props.togglePanel('startOver');
  }

  render() {
    return (
      <div className="sidePanelStyle">
        <h2 className="panel-title">
          <span className="panel-icon-span">
            <SVGIconComponent size={'16'} name={'startOverBtnIcon'} color={'#0c9ed9'} />
          </span>
          {translateObj.startOverTitle[this.props.translate]}
          <span className="panel-close closeStyle"><CloseIcon onClick={this.closePanel} fontSize="small" /></span>
        </h2>
        <div className="panel-body">
          <div className={this.props.mapObj.isSaving ? 'loaderShow' : 'loaderHide'}>
            <div className="panel-loader"></div>
          </div>
          <div className={this.props.mapObj.isSaving ? 'loaderHide' : 'loaderShow'}>
            <div>{translateObj.doYouNeedToSave[this.props.translate]}</div>
            {this.state.saveMessage && !this.state.loadingStarted ?
              <SaveInput
                mapObj={this.props.mapObj}
                updateMapObj={this.props.updateMapObj}
                headers={this.props.headers}
                updateSidebarObj={this.props.updateSidebarObj}
                updateIsSaving={this.props.updateIsSaving}
                closePanel={this.startOver}
              />
              :
              <div className="flex margin-t15">
                <button className="geocodeBtn" onClick={this.showSaveMessage}>{translateObj.saveBtnLowerCase[this.props.translate]}</button>
                <button className="geocodeBtn" onClick={this.startOver}>{translateObj.noStartOverBtn[this.props.translate]}</button>
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default StartOverPanel;