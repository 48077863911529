import React from 'react';
import TextField from '@material-ui/core/TextField';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';

const divStyle = (isDragging, draggableStyle) => ({
    border: '1px solid #d6d7dd',
    borderRadius: '2px',
    padding: '3px 5px',
    marginBottom: '8px',
    marginTop: '4px',
    background: '#ececec',
    display: 'flex',
    alignItems: 'center',
    ...draggableStyle
  });

class ShapeLayerComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          editing: false
        }
        this.editName = this.editName.bind(this);
        this.saveName = this.saveName.bind(this);
        this.toggleNameVisibility = this.toggleNameVisibility.bind(this);
    }

    editName() {
        this.state.editing = true;
        this.props.updateShapeEditing(true)
    }

    saveName(layerix,val){
        this.state.editing=false;
        this.props.updateShapeEditing(false);
        this.props.updateShapeText(null,layerix,val);
    }

    toggleNameVisibility(layerix){
        this.props.toggleNameVisibility(layerix)
    }

render(){
    return(
        <div  
        {...this.props.provided.droppableProps}  
        ref={this.props.provided.innerRef}  
        {...this.props.provided.draggableProps}
        {...this.props.provided.dragHandleProps}
        style={divStyle(this.props.snapshot.isDragging, this.props.provided.draggableProps.style)}
        > 
        {this.state.editing ?
            <TextField
            defaultValue={this.props.name}
            size="small"
            variant="outlined"
            onKeyPress={(e) => {
                if (e.key === 'Enter') {
                this.saveName(this.props.layerIndex, e.target.value)
                }
            }}
            onBlur={(e) => {
                this.saveName(this.props.layerIndex, e.target.value)
            }}
            />
        : 
            <div className="shapeNameDiv">
                <div className="groupNameCompDiv">
            <div>{this.props.name}</div>
            <button className="geocodeBtnSmall margin-l5" onClick={this.editName}>{"Edit"}</button>
            </div>
            <div style={{ marginLeft: 'auto' }} onClick={() => this.props.deleteShapeLayer(this.props.layerIndex)}>
                <Tooltip title="Delete Layer and Shapes" placement="top">
                    <DeleteIcon  style={{ fontSize: 20, cursor: 'pointer' }}/>
                </Tooltip>
            </div>
            <div onClick={() => this.toggleNameVisibility(this.props.layerIndex)}>
                <Tooltip title="Toggle Layer Name Visibility" placement="top">
                    {this.props.visible ? 
                    <VisibilityIcon  style={{ fontSize: 20, cursor: 'pointer' }}/>
                    :
                    <VisibilityOffIcon  style={{ fontSize: 20, cursor: 'pointer' }}/>
                    }
                </Tooltip>
            </div>

            </div>
        }
    </div>
    )
    }
}

export default ShapeLayerComponent;