import React, { Component } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReleaseNotes1Pt2 from './ReleaseNotes1Pt2';
import ReleaseNotes2Pt0 from './ReleaseNotes2Pt0';
import ReleaseNotes2Pt1 from './ReleaseNotes2Pt1';
import ReleaseNotes2Pt2 from './ReleaseNotes2Pt2';
import ReleaseNotes3Pt0 from './ReleaseNotes3Pt0';
import ReleaseNotes3Pt1 from './ReleaseNotes3Pt1';
import ReleaseNotes3Pt2 from './ReleaseNotes3pt2';

export default class MethodologyComponent extends Component {
  render() {
    const methodTitle = this.props.methodTitle || "Title Description";
    const id = 'panel2' + this.props.id + '-header' || "id";
    const aria = 'panel2' + this.props.id + '-content' || "content";
    // const description = this.props.description || "description";
    // const url = this.props.url || "#";
    const buttonText = this.props.buttonText || 'View Document';

    return (
      <Accordion className="innerExpansionPanel" square defaultExpanded={this.props.expanded}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={aria} id={id}>
          <span className="blueHeaderTitle">{methodTitle}</span>
        </AccordionSummary>
        <AccordionDetails>
          {buttonText === 'release1_2' ?
            <ReleaseNotes1Pt2 />
            :
            null
          }
          {buttonText === 'release2_0' ?
            <ReleaseNotes2Pt0 />
            :
            null
          }
          {buttonText === 'release2_1' ?
            <ReleaseNotes2Pt1 />
            :
            null
          }
         {buttonText === 'release2_2' ?
            <ReleaseNotes2Pt2 />
            :
            null
          }
          {buttonText === 'release3_0' ?
            <ReleaseNotes3Pt0 />
            :
            null
          }
          {buttonText === 'release3_1' ?
            <ReleaseNotes3Pt1 />
            :
            null
          }
          {buttonText === 'release3_2' ?
            <ReleaseNotes3Pt2 />
            :
            null
          }
        </AccordionDetails>
      </Accordion>
    );
  }
}